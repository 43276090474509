import React from "react";
import {
  BsArrowUpRight,
  BsRocketTakeoff,
  BsRocketTakeoffFill,
} from "react-icons/bs";
import { HiOutlineArrowTrendingDown, HiOutlineComputerDesktop } from "react-icons/hi2";
import { IoCutOutline } from "react-icons/io5";
import { LiaCalendarAltSolid, LiaClock } from "react-icons/lia";

function DetailsWidge({mostUsedPrestation, reservationCount, selectedYear, freeHours, occupationPercentage}) {
  return (
    <div className="flex md:flex-row flex-col flex-wrap  w-full gap-3 mt-4">
      <div className=" flex flex-row justify-center items-center flex-[1_0_0] px-2 py-5 text-[#FFF] bg-[#2D2E2F] md:w-1/3 w-full">
        <div className="flex h-[80px] items-center gap-[15px]  py-0  self-stretch p-2 w-full">
          <div className="flex items-center self-stretch pr-0">
            <IoCutOutline size={30} />
          </div>
          <div className=" flex flex-col items-start flex-[1_0_0] whitespace-nowrap">
            <p className=" text-xs not-italic font-normal leading-[normal] font-roboto ">
              Prestations la plus réalisée
            </p>
            <p className="font-syne text-white   xl:text-2xl  lg:text-lg text-base not-italic font-bold leading-[normal]">
              {mostUsedPrestation?.prestation.name}
            </p>
            <p className="text-[#B5B5B5] text-xs not-italic font-normal leading-[normal] font-roboto">
              {mostUsedPrestation?.prestation.category}
            </p>
          </div>
          <div className=" flex justify-center items-center gap-1   py-0">
            <BsArrowUpRight size={30} className="mr-2" />
            <span className=" text-[#F6F0E8] text-sm not-italic font-bold leading-[normal] font-syne">
              {mostUsedPrestation?.count}
            </span>
          </div>
        </div>
      </div>

      <div className=" flex flex-row justify-center items-center flex-[1_0_0] px-2 py-5 text-[#FFF] bg-[#2D2E2F] md:w-1/3 w-full">
        <div className="flex h-[80px] items-center gap-[15px]  py-0  self-stretch p-2 w-full">
          <div className="flex items-center self-stretch pr-0">
            <LiaCalendarAltSolid size={30} />
          </div>
          <div className=" flex flex-col items-start flex-[1_0_0] whitespace-nowrap">
            <p className=" text-xs not-italic font-normal leading-[normal] font-roboto ">
              Total rendez-vous
            </p>
            <p className="font-syne text-white md:text-xl   lg:text-2xl text-base not-italic font-bold leading-[normal]">
              {reservationCount}
            </p>
            <p className="text-[#B5B5B5] text-xs not-italic font-normal leading-[normal] font-roboto">
              {selectedYear}
            </p>
          </div>
          <div className=" flex justify-center items-center gap-1 self-stretch  py-0">
            <HiOutlineComputerDesktop size={30} className="mr-2" />
            <span className=" text-[#F6F0E8] text-sm not-italic font-bold leading-[normal] font-syne">
              {reservationCount} <br></br>Rdv en ligne
            </span>
          </div>
        </div>
      </div>

      <div className=" flex flex-row justify-center items-center flex-[1_0_0] px-2 py-5 text-[#FFF] bg-[#2D2E2F] md:w-1/3 w-full">
        <div className="flex h-[80px] items-center gap-[15px]  py-0  self-stretch p-2 w-full">
          <div className="flex items-center self-stretch pr-0">
            <LiaClock size={30} />
          </div>
          <div className=" flex flex-col items-start flex-[1_0_0] whitespace-nowrap">
            <p className=" text-xs not-italic font-normal leading-[normal] font-roboto ">
              Occupation
            </p>
            <p className="font-syne text-white md:text-xl   lg:text-2xl text-base not-italic font-bold leading-[normal]">
              {occupationPercentage}%
            </p>
            <p className="text-[#B5B5B5] text-xs not-italic font-normal leading-[normal] font-roboto">
              {selectedYear}
            </p>
          </div>
          <div className=" flex justify-center items-center gap-1 self-stretch  py-0">
            <HiOutlineArrowTrendingDown size={30} className="mr-2" />
            <div className=" text-[#F6F0E8] text-sm not-italic font-bold leading-[normal] font-syne flex flex-col items-center justify-center ">
              <span className="text-base font-roboto text-center">{freeHours}h <br/> <span className="text-sm">Heures non réservées</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsWidge;
