import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LoadSpinner from "Component/utils/LoadSpinner";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { HiOutlineGift, HiOutlineLockClosed } from "react-icons/hi2";
import toast from "react-hot-toast";

const Checkout = ({
  selectedReservation,
  saveTransaction,
  setSelectedReservation,
  amountToPay,
  reservations,
}) => {
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "http://localhost/profile",
      },
      redirect: "if_required",
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      // console.log(result.error.message);
      toast.error(result.error.message);
    } else {
      await saveTransaction(
        result.paymentIntent,
        selectedReservation,
        amountToPay,
        "credit card"
      );
      setSelectedReservation(null);
      toast.success("Paiement effectué avec succès");
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setLoading(false);

  };

  const getPaymentMethod = (e) => {
    setPaymentMethod(e.value.type);
  };

  return (
    <>
      <form
        id="payment-form"
        className="w-full p-6 mx-auto border rounded-3xl "
        onSubmit={handleSubmit}
      >
        <div className="flex flex-row items-center bg-[#248E35] text-white text-left md:text-lg p-3 gap-2 mb-2">
          <HiOutlineGift />
          <p className="font-syne text-sm text-justify">
            Payez sur Stunfiy pour cumuler des points sur votre compte
          </p>
        </div>
        <div className="font-syne text-lg text-beige font-bold flex flex-row justify-between pb-2">
          <span>Carte bancaire</span>
          <span className="flex flex-row items-center gap-2">
            <span>
              <HiOutlineLockClosed size={18} />
            </span>
            <span className="font-normal text-sm font-roboto">
              Paiement sécurisé
            </span>
          </span>
        </div>
        <PaymentElement onChange={(e) => getPaymentMethod(e)} />
      </form>
      <button
        onClick={handleSubmit}
        disabled={loading}
        className={`cursor-pointer px-12 py-2 mt-5 float-right text-black bg-white rounded-3xl text-roboto`}
      >
        {loading ? "loading..." : "Confirmer et payer"}
      </button>
      {loading && <LoadSpinner />}
    </>
  );
};

export default Checkout;
