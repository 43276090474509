//import cookie from "js-cookie";
import cookie from "js-cookie";

const createPaymentIntent = async (data) => {
	const token = cookie.get("authToken");
    return fetch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/secure/paiement/create-link-paiement`, {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`,
		},
	});
}

const storeReservationInDataBase = async (data) => {
	const token = cookie.get("authToken");
	return fetch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/secure/reservation/new`, {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`,
		},
	})
}

export {createPaymentIntent, storeReservationInDataBase};