import { useState } from "react";
import FullImageModal from "./FullImageModal";
import { FiX } from "react-icons/fi";

const GalleryModal = ({ setShowModal, slides }) => {
  const [fullshowModal, setFullshowModal] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <div className="justify-center items-center h-fit hidden md:flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto">
          {/*content*/}
          <div className="w-full md:w-[80%] lg:w-[1000px] h-screen  mx-auto border-0 rounded-lg shadow-lg relative flex flex-col bg-black outline-none focus:outline-none">
            {/*header*/}
            <div className="mt-2 ml-4">
              <button
                className="bg-[#F6F0E8] flex flex-row justify-center items-center gap-2 px-4 py-[10.5px] p-1 border rounded-[30px] text-black text-sm font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="outline-none block focus:outline-none">
                  <FiX size={20} color="#0F0F0F" />
                </span>
                Fermer
              </button>
            </div>
            {/*body*/}
            <div className="relative p-4 flex-auto overflow-y-scroll ">
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 p-4 place-items-center">
                {slides &&
                  slides.map((image, index) => (
                    <div key={index} onClick={() => setActiveIndex(index)}>
                      <img
                        className="hover:opacity-75  w-[500px]  cursor-pointer hover:cursor-zoom-in"
                        style={{ aspectRatio: "16/9", width: "100%", objectFit: "contain" }}

                        src={image}
                        alt=""
                        onClick={() => setFullshowModal(true)}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black  hidden md:block"></div>

      {fullshowModal ? (
        <FullImageModal
          setShowModal={setShowModal}
          slides={slides}
          activeIndex={activeIndex}
          setFullshowModal={setFullshowModal}
        />
      ) : null}
    </>
  );
};

export default GalleryModal;
