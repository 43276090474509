import { useRef, useEffect } from "react";
import { useNewInstitute } from "Context/InstituteAuthContext";

const Adresse = ({ setIsPossiblePhoneNumber }) => {
  const { newInstitute, setNewInstitute } = useNewInstitute();
  let place = {};
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: ["fr", "ch"] },
    fields: [
      "formatted_address",
      "address_components",
      "geometry",
      "icon",
      "name",
      "website",
      "international_phone_number",
      "place_id",
      "photos",
      "adr_address",
    ],
  };

  // Create an load google script
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places&callback=Function.prototype`;
    script.async = true;

    // Append the script element to the document body
    document.body.appendChild(script);

    // Define a function to be called when the script is loaded
    const handleScriptLoad = () => {
      initAutoComplete();
    };
    // Set the onload event to the defined function
    script.onload = handleScriptLoad;

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Initialize the autocomplete
  const initAutoComplete = () => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", onPlaceChanged);
  };

  // When the user selects a prediction from the dropdown menu generated by the autocomplete
  const onPlaceChanged = async () => {
    //TODO empty affected institute fields if user change address

    place = await autoCompleteRef.current.getPlace();
    //User did not select a prediction; reset fields
    if (!place?.geometry) {
      emptyAddressFoundInput();
    } else {
      await setInstituteAddress(place);
      await setGpsdata(place);
      await setPhotoUrl(place);
      await fillPhoneNumberInput(place);
      fillAddressFoundInput(place);
    }
  };

  const setInstituteAddress = async (place) => {
    const _address = await place.formatted_address;
    const adr_address = await place.adr_address;
    let div = document.createElement("div");
    div.innerHTML = adr_address;
    let city = "";
    if (
      div.getElementsByClassName("locality") &&
      div.getElementsByClassName("locality").length > 0
    )
      city = div.getElementsByClassName("locality")[0].innerText;

    if (_address) {
      setNewInstitute((prev) => {
        return {
          ...prev,
          address: _address,
          city: city,
        };
      });
    }
  };

  const setPhotoUrl = async (place) => {
    const photosUrl = await place.photos?.map((photo) => {
      return photo.getUrl();
    });
    if (photosUrl) {
      setNewInstitute((prev) => {
        return {
          ...prev,
          photosFromGgAPI: [...photosUrl],
        };
      });
    }
  };
  const fillPhoneNumberInput = async (place) => {
    let phoneNumber = await place.international_phone_number
    if (phoneNumber) {
      setNewInstitute((prev) => {
        return {
          ...prev,
          phoneNumber: phoneNumber.replace(/\s+/g, ''),
        };
      });
      setIsPossiblePhoneNumber(true);
    }
  };
  
  const setGpsdata = async (place) => {
    const lat = await place.geometry.location.lat();
    const lng = await place.geometry.location.lng();
    setNewInstitute((prev) => {
      return {
        ...prev,
        position: { lat, lng },
      };
    });
  };

  const fillAddressFoundInput = () => {
    if (place) {
      const searchInput = document.getElementById("institue_address");
      const addressFoundText = document.getElementById(
        "address_found_innerText"
      );
      const foundAdressField = document.getElementById(
        "address_found_innerText"
      );
      if (searchInput) {
        searchInput.classList.add("!border-green-400");
        searchInput.value = place.name;
      }
      //display address found
      if (addressFoundText) addressFoundText.value = place.formatted_address;
      if (foundAdressField) foundAdressField.classList.add("!border-green-400");
    }
  };

  const emptyAddressFoundInput = () => {
    const searchInput = document.getElementById("institue_address");
    const foundAdressField = document.getElementById("address_found_innerText");
    searchInput.classList.remove("!border-green-400");
    foundAdressField.value = "";
    foundAdressField.classList.remove("!border-green-400");
  };

  return (
    <>
      <div className="grid sm:grid-cols-2 xs:max-sm:grid-rows-2 gap-4">
        <label
          htmlFor="institue_address"
          className="text-sm font-roboto text-beige"
        >
          Addresse
          <input
            id="institue_address"
            type="text"
            ref={inputRef}
            className="input rounded-md placeholder:text-beige"
            placeholder="Commencer à taper.."
            onChange={emptyAddressFoundInput}
          />
        </label>
        <label
          id="adress_found"
          className="grow text-sm text-beige font-roboto"
        >
          Adresse trouvée
          <input
            disabled
            id="address_found_innerText"
            placeholder="Votre adresse s'affichera ici"
            className="text-sm input align-center rounded-md placeholder:text-beige"
          />
        </label>
      </div>
    </>
  );
};

export default Adresse;
