import React from "react";
//icons 
import {HiPlus} from "react-icons/hi2"
const InputFileElement = ({ index, handleFile, message }) => {
  return (
		<div className='relative px-12 py-8 rounded-md aspect-video w-48 min-[500px]:w-64 sm:w-80 border border-taupe flex flex-col justify-center items-center'>
			<input type='file' data-file={index} onChange={handleFile} className='cursor-pointer opacity-0 h-full w-full absolute inset-0' />
			{/* plus Icon */}
			<HiPlus className='w-8 h-8 p-1 border rounded-full dark:border-beige border-black' />
			<span className='whitespace-nowrap text-sm font-roboto mt-1'>Ajouter une photo</span>
		</div>
  );
};

export default InputFileElement;
