import React from "react";
import { Link, useMatch } from "react-router-dom";
import ThemeSwitcher from "../../UIThemeProvider/ThemeSwitcher";

const NotLoggedFooter = () => {
  const isPro = useMatch("/pro/*");
  const isRegistrating = useMatch("/pro/institute-registration/*");
  return (
    <>
      {!isRegistrating && (
        <footer className="w-full  text-beige linear-background flex gap-2 flex-row justify-center xs:max-sm:text-[14px] snap-start  ">
          <div className="border-r border-beige pr-2 snap-start">
            © Stunify.com SV Ltd
          </div>
          <Link to="/cgu" className="border-r border-beige pr-2">
            Conditions d'utilisation
          </Link>
          <Link to="/privacy">Politique de confidentialité</Link>
          {/* {isPro && <ThemeSwitcher />} */}
        </footer>
      )}
    </>
  );
};

export default NotLoggedFooter;
