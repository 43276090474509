import { useState, useContext, useEffect } from "react";
import Modal from "react-modal";
import AgendaContext from "Context/AgendaContext";
import NewRDV from "./NewRDV";
import EditRDV from "./EditRDV";
import ClientView from "./ClientView";
import PrestationView from "./PrestationView";
import { useModalContext } from "Context/ModalContext";
import "../../../styles/Agenda.css";

const EventModal = ({ event }) => {
  const [selectedEvent, setSelectedEvent] = useState({});
  const [selectionMode, setSelectionMode] = useState("");
  const { modalIsOpen, closeModal, afterOpenModal } = useContext(AgendaContext);
  const { SetselectedPres, SetSelectedclient, EmployeeSelected, Body, SetBody } =
    useModalContext();
  if (document?.getElementById("app")) {
    Modal.setAppElement("#app");
  }

  const returnNav = () => {
    setSelectionMode("");
  };

  useEffect(() => {
    setSelectedEvent(event);
  }, [event]);

  const eventChanged = (field, value) => {
    if (field === "client") {
      SetSelectedclient(value);
      SetBody({ ...Body, clientid: value._id, staffid: EmployeeSelected });
    }

    if (field === "prestation") {
      SetselectedPres(value);
      SetBody({
        ...Body,
        prestationid: value._id,
        staffid: EmployeeSelected,
        slotids: "",
      });
    }

    setSelectionMode("");
  };

  let bodySelector;
  switch (selectionMode) {
    case "client":
      bodySelector = <ClientView eventChanged={eventChanged} returnNav={returnNav} />;
      break;
    case "prestation":
      bodySelector = <PrestationView eventChanged={eventChanged} returnNav={returnNav} />;
      break;
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={{
        overlay: {
          background:
            "linear-gradient(90deg, rgba(255, 255, 255, 0.40) 9%, rgba(255, 255, 255, 0.10) 76.84%)",
          backdropFilter: "blur(20px)",
        },
      }}
      transparent={true}
      contentLabel="Example Modal"
      className="agenda-modal bg-heavy_mud absolute p-8 py-12 text-white rounded-md w-2/5 min-w-[700px]">
      {!selectedEvent?.name ? (
        <NewRDV
          selectionMode={selectionMode}
          bodySelector={bodySelector}
          setSelectionMode={setSelectionMode}
        />
      ) : (
        <EditRDV
          selectionMode={selectionMode}
          bodySelector={bodySelector}
          setSelectionMode={setSelectionMode}
          selectedEvent={selectedEvent}
        />
      )}
    </Modal>
  );
};

export default EventModal;
