import axios from "axios";
import React, { useState } from "react";
import axiosInstance from "config/Interceptor";
import toast from "react-hot-toast";

function Newsletter() {
  const [valid, setValid] = useState(false);
  const [email, setEmail] = useState("");

  const subscribe = async () => {
    let url = "/api/v1/subscriber/subscribe";
    try {
      const res = axiosInstance.post(url, { email: email }).then((item) => {
        if (item.status === 200) {
          toast.error(item.data.message);
        } else {
          setValid(true);
          toast.success(item.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  function isValidEmail(email) {
    // Define a regular expression pattern for email validation.
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const onSubmit = () => {
    subscribe();
    setEmail("");
  };

  return (
    <div className="px-6 w-full py-24 bg-black relative z-[0] ">
      <div className="flex flex-col md:flex-row items-center justify-center gap-14  ">
        <div className="flex flex-col justify-center items-center gap-2">
          <h5 className="text-[#F6F0E8] md:text-2xl w-80 md:w-full text-base not-italic text-center md:text-left font-bold leading-[normal] font-syne">
            Inscrivez-vous à notre newsletter.
          </h5>
          <p className="text-#F6F0E8] md:text-base text-xs not-italic font-normal leading-[normal] text-center md:text-left font-roboto">
            Facilitez-vous la vie et multipliez vos rendez-vous avec Stunify Pro
          </p>
        </div>
        <div className="flex w-[80%] sm:w-96 flex-row justify-between items-center pl-2 bg-[#F6F0E8] p-1 border border-[#D0CBC4] shadow-80  [0px_1px_2px_0px_rgba(0,0,0,0.06),0px_1px_3px_0px_rgba(0,0,0,0.10)] rounded-[30px] border-solid md:w-[450px]">
          <input
            value={email}
            onChange={(e) => handleChange(e)}
            type="email"
            className={`${
              valid ? "w-0" : "pl-6 md:w-[300px] w-[60%] "
            }  h-[50px] rounded-[30px] ${
              !isValidEmail(email) && "outline-red-600"
            }    bg-[#F6F0E8] text-black text-base not-italic font-normal leading-[normal]  font-roboto transition-width transition-slowest ease duration-1000`}
            placeholder="info@exemple.com"
          />
          {!valid && (
            <span className=" mr-4 md:mr-4 border border-[#D0CBC4] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.06),0px_1px_3px_0px_rgba(0,0,0,0.10)] rounded-[30px] border-solid h-7"></span>
          )}
          <button
            disabled={!isValidEmail(email)}
            onClick={() => {
              onSubmit();
            }}
            className={`${valid ? "w-full" : "w-[30%]"} 
            }flex justify-center items-center ${
              !isValidEmail(email) && !valid && "opacity-10"
            } px-4 py-3 rounded-[30px] bg-black text-white transition-width transition-slowest ease duration-1000`}>
            {valid ? "Abonné" : `Envoi`}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
