import { useState, useEffect } from "react";
import WeekCalendaritem from "./WeekCalendaritem";

import { HiMoon, HiPlus } from "react-icons/hi";
import { useContext } from "react";
import moment from "moment";
import AgendaContext from "Context/AgendaContext";

const WeeklyCalendarAgenda = ({ Date }) => {
  const {
    EmployeeData,
    openModal,
    CombineFreeslots,
    sevenDays,
    AddEvent,
    GetWeekWorkingHours,
  } = useContext(AgendaContext);

  const getDuration = (frame) => {
    let period = [frame.timerPeriod[0].split(":"), frame.timerPeriod[1].split(":")];
    let a = (Number(period[1][0]) - Number(period[0][0])) * 60;
    let b = Number(period[1][1]) - Number(period[0][1]);
    return a + b;
  };

  const [FiltredData, setFiltredData] = useState([]);
  useEffect(() => {
    let Temp = EmployeeData.filter((item) => {
      return moment(item.startTimer).isSame(Date, "day");
    });
    setFiltredData(CombineFreeslots(Temp));
    GetWeekWorkingHours(EmployeeData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EmployeeData, sevenDays]);

  return (
    <div className="min-h-[1px] grow max-w-[19%] min-w-[233px] ">
      {FiltredData.map((item, index) => {
        return item.isEvent === false ? (
          <div className="flex justify-center">
            <div
              onClick={AddEvent}
              key={index}
              style={{ height: getDuration(item) * 7.5 }}
              className="bg-black  flex items-center text-white  border-opacity-60 border-beige ">
              <button className="rounded-full  p-2  bg-mud mx-4" onClick={openModal}>
                <HiPlus />
              </button>
            </div>
          </div>
        ) : item.type === "reservation" ? (
          <WeekCalendaritem
            Slot={item}
            key={index}
            Start={item.startTimer}
            SelectedDate={Date}
            event={item.name}
            customer={item.customer}
            timePeriod={item.timerPeriod}
            selected={item.selected}
            startTimer={item.startTimer}
            endTimer={item.endTimer}
            duration={getDuration(item)}
          />
        ) : (
          <div>
            <div
              key={index}
              style={{ height: getDuration(item) * 7.5 }}
              className="bg-black  flex items-center text-white border-y-2  border-opacity-60 border-beige ">
              <button className="  p-2   mx-2">
                <HiMoon />
              </button>{" "}
              <h5 className="font-syne text-lg">En pause</h5>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WeeklyCalendarAgenda;
