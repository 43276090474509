import { useChatContext } from "Context/ChatContext";
import React, { useEffect } from "react";
import moment  from "moment";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import Nomessage from "./Nomessage";


const Messages = () => {
  const { messages, newMessage, selectedChat } = useChatContext();
  const decoded = jwt_decode(Cookies.get("authToken"));

  return (
    <div className="bg-[#100F0D] xxs:h-[60%] md:min-h-[70%] flex flex-col-reverse px-[20px] py-5 overflow-auto xxs:grow xxs:mx-3">
      {messages?.length>0 ? messages?.map((message) => {
        return (
          message.chatId === selectedChat._id && (
            <>
            <span className={`text-xxs text-[#828282] mt-1 ${message.senderId === decoded.id ? 'self-end ' : ''}`} >{moment(message.createdAt).format('DD/mm/yy  HH:mm  ')}</span>
            <div key={message._id} className={` ${message.senderId === decoded.id ? 'sentMessage' : 'receivedMessage'}`}>
              <p>{message?.text}</p>
            </div>
            
            </>
          )
        );
      }): <Nomessage/>}
    </div>
  );
};

export default Messages;
