import React, { useState } from "react";
import BusinessHours from "../institute-registration/BusinessHours";
import Clientele from "../institute-registration/Clientele";
import Nav from "./Nav";
import { useNewInstitute } from "Context/InstituteAuthContext";
import toast from "react-hot-toast";

//icons
import {
  HiOutlineCalendarDays,
  HiOutlineClock,
  HiChevronRight,
  HiChevronLeft,
} from "react-icons/hi2";

const InstituteInfoProfil = ({ handleStepChange }) => {
  const { newInstitute, setNewInstitute } = useNewInstitute();

  // check length of description
  const [remainingCharacters, setRemainingCharacters] = useState(1000);

  // Function to update character counter in description textArea
  const updateCharacterCounter = (inputLength) => {
    const remainingChars = 1000 - inputLength;
    setRemainingCharacters(remainingChars);
  };

  const handleSubmitInfo = (e) => {
    e.preventDefault();
    if(!checkFormValidity())return 
    handleStepChange(e, "next");
  };

	
  const checkFormValidity = () => {
		const emptyFields = [];
		if (!newInstitute.description) {
			emptyFields.push("Description");
		}
		if (instituteIsNeverOpen()) {
			emptyFields.push("Horaires");
		}
		if (someInputTypeTimeAreEmptyOrInvalid()) {
			emptyFields.push("Horaires manquants");
		}
		if (emptyFields.length > 0) {
			const emptyFieldsMessage = emptyFields.join(", ");
			toast.error(`Veuillez renseigner les champs suivants : ${emptyFieldsMessage}`);
			return false;
		} else return true;
  };

  const instituteIsNeverOpen = () => {
    const instituteIsNeverOpen = newInstitute.businessHours.every(
      (day) => !day.isOpen
    );
    return instituteIsNeverOpen;
  }
	
	const someInputTypeTimeAreEmptyOrInvalid = () => {
		let result = false;
		newInstitute.businessHours.forEach((day, index) => {
			if (day.isOpen) {
				if (!day.morningStart || !day.eveningEnd) {
					result =  true;
				}
				if (day.lunchBreak) {
					if (!day.morningEnd || !day.eveningStart) {
						result = true;
					}
				}
			}
		});
		return result;
	};

  return (
		<>
			<h1 className='font-syne text-2xl mt-8 font-bold text-white'>Mon profil - {newInstitute?.name}</h1>
			<form action='' onSubmit={handleSubmitInfo} className='flex grow flex-col w-full mt-4'>
				<div className='grid lg:grid-cols-2 gap-2 mb-4 grow'>
					{/* LEFT SIDE CLIENTELE AND DESCRIPTION */}
					<div className='flex-grow flex flex-col gap-2'>
						{/* CLIENTELE */}
						<Clientele />
						{/* DESCRIPTION */}
						<div className='grow flex flex-col xs:max-lg:pb-8 rounded-md border bg-black dark:border-dark_grey p-6 pt-0 '>
							<div className='flex flex-row justify-start items-center gap-2'>
								<HiOutlineCalendarDays className='h-7 w-7 text-beige' />
								<h2 className='my-4 text-lg font-syne font-bold text-beige lg:whitespace-nowrap'>Description de votre établisssement</h2>
							</div>
							<div className='flex grow flex-col relative w-full  xs:max-lg:h-40'>
								<label htmlFor='institute_description' className='relative text-sm font-roboto text-beige'>
									Votre description{" "}
								</label>
								<textarea
									className='w-full grow p-4 input bg-transparent rounded-md placeholder:text-sm'
									name='institute_description'
									id='institute_description'
									maxLength={1000}
									placeholder='Décrire votre établissement en quelques mots..'
									onChange={(e) => {
										const inputValue = e.target.value;
										setNewInstitute({
											...newInstitute,
											description: e.target.value,
										});
										updateCharacterCounter(inputValue.length);
									}}></textarea>
								<span className='text-xs text-white absolute left-1 -bottom-5 pointer-events-none'>{remainingCharacters} /1000 </span>
							</div>
						</div>
					</div>
					{/* RIGHT SIDE  HORAIRES DU SALON*/}
					<div className='bg-black flex flex-col basis-1/3 lg:min-h-[390px] max-h-[calc(100vh_-_365px)] overflow-y-scroll rounded-md border dark:border-dark_grey p-6 pt-0 '>
						<div className='flex flex-row gap-2 items-center mt-2'>
							{/* clock Icon  */}
							<HiOutlineClock className='h-6 w-6' />

							<h2 className='my-4 text-lg font-syne font-bold'>Horaires</h2>
						</div>
						<ul>
							{newInstitute?.businessHours.map((day, index) => (
								<BusinessHours key={"businessDay" + index} index={index} {...day} />
							))}
						</ul>
					</div>
				</div>
				<Nav disableBack disableSkip handleStepChange={handleStepChange} />
				{/* <div className='flex flex-row justify-between my-6'>
					<Link to='/pro' className='btn-outline border-black dark:border-beige rounded-3xl w-fit flex flex-row justify-center items-center px-3 gap-1'>
						<HiChevronLeft className='w-5 h-5' />
						<span>Retour</span>
          </Link>
          
					<button type='submit' className='btn-light dark:bg-beige border border-dark_grey self-end w-fit flex flex-row gap-1 items-center dark:text-black'>
						<span>Suivant</span>
						<HiChevronRight className='w-5 h-5' />
					</button>
				</div> */}
			</form>
		</>
  );
};

export default InstituteInfoProfil;
