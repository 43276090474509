import { FaArrowRight } from "react-icons/fa6";
import React, { useState, useEffect, useRef, useContext } from "react";
import instituteJson from "../../dummyData/instituteType.json";
import byType from "../../dummyData/partners.json";
import ProCard from "../utils/ProCard";
import { Link } from "react-router-dom";
import LoadingCard from "./LoadingCard";

import Datacontext from "../../Context/Datacontext";

const ProPartners = () => {
  const [activeType, setActiveType] = useState("coiffeur");
  const categoryDescription = useRef(null);

  const handleActiveTab = (e, arg) => {
    const procard = document.querySelector(".procard");
    setActiveType(arg);
    if(procard) procard.scrollLeft -= 100000;
  };
  const { fetch, institutes, fetching } = useContext(Datacontext);

  useEffect(() => {
    fetch(true,{ type: activeType }, false, true, 0, 5);
  }, [activeType]);

  return (
    <div className="!max-w-[1920px] h-screen pb-20">
      <h3 className="text-2xl float-left mt-5 mb-10 font-bold text-white font-syne ">
        Nos professionnels
      </h3>

      <article
        className={`rounded-3xl flex  flex-col h-[86%] w-full blur-effect  mb-[2%] max-w-[2413px] px-3 `}>
        <div className="flex justify-between flex-row  h-[40%] xs:max-md:flex-wrap w-full overflow-hidden">
          <nav className="w-full md:w-2/5 lg:w-1/4 shrink-0 mt-8 px-10 overflow-y-scroll">
            <div
              id="institute_type"
              className="flex w-full flex-col md:flex-col items-center gap-4 md:h-48 h-full">
              {instituteJson.categories.map((cat, index) => (
                <button
                  type="button"
                  onClick={(e) => handleActiveTab(e, cat.type)}
                  key={index}
                  data-type={cat.name}
                  className={`w-full font-normal text-left pl-5 cursor-pointer whitespace-nowrap  snap-start ${
                    activeType === cat.type ? "btn-dark pl-0" : ""
                  }`}>
                  {cat.name}
                </button>
              ))}
            </div>
          </nav>

          {instituteJson.categories.map((cat, index) => {
            return (
              activeType === cat.type && (
                <div
                  key={index}
                  className="p-6 flex flex-col sm:p-8  w-full mx-6 rounded-3xl relative">
                  <span
                    ref={categoryDescription}
                    className="font-medium xs:max-sm:max-h-24 xs:max-sm:line-clamp-4 text-justify ">
                    {cat.description}
                  </span>
                </div>
              )
            );
          })}
        </div>
        <ul className="flex justify-between mt-6 gap-3  grow px-4 overflow-x-scroll">
          {fetching ? (
            <>
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </>
          ) : (
            institutes.map((shop, index) => {
              return (
                activeType === shop.type && (
                  <ProCard width={"100%"} height={"235px"} {...shop} key={index} />
                )
              );
            })
          )}
        </ul>

        <Link
          to="/search"
          className="rounded-3xl mb-3 p-1 px-3 py-3 text-white self-end flex items-center bg-black">
          Rechercher
          <FaArrowRight />
        </Link>
      </article>
    </div>
  );
};

export default ProPartners;
