import React from 'react'
import Hero from './Hero'
import NumClient from './NumClient'
import Comments from './Comments'
import './../../../styles/homePagePro.css'
import AgendaPrevPro from './AgendaPrevPro'
import Newsletter from './Newsletter'

function ProHome() {
  return (
		<>
			<Hero />
			<NumClient />
			<AgendaPrevPro />
			<Newsletter />
			<Comments />
		</>
  );
}

export default ProHome
