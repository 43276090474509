import { useState, useEffect, useContext, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuthProvider } from "Context/UserAuthContext";
import LoggedContext from "Context/isLogged";
import SalonDataContext from "Context/SalonDataContext";
import prestationContext from "Context/prestationContext";
import {
  DisconnectUserInFront,
  disconnectUserInBackend,
} from "Component/utils/connectionHandler";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "Component/ui/dialog";
import UserSignupForm from "Component/UserSignup/UserSignupForm";
import UserComfirmPhoneForm from "Component/UserSignup/UserComfirmPhoneForm";
import LoginForm from "Component/utils/LoginForm";
import MapGoogle from "Component/utils/MapGoogle";
import Checkout from "./Checkout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Cookies from "js-cookie";
import { toast } from "react-hot-toast";
import LoadSpinner from "Component/utils/LoadSpinner";

//icons
import { HiOutlineX } from "react-icons/hi";
import { FaStar } from "react-icons/fa6";
//apiHelper
import { createPaymentIntent, storeReservationInDataBase } from "./apiServices";
import moment from "moment";
import { RdvNew } from "Component/utils/NotificationCards";
import Stepbar from "./Stepbar";
import { step } from "@material-tailwind/react";
import salonContext from "Context/SalonDataContext";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY_DEV}`);

const StepThree = () => {
  const navigate = useNavigate();
  const disconnectUserInFront = DisconnectUserInFront();
  const { salon } = useContext(SalonDataContext);
  const [salonLocation, setSalonLocation] = useState([]);
  const { userGlobal, isLogged } = useContext(LoggedContext);
  const {
    setSelectedPrestation,
    employees,
    amountToPayNow,
    totalPrice,
    amountToPayLater,
    selectedPrestation,
    totalDuration,

    checkIfAvailableTime,
  } = useContext(prestationContext);
  const [signupStep, setSignupStep] = useState(1);
  const [userWantsToConnect, setUserWantsToConnect] = useState(false);
  const [userWantsToSignup, setUserWantsToSignup] = useState(false);
  const [isPossibleToDeletePrestation, setIsPossibleToDeletePrestation] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const selectedFreeSlot = JSON.parse(localStorage.getItem("selectedFreeSlot"));

  const timeStartOfSlot = selectedFreeSlot?.startTimer;
  const timeStartOfrdv = selectedPrestation[0]?.newSlot?.startTimer;
  const appearance = {
    theme: "flat",

    rules: {
      ".Input": {
        border: "1px solid #F6F0E8",
        backgroundColor: "transparent",
        borderRadius: "12px",
      },
      ".Input::placeholder": {
        color: "#F6F0E8",
      },
      ".Label": {
        color: "#F6F0E8",
      },
    },
  };

  const stripeOptions = {
    // passing the client secret obtained from the server
    // externalPaymentMethodTypes: ["external_paypal", "external_postfinance"],
    appearance,
  };

  // remove the connection block if user is connected
  useEffect(() => {
    if (isLogged) {
      setUserWantsToSignup(false);
      setUserWantsToConnect(false);
    }
  }, [isLogged]);

  // close logging Modal when press escape key
  useEffect(() => {
    window.addEventListener("keydown", handleCloseModal);
    return () => {
      window.removeEventListener("keydown", handleCloseModal);
    };
  }, []);

  //block scrolling when modal is open
  useEffect(() => {
    if (userWantsToConnect || userWantsToSignup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [userWantsToConnect, userWantsToSignup]);

  // set salon GPS position to display a Google Map
  useEffect(() => {
    if (salon.position) {
      setSalonLocation([
        {
          address: salon.address,
          lat: salon.position.lat,
          lng: salon.position.lng,
        },
      ]);
    }
  }, [salon]);

  const deletePrestation = (index) => {
    let newSelectedPrestation = [...selectedPrestation];
    newSelectedPrestation.splice(index, 1);
    setSelectedPrestation(newSelectedPrestation);
    toast.success("Prestation supprimée");
  };

  const handleCloseModal = (e) => {
    if (e.key === "Escape") {
      setUserWantsToConnect(false);
      setUserWantsToSignup(false);
    }
  };
  const getEmployeeName = (id) => {
    let employee = employees.find((item) => item._id === id);
    return employee?.fullName;
  };

  const userWantsToSwitchAccount = () => {
    let token = Cookies.get("authToken");
    disconnectUserInBackend(token).then((res) => {
      if (!res.ok) {
        // console.log("error");
      }
      disconnectUserInFront({ isAmodal: true });
    });

    setUserWantsToConnect(true);
  };
 
  const handleUserWantsToPay = () => {
    setLoading(true);
    //Check if user is logged
    if (!isLogged) {
      toast.error("Vous devez être connecté pour réserver");
      setLoading(false);
      return;
    }
    //Check if user has selected at least one prestation
    if (selectedPrestation.length === 0) {
      toast.error("Vous devez sélectionner au moins une prestation");
      setLoading(false);

      return;
    }
    //remove delete prestation possibility
    setIsPossibleToDeletePrestation(false);
    // send payment intent to backend
    createPaymentIntent({payment_method_types : salon.payementMethode , amount: amountToPayNow * 100 })
      .then((res) => {
        if (!res.ok) {
          toast.error("Une erreur est survenue, veuillez réessayer plus tard");
          return;
        }
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.client_secret);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const computeEndDateTime = () => {
    const dateTime = moment(timeStartOfrdv);
    const starTimeInMiliseconds = dateTime.valueOf();
    const endTimeInMiliseconds = starTimeInMiliseconds + totalDuration;
    const endTime = new Date(endTimeInMiliseconds).toISOString();
    return endTime;
  };

  const computeTotalDuration = () => {
    const total = moment.duration(totalDuration && totalDuration);
    let sum = moment.utc(total.asMilliseconds()).format("HH:mm");
    return sum;
  };

  const endTimeComputedFromSumOfDuration = computeEndDateTime();

  // Ensure that endTimeComputedFromSumOfDuration is a Moment.js object by parsing it
  const parsedEndTime = moment(endTimeComputedFromSumOfDuration);

  // Now you can subtract 1 hour for mutch utc and format it as needed
  const formattedEndTime = parsedEndTime?.subtract(1, "hour").format("HH:mm");

  function formatTimeToCustomFormat(time) {
    const [hours, minutes] = time.split(":");
    const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
    const formattedTime = `${Math.floor(totalMinutes / 60)}h ${totalMinutes % 60}mn`;
    return formattedTime;
  }
  const originalTime = computeTotalDuration();

  const totalDurationOfselectedPrestationsForInfoPrestations =
    formatTimeToCustomFormat(originalTime);

  const handleUserWantsToCheckout = async () => {
    // let endDateTimeComputedFromSumOfDuration = computeEndDateTime();
    let totalDurationOfselectedPrestations = computeTotalDuration();
    let payment = {};

    let dataToSend = {
      institute: salon._id,

      totalDuration: totalDurationOfselectedPrestations,
      totalPrice: totalPrice,
      payment: [payment],
      prestations: selectedPrestation,
      customer: {
        _id: userGlobal._id,
        email: userGlobal.email,
        phone: userGlobal.phone,
      },
    };
    setLoading(true);

    const checkTimeResult = await checkIfAvailableTime(dataToSend);

    if (checkTimeResult) {
      toast.custom(
        <RdvNew
          message={`Vous avez déjà un rendez-vous à  cette date  ${moment(
            selectedPrestation[0].newSlot.startTimer
          )
            .subtract(1, "hour")
            .locale("fr")
            .format("dddd D MMMM HH:mm")} H`}
          toast
        />
      );
      setLoading(false);
      setSelectedPrestation([]);
      setTimeout(() => {
        navigate("/profile");
      }, 2000);
      return;
    }
    setLoading(true);
    if (!isLogged) {
      toast.error("Vous devez être connecté pour réserver");
      setLoading(false);
      return;
    }
    if (selectedPrestation.length === 0) {
      toast.error("Vous devez sélectionner au moins une prestation");
      setLoading(false);
      return;
    }

    storeReservationInDataBase(dataToSend)
      .then((res) => {
        if (!res.ok) {
          toast.error("Une erreur est survenue, veuillez réessayer plus tardaa");
          return;
        } else {
          toast.success("Votre réservation a bien été enregistrée");
          setSelectedPrestation([]);
          setTimeout(() => {
            navigate("/profile");
          }, 2000);
        }
      })
      .catch((e) => {
        // console.log(e);
      })
      .finally(() => setLoading(false));
    //empty the prestation context and localStorage
    setSelectedPrestation([]);
  };

  return (
    <div className="bg-no-repeat bg-cover min-h-[calc(100dvh_-_128px)]">
      <div className="w-full backdrop-blur md:backdrop-blur-md xl:py-[4rem] xl:px-[7.5rem] py-[1rem] md:px-[2rem] px-0 min-h-[calc(100dvh_-_128px)]">
        <div className="md:trans-bg min-h-[50rem] md:p-[3.4rem] p-4 max-w-[75rem] m-auto  md:bg-white md:bg-opacity-20 md:backdrop-blur-lg  md:rounded-3xl drop-shadow-lg  relative">
          <Stepbar step={clientSecret ? 4 : 3} />

          <h4 className="text-beige font-syne  md:hidden block">Resume</h4>
          <h4 className="text-beige mb-4 font-syne hidden md:block">
            Confirmation & paiement
          </h4>
          <div className="flex xl:flex-row flex-wrap flex-col justify-start gap-6 my-[2rem]">
            {!isLogged ? (
              // Login / signup Forms
              <div className="max-[1432px]:mx-auto w-full flex flex-col gap-2 md:w-[530px] md:bg-beige bg-gray-400/60 rounded-3xl p-2 py-8">
                <h5 className="font-syne text-lg font-bold text-left pl-4 pb-4">
                  Connexion / inscription
                </h5>
                <div className="flex flex-col gap-1">
                  <div className="flex flex-col justify-center items-center ">
                    <p className="text-base font-roboto text-white md:text-black">
                      {" "}
                      Première fois avec Stunify{" "}
                    </p>
                    <button
                      type="button"
                      aria-label="créer un compte"
                      onClick={() => setUserWantsToSignup(true)}
                      className="text-sm font-roboto py-3 px-4 text-black border border-black rounded-3xl w-44 bg-[#F6F0E8] md:w-[240px]">
                      Créer mon compte
                    </button>
                  </div>
                  <hr className="bg-[#D0CBC4] h-[1px] mt-3 mb-3 mx-4" />

                  <div className="flex flex-col justify-center items-center gap-2 ">
                    <p className="text-base font-roboto text-white md:text-black">
                      {" "}
                      Vous avez déjà un compte{" "}
                    </p>
                    <button
                      type="button"
                      aria-label="Se connecter"
                      onClick={() => setUserWantsToConnect(true)}
                      className="text-sm font-roboto py-3 px-4 bg-black text-white rounded-3xl  w-44 md:w-[240px]">
                      Se connecter
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              // User infos
              <div className="xs:max-md:linear-background max-[1432px]:mx-auto w-full md:w-[530px] backdrop-blur-xl py-4 px-6 rounded-3xl ">
                <div className="flex flex-row text-beige justify-between items-end ">
                  <div>
                    <p>
                      {userGlobal.firstName} {userGlobal.lastName}{" "}
                    </p>
                    <p className="text-black">{userGlobal.phone} </p>
                  </div>
                  <button onClick={userWantsToSwitchAccount}>Modifier</button>
                </div>
              </div>
            )}
            <div className="flex-row flex-wrap gap-6 flex">
              {/* Prestation infos */}
              <div className="flex flex-col justify-between xs:max-md:linear-background md:bg-beige text-black p-[1.5rem] md:w-[530px] w-full rounded-3xl mx-auto">
                <section id="prestations_info">
                  <h5 className="xs:max-md:text-beige font-bold text-lg md:text-2xl font-syne mb-4">
                    {salon.name}
                  </h5>
                  <div className="flex flex-row items-center space-x-2 text-black text-sm md:text-[#82786E]  ">
                    {salon?.rating?.length > 0 && (
                      <>
                        <span>
                          <FaStar size={18} />
                        </span>
                        <span> {(salon?.rating?.length && salon.rating) || "?"}/5</span>
                        <span> ({salon?.reviews?.length})</span>
                        <span>&#x2022;</span>
                      </>
                    )}
                    <span>
                      <Dialog className="!bg-slate-400">
                        <DialogTrigger aria-label="Ajouter une prestation">
                          <p>{salon.address}</p>
                        </DialogTrigger>

                        <DialogContent className="p-0 no-scrollbar gap-0 flex flex-start flex-col bg-beige w-full h-full md:w-10/12 max-w-[1400px] max-h-[calc(100dvh_-_140px)] overflow-y-scroll !rounded-3xl">
                          <DialogTitle className="h-fit p-6">
                            <p className="font-bold text-2xl  font-syne">{salon.name}</p>

                            <p>{salon.address}</p>
                          </DialogTitle>
                          <div className="w-full h-full rounded-3xl overflow-hidden">
                            <MapGoogle markers={salonLocation} />
                          </div>
                          {/* Add a prestation modal*/}
                        </DialogContent>
                      </Dialog>
                    </span>
                  </div>

                  <hr className="bg-[#D0CBC4] h-[1px] mt-3 mb-2" />
                  <h5 className="xs:max-md:text-beige font-bold text-sm md:text-base font-roboto mb-2 capitalize">
                    {moment(timeStartOfrdv)
                      .subtract(1, "hour")
                      .locale("fr")
                      .format("dddd D MMMM HH:mm")}
                  </h5>

                  <div className=" flex flex-row items-center justify-between">
                    <div className="flex flex-row space-x-2 text-black text-sm md:text-[#82786E] ">
                      <p className="mr-2">
                        Pendant{" "}
                        <span>
                          {totalDurationOfselectedPrestationsForInfoPrestations}
                        </span>
                      </p>{" "}
                      • <span>Fini à {formattedEndTime}</span>
                    </div>
                  </div>
                  {selectedPrestation.length === 0 && "Aucune prestation sélectionnée"}
                  <ul>
                    {selectedPrestation.map((item, index) => {
                      return (
                        <li
                          className="flex flex-col w-full my-6 py-4 border-b "
                          key={index}>
                          <div className="flex flex-row gap-2 font-roboto w-full items-start justify-between ">
                            <div className="flex flex-row items-start gap-2">
                              {isPossibleToDeletePrestation && (
                                <HiOutlineX
                                  size={20}
                                  className="cursor-pointer xs:max-md:text-beige text-black mt-1"
                                  onClick={() => deletePrestation(index)}
                                />
                              )}
                              <div className="flex flex-col">
                                <p className=" flex-auto text-base xs:max-md:text-beige">
                                  {item.name}
                                </p>
                                <div className="font-roboto text-sm flex flex-row justify-start xs:max-md:text-black text-[#82786E] gap-2">
                                  <p>{item.duration}mn </p>
                                  {employees.length > 0 && <span>&#x2022;</span>}
                                  <p>
                                    {item.idStaff
                                      ? getEmployeeName(item.idStaff)
                                      : "Pas de préférence"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p className="text-right  xs:max-md:text-beige">
                              {item.price} CHF{" "}
                            </p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </section>

                <section id="totals">
                  <div className="justify-self-end text-beige md:text-black flex justify-between font-roboto font-bold text-lg">
                    <p>Total</p>
                    <p> {totalPrice} CHF</p>
                  </div>
                  <hr className="bg-[#D0CBC4] h-[1px] mt-4 mb-4" />
                  {salon.wantADeposit ? (
                    <>
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-col ">
                          <p className="text-lg font-bold font-roboto xs:max-md:text-beige">
                            À payer maintenant ({salon.wantADeposit})
                          </p>
                          <p className="text-taupe text-sm -mt-1 xs:max-md:text-black">
                            Accompte demandé par le salon
                          </p>
                        </div>
                        <p className="text-lg font-bold font-roboto text-right xs:max-md:text-beige">
                          {amountToPayNow} CHF
                        </p>
                      </div>
                      <hr className="bg-[#D0CBC4] h-[1px] mt-4 mb-4" />
                      <div className="flex flex-row justify-between xs:max-md:text-beige">
                        <p className="text-lg font-roboto">Reste à payer sur place</p>
                        <p className="text-lg font-roboto text-right">
                          {amountToPayLater} CHF
                        </p>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </section>
              </div>

              {/* Payment form */}
              {clientSecret && (
                <Elements
                  stripe={stripePromise}
                  options={{ ...stripeOptions, clientSecret }}>
                  <Checkout />
                </Elements>
              )}

              {/*Condition Du Salon  */}
              <div className="flex flex-col gap-2  md:bg-transparent max-[1432px]:mx-auto xs:max-md:linear-background md:border p-3 rounded-3xl border-solid  md:w-[530px]  ">
                <div className="flex flex-col space-y-3  p-3 font-syne text-[#F6F0E8] ">
                  <h5 className="font-syne text-lg font-bold ">Conditions du salon</h5>
                  {salon.wantADeposit ? (
                    <>
                      {" "}
                      <p className="text-sm font-bold font-syne">Conditions 1</p>
                      <p className="font-roboto text-sm">
                        <span className="font-bold">{salon.name} </span>demande un
                        accompte {salon.wantADeposit} pour réserver une prestation.
                      </p>
                      <p className="text-sm font-bold font-syne ">Conditions 2</p>
                      <p className="font-roboto text-sm">
                        Si nécessaire, vous pouvez annuler votre réservation jusqu'à 24
                        heure avant votre rendez-vous et recevoir un remboursement complet
                        selon votre mode de paiement initial.
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="font-roboto text-sm">
                        Ce salon ne demande pas d'acompte,
                        <br />
                        Si nécessaire, vous pouvez annuler votre réservation jusqu'à 24
                        heure avant votre rendez-vous.
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <p className="text-justify text-beige text-sm font-normal leading-[normal] font-roboto mt-6">
            En continuant vous acceptez nos{" "}
            <Dialog className="!bg-slate-400">
              <DialogTrigger aria-label="Ajouter une prestation">
                <span className="underline"> conditions générales d’utilisations </span>
              </DialogTrigger>

              <DialogContent className="bg-beige w-full md:w-10/12 max-w-[1400px] max-h-[calc(100dvh_-_140px)] overflow-y-scroll !rounded-3xl">
                <DialogTitle>
                  <p className="hidden md:block font-roboto text-base font-bold p-4 ">
                    Condition générales d'utilisation
                  </p>
                  <p className="p-4 pt-0">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt
                    culpa autem praesentium soluta cupiditate vitae est voluptate enim ex
                    dolorum, esse aperiam, ullam in iusto deserunt? Temporibus fuga
                    corporis, consequatur quidem voluptatibus autem atque minima amet
                    obcaecati doloremque consequuntur earum impedit nihil sit! Quaerat
                    fuga ducimus quas id. Et nihil dolor sapiente repellendus optio
                    deleniti aliquid illo aut! Quod a tenetur soluta sapiente quas illo
                    consequatur ut error minima quos magnam cum molestiae commodi, facere
                    pariatur, accusamus quo quibusdam. Sed, labore. Maiores tempora
                    eligendi quos debitis qui exercitationem ad maxime expedita
                    voluptatibus, quam placeat eius necessitatibus ipsum beatae ex
                    voluptate minima accusamus illo? Facilis dolore soluta optio et odit,
                    tenetur qui quo reprehenderit culpa suscipit vero vel id blanditiis
                    accusamus rerum necessitatibus fuga mollitia ea sed? Nobis suscipit
                    aliquam placeat quia. Ipsa dolores velit et sit voluptatem quos
                    cupiditate animi incidunt quo. Hic nobis minima eaque dolores
                    pariatur, voluptatum error? Lorem ipsum dolor, sit amet consectetur
                    adipisicing elit. Tempora unde consequatur eveniet, harum ex deleniti
                    sequi <br />
                    <br /> <hr />
                    <br />
                    consectetur numquam alias ea veniam tenetur magnam eligendi minima
                    nostrum, enim quasi tempore, doloremque error porro magni assumenda?
                    Minima asperiores nemo odio rerum voluptatem non modi enim, dolores
                    eligendi facilis! Quia, molestiae impedit expedita numquam aspernatur
                    odit, consectetur tempora ut veritatis corporis libero vitae
                    architecto provident aperiam, quae minima. Recusandae sunt quidem,
                    beatae vitae laborum cum doloribus illo corrupti at aliquid incidunt
                    soluta, exercitationem voluptate sit sequi. Consequuntur similique
                    maxime quis fugit voluptatibus saepe, corrupti, quae exercitationem,
                    non error dolores dolore harum possimus nihil officia? Dolor
                    voluptatibus dolorum a voluptatum sunt quo assumenda deserunt unde
                    esse, fugit ratione laudantium nam odit soluta natus consequuntur
                    harum deleniti, eius ipsum. Facere, aperiam. Magnam autem pariatur
                    quia dolor possimus dolorum! Tempora in molestias nobis voluptates
                    tempore aperiam asperiores tenetur laborum modi est, suscipit unde,
                    sit consectetur libero?
                  </p>
                </DialogTitle>
                {/* Add a prestation modal*/}
              </DialogContent>
            </Dialog>
          </p>
          <div className="flex justify-between mt-4">
            <button
              onClick={() => navigate("/reservation")}
              type="button"
              aria-label="Précédent"
              className="  flex  text-black  bg-[#F6F0E8]  cursor-pointer    items-center  px-6 py-3 rounded-3xl  border-2 border-black font-roboto text-base">
              Précédent
            </button>
            {!clientSecret && (
              <>
                {salon.wantADeposit ? (
                  <button
                    onClick={handleUserWantsToPay}
                    disabled={loading}
                    className="px-12 py-2 text-white bg-black rounded-3xl text-roboto">
                    {loading ? "loading..." : "Suivant"}
                  </button>
                ) : (
                  <button
                    onClick={handleUserWantsToCheckout}
                    disabled={loading}
                    className="px-12 py-2 text-white bg-black rounded-3xl text-roboto">
                    {loading ? "loading..." : "Confirmer et réserver"}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {loading && <LoadSpinner />}
      {userWantsToConnect && (
        <div className="z-50 absolute inset-0 backdrop-blur-sm h-full flex flex-col justify-center">
          <LoginForm isAmodal={true} setUserWantsToConnect={setUserWantsToConnect} />
        </div>
      )}
      {userWantsToSignup && (
        <div className="z-50 absolute inset-0 linear-background h-full flex flex-col justify-center">
          <UserAuthProvider>
            <UserSignupForm
              isAmodal={true}
              isStep={signupStep}
              setStep={setSignupStep}
              setUserWantsToSignup={setUserWantsToSignup}
            />
            {signupStep === 2 && (
              <UserComfirmPhoneForm setStep={setSignupStep} isAmodal={true} />
            )}
          </UserAuthProvider>
        </div>
      )}
    </div>
  );
};

export default StepThree;
