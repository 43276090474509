import Cookies from "js-cookie";
import { useContext } from "react";
import LoggedContext from "Context/isLogged";

export const connectUserInBackend = async (form, googleToken , isProLogin) => {
  let type = 'client'
  if (isProLogin){
    type = 'institute'
  }
  if (googleToken) {
    
    return fetch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/auth/${type}/loginGoogle`, {
      method: "POST",
      body: JSON.stringify({ googleToken: googleToken.code , isInstitute : isProLogin  }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    
  } else {
   
    return fetch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/auth/${type}/login`, {
      method: "POST",
      body: JSON.stringify({...form ,isInstitute: isProLogin}),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
};

export const ConnectUserInFront = () => {
  const { setIsLogged, setUserGlobal, setIsPro } = useContext(LoggedContext);

  const connectInFront = (data, isAmodal) => {
    Cookies.set("authToken", data.token, { expires: 3 });
    let { lastName, firstName, _id, email, phone, imageUrl, institute ,hasGoogleCalendarAccess , isConnectedGoogle } = data.client;
    setUserGlobal({
      isConnectedGoogle,
      hasGoogleCalendarAccess,
      lastName,
      firstName,
      _id,
      email,
      phone,
      imageUrl,
      institute: { ...institute }, 
    });
    setIsLogged(true);
    if (data.client.institute) {
      setIsPro(true);
    } else {
      setIsPro(false);
    }
  };
  return connectInFront;
};

export const disconnectUserInBackend = async (token) => {
  return fetch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/auth/client/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const DisconnectUserInFront = () => {
  const { setIsLogged, setUserGlobal, setIsPro, isPro } = useContext(LoggedContext);

  const disconnectInFront = (isAmodal) => {
    setTimeout(() => {
      localStorage.removeItem("selectedChat");
      Cookies.remove("authToken");
      setIsLogged(false);
      setUserGlobal({});
      if (isPro) setIsPro(false);
      if (!isAmodal) window.location.href = "/login";
    }, 1000);
  };
  return disconnectInFront;
};
