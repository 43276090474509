import { Spinner } from "@material-tailwind/react";
import React from "react";

const LoadingCard = () => {
  return (
    <li
      className={`cursor-pointer snap-center md:min-w-[284px] w-[100%] h-[100%] items-stretch justify-stretch md:w-[6%] md:h-full overflow-hidden`}>
      {/*  shop infos */}
      <div className="grow px-5 py-3 flex flex-col gap-0 items-start backdrop-sm rounded-t-3xl bg-[#ffffff9c] overflow-hidden h-[30%] z-10">
        <div className="flex flex-row items-center gap-1">
          <span className="text-xs"></span>
        </div>
        <span className="text-xs font-roboto  line-clamp-2 "></span>
      </div>
      {/* shop image */}
      <div className="md:h-[60%] h-[30svh] rounded-b-3xl flex justify-center items-center  overflow-hidden md:-mt-4 z-20 bg-[#ffffff9c]">
        <Spinner />
      </div>
    </li>
  );
};

export default LoadingCard;
