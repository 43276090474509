import React from 'react'
import { Dialog, DialogContent, DialogTrigger, DialogTitle } from "Component/ui/dialog";

const ContactForm = () => {
  return (
		<Dialog className='hello1'>
			<DialogTrigger aria-label='voir toutes les photos' className='rounded-full hover:bg-black hover:text-white bg-white text-black w-[100%]'>
				Nous contacter
			</DialogTrigger>

			<DialogContent
				id='contact_form'
				className='text-black font-roboto p-0 px-6 gap-4 flex flex-center flex-col bg-beige w-full md:w-10/12 !rounded-3xl'>
              <DialogTitle className="py-4 font-syne font-bold">Nous contacter</DialogTitle>
            <hr />
              <div className="flex flex-row justify-between items-start">
                  <div className='flex flex-col gap-1'>
                        <span>Stunify@email.fr </span>
                        <span>+41 (0) 78 123 56 78</span>
                        
                  </div>
                  <div className='flex flex-col'>
                      <span>Stunify </span>
                      <span>Lorem, ipsum, 5</span>
                      <span>1234 Ville</span>
                  </div>
              </div>
              <hr />
				<form className='flex flex-col gap-6 w-full'>
					<label htmlFor='email'>
						email
						<input type='email' id='email' placeholder='Email' className='input focus:bg-transparent rounded-xl' />
					</label>
					<label htmlFor='name'>
						votre nom
						<input type='text' id='name' placeholder='Votre nom' className='input focus:bg-transparent rounded-xl' />
					</label>
					<label htmlFor='message'>
						votre message
						<textarea id='message' placeholder='Votre message...' className='input focus:bg-transparent rounded-xl' />
					</label>
					<button type='submit' className='btn-dark my-4 w-full'>
						envoyer
					</button>
				</form>
			</DialogContent>
		</Dialog>
  );
}

export default ContactForm