import React, { useRef, useEffect } from "react";
import SMScodeInput from "./SMScodeInput";
import { formatPhoneNumber } from "react-phone-number-input";
import { useNewUser } from "../../Context/UserAuthContext";
import {HiXMark} from "react-icons/hi2";
import SmSpinner from "Component/ui/SmSpinner";

const UserComfirmPhoneForm = ({ setStep, isAmodal }) => {
	const { newUser , signupLod } = useNewUser();
	const wrapperRef = useRef(null);
	
	
	 const handleClickOutside = (event) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setStep(1);
			}
		};

		useEffect(() => {
			const handleClick = (event) => {
				handleClickOutside(event);
			};

			const handleKeydown = (event) => {
				if (event.key === "Escape") {
					setStep(1);
				}
			};

			document.addEventListener("mousedown", handleClick);
			document.addEventListener("keydown", handleKeydown);

			return () => {
				document.removeEventListener("mousedown", handleClick);
				document.removeEventListener("keydown", handleKeydown);
			};
		}, [wrapperRef, setStep]);
	

	const formatedPhoneNumber = formatPhoneNumber(newUser.phone);

	const handelEditPhoneNumber = () => {
		setStep(1);
		document.getElementById("telephone").focus();
  };
  
  

	return (
		<div
			ref={wrapperRef}
			data-confirm-phone-form
			className='absolute inset-0 shadow-2xl mx-auto translate-y-1/2 flex flex-col items-start gap-6 w-11/12 sm:w-9/12 lg:w-7/12 xl:w-[360px] h-fit bg-beige rounded-xl'>
			<div className='w-full relative'>
				<button
					type="button"
					aria-label='fermer la boite de dialogue'
					className="absolute top-4 right-4"
					onClick={() => {
						setStep(1);
					}}>
					<HiXMark className='w-6 h-6 ' />
				</button>

				<h3 className='p-4 pb-0 text-xl'>Confirmation par SMS</h3>
				<hr className='h-px my-2 bg-light_grey border-0 w-full' />
				
				<p className='p-4'>
					Veuillez saisir le code confirmation envoyé par SMS au {formatedPhoneNumber}
					<br />
					<button type="button" aria-label="changer le numero de telephone" className='underline cursor-pointer' onClick={handelEditPhoneNumber}>
						Changer de numéro
					</button>
				</p>
			</div>
			<div className='ml-6'>
				<SMScodeInput isAmodal={isAmodal} />
			</div>
			
		</div>
	);
};

export default UserComfirmPhoneForm;
