import { useChatContext } from "Context/ChatContext";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { Star, Trash, Search, Info, Send } from "./Icon";
// import profile from '../../assets/profile.jpeg'
import Messages from "./Messages";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

const ChatwWindow = ({ socket }) => {
  const {
    setToggleChatDisplay,
    toggleChatDisplay,
    setChatList,
    chatList,
    isInstitute,
    selectedChat,
    fetchMessages,
    fetchMoreMessage,
    setMessagesHasmore,
    messagesHasmore,
    postMessage,
    setNewmessage,
  } = useChatContext();
  const { type } = useParams();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [message, setMessage] = useState("");
  const [userTyping, setUserTyping] = useState(false);
  const [senderTyping, setSenderTyping] = useState(false);
  const [focus, setFocus] = useState(true);
  const location = useLocation();
  useEffect(() => {
    setPage(0);
    setMessagesHasmore(true);
    fetchMessages(selectedChat._id, false, true);
  }, [selectedChat]);

  const fetchMoreData = () => {
    fetchMoreMessage(messagesHasmore, page + 1, perPage);
    setPage(page + 1);
  };

  const handleSubmit = (e) => {
    const decoded = jwt_decode(Cookies.get("authToken"));
    const receiver = isInstitute ? selectedChat.client : selectedChat.institute;
    e.preventDefault();
    setNewmessage({
      chatId: selectedChat._id,
      _id: uuidv4(),
      text: message,
      receiver: receiver._id,
      senderId: decoded.id,
      isInstitute,
      createdAt: moment(),
    });
    postMessage(selectedChat, message);
    setMessage("");
  };
  ///////////////////////////////////////////////////////////////////////// Sending is typing socket /////////////////////////////////////////////////////////////
  useEffect(() => {
    const receiver = isInstitute ? selectedChat.client : selectedChat.institute;
    if (socket) {
      if (message.length === 1) {
        socket.emit("userTyping", {
          receiverId: receiver._id,
          userTyping: true,
          chatId: selectedChat._id,
        });
      }
      if (message.length <= 0) {
        socket.emit("userTyping", {
          receiverId: receiver._id,
          userTyping: false,
          chatId: selectedChat._id,
        });
      }
      const interval = setInterval(() => {
        if (message) {
          socket.emit("userTyping", {
            receiverId: receiver._id,
            userTyping: true,
            chatId: selectedChat._id,
          });
        }
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [message, socket]);
  ///////////////////////////////////////////////////////////////////////// Receiving is typing socket /////////////////////////////////////////////////////////////
  window.onblur = function () {
    setFocus(false);
  };
  window.onfocus = function () {
    setFocus(true);
  };
  useEffect(() => {
    let typingTimeout;
    if (socket) {
      setMessage("");
      let temp = {};

      const handleUserTyping = (payload) => {
        clearTimeout(typingTimeout);

        if (payload.chatId === selectedChat._id) {
          setSenderTyping(payload.userTyping);
          temp = payload;

          typingTimeout = setTimeout(() => {
            setSenderTyping(false);
          }, 2000);
        } else {
          setSenderTyping(false);
          temp = payload;
        }
      };

      handleUserTyping(temp);
      socket.on("isTyping", (payload) => handleUserTyping(payload));
    }

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [selectedChat, socket]);
  /////////////////////////////////////////////////////////////////Sending openned chat Socket /////////////////////////////////////////////////////////////////
  useEffect(() => {
    const decoded = jwt_decode(Cookies.get("authToken"));
    // console.log(decoded)
    if (socket) {
      if (selectedChat && focus && location.pathname.slice(0, 6) === "/chat/") {
        socket.emit("openChat", {
          chatId: selectedChat._id,
          userId: decoded.id,
          isOpen: true,
        });
        let temp = chatList.map((chat) => {
          if (chat._id === selectedChat._id) {
            return { ...chat, unseenMessages: 0 };
          }
          return chat;
        });
        setChatList(temp);
      }
      return () => {
        socket.emit("openChat", {
          chatId: selectedChat._id,
          userId: decoded.id,
          isOpen: false,
        });
      };
    }
  }, [selectedChat, focus]);

  return (
    <div
      className={`${
        toggleChatDisplay ? "xxs:flex xxs:flex-col xxs:h-full " : "xxs:hidden"
      } md:h-[91%] grow md:gap-4 rounded-2xl  md:flex md:flex-col border-[1px] border-[#d1d1d14d] text-white xxs:gap-1  ${
        isInstitute ? "" : "bg-slate-400"
      } `}>
      <div
        onClick={() => setToggleChatDisplay(false)}
        className="bg-[#231F1C] md:hidden xxs:flex xxs:h-[7%] xxs:justify-end xxs:items-center xxs:p-3">
        <div className="border-[1px] border-[#696969] p-1 rounded-[6px]">
          <IoMenu className="text-white text-opacity-70" size={20} />
        </div>
      </div>
      <div className="md:h-[10%] flex  pb-[21px] items-center gap-3 md:border-b-[1px] md:border-[#d1d1d14d]  xxs:px-3 xxs:border-0 xxs:pb-0 xxs:max-h-[12%]">
        {/* <div className="bg-gray-500 rounded-full aspect-square md:h-[40%] xxs:h-[60%]"></div> */}
        {/* <img src={profile} className="h-[50%] rounded-full aspect-square" alt="Profile"/> */}
        <div className="flex flex-col text-sm grow">
          {type === "institute"
            ? selectedChat.client.firstName.charAt(0).toUpperCase() +
              selectedChat.client.firstName.slice(1) +
              " " +
              selectedChat.client.lastName
            : selectedChat.institute?.firstName}
          <span className="text-white text-opacity-40 text-sm -mt-1">Active Now</span>
        </div>
        <div className=" px-2 py-1 rounded-[5px] flex gap-1">
          <Search size={20} />
          <Star size={20} />
          <Trash size={20} />
          <Info size={20} />
        </div>
      </div>
      <Messages />
      {/* <p>{senderTyping && "Typing ........."}</p> */}
      <form
        className="grow flex gap-2 md:h-[10%] xxs:max-h-[12%] md:mt-0    md:p-0 xxs:px-3 xxs:mt-4 "
        onSubmit={handleSubmit}>
        <div className="w-full  flex  rounded-[5px] items-center px-3 py-2 md:h-full xxs:h-[50%] xxs:m-auto">
          <input
            placeholder="Message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="px-6 w-full bg-transparent border-[#E9E9EB] border-[1px] rounded-[21px] h-[50%]"
          />
          <div className={` cursor-pointer text-black  xxs:text-[100%] -translate-x-1 `}>
            <Send  />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChatwWindow;
