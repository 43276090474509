import React, { useEffect, useState, useCallback, useContext } from "react";
import Card from "../../../Component/ProConnected/Card";
import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import { Tooltip } from "@material-tailwind/react";
import LoggedContext from "Context/isLogged";
import { MdOutlineHomeRepairService } from "react-icons/md";
import { HiChevronDown } from "react-icons/hi";
import { HiOutlineRectangleGroup } from "react-icons/hi2";
import { LiaClockSolid } from "react-icons/lia";
import { IoIosArrowForward } from "react-icons/io";
import { IoNotificationsOutline } from "react-icons/io5";
import { HiOutlineBanknotes, HiArrowRightOnRectangle } from "react-icons/hi2";
import { BiChevronDown, BiTransfer } from "react-icons/bi";
import { CiViewList } from "react-icons/ci";
import { useCaisseContext } from "Context/CaisseContext";
import "../../../styles/Caisse.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-hot-toast";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "Component/ProConnected/Checkout";
import { createPaymentIntent } from "Component/Reservation/apiServices";
import LoadSpinner from "Component/utils/LoadSpinner";
import ConfirmModal from "Component/ProConnected/ConfirmPyamentModal";
import AddPrestationModal from "Component/ProConnected/AddPrestationModal";
import {
  DisconnectUserInFront,
  disconnectUserInBackend,
} from "Component/utils/connectionHandler";
import Cookies from "js-cookie";
const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLIC_KEY_DEV}`
);
// import profile from "./../../../assets/profile.jpeg";
function CaiseDash() {
  const {
    reservations,
    fetchReservations,
    hasMore,
    fetchMore,
    setReservations,
    saveTransaction,
    setHasMore,
    selectedReservation,
    setSelectedReservation,
    fetchInstitute,
    institute,
    updatePrestations,
    setNewPrestationsIds,
  } = useCaisseContext();
  const {isPro} = useContext(LoggedContext)
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [paymentMethod, setPaymentMethod] = useState("creditCard");
  const [showPaymentCard, setShowPaymentCard] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [amountToPay, setAmountToPay] = useState();
  const [showModal, setShowModal] = useState(false);
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [showAddPrestationModal, setShowAddPrestationModal] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const appearance = {
    theme: "flat",

    rules: {
      ".Input": {
        border: "1px solid #F6F0E8",
        backgroundColor: "transparent",
        borderRadius: "12px",
      },
      ".Input::placeholder": {
        color: "#F6F0E8",
      },
      ".Label": {
        color: "#F6F0E8",
      },
    },
  };

  const stripeOptions = {
    // passing the client secret obtained from the server
    externalPaymentMethodTypes: ["external_paypal", "external_postfinance"],
    appearance,
  };

  useEffect(() => {
    fetchInstitute();
    fetchReservations(false, hasMore, page, perPage);
    return () => {
      setSelectedReservation(undefined);
      setReservations([]);
      setHasMore(true);
      setNewPrestationsIds([]);
    };
  }, []);

  const fetchMoreData = () => {
    fetchMore(hasMore, page + 1, perPage, true, showAll);
    setPage(page + 1);
  };

  useEffect(() => {
    if (selectedReservation) {
      setShowPaymentCard(false);
      window.scrollTo(0, 0);
      if (
        selectedReservation.payment &&
        selectedReservation.payment.length > 0 &&
        selectedReservation?.payment[0]?.amountPaid
      ) {
        setAmountToPay(
          selectedReservation.totalPrice -
            selectedReservation.payment[0].amountPaid / 100
        );
      } else {
        setAmountToPay(selectedReservation.totalPrice);
      }
    }
  }, [selectedReservation]);

  const pay = async () => {
    if (!amountToPay) {
      toast.error("Invalide reservation");
    } else {
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (confirmPayment && amountToPay) {
      const save = async () => {
        try {
          setLoading(true);
          await saveTransaction(
            selectedReservation,
            amountToPay,
            paymentMethod === "creditCard" ? "credit card" : "cash"
          );
          setSelectedReservation(null);
          setLoading(false);
          toast.success("Paiement effectué avec succès");
          setConfirmPayment(false);
        } catch (e) {
          setLoading(false);
          toast.error(`Une erreur est survenue: ${e}`);
        }
      };
      save();
    }
  }, [confirmPayment]);

  const { userGlobal } = useContext(LoggedContext);
  const disconnectUserInFront = DisconnectUserInFront();

  const handleUserDisconnection = () => {
    let token = Cookies.get("authToken");
    disconnectUserInBackend(token).then((res) => {
      if (!res.ok) {
        // console.log("error");
      }
      disconnectUserInFront();
      toast.success("Vous êtes déconnecté");
    });
  };

  const handleShowAll = () => {
    setSelectedReservation(undefined);
    setReservations([]);
    setNewPrestationsIds([]);
    setHasMore(true);
    setPage(0);
    fetchReservations(false, true, 0, perPage, !showAll);
    setShowAll(!showAll);
  };

  return (
    <>
      {/* header */}
      <div className="flex flex-row justify-between items-center  md:w-[80%]  w-full container mx-auto p-3 border-b-2 border-[#4A4A49]  ">
        <div className="flex flex-row justify-between items-center gap-4 font-syne">
          <div className=" flex flex-col justify-between items-center space-y-1   ">
            <HiOutlineBanknotes size={20} className="text-[#FFC77C]" />
            <p className="">Caisse</p>
          </div>
          <Link
            to="/ProConnected/caissedetails"
            className=" flex flex-col  justify-between items-center space-y-1"
          >
            <BiTransfer size={20} />
            <p className="text-[#A29689]">Transaction</p>
          </Link>
        </div>
        <div>
          <div className="flex flex-row justify-between items-center space-x-4">
            <IoNotificationsOutline
              size={30}
              className="absolute cursor-pointer"
            />
            <span className="bg-[#F59E0B] h-2 w-2 rounded-full relative bottom-2 right-0  cursor-pointer"></span>
            <div className="cursor-pointer">
              {userGlobal.imageUrl ? (
                <img
                  src={userGlobal.imageUrl}
                  alt="user profile PIC"
                  className="w-8 h-8 rounded-full"
                />
              ) : (
                <span className="tracking-wide" id="ProprofileImage">
                  {userGlobal.firstName[0]?.toUpperCase() +
                    userGlobal.lastName[0]?.toUpperCase()}
                </span>
              )}
            </div>

            <DropdownMenu>
              <DropdownMenuTrigger className="flex flex-row justify-between items-center space-x-1 cursor-pointer group">
                <p className="text-[#F6F0E8] text-sm not-italic font-bold capitalize leading-[normal] font-roboto cursor-pointer">
                  {userGlobal.firstName}
                </p>
                <HiChevronDown className="w-5 h-5 transition duration-500 ease-in-out transform scale-y-100 group-data-[state=open]:-scale-y-100 " />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="bg-[#2D2E2F] text-white mx-8 rounded-xl">
                <Link to={!isPro? '/profile' : '/proconnected/caisseDash'}>
                  <DropdownMenuItem className="pl-7 py-3 hover:underline flex flex-row items-center gap-2 text-white">
                    <HiOutlineRectangleGroup className="h-6 w-6" />
                    <span className="text-white">Dashboard</span>
                  </DropdownMenuItem>
                </Link>
                <DropdownMenuSeparator />
                <Link to="/pro">
                  <div className="hover:underline flex flex-row gap-2 justify-start py-3 pl-6 pr-20 cursor-pointer">
                    <MdOutlineHomeRepairService className="w-6 h-6 text-white" />
                    <span className="text-white">Pro Home</span>
                  </div>
                </Link>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={handleUserDisconnection}>
                  <div className="hover:underline flex flex-row gap-2 justify-start py-3 pl-6 pr-20 cursor-pointer">
                    <HiArrowRightOnRectangle className="w-6 h-6 text-white" />
                    <span className="text-white">Se déconnecter</span>
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      {/* header -end */}
      <div className="w-full flex flex-row mt-2">
        <div className="w-full">
          <div className="flex md:flex-row flex-col-reverse w-full md:container md:mx-auto px-2 md:px-0 p-0 md:p-2 md:space-x-10  space-x-0 md:w-[95%]  ">
            <div className= " max-h-[98vh] flex flex-col gap-3 md:w-1/2  w-full ">
              <h5 className=" w-full text-lg not-italic font-normal p-2 leading-[19px] mt-4 md:mt-0  font-roboto text-[#A29689] md:mb-4">
                Choisissez le rendez-vous que vous venez de faire.
              </h5>
              {/* card */}
              <div className="flex justify-between p-2 text-sm">
                <h6 className="text-sm">
                  {showAll
                    ? "Tous les rendez-vous(non payés):"
                    : "Tous les rendez-vous d'aujourd'hui(non payés):"}
                </h6>
                <Tooltip
                  animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0, y: 25 },
                  }}
                  className="border text-black border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10"
                  content={
                    showAll
                      ? "Cliquez pour afficher uniquement les rendez-vous d'ajourd'hui."
                      : "Cliquez pour afficher tous les rendez-vous."
                  }
                >
                  <div className="cursor-pointer" onClick={handleShowAll}>
                    <CiViewList
                      size={23}
                      color={showAll ? "#ffc77c" : "white"}
                    />
                  </div>
                </Tooltip>
              </div>
              <div className="flex flex-col gap-3 overflow-overlay">
                <div
                  id="scrollableDiv"
                  style={{ height: "98vh", overflow: "auto" }}
                >
                  <InfiniteScroll
                    dataLength={reservations.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<h5 className="text-center">Loading...</h5>}
                    endMessage={
                      <p className="text-center">
                        <b>No more data.</b>
                      </p>
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    {reservations.map((reservation, i) => (
                      <div key={reservation._id + i}>
                        {selectedReservation && (
                          <Card
                            reservation={reservation}
                            selectedReservation={selectedReservation}
                            setSelectedReservation={setSelectedReservation}
                            showAll={showAll}
                          ></Card>
                        )}
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
            {/* right */}
            <div className="flex flex-col  md:w-1/2   md:p-5  w-full   mx-auto  rounded border border-[#4A4A49] border-solid p-2  ">
              <div className="border-b-[1px] ">
                <h1 className="mb-2 font-syne text-2xl font-bold">Encaisser</h1>

                {!loading && selectedReservation && !showPaymentCard && (
                  <div className=" my-5 justify-items-center bg-[#4A4A4966] py-2 px-5 rounded font-roboto   text-lg not-italic font-bold leading-[19px]">
                    <ul className="list-disc ml-1">
                      {selectedReservation?.prestation?.map((prestation, i) => (
                        <li key={prestation.name + i}>
                          <div className="flex justify-between font-roboto text-xs md:text-base not-italic font-bold leading-[19px] mr-1">
                            <div>{prestation.name}</div>
                            <div className="end-0">{prestation.price} CHF</div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {!loading && selectedReservation && !showPaymentCard && (
                  <div className="flex flex-row items-center">
                    <div
                      onClick={() => setShowAddPrestationModal(true)}
                      className="cursor-pointer mb-6 flex flex-row   items-center space-x-3 py-3 p-6  rounded-[30px] border-solid  border border-[#F6F0E8]  "
                    >
                      <span>+</span>
                      <div>Ajouter une autre prestation</div>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="mt-8">
                <h1 className="mb-2 font-syne text-lg font-bold">
                  Fidélité Client
                </h1>
                <div className="flex  lg:flex-row flex-col justify-between items-center">
                  {/* SCORE  Fidélité }
                  <div className="mt-1 flex flex-row space-x-1 w-full justify-center lg:justify-start">
                    <div className="rounded-[5px] bg-[#A29689] w-3 h-4 "></div>
                    <div className="rounded-[5px] bg-[#4A4A49] w-3 h-4"></div>
                    <div className="rounded-[5px] bg-[#4A4A49] w-3 h-4"></div>
                    <div className="rounded-[5px] bg-[#4A4A49] w-3 h-4"></div>
                    <div className="rounded-[5px] bg-[#4A4A49] w-3 h-4"></div>
                    <div className="rounded-[5px] bg-[#4A4A49] w-3 h-4"></div>
                    <div className="rounded-[5px] bg-[#4A4A49] w-3 h-4"></div>
                    <div className="rounded-[5px] bg-[#4A4A49] w-3 h-4"></div>
                    <div className="rounded-[5px] bg-[#4A4A49] w-3 h-4"></div>
                    <div className="rounded-[5px] bg-[#4A4A49] w-3 h-4"></div>
                  </div>

                  {/* PROMO  }
                  <div className="bg-black border border-[#F6F0E8)] mt-4 lg:mt-0 rounded-[30px] border-solid py-1.5 px-3  lg:py-3 lg:px-6 ">
                    <select
                      name="promo"
                      className="bg-black text-white outline-none focus:border-white "
                    >
                      <option value="volvo">Choisir une promo</option>
                      <option value="promo 1">promo 1</option>
                      <option value="promo 2">promo 2</option>
                      <option value="promo 3">promo 2</option>
                    </select>
                  </div>
                </div>
              </div> */}
              {/* Détails */}
              {!loading &&
                selectedReservation &&
                selectedReservation.prestation &&
                !showPaymentCard && (
                  <div className="lg:mt-[15%]  mt-[10%] w-full">
                    <h1 className="mb-3 font-syne text-lg font-bold">
                      Détails
                    </h1>
                    {selectedReservation?.prestation?.map((prestation, i) => (
                      <div
                        key={prestation.name + i}
                        className="flex flex-row justify-between  items-center w-full mt-4 font-roboto text-sm md:text-base"
                      >
                        <p>{prestation.name}</p>
                        <div className="flex-grow border-b border-[#4a4a49] mt-1 mx-2"></div>

                        <p>{prestation.price} CHF</p>
                      </div>
                    ))}
                    {/* <div className="flex flex-row justify-between items-center mt-4  font-roboto text-sm md:text-base">
                  <p>Promo</p>
                  <div className="flex-grow border-b border-[#4A4A49] mt-1 mx-2 "></div>
                  <p>-0 CHF</p>
                </div> */}

                    {selectedReservation &&
                      selectedReservation.payment[0]?.amountPaid && (
                        <div className="flex flex-row justify-between items-center mt-4  font-roboto text-sm md:text-base">
                          <p>Déjà payé</p>
                          <div className="flex-grow border-b border-[#4A4A49] mt-1 mx-2 "></div>
                          <p>
                            -{selectedReservation?.payment[0]?.amountPaid / 100}
                            <> CHF</>
                          </p>
                        </div>
                      )}

                    <div className="flex flex-row justify-between items-center mt-9   font-bold ">
                      <p className=" font-syne  text-2xl not-italic font-bold leading-[normal]">
                        Total
                      </p>
                      <div className="flex-grow border-b border-[#4A4A49] mt-1 mx-2 "></div>
                      <p className="font-roboto  text-right text-xl not-italic font-bold leading-[19px]">
                        <>{amountToPay} </>
                        <span className="text-white text-right text-base not-italic font-normal leading-[normal]">
                          CHF
                        </span>
                      </p>
                    </div>
                    {/* Méthode de paiement */}
                    <div className="p-5  mt-6 rounded border border-[#4A4A49] border-solid ">
                      <h1 className="font-syne text-lg not-italic font-bold leading-[normal] mb-4">
                        Méthode de paiement
                      </h1>

                      <div className="flex lg:flex-row flex-col  w-full lg:space-x-2  space-y-2 lg:space-y-0 space-x-0 justify-center">
                        <label
                          className="rounded-lg h-16 w-full"
                          style={{ backgroundColor: "rgba(74, 74, 73, 0.50)" }}
                        >
                          <div className="flex flex-row space-x-5 items-center w-full h-full mx-2">
                            <input
                              type="radio"
                              name="radio"
                              id="Carte"
                              value="creditCard"
                              defaultChecked
                              onChange={(e) => setPaymentMethod(e.target.value)}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="17"
                              viewBox="0 0 24 17"
                              fill="none"
                            >
                              <rect
                                x="1.35938"
                                y="1"
                                width="22"
                                height="15"
                                rx="2"
                                fill="white"
                                stroke="#D9D9D9"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.88454 11.3382H6.49119L5.44634 7.29427C5.39675 7.10825 5.29145 6.94379 5.13656 6.86628C4.75001 6.6715 4.32405 6.51649 3.85938 6.4383V6.28261H6.10396C6.41374 6.28261 6.64608 6.51649 6.68481 6.7881L7.22693 9.70511L8.61961 6.28261H9.97424L7.88454 11.3382ZM10.7487 11.3382H9.43279L10.5164 6.28261H11.8323L10.7487 11.3382ZM13.5347 7.68316C13.5735 7.41087 13.8058 7.25518 14.0769 7.25518C14.5028 7.21609 14.9668 7.29427 15.354 7.48838L15.5864 6.39989C15.1991 6.2442 14.7732 6.16602 14.3866 6.16602C13.1095 6.16602 12.1801 6.86629 12.1801 7.83817C12.1801 8.57754 12.8384 8.96575 13.3031 9.19963C13.8058 9.43283 13.9994 9.58852 13.9607 9.82172C13.9607 10.1715 13.5735 10.3272 13.1869 10.3272C12.7222 10.3272 12.2575 10.2106 11.8323 10.0158L11.5999 11.105C12.0646 11.2991 12.5673 11.3773 13.032 11.3773C14.4641 11.4157 15.354 10.7161 15.354 9.66602C15.354 8.34366 13.5347 8.26616 13.5347 7.68316ZM19.9594 11.3382L18.9145 6.28261H17.7922C17.5599 6.28261 17.3276 6.4383 17.2501 6.6715L15.3153 11.3382H16.67L16.9403 10.5995H18.6048L18.7596 11.3382H19.9594ZM17.9858 7.64407L18.3724 9.54943H17.2888L17.9858 7.64407Z"
                                fill="#172B85"
                              />
                            </svg>
                            <label htmlFor="Carte">Carte bancaire</label>
                          </div>
                        </label>

                        <label
                          className="rounded-lg h-16 w-full"
                          style={{ backgroundColor: "rgba(74, 74, 73, 0.50)" }}
                        >
                          <div className="flex flex-row space-x-5 items-center w-full h-full mx-2">
                            <input
                              type="radio"
                              name="radio"
                              id="Especes"
                              value="cash"
                              onChange={(e) => setPaymentMethod(e.target.value)}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="17"
                              viewBox="0 0 24 17"
                              fill="none"
                            >
                              <rect
                                x="1.42969"
                                y="1"
                                width="22"
                                height="15"
                                rx="2"
                                fill="white"
                                stroke="#D9D9D9"
                              />
                              <path
                                d="M17.029 7.68542C16.9816 7.39675 16.7207 7.20429 16.365 7.20429C16.2465 7.20429 16.1516 7.20429 16.0331 7.20429C15.8196 7.18024 15.7722 7.25241 15.7722 7.4208C15.7722 8.26278 15.7722 9.0807 15.7722 9.92268C15.7722 10.3557 15.3454 10.8128 14.8474 10.909C13.2824 11.2698 11.7173 11.6066 10.1523 11.9675C9.98629 12.0156 9.79659 12.0156 9.60689 12.0156C9.53575 12.0156 9.41718 11.9675 9.39347 11.8953C9.36976 11.8472 9.4409 11.751 9.48832 11.7029C9.55946 11.6547 9.65431 11.6066 9.74916 11.5585C10.0337 11.4623 10.342 11.3661 10.6265 11.2698C11.7885 10.8849 12.9504 10.5 14.1123 10.0911C14.634 9.92268 14.9186 9.56183 14.9186 9.10476C14.9423 7.66137 14.9186 6.19392 14.9186 4.75053C14.9186 4.50997 14.8 4.31752 14.5391 4.22129C14.2309 4.10101 13.9463 4.19723 13.6855 4.31752C12.0256 5.11138 10.3894 5.90525 8.72951 6.69911C8.25525 6.93967 7.9707 7.27646 7.9707 7.75759C7.9707 9.12881 7.9707 10.5 7.9707 11.8713C7.9707 11.9434 7.9707 12.0156 7.99441 12.0637C8.1604 12.5208 8.58724 12.8095 9.15634 12.8095C10.3183 12.8095 11.4802 12.8095 12.6421 12.8095C13.8041 12.8095 14.9423 12.8095 16.1042 12.8095C16.365 12.8095 16.5784 12.7613 16.7682 12.5929C17.0053 12.4005 17.0053 12.1359 17.0053 11.8953C17.0053 10.7118 17.029 7.68542 17.029 7.68542ZM12.1916 7.25241C12.3101 6.93968 12.571 6.74722 12.9504 6.69911C13.2112 6.67505 13.4246 6.81939 13.4721 7.05996C13.5195 7.4208 13.0927 7.85382 12.6421 7.85382C12.2864 7.85382 12.073 7.5892 12.1916 7.25241Z"
                                fill="#FD6727"
                              />
                            </svg>
                            <label htmlFor="Especes">Espèces</label>
                          </div>
                        </label>
                      </div>
                      {/* Encaisser btn */}
                      <div
                        onClick={pay}
                        className="flex justify-center cursor-pointer items-center gap-3 self-stretch pl-[25px] pr-[23px] py-[13px] mt-9  rounded-[30px] bg-[#F6F0E8]  "
                      >
                        <button className="text-[#0F0F0F]">Encaisser</button>
                        <IoIosArrowForward size={25} color="0F0F0F" />
                      </div>
                    </div>
                  </div>
                )}
              {!loading &&
                selectedReservation &&
                showPaymentCard &&
                clientSecret && (
                  <div className="mt-6">
                    <Elements
                      stripe={stripePromise}
                      options={{ ...stripeOptions, clientSecret }}
                    >
                      <Checkout
                        reservations={reservations}
                        selectedReservation={selectedReservation}
                        setSelectedReservation={setSelectedReservation}
                        saveTransaction={saveTransaction}
                        amountToPay={amountToPay}
                      />
                    </Elements>
                  </div>
                )}
              {loading && <LoadSpinner />}
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        amountToPay={amountToPay}
        setConfirmPayment={setConfirmPayment}
      />
      <AddPrestationModal
        showModal={showAddPrestationModal}
        setShowModal={setShowAddPrestationModal}
        prestations={institute?.prestations}
        updatePrestations={updatePrestations}
        idReservation={selectedReservation?._id}
      />
    </>
  );
}

export default CaiseDash;
