import { useChatContext } from "Context/ChatContext";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import "../../styles/Agenda.css";

const ChatCard = ({ chat }) => {
  const { type } = useParams();
  const { setSelectedChat, selectedChat, chatList, setChatList, setToggleChatDisplay } =
    useChatContext();

  const chatListRef = useRef(chatList);
  chatListRef.current = chatList;
  const handleClick = (id) => {
    const updatedChatList = chatListRef.current.map((item) => {
      if (item._id === id) {
        return {
          ...item,
          unseenMessages: 0,
        };
      }
      return item;
    });
    setChatList(updatedChatList);
  };

  return (
    <div
      onClick={() => {
        
        setSelectedChat(chat);
        handleClick(chat._id);
        setToggleChatDisplay(true)
      }}
      className={` cursor-pointer flex md:h-[10%] md:px-3 xxs:px-3     xxs:mt-3 grow gap-5  justify-around items-center xxs:flex  xxs:h-[12%] ${
        selectedChat?._id === chat._id
          ? "bg-[#70707017]"
          : "border-l-[6px] border-l-transparent"
      }`}>
      <div className="md:h-[70%] aspect-square bg-black rounded-full xxs:h-[65%]"></div>
      <div className="md:grow md:h-[80%]  [font-family:'Sora-SemiBold',Helvetica] font-semibold text-white text-[13px] tracking-[0] leading-[normal] lg:text-base md:text-sm  xxs:grow ">
        {type === "institute"
          ? chat.client.firstName.charAt(0).toUpperCase() +
            chat.client.firstName.slice(1) +
            " " +
            chat.client.lastName
          : chat.institute?.firstName}

        <div className="md:w-[9%] -translate-y-1">
          <span className="text-neutral-400 text-xs font-normal font-['Sora']">{chat.lastMessage?.text}</span>
          {/* <span className="text-gray-500">
            {chat.lastMessage?.text &&
              moment(chat.lastMessage?.createdAt).format("HH:mm")}
          </span> */}
        </div>
      </div>
      
      <div className="md:w-[10%] flex -translate-y-3 justify-end">
        {chat.unseenMessages > 0  && (
          <div className="bg-[#B6BE52]  rounded-full aspect-square w-[30px] flex justify-center items-center">
            <span className="text-base font-bold text-black ">{chat.unseenMessages}</span>
          </div>
        )}

        {/* <span>{chat.isTyping && "user Typing"}</span> */}
      </div>
    </div>
  );
};

export default ChatCard;
