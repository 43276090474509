import { useParams, useNavigate, Link } from "react-router-dom";
import MapGoogle from "Component/utils/MapGoogle";
import Avis from "Component/Salons/Avis";
import About from "./AboutResume";
import GalleryModal from "Component/Salons/GalleryModal ";
import ShowAllPhotosModal from "./ShowAllPhotosModal";
//icons
import { FaStar } from "react-icons/fa6";
import { BsGift } from "react-icons/bs";
import { BiGridAlt } from "react-icons/bi";
import { IoIosArrowRoundBack } from "react-icons/io";

import React, { useState, useContext, useEffect, useRef } from "react";
import { useNewInstitute } from "Context/InstituteAuthContext";

import LoggedContext from "Context/isLogged";
import { registerInstitute } from "./ApiService";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";
//icons
import {
  HiOutlineClipboard,
  HiOutlinePhoto,
  HiChevronLeft,
  HiChevronRight,
  HiOutlineUsers,
} from "react-icons/hi2";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";

const Resume = ({ handleStepChange }) => {
  const { setIsPro, toggleUserState, setUserGlobal } = useContext(LoggedContext);
  const [salon, setSalon] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAllPrestations, setShowAllPrestations] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [allPhotos, setAllPhotos] = useState();
  const [photosFromInput, setPhotosFromInput] = useState([]);
  const [photosFromGgAPI, setPhotosFromGgAPI] = useState([]);
  const [salonLocation, setSalonLocation] = useState([]);
  const sliderRef = useRef(null);

  const navigate = useNavigate();

  const { newInstitute } = useNewInstitute();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // console.log(newInstitute, "institute");

    let newPhotosArray = [];
    for (let i = 0; i < newInstitute?.photos.length; i++) {
      let element = newInstitute.photos[i];
      newPhotosArray.push(URL.createObjectURL(element));
    }
    setAllPhotos(newInstitute?.photosFromGgAPI.concat(newPhotosArray));
    if (newInstitute?.position) {
      setSalonLocation([
        {
          address: newInstitute.address,
          lat: newInstitute.position?.lat,
          lng: newInstitute.position?.lng,
        },
      ]);
    }
  }, [newInstitute]);

  // open first Category available
  useEffect(() => {
    const firstAvailableCategory = newInstitute?.prestationsCategories?.find((category) =>
      newInstitute.prestations.some((prestation) => prestation.category === category)
    );

    if (firstAvailableCategory) {
      handleCategorySelect(firstAvailableCategory);
    }
  }, [newInstitute]);

  const handleError = (message) => {
    setError(message);
  };

  const totalNumberOfPhotos = () => {
    let result = allPhotos.length;
    return result;
  };

  // calculate  opening hours
  // Get the current date and time
  const currentDate = new Date();
  const currentDay = currentDate.toLocaleDateString("fr-FR", {
    weekday: "long",
  });

  // Convert the current day to uppercase to match with api
  const currentDayUpperCase = currentDay.toUpperCase();

  // Find the business hours for the current day
  const currentDayHours =
    newInstitute &&
    newInstitute.businessHours &&
    newInstitute.businessHours.find(
      (day) => day.name.toUpperCase() === currentDayUpperCase
    );

  // Check if the salon is open today
  const isSalonOpen = currentDayHours && currentDayHours.isOpen;

  // Format the opening hours for display and start with ferme then change it if the salon is open
  let openingHoursText = "Fermé aujourd’hui";

  if (isSalonOpen) {
    const morningStart = currentDayHours.morningStart;
    const eveningEnd = currentDayHours.eveningEnd;

    if (morningStart && eveningEnd) {
      openingHoursText = `Ouvert aujourd’hui ${morningStart} - ${eveningEnd}`;
    }
  }
  const handleScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const imageWidth = sliderRef.current.scrollWidth / allPhotos.length;
    const newIndex = Math.round(scrollLeft / imageWidth);
    setCurrentIndex(newIndex);
  };
  //  handle category selection
  const handleCategorySelect = (category) => {
    setShowAllPrestations(false);
    setSelectedCategory(category);
  };

  const handleShowAllPrestations = () => {
    setSelectedCategory(null); // Reset selected category
    setShowAllPrestations(true); // Set showAllPrestations to true
  };

  const getPrestationDuration = (duration) => {
    return +duration?.split(":")[1] + +duration?.split(":")[0] * 60;
  };
  const toggleExpand = (item) => {
    const temp = [...expanded];
    const index = temp.indexOf(item.id);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(item.id);
    }
    setExpanded(temp);
  };

  const handleSendData = (e) => {
    e.preventDefault();
    setLoading(true);
    registerInstitute(newInstitute)
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(res);
        } else {
          return res.json();
        }
      })
      .then((data) => {
        Cookies.set("authToken", data.token, { expires: 3 });
        let { lastName, firstName, _id, email, phone , hasGoogleCalendarAccess , 
          isConnectedGoogle
           } = data.institute.client;
        setUserGlobal({ lastName, firstName, _id, email, phone , hasGoogleCalendarAccess , isConnectedGoogle});
        setIsPro(true);
        toggleUserState();
        toast.success("Votre salon a été créé avec succès !");
        setTimeout(() => {
          window.location.href = "/ProConnected/caisseDash";
        }, 500);
      })
      .catch((error) => {
        // Handle any errors that occurred in the previous steps
        switch (error.status) {
          case 422:
            handleError("Certains champs sont manquants ou incorrects");
            break;
          case 429:
            handleError("Trop de tentatives, veuillez réessayer plus tard");
            break;
          case 409:
            handleError("Un compte existe déjà avec ces données");
            break;
          default:
            break;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const displayPhoto = (position) => {
    let url;
    if (allPhotos[position]) {
      url = allPhotos[position];
    } else {
      url = "https://via.assets.so/img.jpg?w=400&h=300";
    }
    return <img src={url} className="w-full h-full object-cover" alt="" />;
  };

  // if the duration is less than an hour, we get rid of the hours to display only minutes
  function formatDuration(presentationDuration) {
    // Split the duration string into hours and minutes
    const [hours, minutes] = presentationDuration.split(":");

    // Check if the duration is less than an hour
    if (parseInt(hours, 10) === 0) {
      return `${parseInt(minutes, 10)}mn`;
    } else {
      return `${parseInt(hours, 10)} H ${parseInt(minutes, 10)}`;
    }
  }

  const cardContainerRef = useRef(null);

  const handleHorizontalScroll = (direction) => {
    const container = cardContainerRef.current;
    if (container) {
      const scrollAmount = direction === "next" ? 200 : -200;
      container.scrollLeft += scrollAmount;
    }
  };
  return (
    <div className="px-2 sm:px-6 mb-6 h-fit">
      <h1 className="font-syne text-2xl mt-8 font-bold text-white">
        Résumé de vos informations
      </h1>
      <p>Voici l'apparence de la page de votre salon sur le site</p>
      <hr className="my-4" />

      <div className="w-full xl:py-[4rem] xl:px-[7.5rem]  md:px-[2rem] px-0 text-[#F6F0E8]  ">
        {/* Hero pc */}
        <div className="hidden md:block ">
          <h5 className="font-bold font-syne text-[40px] mb-4 mt-4">
            {newInstitute && newInstitute.name}
          </h5>
          {/* Hero imgs */}
          <div className="flex flex-row  justify-start mb-5">
            <div className="flex flex-row items-center text-[#F6F0E8] gap-1">
              {newInstitute && newInstitute.reviews && newInstitute.reviews.length ? (
                <>
                  <FaStar size={16} color="#F6F0E8" />

                  <span className=" text-sm font-roboto">5</span>

                  <span className=" text-sm font-roboto ">
                    ({newInstitute && newInstitute.reviews && newInstitute.reviews.length}
                    )
                  </span>
                </>
              ) : null}
            </div>

            <div className=" text-sm font-roboto ml-2">
              {newInstitute && newInstitute.businessHours && openingHoursText}
            </div>
            <li className=" text-sm font-roboto ml-2">{newInstitute?.address}</li>
            <li className=" text-sm font-roboto ml-2"></li>
          </div>
        </div>

        {/* button voir plus */}

        <div className="md:flex flex-row h-[390px] gap-2 hidden">
          {/* Images loaded by the institute */}
          {allPhotos?.length > 0 && (
            <div className="w-1/2  flex flex-row gap-2 rounded-3xl overflow-hidden relative">
              <div className=" w-3/5 relative ">{displayPhoto(0)}</div>
              <div className="grid grid-rows-2 w-2/5 gap-2 grow-0">
                {displayPhoto(1)}
                {displayPhoto(2)}
              </div>
              {totalNumberOfPhotos() > 3 ? (
                <ShowAllPhotosModal photos={allPhotos} />
              ) : // ::::::::::::::::::::

              null}
            </div>
          )}
          {/* Map */}
          <div className="w-1/2 rounded-3xl overflow-hidden">
            <MapGoogle markers={salonLocation} />
          </div>
        </div>
      </div>
      {/* Hero mobile */}

      {(allPhotos?.length > 0 || newInstitute?.reviews?.length > 0) && (
        <div className=" md:hidden  mx-4">
          <div className="flex flex-col w-full ">
            {/* img */}

            {allPhotos.length > 0 && (
              <div className="relative">
                <div
                  ref={sliderRef}
                  className="flex flex-row overflow-x-scroll no-scrollbar snap-x"
                  onScroll={handleScroll}
                  style={{ scrollSnapType: "x mandatory" }}>
                  {allPhotos &&
                    allPhotos.map((image, index) => {
                      return (
                        <img
                          key={"image" + index}
                          src={image}
                          alt={index}
                          className="snap-center w-full h-[300px]  object-cover "
                        />
                      );
                    })}
                </div>
                {/* Image counter */}
                <div className="bg-black text-white p-[6px] rounded-[30px] text-xs font-roboto absolute right-2 bottom-2  gap-[10px] flex">
                  {allPhotos.length > 0 && (
                    <>
                      <span>{currentIndex + 1}</span> / <span>{allPhotos?.length}</span>{" "}
                    </>
                  )}
                </div>
              </div>
            )}
            {/* bottom of img */}
            <div className="flex flex-col space-y-2 text-[#F6F0E8] pl-4 pt-2  py-7 salonmobile h-full">
              <h5 className="font-syne text-2xl text-black">
                {newInstitute && newInstitute.name}
              </h5>
              <div className="flex flex-row gap-1 items-center ">
                {newInstitute &&
                newInstitute.reviews &&
                newInstitute.reviews.length > 0 ? (
                  <>
                    <FaStar size={16} color="#F6F0E8" />

                    <span className=" text-sm font-roboto">5</span>

                    <span className=" text-sm font-roboto ">
                      (
                      {newInstitute &&
                        newInstitute.reviews &&
                        newInstitute.reviews.length}
                      )
                    </span>
                  </>
                ) : null}
              </div>

              <p className=" text-sm font-roboto ">{newInstitute?.address}</p>
            </div>
          </div>
        </div>
      )}
      {/* Prestations section*/}

      <h3 className="font-syne font-bold tetx-[28px] hidden md:block mt-10">
        Prestations
      </h3>
      <div className=" flex-row items-center text-[#F6F0E8] gap-1 text-[16px] font-roboto mt-3  hidden md:flex mb-6">
        <p>Paiement en ligne ou sur place</p>
        {newInstitute && newInstitute.wantADeposit && (
          <li className="  ml-2">
            {" "}
            Acompte de {newInstitute && newInstitute.wantADeposit}
          </li>
        )}
        <li className="  ml-2">Confirmation immédiate</li>
      </div>
      <div className="md:flex">
        <div className=" relative w-full p-4 md:w-1/3 md:bgprestations">
          <div
            className="  overflow-x-auto no-scrollbar  mx-auto  w-[78%] md:w-full "
            ref={cardContainerRef}
            style={{ scrollBehavior: "smooth" }}>
            <div className="mx-auto flex flex-row p-2 md:p-0 md:flex-col gap-2 flex-nowrap">
              {newInstitute?.prestationsCategories
                ?.filter((category) =>
                  newInstitute.prestations.some(
                    (prestation) => prestation.category === category
                  )
                )
                .map((category, i) => (
                  <button
                    type="button"
                    aria-label={`Selectionner la catégorie de prestation ${category}`}
                    key={category + i}
                    onClick={() => handleCategorySelect(category)}
                    className={`btnsalonmobile capitalize border md:rounded-3xl lg:rounded-3xl border-gray-300 rounded-3xl font-roboto text-sm md:text-base h-fit text-[#0F0F0F] h-11 py-3 px-4 mx min-w-fit md:text-left md:border-0 ${
                      selectedCategory === category
                        ? "md:bg-white md:text-[#0F0F0F] md:font-bold md:rounded-3xl "
                        : "md:text-[#F6F0E8]"
                    }`}>
                    {category}
                  </button>
                ))}
              <button
                type="button"
                onClick={handleShowAllPrestations} // Add this event handler
                className={`btnsalonmobile capitalize border md:rounded-3xl lg:rounded-3xl border-gray-300 rounded-3xl font-roboto text-sm md:text-base text-[#0F0F0F] h-11 py-3 px-4 mx min-w-fit md:text-left md:border-0 ${
                  showAllPrestations
                    ? "md:bg-white md:text-[#0F0F0F] md:font-bold md:rounded-3xl"
                    : "md:text-[#F6F0E8]"
                }`}>
                Toutes les prestations
              </button>
              <button
                onClick={() => handleHorizontalScroll("next")}
                className="text-white flex md:hidden justify-center items-center absolute z-50 right-0 top-[25%]">
                <GrFormNextLink
                  size={22}
                  style={{ "--tw-backdrop-blur": "35px", zIndex: 10 }}
                  className="cursor-pointer glass-effect  w-fit h-fit rounded-full p-3 flex items-center gap-1 active:border"
                />
              </button>

              <button
                onClick={() => handleHorizontalScroll("previous")}
                className="text-white flex md:hidden justify-center items-center absolute z-50 left-0 top-[25%]">
                <GrFormPreviousLink
                  size={22}
                  style={{ "--tw-backdrop-blur": "35px", zIndex: 10 }}
                  className="cursor-pointer glass-effect w-fit h-fit rounded-full p-3 flex items-center gap-1 active:border   "
                />
              </button>
            </div>
          </div>
        </div>
        {/* list of Prestations section filter-category )  */}

        <div className="max-h-[80vh] overflow-y-scroll p-4 mx-4 md:mx-0  md:w-2/3 bgprestations rounded-[25px] md:rounded-s-lg mt-2 md:mt-0  ">
          <ul>
            {newInstitute &&
              newInstitute.prestationsCategories &&
              (showAllPrestations
                ? newInstitute.prestations
                : newInstitute.prestations.filter(
                    (prestation) => prestation.category === selectedCategory
                  )
              ).map((item, index) => (
                <li
                  key={item.id}
                  className={`py-4 ${
                    index !==
                    (showAllPrestations
                      ? newInstitute.prestations
                      : newInstitute.prestations.filter(
                          (prestation) => prestation.category === selectedCategory
                        )
                    ).length -
                      1
                      ? "border-b border-black md:border-beige"
                      : ""
                  }`}>
                  <div>
                    <p className="text-sm text-black font-roboto mb-3 capitalize  md:text-base lg:text-lg md:text-beige">
                      {item.name}
                    </p>
                    <div className="flex flex-row items-center justify-between flex-wrap">
                      <div className="flex md:flex-row md:gap-1 items-center flex-wrap justify-between w-full">
                        <div className="flex flex-row gap-2 items-center">
                          <p className="text-sm font-roboto font-bold text-[#82786E] hidden md:block   md:text-[#F6F0E8] ">
                            {getPrestationDuration(item.duration)}mn
                          </p>
                          <span className="text-[#82786E]    md:text-[#F6F0E8] text-sm font-roboto  font-medium hidden md:block ">
                            •
                          </span>
                          {expanded.includes(item.id) ? (
                            <p className="text-white text-sm font-roboto  md:text-[#F6F0E8]">
                              {item.description}
                            </p>
                          ) : (
                            <p className="text-white text-xs font-roboto text-justify md:text-[#F6F0E8]">
                              {item.description.length > 30
                                ? item.description.slice(0, 30) + "..."
                                : item.description}
                            </p>
                          )}
                        </div>
                        {!expanded.includes(item.id) && (
                          <span
                            className="font-roboto text-base font-normal underline text-black md:hidden cursor-pointer"
                            onClick={() => toggleExpand(item)}
                            id="lire">
                            Tout lire
                          </span>
                        )}
                        <div className="flex flex-row gap-6 items-center">
                          <p className=" md:text-left hidden md:block text-[#F6F0E8] text-base font-roboto font-bold">
                            {item.price} CHF <br />
                            {item.oldprice && (
                              <span className="text-[#F6F0E8] line-through">
                                {item.oldprice} CHF
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                      {/* for mobile */}
                      <div className="flex justify-between w-full md:w-1/2 ">
                        <div className="flex md:hidden items-center justify-between w-full">
                          <div className="flex flex-row items-center gap-3">
                            <p className="text-sm font-roboto text-[#F6F0E8] ">
                              {getPrestationDuration(item.duration)}mn
                            </p>
                            <span className="text-sm font-roboto text-[#F6F0E8] ">•</span>
                            <p className=" text-sm font-roboto text-[#F6F0E8]">
                              {item.price}CHF
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* for desktop (Tout lire)*/}
                    {!expanded.includes(item.id) ? (
                      <label
                        onClick={() => toggleExpand(item)}
                        htmlFor="lire"
                        className="font-roboto text-base font-normal underline text-black hidden md:block cursor-pointer">
                        Tout lire
                      </label>
                    ) : (
                      <label
                        className="font-roboto text-base font-normal underline text-black hidden md:block cursor-pointer"
                        onClick={() => toggleExpand(item)}>
                        Voir moins
                      </label>
                    )}
                  </div>
                </li>
              ))}
            <p
              onClick={handleShowAllPrestations}
              className=" cursor-pointer text-base not-italic font-normal leading-[normal] pt-2 underline text-black font-roboto md:text-[#F6F0E8]">
              Toutes les prestations
            </p>
          </ul>
        </div>
      </div>
      {newInstitute && newInstitute.reviews?.length > 0 ? <Avis /> : null}
      {/* Equipe  */}
      {newInstitute && newInstitute.staff?.length > 0 ? (
        <div className="mx-4 md:mx-0 ">
          <h5 className=" font-syne font-bold text-[28px] mx-4  md:mx-0 md:pt-4   mb-5">
            Equipe
          </h5>
          {newInstitute && newInstitute.staff && (
            <div className="btnsalonmobile blur-effect p-5 md:mx-0 md:pt-4 grid  grid-cols-2 md:flex-row md:justify-center md:gap-28  md:flex  gap-3">
              {/* item */}
              {newInstitute.staff.map((staffMember) => (
                <div
                  className="flex flex-col  items-center  justify-center  "
                  key={staffMember._id}>
                  {/* <div className="bg-[#D0CBC4]  font-bold capitalize w-[100px] h-[100px] flex flex-col items-center  justify-center rounded-full  text-[32px] font-syne text-[#4A4A49]">
                    {staffMember?.fullName?.charAt(0)}
                  </div>  */}
                  {staffMember.imgStaff ? (
                    <div className="w-[100px] h-[100px] rounded-full mb-2">
                      <img
                        src={URL.createObjectURL(staffMember.imgStaff)}
                        alt={`image de`}
                        className=" rounded-full w-[100px] h-[100px] object-cover absolute"
                      />
                    </div>
                  ) : (
                    <div className="bg-[#D0CBC4] mb-2  font-bold capitalize w-[100px] h-[100px] flex flex-col items-center  justify-center rounded-full   text-[32px] font-syne text-[#4A4A49]">
                      {staffMember?.fullName?.charAt(0)}
                    </div>
                  )}
                  <p className="text-[#000] font-bold font-syne text-sm md:text-2xl capitalize  ">
                    {staffMember?.fullName}
                  </p>
                  <p className=" capitalize font-roboto text-[#F6F0E8] text-sm md:text-[#000] ">
                    {staffMember?.post}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : null}
      {/* Equipe-end  */}
      {/* À propos (About)  */}
      <About salon={newInstitute} location={salonLocation || ""} />
      <div>
        {showModal ? (
          <GalleryModal setShowModal={setShowModal} slides={newInstitute.photos} />
        ) : null}
      </div>

      {/* NAVIGATION */}
      <div>
        <div className="flex flex-row justify-between gap-6 items-end grow my-6">
          <button
            className="flex flex)row btn-outline  border-black dark:border-beige "
            type="button"
            value="prev"
            onClick={(e) => handleStepChange(e, "prev")}>
            <HiChevronLeft className="h-6 w-6" />
            <span>Retour</span>
          </button>
          <div className="flex flex-row gap-6 items-center">
            <button
              disabled={loading}
              className={`${
                loading && "cursor-wait"
              } btn-light border border-dark_grey self-end w-fit flex flex-row gap-3`}
              onClick={handleSendData}>
              {loading ? (
                <span className="px-4">
                  loading <span className="animate-pulse">... </span>
                </span>
              ) : (
                "Créer mon salon"
              )}
              {!loading && <HiChevronRight className="h-6 w-6" />}
            </button>
          </div>
        </div>
        <p className="float-right text-red-400 ml-1 h-5">{error || ""}</p>
      </div>
    </div>
    // <section className='grow flex flex-col justify-start'>
    // 	{/* TITLE */}
    // 	<h1 className='font-syne text-4xl mt-8 font-bold'>Résumé de votre salon</h1>
    // 	<p className='font-roboto dark:text-dark_grey'>Aperçu de votre boutique</p>
    // 	{/* Resume */}
    // 	<div className='p-6 mb-6 mt-5 rounded-md bg-dark_grey dark:bg-beige text-beige dark:text-black flex flex-row flex-wrap xl:justify-between justify-start gap-12'>
    // 		{/* Institute Name and contact info */}
    // 		<div className='items-start flex flex-col gap-4'>
    // 			<h2 className='text-3xl font-syne font-bold capitalize'>{newInstitute.name}</h2>
    // 			<p className='text-lg text-roboto'> {newInstitute.address} </p>
    // 			<p className='text-lg text-roboto'>
    // 				{newInstitute.phoneNumber}&nbsp;•&nbsp;{newInstitute.email}{" "}
    // 			</p>
    // 		</div>

    // 		{/* BusinessHours */}
    // 		<div className='border border-taupe w-fit p-2 rounded-md text-sm'>
    // 			{newInstitute.businessHours.map((day, index) => {
    // 				if (!day.isOpen) {
    // 					return (
    // 						<p key={day + "_" + index} className='grid-cols-2 grid '>
    // 							{" "}
    // 							<span>{day.name}</span>
    // 							<span> Fermé</span>
    // 						</p>
    // 					);
    // 				}
    // 				if (day.isOpen && !day.lunchBreak) {
    // 					return (
    // 						<p key={day + "_" + index} className='grid-cols-2 grid'>
    // 							<span>{day.name}&nbsp;:&nbsp;</span>
    // 							<span>
    // 								{day.morningStart}-{day.eveningEnd}
    // 							</span>
    // 						</p>
    // 					);
    // 				}
    // 				if (day.isOpen && day.lunchBreak) {
    // 					return (
    // 						<div key={day + "_" + index} className='grid-cols-2 grid'>
    // 							<p className='whitespace-nowrap'> {day.name}</p>
    // 							<div className=''>
    // 								<span>
    // 									{day.morningStart}-{day.morningEnd},&nbsp;
    // 								</span>

    // 								<span className='inline-block'>
    // 									{day.eveningStart}-{day.eveningEnd}
    // 								</span>
    // 							</div>
    // 						</div>
    // 					);
    // 				} else return null;
    // 			})}
    // 		</div>
    // 	</div>

    // 	{/* Presta + Photo */}
    // 	<div className='flex flex-col md:flex-row justify-center gap-6 md:h-96'>
    // 		{/* Prestations */}
    // 		<ul className='flex flex-col gap-6 grow border border-taupe rounded-md px-6 min-w-[20px] overflow-y-auto max-w-[1000px] w-full '>
    // 			{/* SubTitle */}
    // 			<div className='sticky bg-beige dark:bg-black top-0 left-0 flex flex-row justify-start items-center gap-2'>
    // 				{/* clipboard Icon */}
    // 				<HiOutlineClipboard className='w-6 h-6' />
    // 				<h2 className='py-4 text-lg font-bold font-roboto'>Vos prestations</h2>
    // 			</div>
    // 			{newInstitute.prestations.length === 0 && (
    // 				<div className=''>
    // 					<p className='mb-6 font-roboto'>Aucune prestation n'a été ajoutée</p>
    // 				</div>
    // 			)}
    // 			{newInstitute.prestations.map((prestation, index) => {
    // 				return (
    // 					<li key={"prestation_" + index} className='flex flex-col  items-start gap-2 border-b border-taupe last:border-0 p-2 pb-6'>
    // 						<p className='font-roboto w-full capitalize'>{prestation.name}</p>
    // 						<div className='flex flex-row justify-between gap-4 items-baseline w-full'>
    // 							<p className='text-sm font-roboto flex flex-row gap-2'>
    // 								<span className='dark:taupe'>{formatDuration(prestation.duration)}</span>
    // 								<span className='text-xl'>•</span>
    // 								<span className='capitalize text-dark_grey text-justify'>{prestation.description}</span>
    // 							</p>
    // 							<p className='text-taupe'>{prestation.price} CHF</p>
    // 						</div>
    // 					</li>
    // 				);
    // 			})}
    // 		</ul>

    // 		{/* Photos */}
    // 		<div className='relative gap-6 border border-taupe rounded-md px-6 md:h-96 overflow-auto'>
    // 			{/* SubTitle */}
    // 			<div className='sticky bg-beige dark:bg-black top-0 left-0 flex flex-row justify-start items-center gap-2 '>
    // 				{/* picture Icon */}
    // 				<HiOutlinePhoto className='w-7 h-6' />
    // 				<h2 className='py-4 text-lg font-bold font-roboto'>Vos photos</h2>
    // 			</div>
    // 			<div className='flex py-6 flex-row md:flex-col gap-6'>
    // 				{newInstitute.photosFromGgAPI &&
    // 					newInstitute.photosFromGgAPI.map((photoUrl, index) => (
    // 						<img key={index} src={photoUrl} alt='photo_salon+index' width={400} height={400} className='aspect-video object-cover object-center rounded-md  bg-slate-200' />
    // 					))}
    // 				{newInstitute.photos?.map((photo, index) => (
    // 					<img key={index} src={URL.createObjectURL(photo)} alt='photo_salon+index' width={400} height={400} className='aspect-video object-cover object-center rounded-md' />
    // 				))}
    // 				{newInstitute.photos?.length === 0 && newInstitute.photosFromGgAPI?.length === 0 && (
    // 					<div className='flex flex-col justify-center items-center'>
    // 						<p className='font-roboto'>Aucune photo n'a été ajoutée</p>
    // 					</div>
    // 				)}
    // 			</div>
    // 		</div>
    // 	</div>

    // 	{/* Team Members */}
    // 	<div className='border border-taupe rounded-md px-6 md:h-96 overflow-auto mt-6'>
    // 		<div className='sticky bg-beige dark:bg-black top-0 left-0 flex flex-row justify-start items-center gap-2 '>
    // 			{/* picture Icon */}
    // 			<HiOutlineUsers className='w-7 h-6' />
    // 			<h2 className='py-4 text-lg font-bold font-roboto'>Votre équipe</h2>
    // 		</div>
    // 		<DisplayTeamMembers isAStandAloneComponent />
    // 	</div>

    // 	{/* NAVIGATION */}
    // 	<p className='float-right text-red-400 text-sm ml-1 -mb-5 h-5'>{error || ""}</p>
    // 	<div className='flex flex-row justify-between gap-6 items-end grow my-6'>
    // 		<button className='flex flex)row btn-outline  border-black dark:border-beige ' type='button' value='prev' onClick={(e) => handleStepChange(e, "prev")}>
    // 			<HiChevronLeft className='h-6 w-6' />
    // 			<span>Retour</span>
    // 		</button>
    // 		<div className='flex flex-row gap-6 items-center'>
    // 			<button disabled={loading} className={`${loading && "cursor-wait"} btn-light border border-dark_grey self-end w-fit flex flex-row gap-3`} onClick={handleSendData}>
    // 				{loading ? (
    // 					<span className='px-4'>
    // 						loading <span className='animate-pulse'>... </span>
    // 					</span>
    // 				) : (
    // 					"Créer mon salon"
    // 				)}
    // 				{!loading && <HiChevronRight className='h-6 w-6' />}
    // 			</button>
    // 		</div>
    // 	</div>
    // </section>
  );
};
export default Resume;
