import { useContext } from "react";
import moment from "moment";
import "../../styles/Agenda.css"
import { HiOutlineClock, HiPhone } from "react-icons/hi";

import AgendaContext from "Context/AgendaContext";

const CalendarItem = ({
  event,
  customer,
  timePeriod,
  phone,
  duration,
  Slot,
  startTimer,
  endTimer,
  type
}) => {
  let size = duration * 5;

  const { OpenSelected, CurrentDate } = useContext(AgendaContext);
  const currentUtcOffset = moment(startTimer).utcOffset();
  const Start = moment(startTimer).local();
  const Current = moment(CurrentDate).utc(currentUtcOffset);
  const end = moment(endTimer).local();
 
  return (
    
    <div className="flex   ">
    
      <div
        style={{ height: size }}
        onClick={() => {
          OpenSelected(Slot);
        }}
        className={`@container flex justify-between flex-wrap bg-calenderSelected min-w-[490px]    ${
          Current.isBetween(Start, end, "minute", [])
            ? "border-white  opacity-100 shadow "
            : "opacity-70 border-t-2 border-sand border-opacity-10"
        } 
         hover:bg-calendarHover hover:cursor-pointer grow text-white  items-center  px-4 py-1`}>
        <div className={` text-sand text-3xl ml-3 `}>{event}</div>
        <div className=" flex justify-start gap-3 items-center flex-wrap">
          <div className="flex  justify-center font-bold ml-3">{customer.firstName} {customer.lastName}</div>
          <div className=" flex justify-between grow ">
            <div className=" flex items-center ">
              <HiOutlineClock size={20} className=" text-sand mx-3 " />
              {timePeriod[0]} - {timePeriod[1]}
            </div>
            <div className="flex justify-center items-center">
              <HiPhone size={20} className=" text-sand mx-3 " />
              {customer.phone}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarItem;
