import axiosInstance from "config/Interceptor";
import { createContext, useState, useMemo, useContext, useEffect } from "react";

const CaisseContext = createContext();
function CaisseProvider({ children }) {
  const [reservations, setReservations] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState();
  const [transactions, setTransactions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreTransactions, setHasMoreTransactions] = useState(true);
  const [dateFilter, setDateFilter] = useState("today");
  const [totalToday, setTotalToday] = useState(0);
  const [totalTodayClients, setTotalTodayClients] = useState(0);
  const [totalMonth, setTotalMonth] = useState(0);
  const [totalMonthClients, setTotalMonthClients] = useState(0);
  const [totalYear, setTotalYear] = useState(0);
  const [totalYearClients, setTotalYearClients] = useState(0);
  const [institute, setInstitute] = useState();
  const [newPrestationsIds, setNewPrestationsIds] = useState([]);

  const fetchReservations = async (
    fetchMore = false,
    hasMore = hasMore,
    page = 0,
    perPage = 10,
    showAll = false
  ) => {
    if (hasMore) {
      try {
        setFetching(true);
        let url = `/api/v1/secure/reservation/list/connected?page=${page}&perPage=${perPage}`;
        if(showAll) url += `&all=true`
        const response = await axiosInstance.get(url);
        if (fetchMore) {
          setReservations((reservations) =>
            reservations.concat(response.data.list)
          );
        } else {
          setReservations(response.data.list);
          if (response.data.list && response.data.list.length > 0) {
            setSelectedReservation(response.data.list[0]);
          }
        }
        setFetching(false);
        if (response.data.pages - 1 === page || response.data.pages === 0)
          setHasMore(false);
      } catch (error) {
        console.error("Error Fetching ", error);
      }
    }
  };

  useEffect(() => {
    if (reservations && reservations.length > 0 && !selectedReservation)
      setSelectedReservation(reservations[0]);
  }, [selectedReservation, reservations]);

  const fetchMore = (hasMore, page, perPage, fetchMore = true, showAll = false) => {
    fetchReservations(fetchMore, hasMore, page, perPage, showAll);
  };

  const fetchMoreTransactions = (hasMore, page, perPage, fetchMore = true) => {
    fetchTransactions(fetchMore, hasMore, page, perPage);
  };

  const saveTransaction = async (reservation, amountToPay, type) => {
    let body = {
      reservationId: reservation._id,
      datePaid: "",
      amountPaid: amountToPay,
      clientId: reservation.customer._id,
      description: "",
      type: type,
    };
    if(newPrestationsIds && newPrestationsIds.length > 0) body.newPrestations = newPrestationsIds
    try {
      const res = await axiosInstance.post(
        `/api/v1/secure/transaction/new`,
        body
      );
      setReservations((reservations) =>
        reservations.filter((res) => res._id !== reservation._id)
      );
      setSelectedReservation(undefined);
      setNewPrestationsIds([]);
      return res;
    } catch (error) {
      return error;
    }
  };

  const fetchTransactions = async (
    fetchMoreTransactions = false,
    hasMoreTransactions = hasMoreTransactions,
    page = 0,
    perPage = 10
  ) => {
    if (hasMoreTransactions) {
      try {
        setFetching(true);
        let url = `/api/v1/secure/transaction/list/client?page=${page}&perPage=${perPage}&dateFilter=${dateFilter}`;
        const response = await axiosInstance.get(url);
        if (fetchMoreTransactions) {
          setTransactions((transactions) =>
            transactions.concat(response.data.list)
          );
        } else setTransactions(response.data.list);
        setTotalToday(response.data.totalToday);
        setTotalTodayClients(response.data.totalTodayClients);
        setTotalMonth(response.data.totalMonth);
        setTotalMonthClients(response.data.totalMonthClients);
        setTotalYear(response.data.totalYear);
        setTotalYearClients(response.data.totalYearClients);
        setFetching(false);
        if (response.data.pages - 1 === page || response.data.pages === 0)
          setHasMoreTransactions(false);
      } catch (error) {
        console.error("Error Fetching ", error);
      }
    }
  };

  const fetchInstitute = async () => {
    try {
      setFetching(true);
      let url = `/api/v1/secure/institute/client`;
      const response = await axiosInstance.get(url);
      setInstitute(response.data);
    } catch (error) {
      console.error("Error Fetching ", error);
    }
  };

  const updatePrestations = (id, prestations) => {
    let presArray = selectedReservation.prestation.concat(prestations);
    const totalPrice = presArray.reduce(
      (a, { price }) => parseFloat(a) + parseFloat(price),
      0
    );
    const body = { prestation: presArray, totalPrice };
    try {
      /* let url = `/api/v1/secure/reservation/update/${id}`;
      const response = await axiosInstance.put(url, body); */
      let res = [...reservations];
      for (let i = 0; i < res.length; i++) {
        let element = res[i];
        if (element._id === id) {
          element.prestations = presArray;
          element.totalPrice = totalPrice;
        }
      }
      let selectedRes = {
        ...selectedReservation,
        prestation: presArray,
        totalPrice: totalPrice,
      };
      setReservations(res);
      setSelectedReservation(selectedRes);
      setNewPrestationsIds(prestations.map(prestation => prestation._id));
    } catch (error) {
      console.error("Error updating reservation prestations", error);
    }
  };

  const valueToShare = useMemo(
    () => ({
      fetchReservations,
      reservations,
      setReservations,
      selectedReservation,
      setSelectedReservation,
      hasMore,
      setHasMore,
      fetching,
      fetchMore,
      saveTransaction,
      transactions,
      fetchTransactions,
      hasMoreTransactions,
      setHasMoreTransactions,
      fetchMoreTransactions,
      setTransactions,
      dateFilter,
      setDateFilter,
      totalToday,
      totalTodayClients,
      totalMonth,
      totalYear,
      totalMonthClients,
      totalYearClients,
      institute,
      fetchInstitute,
      updatePrestations,
      newPrestationsIds,
      setNewPrestationsIds
    }),
    [
      reservations,
      selectedReservation,
      hasMore,
      transactions,
      dateFilter,
      hasMoreTransactions,
      institute,
      newPrestationsIds
    ]
  );

  return (
    <CaisseContext.Provider value={valueToShare}>
      {children}
    </CaisseContext.Provider>
  );
}
function useCaisseContext() {
  return useContext(CaisseContext);
}
export { CaisseProvider, useCaisseContext };
export default CaisseContext;
