import { useContext, useEffect } from "react";
import UserHeader from "../Component/Header/UserHeader";
import LoggedHeader from "../Component/Header/LoggedHeader";
import LoggedContext from "../Context/isLogged";
import { Outlet, useLocation, useMatch } from "react-router-dom";
import NotLoggedFooter from "../Component/Footer/NotLoggedFooter";
import LoggedFooter from "../Component/Footer/LoggedFooter";
import { FormDataProvider } from "Context/FormContext";
import { ChatProvider, useChatContext } from "Context/ChatContext";
import { io } from "socket.io-client";

const Header = () => {
  const homePage = useMatch("/");
  const location = useLocation();
  const { isLogged } = useContext(LoggedContext);
  const { selectedChat } = useChatContext();
  const { socket, setSocket } = useChatContext();

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_BASE_API_URL);
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  // useEffect(()=>{
  //   const decoded = jwt_decode(Cookies.get("authToken"));
  //   console.log('useeffect trigred')
  //   // if(selectedChat && location.pathname.slice(0,6) !== "/chat/"){
  //   //   socket.emit("openChat", {
  //   //     chatId: selectedChat._id,
  //   //     userId: decoded.id,
  //   //     isOpen: false,
  //   //   });
  //   // }
  // },[location])
  //set lightTheme
  useEffect(() => {
    const root = window.document.documentElement;
    root.classList?.remove("dark");
  }, []);
  
  return (
    <FormDataProvider>
      <div className="bg-cover  relative h-fit  flex flex-col overflow-x-hidden xs:snap-both ">
       {location.pathname !== '/prologin' && <img
          src={'https://bucket-stunify-app.s3.eu-north-1.amazonaws.com/static/assets/HP-bg-10-2023.jpg'}
          alt=""
          className="fixed top-0 left-0 right-0 w-full h-auto object-cover z-[-1] "
        />}
        <div
          className={`${
            location.pathname.slice(0, 6) === "/chat/" ? "xxs:hidden md:flex " : ""
          }`}>
          {isLogged ? <LoggedHeader /> : <UserHeader />}
        </div>
        <main
          className={`h-fit  ${
            location.pathname.slice(0, 6) === "/chat/" ? "md:mt-20 xxs:mt-0" : "mt-20"
          }`}>
          <Outlet />
        </main>
        <div
          className={`${
            location.pathname.slice(0, 6) === "/chat/" ? "xxs:hidden md:flex" : ""
          }`}>
          {!homePage && isLogged ? <LoggedFooter /> : <NotLoggedFooter />}
        </div>
      </div>
    </FormDataProvider>
  );
};

export default Header;
