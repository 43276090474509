import React, { useContext, useState } from "react";
import { SlLocationPin } from "react-icons/sl";
import MapGoogle from "Component/utils/MapGoogle";
function About({ location, salon }) {
  const [showFullText, setShowFullText] = useState(false);
  const toggleText = () => {
    setShowFullText(!showFullText);
  };
  return (
    <>
      <h5 className="font-syne font-bold text-[28px] py-8 mx-8 md:mx-0   ">À propos</h5>
      <div></div>
      <div className="  text-[#F6F0E8] max-md:btnsalonmobile rounded-[25px] p-6 md:p-0 mx-4 md:mx-0 ">
        <div>
          <p
            className={` text-base not-italic font-normal leading-[23px] font-roboto   ${
              showFullText ? "hidden" : "block md:hidden"
            }`}>
            {salon && salon.description && salon.description.substring(0, 150)}{" "}
            {/* display the first 150 char*/}
          </p>
          <p
            className={`text-base font-roboto  leading-[23px]  duration-500  ${
              showFullText ? "block ease-in " : "hidden ease-out md:block"
            }`}>
            {salon && salon.description && salon.description}
          </p>
          {salon.description?.length > 70 && (
            <div
              className="text-[#F6F0E8] text-base font-roboto underline underline-offset-1 mt-2 md:hidden block mb-[14px]"
              onClick={toggleText}>
              {showFullText ? "Moins" : "Voir plus"}
            </div>
          )}
          <div className="flex flex-col md:flex-row gap-2  md:space-x-7 md:h-[500px]  md:mt-6">
            <div className="rounded-3xl md:w-3/5  w-full object-cover overflow-hidden ">
              <MapGoogle markers={location} />
            </div>
            <div className="md:w-2/5 glass-effect  rounded-3xl p-3 ">
              <h5 className="font-syne font-bold text-18px] pt-4 md:mx-0 md:pt-0 mb-4">
                Adresse
              </h5>
              <div className="flex flex-row   w-full space-x-1">
                <SlLocationPin size={24} color="#F6F0E8" />
                <p>{salon?.address}</p>
              </div>
              <h5 className="font-syne font-bold text-18px] pt-4 md:mx-0 md:pt-10   mb-4">
                Horaires
              </h5>
              {salon && salon.businessHours && (
                <div className="space-y-4">
                  {salon && salon.businessHours && (
                    <div className="space-y-4">
                      {salon.businessHours.map((day) => (
                        <div
                          className="flex flex-row w-full justify-between"
                          key={day.name}>
                          <p>{day.name}</p>
                          {day.isOpen ? (
                            <p>
                              {day.morningStart} -{" "}
                              {day.lunchBreak ? (
                                <>
                                  {day.morningEnd} / {day.eveningStart} - {day.eveningEnd}
                                </>
                              ) : (
                                day.eveningEnd
                              )}
                            </p>
                          ) : (
                            <p>Fermé</p>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className=" block md:hidden p-2 mt-3"></div>
    </>
  );
}
export default About;
