import { useState, useEffect, useRef } from "react";
import { HiX } from "react-icons/hi";
import InformationSection from "./InformationSection";
import PasswordSection from "./PasswordSection";
import PaymentSection from "./PaymentSection";
import ReviewsSection from "./ReviewsSection";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
const ProfileModal = ({ viewMode, setViewMode }) => {
  const [selected, setSelected] = useState(1);

  useEffect(() => {
    setSelected(viewMode);
  }, [viewMode]);

  let selectedEle = <></>;

  switch (selected) {
    case 1:
      selectedEle = (
        <InformationSection setViewMode={setViewMode} setSelected={setSelected} />
      );
      break;
    case 2:
      selectedEle = <PasswordSection setViewMode={setViewMode} />;
      break;
    // case 3:
    //   selectedEle = <PaymentSection />;
    //   break;
    case 3:
      selectedEle = <ReviewsSection />;
      break;
  }
  const containerRef = useRef(null);

  const handleCatHorizontalScroll = (direction) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = direction === "next" ? 100 : -100;
      container.scrollTo({
        left: container.scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      className={`flex flex-col gap-12  overflow-y-hidden  justify-center lg:w-[78%] w-full   mx-2 animate__animated animate__zoomIn  ${
        viewMode ? `absolute top-[40px]  animate__zoomIn` : `hidden`
      }`}>
      {/* mobile btn */}
      <div className="relative  w-full">
        <div
          ref={containerRef}
          className="  w-[70%] h-fit flex-row font-roboto text-sm mx-auto  overflow-x-scroll no-scrollbar flex md:hidden  bgprestations rounded-3xl  ">
          <a
            href="#"
            className={`profile-link  py-3 px-4 rounded-3xl mr-2 min-w-fit   snap-center    ${
              selected == 1 ? "bg-black text-white" : "bg-sand"
            }`}
            onClick={() => setSelected(1)}>
            Mes informations{" "}
          </a>
          <a
            href="#"
            className={`profile-link  py-3 px-4 rounded-3xl mr-2 min-w-fit   snap-center  ${
              selected == 2 ? "bg-black text-white" : "bg-sand"
            }`}
            onClick={() => setSelected(2)}>
            Mot de passe
          </a>
          {/* <a
            href="#"
            className={`profile-link  py-3 px-4 rounded-3xl mr-2 min-w-fit   snap-center   ${
              selected == 3 ? "bg-black text-white" : "bg-sand"
            }`}
            onClick={() => setSelected(3)}
          >
            Méthode de paiement
          </a> */}

          <a
            href="#"
            className={`profile-link  py-3 px-4 rounded-3xl mr-2 min-w-fit   snap-center  ${
              selected == 3 ? "bg-black text-white" : "bg-sand"
            }`}
            onClick={() => setSelected(3)}>
            Mes avis
          </a>
        </div>
        <button
          onClick={() => handleCatHorizontalScroll("next")}
          className="text-white flex justify-center items-center absolute z-10 right-4 bottom-0.5">
          <GrFormNextLink
            size={22}
            style={{ "--tw-backdrop-blur": "35px", zIndex: 10 }}
            className="cursor-pointer glass-effect  w-fit h-fit rounded-full p-2 flex items-center active:border"
          />
        </button>

        <button
          onClick={() => handleCatHorizontalScroll("previous")}
          className="text-white flex justify-center items-center absolute z-10 left-4 bottom-0.5">
          <GrFormPreviousLink
            size={22}
            style={{ "--tw-backdrop-blur": "35px", zIndex: 10 }}
            className="cursor-pointer glass-effect w-fit h-fit rounded-full p-2 flex items-center  active:border   "
          />
        </button>
      </div>
      <div className="p-6 trans-bg cus-radius  flex w-full h-[80svh]   ">
        {/* pc btn */}
        <div className="md:flex flex-col lg:w-[20%]  md:w-[30%px]  hidden font-roboto text-lg ">
          <a
            href="#"
            className={`profile-link hover:bg-black hover:text-white  py-3   my-4   mx-1 rounded-3xl px-4 ${
              selected == 1 ? "bg-black text-white" : ""
            }`}
            onClick={() => setSelected(1)}>
            Mes informations
          </a>
          <a
            href="#"
            className={`profile-link hover:bg-black hover:text-white  py-3   my-4   mx-1 rounded-3xl px-4 ${
              selected == 2 ? "bg-black text-white" : ""
            }`}
            onClick={() => setSelected(2)}>
            Mot de passe
          </a>
          {/* <a
            href="#"
            className={`profile-link hover:bg-black hover:text-white  py-3   my-4   mx-1 rounded-3xl px-4 ${
              selected == 3 ? "bg-black text-white" : ""
            }`}
            onClick={() => setSelected(3)}
          >
            Méthode de paiement
          </a> */}

          <a
            href="#"
            className={`profile-link hover:bg-black hover:text-white  py-3   my-4   mx-1 rounded-3xl px-4 ${
              selected == 3 ? "bg-black text-white" : ""
            }`}
            onClick={() => setSelected(3)}>
            Mes avis
          </a>
        </div>
        <div className="lg:w-[80%] md:w-[70%]  mt-4  mx-auto py-4 h-[100%]  relative">
          {selectedEle}
          <div className=" text-white  w-fit h-fit  absolute top-[-20px] right-0 ">
            <HiX
              size={24}
              className="cursor-pointer hover:text-black "
              onClick={() => {
                setViewMode(false);
                setSelected(0);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;
