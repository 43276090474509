/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import { IoNotificationsOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { MdOutlineHomeRepairService } from "react-icons/md";
import {
  DisconnectUserInFront,
  disconnectUserInBackend,
} from "Component/utils/connectionHandler";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import { ModalProvider } from "Context/ModalContext";
import AgendaContext from "../../Context/AgendaContext";
import EventModal from "../../Component/Agenda/EventModal/EventModal";
import {
  HiChevronDown,
  HiOutlineCalendar,
  HiOutlineClock,
  HiChevronLeft,
  HiChevronRight,
  HiPlus,
} from "react-icons/hi";
import { HiOutlineRectangleGroup, HiArrowRightOnRectangle } from "react-icons/hi2";

import moment from "moment";
import DayAgenda from "Component/Agenda/DayAgenda";
import WeeklyAgenda from "Component/Agenda/WeeklyAgenda";
import LoggedContext from "Context/isLogged";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";

const Agenda = () => {
  const {
    modalIsOpen,
    SelectedEvent,
    resetDate,
    incrementDate,
    decrementDate,
    SelectedDate,
    SelectedEmpl,
    setSelectedEmp,
    Employee,
    CurrentDate,
    AddEvent,
    FetchEmployeelist,
    Weekly,
    Setweekly,
    setCurrentDate,
    generateSevenDays,
    sevenDays,
    GetworkingHours,
    EmployeeData,
  } = useContext(AgendaContext);
  const { userGlobal , isPro } = useContext(LoggedContext);
  const disconnectUserInFront = DisconnectUserInFront();

  const handleUserDisconnection = () => {
    let token = Cookies.get("authToken");
    disconnectUserInBackend(token).then((res) => {
      if (!res.ok) {
        console.log("error");
      }
      disconnectUserInFront();
      toast.success("Vous êtes déconnecté");
    });
  };

  useEffect(() => {
    FetchEmployeelist();
  }, []);
  useEffect(() => {
    generateSevenDays(SelectedDate);

    const interval = setInterval(() => {
      setCurrentDate(moment());
    }, 30000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SelectedDate]);

  useEffect(() => {
    GetworkingHours(EmployeeData);
  }, [SelectedDate, EmployeeData]);

  return (
    <div className="border-solid border-[10px] w-full  border-black flex  flex-row h-fit ">
      <div className=" py-5 flex-grow flex flex-col ">
        <div className="bg-black  flex h-[10rem] flex-col text-sand">
          <div className="h-[40px] flex  grow align-middle  items-baseline ">
            <div className="flex flex-1 hover:cursor-pointer ">
              <div onClick={resetDate} className="flex">
                <HiOutlineCalendar className=" text-sand " size={20} />
                <span className="mx-2 font-syne ">{moment().format("ddd Do/MM")}</span>
              </div>
              <HiOutlineClock className=" text-sand " size={20} />
              <span className="mx-2 font-syne">{CurrentDate?.format("hh:mm")}</span>
            </div>
            <div className="flex flex-1 align-middle justify-center items-center  ">
              <button
                onClick={decrementDate}
                className="relative h-10 mx-2 w-10 rounded-full border-2 border-white  hover:text-black hover:bg-white ">
                <HiChevronLeft
                  size={25}
                  style={{ position: "absolute", top: "18%", right: "17%" }}
                />
              </button>
              <span className="font-syne underline underline-offset-4">
                {Weekly && sevenDays && sevenDays.length
                  ? moment(sevenDays[0]).format("Do/MM") +
                    " au " +
                    moment(sevenDays[4]).format("Do/MM")
                  : SelectedDate ? SelectedDate.format("ddd Do/MM"): null}
              </span>

              <button
                onClick={incrementDate}
                className="relative h-10 mx-2 w-10 rounded-full border-2 border-white hover:text-black hover:bg-white">
                <HiChevronRight
                  size={25}
                  style={{ position: "absolute", top: "18%", right: "17%" }}
                />
              </button>
            </div>
            <div className="flex flex-row justify-end  items-center w-1/3 gap-2">
              <IoNotificationsOutline size={30} className=" cursor-pointer" />

              <div>
                {userGlobal.imageUrl ? (
                  <img
                    src={userGlobal.imageUrl}
                    alt="user profile PIC"
                    className="w-8 h-8 rounded-full "
                  />
                ) : (
                  <span className="tracking-wide" id="ProprofileImage">
                    {userGlobal.firstName[0]?.toUpperCase() +
                      userGlobal.lastName[0]?.toUpperCase()}
                  </span>
                )}
              </div>
              <div className={`${modalIsOpen? '' : 'z-10' } relative`}>
              <DropdownMenu  >
                <DropdownMenuTrigger className="flex flex-row justify-between items-center space-x-1 cursor-pointer group">
                  <p className="text-[#F6F0E8] text-sm not-italic font-bold capitalize leading-[normal] font-roboto cursor-pointer">
                    {userGlobal.firstName}
                  </p>
                  <HiChevronDown className="w-5 h-5 transition duration-500 ease-in-out transform scale-y-100 group-data-[state=open]:-scale-y-100 " />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="bg-[#2D2E2F] text-white mx-8 rounded-xl">
                  <Link to={!isPro? '/profile' : '/proconnected/caisseDash'}>
                    <DropdownMenuItem className="pl-7 py-3 hover:underline flex flex-row items-center gap-2 text-white">
                      <HiOutlineRectangleGroup className="h-6 w-6" />
                      <span className="text-white z-10">Dashboard</span>
                    </DropdownMenuItem>
                  </Link>
                  <DropdownMenuSeparator />
                  <Link to="/pro">
                    <div className="hover:underline flex flex-row gap-2 justify-start py-3 pl-6 pr-20 cursor-pointer">
                      <MdOutlineHomeRepairService className="w-6 h-6 text-white z-10" />
                      <span className="text-white z-10">Pro Home</span>
                    </div>
                  </Link>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={handleUserDisconnection}>
                    <div className="hover:underline flex flex-row gap-2 justify-start py-3 pl-6 pr-20 cursor-pointer z-50">
                      <HiArrowRightOnRectangle className="w-6 h-6 text-white" />
                      <span className="text-white">Se déconnecter</span>
                    </div>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              </div>
            </div>
          </div>

          <div className="flex flex-grow justify-between align-middle items-center ">
            <div className="flex flex-1 flex-col">
              <p>Vue</p>
              <select
                value={SelectedEmpl}
                onChange={(e) => {
                  if (e.target.value === "All") {
                    Setweekly(false);
                  }
                  setSelectedEmp(e.target.value);
                }}
                className="hover:cursor-pointer border-2 my-1 rounded-full w-2/5 bg-black  p-2 px-4 flex justify-between ">
                <option value="All">Toute l’équipe</option>
                {Employee.map((item, i) => (
                  <option key={i} value={item._id}>
                    {item.fullName}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-1 justify-center gap-20">
              <div
                onClick={() => Setweekly(false)}
                className={`${
                  !Weekly ? "border-b-2 border-sand " : ""
                }hover:border-b-2 border-sand flex justify-center w-[6.24rem] p-2 hover:cursor-pointer`}>
                <p>Aujourd’hui</p>
              </div>
              {SelectedEmpl !== "All" && (
                <div
                  onClick={() => {
                    Setweekly(true);
                  }}
                  className={`${
                    Weekly ? "border-b-2 border-sand " : ""
                  }hover:border-b-2 border-sand flex justify-center w-[6.24rem] p-2 hover:cursor-pointer`}>
                  <p>Semaine</p>
                </div>
              )}
            </div>
            <div className="flex-1 flex justify-end ">
              <button
                onClick={AddEvent}
                className="border-2 rounded-full border-sand p-2 px-4  flex hover:text-black hover:bg-white  items-center">
                <HiPlus className="relative t-1 mx-2 " size={20} /> Ajouter un rendez-vous
              </button>
            </div>
          </div>
        </div>

        {Weekly ? <WeeklyAgenda /> : <DayAgenda />}
      </div>
      <ModalProvider>
        <EventModal event={SelectedEvent} />
      </ModalProvider>
    </div>
  );
};

export default Agenda;
