import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ToggleButton = ({ isAddingMember, setIsAddingMember}) => {

  useEffect(() => {
    setClasses();
  }, [isAddingMember]);

  const setClasses = () => {
    const leftId = document.getElementById('display_team_members');
    const rightId = document.getElementById('add_team_member');
    if (!isAddingMember) {
      leftId?.classList.remove("bg-black", "text-beige");
      leftId?.classList.add("text-dark_grey", "bg-beige");

      rightId?.classList.remove("text-dark_grey", "bg-beige");
      rightId?.classList.add("text-beige", "bg-black");
    } else {
      rightId?.classList.remove("text-beige", "bg-black");
      rightId?.classList.add("bg-beige", "text-dark_grey");

      leftId?.classList.remove("bg-beige", "text-dark_grey");
      leftId?.classList.add("text-beige", "bg-black");
    }
  };

  const handleHover = (e) => {
    const leftId = document.getElementById('display_team_members');
    const rightId = document.getElementById('add_team_member');
    if (e?.target?.id === 'add_team_member') {
      rightId?.classList.remove("text-beige", "bg-black");
      rightId.classList.add("bg-beige", "text-dark_grey");

      leftId?.classList.remove("text-dark_grey", "bg-beige");
      leftId?.classList.add("text-beige", "bg-black");
    } else {
      leftId?.classList.remove("text-beige", "bg-black");
      leftId?.classList.add("bg-beige", "text-dark_grey");

      rightId?.classList.remove("text-dark_grey", "bg-beige");
      rightId?.classList.add("text-beige", "bg-black");
    }
  };
  return (
    <nav className="flex h-10 justify-center gap-3 md:gap-5 max-w-fit shadow-outer-beige items-center rounded-3xl p-1 bg-black " onMouseLeave={setClasses}>
      <button
      onClick={() => setIsAddingMember(false)}
        type="button"
        aria-label="Mon équipe"
        id='display_team_members'
        onMouseOver={handleHover}
        className={`focus:outline-none whitespace-nowrap px-4 py-1 h-full font-roboto font-bold rounded-3xl transition-all duration-400`}>
        Mon équipe
      </button>
      <button
      onClick={()=>setIsAddingMember(true)}
        type="button"
        aria-label="Ajouter un membre à l'équipe"
        id="add_team_member"
        onMouseOver={handleHover}
        className={`focus:outline-none whitespace-nowrap px-4 py-1 h-full rounded-3xl font-roboto font-bold transition-all duration-500`}>
       En ajouter
      </button>
    </nav>
  );
};

export default ToggleButton;
