import { useState, useContext, useEffect, useRef } from "react";
import { VscSettings } from "react-icons/vsc";
import { useParams } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi";
import { FaArrowRight } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Map from "../../Component/utils/MapGoogle";
import Datacontext from "../../Context/Datacontext";
import "../../styles/animate.css";
import ProCardMob from "./ProCardMob";
import "../../styles/Search.css";
import FilterMob from "./FilterMob";
import SortMob from "./SortMob";
import PlacesAutocomplete from "react-places-autocomplete";

import ProcardSimple from "./ProcardSimple";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import InfiniteScroll from "react-infinite-scroll-component";

import prestationContext from "Context/prestationContext";
import SalonDataContext from "Context/SalonDataContext";
import jwtDecode from "jwt-decode";

import Cookies from "js-cookie";

const VerificationMob = ({ searchbarChoices }) => {
  const [arrowDisable, setArrowDisable] = useState(true);
  const elementRef = useRef(null);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [md, setMd] = useState(false);

  const [toggleMap, setTogglemap] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const [middle, setMiddle] = useState([]);

  const {
    institutes,
    fetch,
    setFilterlist,
    FilterList,
    fetchMore,
    hasMore,
    fetching,
    setHasMore,
  } = useContext(Datacontext);
  const { setSelectedPrestation, setSelectedFreeSlot } = useContext(prestationContext);
  const { setSalon } = useContext(SalonDataContext);

  const locations = institutes.map((item, i) => {
    return {
      address: item.address,
      lat: item.position.lat,
      lng: item.position.lng,
    };
  });

  useEffect(() => {
    if (document.body.clientWidth < 768) {
      setMd(true);
    } else {
      setMd(false);
    }
  }, [document.body.clientWidth]);

  useEffect(() => {
    if (md) {
      setSelectedPrestation([]);
      setSelectedFreeSlot("");
      setSalon([]);
      setPage(0);
      setHasMore(true);
    }
  }, [FilterList]);
  const Navigate = useNavigate();

  const handleSelectedCard = (e) => {
    setSelectedCard(e);
  };

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const fetchMoreData = () => {
    fetchMore(false,FilterList, hasMore, page + 1, perPage);
    setPage(page + 1);
  };
  useEffect(() => {
    const authToken = Cookies.get("authToken");
    if (authToken) {
      const decoded = jwtDecode(authToken);
      if (!decoded.isAdmin) {
        Navigate("/search");
      }
      if (decoded.isAdmin) {
        fetch(false, {}, false, true);
      }
    } else {
      Navigate("/search");
    }
  }, []);

  return (
    <div className="xs:max-md:flex md:hidden relative">
      {toggleMap && (
        <div className="flex ">
          <div className=" self-center absolute h-[60%] w-[90%] translate-x-[23px] mx-auto rounded-3xl overflow-hidden mt-[20%]">
            {" "}
            <Map
              middle={middle}
              markers={locations}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
            />
          </div>
          <div className="absolute bottom-1 mt-5 flex flex-row w-[90%] left-4 gap-2 overflow-x-scroll  ">
            {toggleMap &&
              institutes.map((item) => (
                <ProcardSimple
                  middle={middle}
                  setMiddle={setMiddle}
                  selectedCard={selectedCard}
                  handleSelectedCard={handleSelectedCard}
                  institute={item}
                  key={item._id}
                />
              ))}
          </div>
        </div>
      )}
      <div className=" h-[90vh] w-screen justify-center px-7 mt-4 flex flex-col overflow-x-hidden">
       
        <div className="flex flex-row h-2/3 w-full gap-2 ins-cards-container overflow-x-scroll justify-start snap-x snap-mandatory mt-4">
          {!toggleMap && (
            <div
              id="scrollableDivMob"
              style={{ height: "100%", overflow: "auto", width: "90%" }}>
              <InfiniteScroll
                dataLength={institutes.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={
                  <div className="text-center h-full w-full text-white mt-2 mb-2 justify-center items-center overflow-hidden"></div>
                }
                scrollableTarget="scrollableDivMob">
                {institutes.length > 0 ? (
                  institutes.map((item) => (
                    <div style={{ width: "90%", margin: "1rem" }} key={item._id}>
                      <ProCardMob institute={item} />
                    </div>
                  ))
                ) : (
                  <>
                    {!fetching ? (
                      <div className="p-5 animate__animated  animate__fadeInDown flex-grow text-beige text-sm flex flex-col justify-center items-center h-full gap-6 w-full linear-background rounded-3xl overflow-hidden">
                        <p className="text-base">
                          Votre recheche n'a donné aucuns résultats
                        </p>
                      </div>
                    ) : null}
                  </>
                )}
              </InfiniteScroll>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerificationMob;
