import React from "react";

const Stepbar = ({ step }) => {
  // console.log(step);
  return (
    <>
      <div
        className={`absolute top-[-15px] md:top-0 left-4 h-[5px] w-${step}/5 bg-white rounded-md z-20 transition-width transition-slowest ease duration-1000`}></div>
      <div
        className={`absolute top-[-15px]  md:top-0 right-4 h-[5px] w-4/5 bg-black rounded-md z-0`}></div>
    </>
  );
};

export default Stepbar;
