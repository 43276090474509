import React from "react";
import {HiOutlineUser, HiXMark} from "react-icons/hi2";

const TeamMemberCard = ({isAStandAloneComponent, imageUrl, name, role, deleteThisMember, index}) => {
  return (
   <div className="border border-dark_grey rounded-3xl w-full p-6 pb-8 flex flex-col justify-center items-center gap-4 relative">
    {!isAStandAloneComponent && <button onClick={(e)=>deleteThisMember(e, index)} type="button" aria-label="supprimer cet employé" className='absolute top-2 right-2 rounded-full border p-0.5'   >
      <HiXMark className='h-4 w-4 text-black dark:text-beige' />
    </button>}
       {imageUrl ? <div className="h-28 w-28 rounded-full overflow-hidden relative">
           <img
            src={URL.createObjectURL(imageUrl)}
            alt={name}
            className="h-full w-full object-cover absolute"
                 />
       </div> : <HiOutlineUser className="w-28 h-28 p-4 border rounded-full border-black dark:border-beige" />}
      <div className="flex flex-col justify-center items-center w-full">
        <h3 title={name} className="text-xl w-full overflow-hidden text-ellipsis whitespace-nowrap">{name}</h3>
        <p title={role} className="text-sm w-full overflow-hidden text-ellipsis whitespace-nowrap">{role}</p>
      </div>
    </div>
    
  );
};

export default TeamMemberCard;
