import React, { useState, useContext } from "react";
import VerificationInput from "react-verification-input";
import { requestCodeChallenge, resgisterUser, initiatePhoneCheck } from "./apiService";
import { useNewUser } from "../../Context/UserAuthContext";
import { useNavigate } from "react-router-dom";
import LoggedContext from "../../Context/isLogged";
import Cookies from "js-cookie";
import SmSpinner from "Component/ui/SmSpinner";
import { toast } from "react-hot-toast";

const SMScodeInput = ({ isAmodal }) => {
  const navigate = useNavigate();
  const { newUser, signupLod, setSignupLod } = useNewUser();
  const { toggleUserState, setUserGlobal } = useContext(LoggedContext);
  const [errorOnCode, setErrorOnCode] = useState("");
  const [atempts, setAtempts] = useState(6);
  const [value, setValue] = useState("");
  const [resendLoading, setResendLod] = useState(false);

  const handleUserRegistration = async (token) => {
    try {
		setSignupLod(true)
      const _user = { ...newUser, isPhoneValidated: true };
      const res = await resgisterUser(_user, token);
      if (res.status === 201) {
        Cookies.set("authToken", res.data.token, { expires: 3 });
        setUserGlobal({ ...res.data.client });
        toggleUserState();
        toast.success("Votre compte a été créé avec succès");
        if (!isAmodal) {
          setTimeout(() => {
            navigate("/dashboard");
			setSignupLod(false)
          }, 3000);
        }
      }
    } catch (error) {
		setSignupLod(false)
      // console.log(error);
    }
  };

  const handleError = (message, remainingAttempts) => {
    setErrorOnCode(
      remainingAttempts ? `${message} ${remainingAttempts} essais` : message
    );
    if (remainingAttempts >= 0) {
      setAtempts(remainingAttempts - 1);
      setValue("");
    } else {
      setErrorOnCode("Vous avez fait trop de tentative, veuillez réessayer plus tard");
    }
    clearErrorCode();
  };

  const clearErrorCode = () => {
    setTimeout(() => {
      setErrorOnCode("");
    }, 8000);
  };

  const handleCodeSubmit = async (value) => {
    try {
		setSignupLod(true)
      const res = await requestCodeChallenge(value, newUser.phone);
      if (res.status === 200) {
		setSignupLod(false)
        switch (res.data.verificationStatus) {
          case "approved":
			setSignupLod(false)
            handleUserRegistration(res.data.token);
            break;
          case "pending":
			setSignupLod(false)
            handleError("Code incorrect, encore", atempts);
            break;
          case "canceled":
			setSignupLod(false)
            handleError("Vous avez fait trop de tentative, réssayez plus tard");
            navigate("/signup");
            break;
          case undefined:
			setSignupLod(false)
            handleError("Erreur indefinie, réssayez plus tard");
            navigate("/");

            break;
          default:
            break;
        }
      } else if (res.response?.status === 404) {
		setSignupLod(false)
        handleError("Une erreure est survenue, votre demande n'a pas aboutie ");
        setValue("");
      } else {
		setSignupLod(false)
        handleError("Une erreur est survenue, réssayez plus tard");
      }
    } catch (error) {}
  };

  const handleResendCode = async () => {
    setResendLod(true);
    try {
      const res = await initiatePhoneCheck(newUser.phone);
      setValue("");
      setResendLod(false);
      if (res.response?.status === 429) {
        setResendLod(false);
        handleError("Trop de tentatives, veuillez réessayer plus tard");
        return;
      }
      // TODO: set toast message : code has been resent
    } catch (error) {
      setResendLod(false);
      // console.log(error);
    }
  };

  return (
    <>
      <VerificationInput
        length={4}
        value={value}
        placeholder={""}
        autoFocus={true}
        validChars={"0-9"}
        classNames={{
          container: "container h-full",
          character:
            "mx-1 rounded-md bg-beige border border-taupe border border-beige w-12 md:w-16 h-20 text-black text-6xl font-bold flex justify-center items-center",
          characterInactive:
            "after:inset-0 after:bg-taupe after:animate-pulse after:h-[1px] after:w-8",
          characterSelected:
            "empty:before:absolute empty:before:bottom-1 empty:before:bg-taupe empty:before:h-[1px] empty:before:w-8",
        }}
        onChange={setValue}
        onComplete={(value) => {
          handleCodeSubmit(value, newUser.phone);
        }}
        inputProps={{ inputMode: "numeric", autoComplete: "one-time-code" }}
      />
      <p className="text-red-400 text-[10px] md:text-sm ml-1 -mb-5 h-5">
        {errorOnCode || ""}
      </p>

      <div className="py-4 mt-2">
        <span className="pr-2">Vous ne recevez pas le code ?</span>
        <div className="flex justify-between items-center">
          <button
            aria-label="Renvoyer un code"
            className="font-semibold text-basis underline cursor-pointer"
            onClick={handleResendCode}>
            {resendLoading ? <SmSpinner h={5} w={5} /> : ""} Renvoyer un code
          </button>
          {signupLod? <SmSpinner h={5} w={5} /> : ""}
        </div>
      </div>
    </>
  );
};

export default SMScodeInput;
