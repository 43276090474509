import React, { useContext, useEffect, useState } from "react";
import { useModalContext } from "Context/ModalContext";
import SmSpinner from "Component/ui/SmSpinner";
import toast from "react-hot-toast";
import AgendaContext from "Context/AgendaContext";
import { isPossiblePhoneNumber } from "react-phone-number-input";

const Newclient = ({
  setEditClient,
  SetSelectedclient,
  Body,
  SetBody,
  EmployeeSelected,
  SelectedClient,
}) => {
  const { saveClient, updateClient, editBody, setEditBody } = useModalContext();
  const {
    FetchAllEmployeeData,
    FetchEmployeeData,
    SelectedEmpl,
    loading,
    phoneAvailability,
    checkPhone,
  } = useContext(AgendaContext);

  const [fetching, setFetching] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    if (SelectedClient) {
      setForm({
        firstName: SelectedClient.firstName,
        lastName: SelectedClient.lastName,
        email: SelectedClient.email,
        phone: SelectedClient.phone,
        _id: SelectedClient._id,
      });
    }
  }, []);

  const submitClient = async (e) => {
    e.preventDefault();
    if (form?.phone && form.phone.length < 8 || phoneErr || !phoneAvailability || !isPossiblePhoneNumber(form.phone)) {
      toast.error(
        "Renseignez un numéro de téléphone valide!"
      );
      return;
    }
    setFetching(true);
    saveClient(form)
      .then((res) => {
        let client = res.data.client;
        setFetching(false);
        SetSelectedclient(client);
        SetBody({ ...Body, clientid: client._id, staffid: EmployeeSelected });
      })
      .catch((err) => {
        if (
          err.response.status === 409 &&
          err.response.statusText === "Conflict"
        )
          toast.error("Il existe déjà un compte ayant cette adresse e-mail");
        console.error(err);
        setFetching(false);
      });
  };

  const editClient = async (e) => {
    e.preventDefault();
    if (form?.phone && form.phone.length < 8 || phoneErr || !phoneAvailability || !isPossiblePhoneNumber(form.phone)) {
      toast.error(
        "Renseignez un numéro de téléphone valide!"
      );
      return;
    }
    setFetching(true);
    await updateClient(form)
      .then((res) => {
        let client = res.data.client;

        SetSelectedclient(client);
        setEditBody({ ...editBody, clientid: client._id });
      })
      .catch((err) => {
        if (err.status === 409 && err.statusText === "Conflict")
          toast.error("Il existe déjà un compte ayant cette adresse e-mail");
        console.error(err);
        setFetching(false);
      });
    if (SelectedEmpl === "All") {
      await FetchAllEmployeeData();
    } else {
      await FetchEmployeeData(SelectedEmpl);
    }
    setFetching(false);
    setEditClient(false);
  };

  const handlePhoneChange = async (e) => {
    const num = e.target.value;
    if (num.length < 8 || !isPossiblePhoneNumber(num)) {
      setPhoneErr(true);
    } else {
      setPhoneErr(false);
      checkPhone(e.target.value);
    }
    setForm({ ...form, phone: e.target.value });
  };

  const checkInputStyle = () => {
    if(form.phone && (phoneErr || !phoneAvailability || !isPossiblePhoneNumber(form.phone))) {
      return "border-red-700 border-2 outline-none";
    } else if(form.phone && (!phoneErr && phoneAvailability && isPossiblePhoneNumber(form.phone)) ) {
      return "border-green-700 border-2 outline-none";
    } else return "border-gray-700 border-2 outline-none";
  }

  return (
    <form
      className="w-[100%]"
      onSubmit={!SelectedClient ? submitClient : editClient}
    >
      <div className="modalElement_eventModal flex flex-col mt-2 gap-2">
        <div className="flex gap-2">
          <div className="flex flex-col grow">
            <h6 className="font-syne text-sm">Prénom</h6>
            <input
              value={form.lastName}
              onChange={(e) => setForm({ ...form, lastName: e.target.value })}
              required
              className="bg-transparent border-2 rounded-lg  border-[#4A4A49] p-1 mt-1"
              type="text"
            />
          </div>

          <div className="flex flex-col grow">
            <h6 className="font-syne text-sm">Nom</h6>
            <input
              value={form.firstName}
              onChange={(e) => setForm({ ...form, firstName: e.target.value })}
              required
              className="bg-transparent border-2 rounded-lg  border-[#4A4A49] p-1 mt-1"
              type="text"
            />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex flex-col grow">
            <h6 className="font-syne text-sm">
              Téléphone{" "}
              <span className="ml-2 mb-2">
                {loading && <SmSpinner h={4} w={4} />}
              </span>
            </h6>
            <input
              value={form.phone}
              onInput={handlePhoneChange}
              required
              className={`bg-transparent border-2 rounded-lg  border-[#4A4A49] p-1 mt-1 ` + checkInputStyle()}
              type="+"
            />
            {phoneErr &&
              !isPossiblePhoneNumber(form.phone) && (
                <span className="text-xs text-red-400">
                  Renseignez un numéro de téléphone valide!
                </span>
              )}
          </div>
          <div className="flex flex-col grow">
            <h6 className="font-syne text-sm">Email</h6>
            <input
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              required
              className="bg-transparent border-2 rounded-lg  border-[#4A4A49] p-1 mt-1"
              type="email"
            />
          </div>
        </div>
      </div>
      <button
        className="float-right text-sm rounded-full bg-black text-sand border-2 border-sand px-4 py-2 mt-4 mb-2 mr-1"
        type="submit"
      >
        {fetching ? (
          <>
            <SmSpinner w={5} h={5} /> Enregistrer
          </>
        ) : (
          "Enregistrer"
        )}
      </button>
    </form>
  );
};

export default Newclient;
