import { useState, useContext, useEffect, useRef } from "react";
import { VscSettings } from "react-icons/vsc";
import { useParams } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi";
import { FaArrowRight } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Map from "../../Component/utils/MapGoogle";
import Datacontext from "../../Context/Datacontext";
import "../../styles/animate.css";
import ProCardMob from "./ProCardMob";
import "../../styles/Search.css";
import FilterMob from "./FilterMob";
import SortMob from "./SortMob";
import PlacesAutocomplete from "react-places-autocomplete";

import ProcardSimple from "./ProcardSimple";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import InfiniteScroll from "react-infinite-scroll-component";
import SmSpinner from "Component/ui/SmSpinner";
import prestationContext from "Context/prestationContext";
import SalonDataContext from "Context/SalonDataContext";

const SearchMob = ({ searchbarChoices }) => {
  let { typeParams, cityParams } = useParams();
  const [arrowDisable, setArrowDisable] = useState(true);
  const [address, setAddress] = useState("");

  const elementRef = useRef(null);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [Filter, setFilter] = useState(false);
  const [Sort, setSort] = useState(false);
  const [md, setMd] = useState(false);
  const [params, setParams] = useState({
    type: typeParams ? typeParams : "",
    city: cityParams ? cityParams : "",
  });
  const [SortType, setSortType] = useState("Pertinence");
  const [toggleMap, setTogglemap] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [middle, setMiddle] = useState([]);
  const [TempFilter, setTempFilter] = useState({
    name: "all",
    type: "all",
    maxPrice: 0,
    clientGender: "all",
    address: "all",
    payment: "all",
    promotion: false,
  });

  const {
    institutes,
    fetch,
    setFilterlist,
    FilterList,
    fetchMore,
    hasMore,
    fetching,
    setHasMore,
  } = useContext(Datacontext);
  const { setSelectedPrestation, setSelectedFreeSlot } = useContext(prestationContext);
  const { setSalon } = useContext(SalonDataContext);

  const locations = institutes.map((item, i) => {
    return {
      address: item.address,
      lat: item.position.lat,
      lng: item.position.lng,
    };
  });

  useEffect(() => {
    if (document.body.clientWidth < 768) {
      setMd(true);
    } else {
      setMd(false);
    }
  }, [document.body.clientWidth]);

  useEffect(() => {
    if (md) {
      if (params.type && params.city) {
        fetch(true,{ ...FilterList, type: params.type, city: params.city }, false, true);
      } else if (typeParams) {
        fetch(true,{ ...FilterList, type: params.type }, false, true);
      }
    }
  }, []);

  useEffect(() => {
    if (md) {
      setSelectedPrestation([]);
      setSelectedFreeSlot("");
      setSalon([]);
      setPage(0);
      setHasMore(true);
      if (!params.type && !params.city) {
        fetch(FilterList, false, true);
      }
    }
  }, [FilterList]);
  const Navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterlist({ ...FilterList, name, city : address.split(',')[0] });
    Navigate("/search");
  };

  const handleSelectedCard = (e) => {
    setSelectedCard(e);
  };

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const fetchMoreData = () => {
    fetchMore(true,FilterList, hasMore, page + 1, perPage);
    setPage(page + 1);
  };

  return (
    <div className="xs:max-md:flex md:hidden relative">
      {toggleMap && (
        <div className="flex ">
          <div className=" self-center absolute h-[60%] w-[90%] translate-x-[23px] mx-auto rounded-3xl overflow-hidden mt-[20%]">
            {" "}
            <Map
              middle={middle}
              markers={locations}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
            />
          </div>
          <div className="absolute bottom-1 mt-5 flex flex-row w-[90%] left-4 gap-2 overflow-x-scroll  ">
            {toggleMap &&
              institutes.map((item) => (
                <ProcardSimple
                  middle={middle}
                  setMiddle={setMiddle}
                  selectedCard={selectedCard}
                  handleSelectedCard={handleSelectedCard}
                  institute={item}
                  key={item._id}
                />
              ))}
          </div>
        </div>
      )}
      <div className=" h-[90vh] w-screen justify-center px-7 mt-4 flex flex-col overflow-x-hidden">
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="flex  rounded-3xl z-20  py-2 glass-effect mt-2 px-3 ">
          <div className="w-1/2 border-r-2 px-3 flex items-center text-white">
            <BsSearch />
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className=" w-full bg-transparent placeholder-white px-2"
              placeholder="Coiffeurs"
            />
          </div>
          <div className="w-1/2 items-center flex ">
            <PlacesAutocomplete
              searchOptions={{ types: ["(cities)"] }}
              value={address}
              onChange={setAddress}
              onSelect={setAddress}>
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className="w-full">
                  <input
                    type="text"
                    placeholder="Genève "
                    className="w-[90%] bg-transparent placeholder:text-white text-white px-3 placeholder:font-syne"
                    {...getInputProps({ placeholder: "Genève" })}></input>
                  <div className="absolute rounded-2xl  overflow-hidden">
                    {suggestions.slice(0, 4).map((suggestion) => {
                      return (
                        <div
                          key={suggestion._id}
                          {...getSuggestionItemProps(suggestion)}
                          className=" cursor-pointer   px-2 py-1 top-44 hover:bg-black hover:text-white h-fit bg-beige text-[#716960] text-xs w-[100%] flex flex-wrap">
                          {suggestion.terms[0].value}, {suggestion.terms[1]?.value}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {/* <input
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="w-full bg-transparent placeholder-white  px-2 text-white"
              placeholder="Genève "
            /> */}
          </div>
          <button
            onClick={(e) => handleSubmit(e)}
            className="bg-black max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px] rounded-full text-white flex justify-center items-center ">
            <FaArrowRight />
          </button>
        </form>
        <div
          className="flex mt-2 gap-2 overflow-x-scroll whitespace-nowrap  no-scrollbar w-[75%] self-center text-xs justify-normal"
          ref={elementRef}>
          <button
            disabled={arrowDisable}
            onClick={() => handleHorizantalScroll(elementRef.current, 25, 100, -10)}
            className="text-white flex justify-center items-center">
            <GrFormPreviousLink
              
              style={{  zIndex: 10  }}
              className="cursor-pointer glass-effect w-fit h-fit rounded-full p-3 flex items-center gap-1 active:border absolute left-5"
            />
          </button>
          <button
            onClick={() => {
              setFilter(!Filter);
              setSort(false);
            }}
            className="glass-effect w-fit h-fit text-white rounded-3xl p-3 flex items-center gap-1 ">
            <VscSettings />
            Filter
          </button>
          <button
            onClick={() => {
              setSort(!Sort);
              setFilter(false);
            }}
            className="glass-effect w-fit h-fit text-white rounded-3xl p-3 flex items-center gap-1 ">
            Trier
            <HiChevronLeft
              size={15}
              className={`${
                Sort ? "rotate-[270deg]" : "rotate-0"
              } transition duration-300 `}
            />
          </button>
          <button
            onClick={() => {
              setTogglemap(!toggleMap);
              setFilter(false);
              setSort(false);
            }}
            className={`${
              toggleMap && "border"
            } glass-effect w-fit h-fit text-white rounded-3xl p-3 flex items-center gap-1`}>
            Carte
          </button>
          {searchbarChoices.map((choice, idx) => {
            return (
              <button
                type="button"
                aria-label={`rechercher les ${choice.name}`}
                key={idx + choice.value}
                onClick={() => {
                  setFilterlist({ ...FilterList, type: choice.value });
                }}
                className={`glass-effect w-fit h-fit text-white rounded-3xl p-3 flex items-center gap-1  ${
                  FilterList.type === choice.value && "border"
                } `}>
                {choice.name}
              </button>
            );
          })}
          <button
            type="button"
            aria-label={`réintialiser la recherche`}
            onClick={() => {
              setParams({ type: "", city: "" });
              setFilterlist({
                name: "all",
                city: "all",
                type: "all",
                maxPrice: 0,
                clientGender: "all",
                address: "all",
                payment: "all",
                promotion: false,
              });
              setTempFilter({
                name: "all",
                type: "all",
                maxPrice: 1000,
                clientGender: "all",
                address: "all",
                payment: "all",
                promotion: false,
              });
              setSortType("Pertinence");
            }}
            className={`glass-effect w-fit h-fit text-white rounded-3xl p-3 flex items-center gap-1 active:border`}>
            Supprimer les filtres
          </button>
          <button
            onClick={() => handleHorizantalScroll(elementRef.current, 25, 100, 10)}
            className="text-white flex justify-center items-center">
            <GrFormNextLink
              
              style={{ "--tw-backdrop-blur": "3px" }}
              className="cursor-pointer glass-effect w-fit h-fit rounded-full p-3 flex items-center gap-1 active:border absolute right-5"
            />
          </button>
        </div>
        <div className="flex flex-row h-2/3 w-full gap-2 ins-cards-container overflow-x-scroll justify-start snap-x snap-mandatory mt-4">
          {!toggleMap && (
            <div
              id="scrollableDivMob"
              style={{ height: "100%", overflow: "auto", width: "90%" }}>
              <InfiniteScroll
                dataLength={institutes.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={
                  <div className="text-center h-full w-full text-white mt-2 mb-2 justify-center items-center overflow-hidden">
                    <SmSpinner w={7} h={7} />
                  </div>
                }
                scrollableTarget="scrollableDivMob">
                {institutes.length > 0 ? (
                  institutes.map((item) => (
                    <div style={{ width: "90%", margin: "1rem" }} key={item._id}>
                      <ProCardMob institute={item} />
                    </div>
                  ))
                ) : (
                  <>
                    {!fetching ? (
                      <div className="p-5 animate__animated  animate__fadeInDown flex-grow text-beige text-sm flex flex-col justify-center items-center h-full gap-6 w-full linear-background rounded-3xl overflow-hidden">
                        <p className="text-base">
                          Votre recheche n'a donné aucuns résultats
                        </p>
                        <button
                          type="button"
                          aria-label={`réintialiser la recherche`}
                          onClick={() => {
                            setParams({ type: "", city: "" });
                            setFilterlist({
                              name: "all",
                              city: "all",
                              type: "all",
                              maxPrice: 0,
                              clientGender: "all",
                              address: "all",
                              payment: "all",
                              promotion: false,
                            });
                            setTempFilter({
                              name: "all",
                              type: "all",
                              maxPrice: 1000,
                              clientGender: "all",
                              address: "all",
                              payment: "all",
                              promotion: false,
                            });
                            setSortType("Pertinence");
                          }}
                          className={`text-black bg-beige text-sm rounded-3xl px-3 py-1.5 active:bg-beige active:text-black transition duration-200`}>
                          Supprimer les filtres
                        </button>
                      </div>
                    ) : null}
                  </>
                )}
              </InfiniteScroll>
            </div>
          )}
        </div>
      </div>
      {/* filter */}
      <FilterMob
        Filter={Filter}
        setFilter={setFilter}
        TempFilter={TempFilter}
        setTempFilter={setTempFilter}
        setFilterlist={setFilterlist}
        FilterList={FilterList}
      />
      {/* sort */}
      <SortMob
        Sort={Sort}
        setSort={setSort}
        SortType={SortType}
        setSortType={setSortType}
      />
    </div>
  );
};

export default SearchMob;
