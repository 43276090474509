import { useState, useEffect } from "react";
import { AiFillStar } from "react-icons/ai";
// import placeholder from "../../assets/Procardsimple.jpeg";

const ProcardSimple = ({
  institute,
  handleSelectedCard,
  selectedCard,
  setMiddle,
  middle,
}) => {
  const { name, address } = institute;
  const handleSetMiddle = (item) => {
    setMiddle([
      {
        address: item.address,
        lat: item.position.lat,
        lng: item.position.lng,
      },
    ]);
  };
  const [randomIndex, setRandomIndex] = useState(0);
  const [imgErr, setimgErr] = useState("");

  const handleError = () => {
    setimgErr("https://via.assets.so/img.jpg?w=400&h=300");
  };

  const getRandomIndex = () => {
    return Math.ceil(Math.random() * (institute?.photos.length || 0));
  };

  useEffect(() => {
    setRandomIndex(getRandomIndex());
  }, [institute]);

  return (
    <div
      className={`py-2 px-2 gap-2 overflow-hidden w-fit cursor-pointer gray-blur min-w-[75%] h-24 snap-center flex flex-row rounded-3xl ${
        address === selectedCard ? "border-2 border-black snap-start" : ""
      }`}
      onClick={(e) => {
        handleSelectedCard(address, e);
        handleSetMiddle(institute);
      }}>
      <img
        className="w-1/2 rounded-lg"
        src={
          imgErr
            ? "https://via.assets.so/img.jpg?w=400&h=300"
            : institute?.photos[0]
            ? institute?.photos[0]
            : "https://via.assets.so/img.jpg?w=400&h=300"
        }
        alt="institute"
        style={{ aspectRatio: "1 / 1", objectFit: "contain" }}
        onError={handleError}
      />
      <div className="flex flex-col w-[70%]  ">
        <h1 className="font-syne text-sm font-extrabold">{name}</h1>
        <div className="flex items-center font-roboto text-sm gap-1">
          <AiFillStar /> 4.8(18)
        </div>
        <h1 className="font-roboto text-xs">{address}</h1>
      </div>
    </div>
  );
};

export default ProcardSimple;
