import React, { useEffect, useState } from "react";
import { useNewInstitute } from "Context/InstituteAuthContext";
import EmployeeBusinessHours from "./EmployeeBusinessHours";
import toast from "react-hot-toast";
//icons
import { BiPlus } from "react-icons/bi";
import { MdOutlineMedicalInformation } from "react-icons/md";
import { HiOutlineClock, HiXMark } from "react-icons/hi2";

const AddTeamMemberForm = ({setIsAddingMember}) => {
  const { newInstitute, setNewInstitute } = useNewInstitute();
  const [submitting, setSubmitting] = useState(false);
  const [employee, setEmployee] = useState({
    fullName: "",
    post: "",
    imgStaff: "",
    file: "",
    disponible: true,
    businessHours:[]
  });

  //onMount, set the employee businessHours the same as the institute businessHours
useEffect(() => {
    setEmployee({...employee, businessHours:newInstitute.businessHours})

}, [newInstitute])

  const handleFile = (e) => {
    let file = e.target.files;
    const fileType = file[0]["type"];
    const validImageTypes = ["image/gif", "image/jpeg", "image/jpg", "image/png","image/webp"];
    if (validImageTypes.includes(fileType)) {
        if (file[0].size > 5000000) {
            // 5MB
            toast.error("le fichier est trop volumineux, veuillez choisir un fichier de moins de 1Mo");
        } else {
            toast.success("image ajoutée");
            setEmployee({...employee,   imgStaff:file[0],
            });
            e.target.value = null
        }
    } else {
        toast.error("seules les images sont autorisées");
    }
};

const removeFile = () => {
    setEmployee({...employee, imgStaff:""})
}

const checkFormValidity = () => {
    const emptyFields = [];
    if (!employee.fullName) {
        emptyFields.push("Prènom");
    }
    if (!employee.post) {
        emptyFields.push("Poste de travail");
    }
  if (employeeNeverWorks()) {
      emptyFields.push("Horaires de l'employé");
      
    }
    if (emptyFields.length > 0) {
        const emptyFieldsMessage = emptyFields.join(", ");
        toast.error(`Veuillez renseigner les champs suivants : ${emptyFieldsMessage}`);
        return false;
    } else return true;
};
  
  const employeeNeverWorks = () => {
		const employeeNeverWorks = employee.businessHours.every((day) => !day.isOpen);
		return employeeNeverWorks;
  };

  const handleSubmit = async (e) => {
		e.preventDefault();
        setSubmitting(true);
if(!checkFormValidity()){
    setSubmitting(false);
    return}
   setNewInstitute((prev) => {
        return {
        ...prev,
        staff: [...prev.staff, employee],
        };
    });
    setIsAddingMember(false)
    setSubmitting(false);
    const container = document.querySelector("#institute_registration_layout_step_3");
	container.scrollTo({ top: 0, behavior: "smooth" });
    toast.success("employé ajouté");
}

  return (
		<>
			{/* Header */}

			{/* Add a tema member form */}
			<>
				<form action='' onSubmit={handleSubmit} className='grow flex flex-col justify-between'>
					{/* Photo + name and Role */}
					<div className='grid lg:grid-cols-2 gap-2 mb-4 grow'>
						<div className='bg-black rounded-md border dark:border-dark_grey p-6 flex flex-col justify-start gap-4'>
							{/* Photo */}
							<div className='flex flex-col gap-2 w-fit'>
								<div>
									<h3 className='text-sm mb-1'>Photographie</h3>
								</div>
								<span className='sr-only'>choisissez une photographie de votre employé </span>
								{employee.imgStaff ? (
									<>
										<div className='relative w-fit mb-4'>
											<button type='button' aria-label='Supprimer la photo' onClick={removeFile} className='z-10  absolute top-1.5 right-1.5'>
												<HiXMark className='cursor-pointer bg-black dark:bg-beige dark:text-black text-beige hover:opacity-100 transition-opacity duration-200 rounded-full p-1 h-6 w-6 ' />
											</button>
											<div className='h-28 w-28 rounded-full overflow-hidden relative'>
												<img src={URL.createObjectURL(employee.imgStaff)} alt='image de profil de votre employee' className='h-full w-full object-cover absolute' />
											</div>
										</div>
									</>
								) : (
									<label htmlFor='new_team_member_photo' className='w-fit mb-4 relative'>
										<BiPlus className='bg-black dark:bg-beige text-beige dark:text-black rounded-full h-28 w-28 p-4' />
										<input
											tabIndex='0'
											aria-label='choisissez une photographie de votre employé'
											type='file'
											id='new_team_member_photo'
											className='w-6 h-6 p-14 rounded-full absolute inset-0 opacity-0 focus:opacity-50 focus:bg-slate-400'
											onChange={handleFile}
										/>
									</label>
								)}
							</div>
							{/*champs formulaire */}
							<div className='flex flex-col gap-4 grow'>
								<label htmlFor='fullName text-beige'>
									{" "}
									Prénom
									<input type='text' placeholder='Prénom' id='fullName' className='input rounded-md' onChange={(e) => setEmployee({ ...employee, fullName: e.target.value })} />
								</label>
								<label htmlFor='new_team_member_poste' className='tetx-beige'>
									Poste occupé
									<input
										type='text'
										placeholder='Poste de travail'
										id='new_team_member_poste'
										className='input rounded-md'
										onChange={(e) => setEmployee({ ...employee, post: e.target.value })}
									/>
								</label>
							</div>
						</div>
						<div className='bg-black flex flex-col basis-1/3 lg:max-h-[calc(100vh_-_390px)] lg:min-h-[410px]  overflow-y-scroll rounded-md border dark:border-dark_grey p-6 pt-0 '>
							<div className='flex flex-row gap-2 items-center'>
								{/* clock Icon  */}
								<HiOutlineClock className='h-6 w-6' />
								<h2 className='my-4 text-lg font-syne font-bold'>Horaires de travail de votre employé</h2>
							</div>
							{/* Days */}
							{employee.businessHours.map((day, index) => (
								<EmployeeBusinessHours key={"businessDay" + index} index={index} {...day} employee={employee} setEmployee={setEmployee} />
							))}
						</div>
					</div>

					{/* Submit */}
					<div className='flex flex-row justify-end gap-4 w-full my-6'>
						<label htmlFor='new_team_member_cancel' className=''>
							<input
								type='button'
								onClick={() => setIsAddingMember(false)}
								id='new_team_member_cancel'
								value='Annuler'
								className='btn-outline bg-transparent dark:text-beige text-black border-black dark:border-beige cursor-pointer'
							/>
						</label>
						<label htmlFor='new_team_member_submit' className=''>
							<input
								type='submit'
								disabled={submitting}
								id='new_team_member_submit'
								value={submitting ? "loading..." : "Ajouter"}
								className='btn-light bg-black dark:text-black text-beige dark:bg-beige cursor-pointer'
							/>
						</label>
					</div>
				</form>
			</>
		</>
  );
};

export default AddTeamMemberForm;
