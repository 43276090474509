import { useState, useRef, useeffect, useEffect } from "react";
import { VscClose } from "react-icons/vsc";
import { useContext } from "react";
import Datacontext from "../../Context/Datacontext";
const FilterMob = ({ Filter, setFilter, TempFilter,  setTempFilter}) => {

	const { setFilterlist } = useContext(Datacontext);
	const wrapperRef = useRef(null);
	const [maxPrice, setmaxPrice] = useState(0);


	useEffect(() => {
		const handleKeydown = (event) => {
			if (event.key === "Escape") {
				setFilter(false);
			}
		};

		document.addEventListener("keydown", handleKeydown);

		return () => {
			document.removeEventListener("keydown", handleKeydown);
		};
	}, [wrapperRef]);

	return (
		Filter && (
			<div
				ref={wrapperRef}
				className={`bg-[#F6F0E8] h-[70%] w-[95%] absolute bottom-1 left-2 text-black px-6 z-10 flex flex-col rounded-t-3xl overflow-y-scroll  max-h-[489px]`}>
				<div className='flex justify-between pl-8 py-3 pr-6 text-black'>
					<h6 className='font-bold'>Filter</h6>
					<button onClick={() => setFilter(!Filter)}>
						<VscClose size={24} />
					</button>
				</div>
				<hr />
				<div className='flex flex-row text-black text-sm justify-between  '>
					<span className='font-bold'>Prix maximum</span>
					<span className='font-bold'>{TempFilter.maxPrice} €</span>
				</div>
				<input
					type='range'
					value={maxPrice}
					onChange={(e) => {
						setmaxPrice(e.target.value);
						setTempFilter({ ...TempFilter, maxPrice: maxPrice });
					}}
					min={5}
					max={1000}
				/>
				<div className='text-black text-sm'>Type d’établissement</div>
				<div className='flex flex-row gap-2 mt-1 mb-2 '>
					<button
						onClick={() => setTempFilter({ ...TempFilter, clientGender: "all" })}
						className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 border-black rounded-3xl w-fit h-fit 
                ${TempFilter.clientGender === "all" ? "text-white bg-black" : " text-black"}`}>
						Tous
					</button>
					<button
						onClick={() => setTempFilter({ ...TempFilter, clientGender: "woman" })}
						className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 border-black rounded-3xl w-fit h-fit 
                ${TempFilter.clientGender === "woman" ? "text-white bg-black" : " text-black"}`}>
						Femme
					</button>
					<button
						onClick={() => setTempFilter({ ...TempFilter, clientGender: "man" })}
						className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 border-black rounded-3xl w-fit h-fit 
                ${TempFilter.clientGender === "man" ? "text-white bg-black" : " text-black"}`}>
						Homme
					</button>
				</div>
				<div className='text-black text-sm'>Lieu</div>
				<div className='flex flex-row gap-2 mt-1 mb-2'>
					<button
						onClick={() => setTempFilter({ ...TempFilter, address: "all" })}
						className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 border-black rounded-3xl w-fit h-fit 
                ${TempFilter.address === "all" ? "text-white bg-black" : " text-black"}`}>
						Tous
					</button>
					<button
						onClick={() => setTempFilter({ ...TempFilter, address: "onsite" })}
						className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 border-black rounded-3xl w-fit h-fit 
                ${TempFilter.address === "onsite" ? "text-white bg-black" : " text-black"}`}>
						Sur place
					</button>
					<button
						onClick={() => setTempFilter({ ...TempFilter, address: "remote" })}
						className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2  border-black rounded-3xl w-fit h-fit 
                ${TempFilter.address === "remote" ? "text-white bg-black" : " text-black"}`}>
						à domicile
					</button>
				</div>
				<div className='text-black text-sm'>Paiement</div>
				<div className='flex flex-row gap-2 mt-1 mb-2'>
					<button
						onClick={() => setTempFilter({ ...TempFilter, payment: "all" })}
						className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl border-black w-fit h-fit 
                ${TempFilter.payment === "all" ? "text-white bg-black" : " text-black"}`}>
						Tous
					</button>
					<button
						onClick={() => setTempFilter({ ...TempFilter, payment: "onsite" })}
						className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl border-black w-fit h-fit 
                ${TempFilter.payment === "onsite" ? "text-white bg-black" : " text-black"}`}>
						Sur place
					</button>
					<button
						onClick={() => setTempFilter({ ...TempFilter, payment: "online" })}
						className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl border-black w-fit h-fit 
                ${TempFilter.payment === "online" ? "text-white bg-black" : " text-black"}`}>
						En ligne
					</button>
				</div>
				<div className='text-black text-sm '>Promotions</div>
				<div className='flex flex-row gap-2 mt-1 mb-2 '>
					<button
						onClick={() => setTempFilter({ ...TempFilter, promotion: false })}
						className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl border-black w-fit h-fit 
                ${TempFilter.promotion ? "text-black" : "bg-black text-white"}`}>
						Tous
					</button>
					<button
						onClick={() => setTempFilter({ ...TempFilter, promotion: true })}
						className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl border-black w-fit h-fit 
                  ${!TempFilter.promotion ? "text-black" : "bg-black text-white"}`}>
						Uniquement salon avec promotions
					</button>
				</div>
				<hr />

				<div className='flex flex-row justify-between mt-3 justify-self-end mb-2'>
					<button
						onClick={() => {
							setmaxPrice(0);
							setTempFilter({
								name: "all",
								type: "all",
								maxPrice: 0,
								clientGender: "all",
								address: "all",
								payment: "all",
								promotion: false,
							});
						}}
						className=' font-roboto text-sm text-black underline underline-offset-2'>
						Supprimer filtres
					</button>
					<button
						onClick={() => {
							setFilterlist({ ...TempFilter });
							setFilter(!Filter);
						}}
						className=' rounded-3xl font-roboto text-sm text-white  bg-black px-3 py-2 underline-offset-2'>
						Apply Filter
					</button>
				</div>
			</div>
		)
	);
};

export default FilterMob;
