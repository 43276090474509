import moment from "moment";
import { createContext, useState, useMemo } from "react";
import Cookies from "js-cookie";
import axiosInstance from "config/Interceptor";
import toast from "react-hot-toast";

const AgendaContext = createContext();
function AgendaProvider({ children }) {
  //////////////////////////////////////////////////////       States         //////////////////////////////////////////////////////

  const [EmployeeData, SetEmpData] = useState([]);
  const [SelectedDate, SetSelctedDate] = useState(moment());
  const [SelectedEmpl, setSelectedEmp] = useState("All");
  const [sixDays, setSixdays] = useState([]);
  const [Employee, setEmployee] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [RulerData, setRuler] = useState([]);
  const [SelectedEvent, setSelectedEvent] = useState({});
  const [CurrentDate, setCurrentDate] = useState(moment());
  const [Weekly, Setweekly] = useState(false);
  const [sevenDays, setSevenDays] = useState([]);

  const [loading, setLoading] = useState(false);
  const [phoneAvailability, setphoneAvail] = useState(false);

  ////////////////////////////////////////////////////// Fetching Function //////////////////////////////////////////////////////

  const FetchEmployeelist = async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/secure/institute/getStaff/`);
      if (response.data) {
        setEmployee(response.data.staff);
      }
    } catch (error) {
      console.error("Error Fetching ", error);
      toast.error(error.message);
    }
  };

  const FetchEmployeeData = async (id) => {
    try {
      const response = await axiosInstance.get(`/api/v1/secure/slots/list/staff/${id}`);

      SetEmpData(response.data);
    } catch (error) {
      console.error("Error Fetching", error);
    }
  };
  const FetchAllEmployeeData = async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/secure/slots/institute/list`);

      SetEmpData(response.data);
    } catch (error) {
      console.error("Error Fetching", error);
    }
  };
  ////////////////////////////////////////////////////// Data organizing Function //////////////////////////////////////////////////////
  const GetworkingHours = (data) => {
    if (data?.length) {
      const Temp = data.filter((item) => {
        return moment(item.startTimer).isSame(SelectedDate, "day");
      });
      if (Temp[0] && Temp[Temp.length - 1]) {
        setRuler([
          Number(Temp[0].timerPeriod[0].split(":")[0]),
          Number(Temp[Temp.length - 1].timerPeriod[1].split(":")[0]),
        ]);
      } else {
        setRuler([]);
      }
    }
  };
  const GetWeekWorkingHours = (data) => {
    const Temp = data.filter((item) => {
      return moment(item.startTimer).isBetween(
        sevenDays[0],
        sevenDays[sevenDays.length - 1]
      );
    });
    setRuler([
      Number(Temp[0]?.timerPeriod[0].split(":")[0]),
      Number(Temp[Temp.length - 1]?.timerPeriod[1].split(":")[0]),
    ]);
  };

  function getFullNameById(staffArray, id) {
    for (let i = 0; i < staffArray.length; i++) {
      if (staffArray[i]._id === id) {
        return staffArray[i].fullName;
      }
    }
  }

  const CombineFreeslots = (Data) => {
    const result = [];
    let currentGroup = [];

    for (let i = 0; i < Data.length; i++) {
      const currentObj = Data[i];

      if (currentObj.isEvent) {
        if (currentGroup.length > 0) {
          result.push({
            ...currentGroup[0],
            timerPeriod: [
              currentGroup[0].timerPeriod[0],
              currentGroup[currentGroup.length - 1].timerPeriod[1],
            ],
          });
          currentGroup = [];
        }
        result.push(currentObj);
      } else {
        currentGroup.push(currentObj);
      }
    }

    if (currentGroup.length > 0) {
      result.push({
        ...currentGroup[0],
        timerPeriod: [
          currentGroup[0].timerPeriod[0],
          currentGroup[currentGroup.length - 1].timerPeriod[1],
        ],
      });
    }

    return result;
  };
  ////////////////////////////////////////////////////// Date Manipulation Function //////////////////////////////////////////////////////

  const generateSevenDays = (startDate) => {
    const days = [];
    for (let i = 0; i < 5; i++) {
      const nextDay = moment(startDate).add(i, "days");
      days.push(nextDay.format("YYYY-MM-DD"));
    }
    setSevenDays(days);
  };
  const generatesixDays = (startDate) => {
    const days = [];
    for (let i = 0; i < 6; i++) {
      const nextDay = moment(startDate).add(i, "days");
      days.push(nextDay.format("YYYY-MM-DD"));
    }
    setSixdays(days);
  };
  const resetDate = () => {
    SetSelctedDate(moment());
  };

  const incrementDate = () => {
    if (Weekly) {
      SetSelctedDate(moment(SelectedDate).add(5, "days"));
    } else {
      SetSelctedDate(moment(SelectedDate).add(1, "days"));
    }
  };

  const decrementDate = () => {
    if (Weekly) {
      SetSelctedDate(moment(SelectedDate).subtract(5, "days"));
    } else {
      SetSelctedDate(moment(SelectedDate).subtract(1, "days"));
    }
  };

  const checkPhone = async (phone) => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/api/v1/phone/check?phoneNumber=${phone}`);
      setphoneAvail(true);
      setLoading(false);
      // console.log(phoneAvailability);
    } catch (error) {
      setphoneAvail(false);

      setLoading(false);
      // Handle error here
      // console.log(phoneAvailability);
      console.error("Error checking phone:", error);
    }
  };
  ////////////////////////////////////////////////////// Modal Function ///////////////////////////////////////////////////////

  const OpenSelected = (event) => {
    setSelectedEvent(event);
    setIsOpen(true);
  };
  const AddEvent = () => {
    setSelectedEvent({});
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const afterOpenModal = () => {};

  ////////////////////////////////////////////////////// Distribution  //////////////////////////////////////////////////////
  //TODO add useMemo and refactor the code
  useMemo(
    () => ({
      SetEmpData,
      AddEvent,
      CombineFreeslots,
      setSelectedEvent,
      FetchAllEmployeeData,
      incrementDate,
      decrementDate,
      resetDate,
      Employee,
      setSelectedEmp,
      setIsOpen,
      setCurrentDate,
      OpenSelected,
      closeModal,
      afterOpenModal,
      FetchEmployeelist,
      setEmployee,
      FetchEmployeeData,
      Setweekly,
      setSevenDays,
      generateSevenDays,
      getFullNameById,
      GetworkingHours,
      GetWeekWorkingHours,
      generatesixDays,
      checkPhone,
      loading,
      phoneAvailability,
    }),
    [
      modalIsOpen,
      SelectedEvent,
      EmployeeData,
      SelectedDate,
      SelectedEmpl,
      modalIsOpen,
      CurrentDate,
      Weekly,
      sevenDays,
      RulerData,
      sixDays,
      Employee,
      loading,
      phoneAvailability,
      checkPhone,
    ]
  );
  const valueToShare = {
    SetEmpData,
    AddEvent,
    CombineFreeslots,
    SelectedEvent,
    setSelectedEvent,
    FetchAllEmployeeData,
    incrementDate,
    decrementDate,
    EmployeeData,
    SelectedDate,
    resetDate,
    Employee,
    SelectedEmpl,
    setSelectedEmp,
    modalIsOpen,
    setIsOpen,
    CurrentDate,
    setCurrentDate,
    OpenSelected,
    closeModal,
    afterOpenModal,
    FetchEmployeelist,
    setEmployee,
    FetchEmployeeData,
    Weekly,
    Setweekly,
    sevenDays,
    setSevenDays,
    generateSevenDays,
    getFullNameById,
    RulerData,
    GetworkingHours,
    GetWeekWorkingHours,
    generatesixDays,
    sixDays,
    loading,
    phoneAvailability,
    checkPhone,
  };
  return <AgendaContext.Provider value={valueToShare}>{children}</AgendaContext.Provider>;
}
export { AgendaProvider };
export default AgendaContext;
