import React, { useState, useEffect } from "react";
import "../../styles/phoneInput.css";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { useNewUser } from "../../Context/UserAuthContext";

const PhoneNumberInput = ({ setIsPossiblePhoneNumber, error }) => {
	const [value, setValue] = useState();
	const { setNewUser } = useNewUser();

	useEffect(() => {
		if (value !== undefined && isPossiblePhoneNumber(value)) {
			setNewUser((user) => {
				return { ...user, phone: value };
			});
			setIsPossiblePhoneNumber(true);
		} else {
			setIsPossiblePhoneNumber(false);
			setNewUser((user) => {
				return { ...user, phone: "" };
			});
		}
  }, [value]);
  
	return (
		<>
			<PhoneInput
				id='telephone'
				placeholder={"Numéro de téléphone"}
				value={value}
				defaultCountry={"CH"}
				onChange={setValue}
				rules={{ required: true, validate: isPossiblePhoneNumber }}
				style={
					value
						? isPossiblePhoneNumber(value) && !error
							? { outline: "1px solid green", marginBottom: "20px" }
							: { outline: "1px solid rgb(252 165 165)" }
						: { outline: "none", marginBottom: "20px" }
				}
			/>
			{value !== undefined && !isPossiblePhoneNumber(value) && (
				<span className='text-xs text-red-400'>Renseignez un numéro de téléphone valide </span>
			)}
		</>
	);
};

export default PhoneNumberInput;
