import React from "react";

function NumClient() {
  return (
    <>
      <div className="flex md:flex-row justify-center flex-col gap-x-60 md:pt-11 p-11  md:h-[374px] relative bg-black ">
        <div className="   grid grid-cols-1 ">
          <div className="flex flex-col items-center ">
            <h1 className="md:text-6xl text-4xl text-[#F6F0E8] font-syne">+20%</h1>
            <p className="md:text-lg text-sm text-[#D0CBC4] font-roboto">
              de visibilité en ligne
            </p>
          </div>

          <div className="flex flex-col items-center ">
            <h1 className="md:text-6xl text-4xl text-[#F6F0E8] font-syne">-40% </h1>
            <p className="md:text-lg text-sm text-[#D0CBC4] font-roboto">
              de rendez-vous annulés
            </p>
          </div>
        </div>

        <div className="   grid grid-cols-1  ">
          <div className="flex flex-col items-center ">
            <h1 className="md:text-6xl text-4xl text-[#F6F0E8] font-syne">-50% </h1>
            <p className="md:text-lg text-sm text-[#D0CBC4] font-roboto">
              de temps passé sur l'administration
            </p>
          </div>

          <div className="flex flex-col items-center ">
            <h1 className="md:text-6xl text-4xl text-[#F6F0E8] font-syne">+25% </h1>
            <p className="md:text-lg text-sm text-[#D0CBC4] font-roboto">
              de fidélisation client
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default NumClient;
