import React from "react";
import HeaderCGU from "Component/Privacy/HeaderCGU";
import FooterCGU from "Component/Privacy/FooterCGU";

const Privacy = () => {
  return (
    <>
      <HeaderCGU />
      <div className="h-full bg-[#FAF8F6] mt-[4rem]">
        <div className="w-full p-4 md:p-12 px-8 md:px-20  text-[#212128]">
          <div className="my-6">
            <p>Accueil {">"} CGU</p>
          </div>
          <div className="my-6">
            <h3>Conditions Générales d'Utilisation (CGU)</h3>
          </div>
          <div className="h-full rounded  p-8 border-solid border-[2px] border-[#eeedec] bg-white font-syne">
            <div className="my-4 gap-2">
              <h5 className="font-bold my-2"> 1. Identification</h5>
              <p className="ml-6">
                Stunify, une société anonyme suisse, avec un capital de 100 000 chf ,
                basée à Genève
              </p>
            </div>
            <div className="my-4 gap-2">
              <h5 className="font-bold my-2"> 2. Définitions</h5>

              <ul className="list-disc list-inside my-2">
                <li>
                  <span className="font-bold">Utilisateur:</span> Toute personne physique
                  ou morale utilisant la plateforme Stunify.
                </li>
                <li>
                  <span className="font-bold">Prestataire:</span> Professionnel de la
                  beauté inscrit sur Stunify proposant ses services.
                </li>
                <li>
                  <span className="font-bold">Service:</span> Prestation de mise en
                  relation proposée par Stunify.
                </li>
                <li>
                  <span className="font-bold">Plateforme:</span> Site web et application
                  mobile Stunify.
                </li>
              </ul>
            </div>
            <div className="my-4 gap-2">
              <h5 className="font-bold my-2"> 3. Objet</h5>
              <p className="ml-6">
                Les présentes CGU régissent l'utilisation de la plateforme Stunify,
                facilitant la prise de rendez-vous chez des professionnels de la beauté.
                L'accès et l'utilisation de Stunify impliquent l'acceptation sans réserve
                des présentes CGU par l'Utilisateur.{" "}
              </p>
            </div>
            <div className="my-4 gap-2">
              <h5 className="font-bold my-2"> 4. Inscription et Compte</h5>
              <p className="ml-6">
                L'inscription sur Stunify nécessite la fourniture de données personnelles
                valides. L'Utilisateur est responsable de la confidentialité de ses
                identifiants.
              </p>
            </div>
            <div className="my-4 gap-2">
              <h5 className="font-bold my-2"> 5. Utilisation de la Plateforme</h5>
              <p className="ml-6">
                Stunify permet de rechercher, comparer et prendre rendez-vous avec des
                Prestataires. Les utilisateurs peuvent laisser des avis sur les services
                reçus, sous réserve de vérification par Stunify.
              </p>
            </div>
            <div className="my-4 gap-2">
              <h5 className="font-bold my-2"> 6. Responsabilités</h5>
              <p className="ml-6">
                Stunify agit en tant qu'intermédiaire et n'est pas partie au contrat entre
                le Prestataire et l'Utilisateur. La responsabilité de Stunify est limitée
                aux services de mise en relation.
              </p>
            </div>
            <div className="my-4 gap-2">
              <h5 className="font-bold my-2"> 7. Protection des Données</h5>
              <p className="ml-6">
                Conformément à la législation suisse sur la protection des données,
                Stunify s'engage à protéger les données personnelles des Utilisateurs.
              </p>
            </div>
            <div className="my-4 gap-2">
              <h5 className="font-bold my-2"> 8. Modification des CGU</h5>
              <p className="ml-6">
                Stunify se réserve le droit de modifier les CGU à tout moment. Les
                modifications seront effectives dès leur publication sur la plateforme.
              </p>
            </div>
            <div className="my-4 gap-2">
              <h5 className="font-bold my-2"> 9. Résiliation</h5>
              <p className="ml-6">
                L'Utilisateur peut à tout moment supprimer son compte, entraînant la
                cessation de l'application des CGU à son égard. à{" "}
              </p>
            </div>

            <div className="my-4 gap-2">
              <h5 className="font-bold my-2"> 10. Droit Applicable et Juridiction</h5>
              <p className="ml-6">
                Les présentes CGU sont soumises au droit suisse. Tout litige relatif à
                leur interprétation ou leur exécution relève de la compétence des
                tribunaux suisses.
              </p>
            </div>
            <div className="my-4 gap-2">
              <h5 className="font-bold my-2"> 9. Contact</h5>
              <p className="ml-6">
                Pour toute question relative à ces CGU, merci de contacter Stunify à
                l'adresse sav@stunify.io
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterCGU />
    </>
  );
};

export default Privacy;
