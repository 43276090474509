import { Link } from "react-router-dom";
import React, { useContext } from "react";
import LoggedContext from "../../Context/isLogged";
import Cookies from "js-cookie";
import {
  disconnectUserInBackend,
  DisconnectUserInFront,
} from "Component/utils/connectionHandler";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetTrigger,
} from "../ui/sheet";
//icons
import { HiChevronDown, HiArrowRightOnRectangle, HiOutlineRectangleGroup,HiBars3, HiOutlineBell, HiOutlineUserCircle } from "react-icons/hi2";



const SideNavUserConnected = () => {
  const { userGlobal } = useContext(LoggedContext);
  const disconnectUserInFront = DisconnectUserInFront();

  const handleUserDisconnection = () => {
		let token = Cookies.get("authToken");
		disconnectUserInBackend(token).then((res) => {
			if (!res.ok) {
				// console.log("error");
			}
			disconnectUserInFront();
		});
  };

  return (
		<>
			<Sheet>
				<SheetTrigger asChild>
					{/* burger Icon */}
					<button type='button'>
						<HiBars3 className='w-10 h-10 md:hidden text-beige' />
					</button>
				</SheetTrigger>
				<SheetContent side={"left"} className=' sideTrans-bg  overflow-y-auto w-1/2 sm:w-2/5 md:w-[300px]'>
					<div className='flex flex-col justify-between h-full pt-10'>
						<div className='flex flex-col gap-6'>
							<SheetClose asChild>
								<Link to='/profile' className='w-fit'>
									<span className='btn-dark w-full'>Dashboard</span>
								</Link>
							</SheetClose>
							<SheetClose asChild>
								<Link to='/' className='text-beige hover:underline w-fit'>
									Faire une recherche
								</Link>
							</SheetClose>
						</div>
						<div className='flex flex-row flex-wrap justify-start items-center gap-4'>
							{/* profile thumbnail */}
							<SheetClose asChild>
								<Link to='/profile'></Link>
							</SheetClose>
							{/* User Menu ('mes informations' and 'se déconnecter') */}
							<SheetClose>
								<DropdownMenu>
									<DropdownMenuTrigger className='group '>
										<p className='text-black peer cursor-pointer !pl-0 !pr-3 !py-0 btn-light flex flex-row gap-1 items-center max-w-fit '>
											{userGlobal.imageUrl ? (
												<img src={userGlobal.imageUrl} alt='user profile' className='rounded-full h-[35px] aspect-square border border-beige z-50' />
											) : (
												<HiOutlineUserCircle className='rounded-full z-50 h-9 w-9 aspect-square' />
											)}
											<span className='line-clamp-1 text-left capitalize'> {userGlobal.firstName || "profile"} </span>
											{/* arrow down */}
											<HiChevronDown className='w-6 h-6 transition duration-500 ease-in-out transform scale-y-100 group-data-[state=open]:-scale-y-100 ' />
										</p>
									</DropdownMenuTrigger>
									<DropdownMenuContent className='linear-background bg-transparent linear-background mx-8'>
										{/* <DropdownMenuSeparator /> */}
										<DropdownMenuItem onClick={handleUserDisconnection}>
											<button className='flex flex-row gap-2 justify-start py-3 pl-6 pr-20 cursor-pointer'>
												<HiArrowRightOnRectangle className='w-6 h-6' />
												Se déconnecter
											</button>
										</DropdownMenuItem>
									</DropdownMenuContent>
								</DropdownMenu>
							</SheetClose>
						</div>
					</div>
					<SheetFooter></SheetFooter>
				</SheetContent>
			</Sheet>
		</>
  );
};

export default SideNavUserConnected;
