import React,{useState} from "react";
import Nav from "../institute-registration/Nav";
import PaymentMethod from "../institute-registration/PaymentMethod";
import { useNewInstitute } from "Context/InstituteAuthContext";

//icons
import { HiOutlineHomeModern, HiOutlineClock } from "react-icons/hi2";

const InstitutePaymentMethods = ({
  handleStepChange,
}) => {
    const { newInstitute, setNewInstitute } = useNewInstitute();

  const [wantADeposit, setWantADeposit] = useState(true)
  const paymentMethods = [
	"klarna",
    "postfinance",
    "paypal",
    "twint",
  ];

  const handlePaymentMethod = (e) => {
    if (e.target.checked) {
      if (!newInstitute.payementMethode.includes(e.target.name)) {
        setNewInstitute((prev) => {
          return {
            ...prev,
            payementMethode: [...prev.payementMethode, e.target.name],
          };
        });
      }
    } else {
      setNewInstitute((prev) => {
        return {
          ...prev,
          payementMethode: prev.payementMethode.filter(
            (method) => method !== e.target.name
          ),
        };
      });
    }
  };

  const handleDepositDisplay = (e) => {
    setWantADeposit( () => {return e.target.checked})
    if(e.target.checked === false)setNewInstitute(prev => {return {...prev, wantADeposit: ''}})
    else setNewInstitute(prev => {return {...prev, wantADeposit: '30%'}})
  }

  const handleDepositRadioOption = (e) => {
    if(e.target.checked){
      setNewInstitute(prev => {return {...prev, wantADeposit: e.target.value}})
    }
  }

const handleSubmitPaymentMethod = (e) => {
  e.preventDefault()
  handleStepChange(e, 'next')
}
  return (<>
	
		{newInstitute &&	<section className='flex flex-col justify-start'>
				{/* TITLE */}
				<h1 className='font-syne text-2xl mt-8 font-bold text-white'>Mes moyens de paiements additionnelles</h1>
				{/* 2 COLUMNS MOYEN DE PAIMENT  + ACOMPTE */}
				<form action='' onSubmit={handleSubmitPaymentMethod} className='mt-4 flex flex-col justify-between grow'>
					<div className='xl:grid-cols-2 grid gap-2 mb-4'>
						{/* MOYEN DE PAIEMENT */}
						<div className='flex flex-col justify-start items-start gap-4 border border-taupe p-6 pt-2 rounded-md'>
							{/* SUBTITLE */}
							<div className='flex flex-row justify-start items-center gap-2'>
								{/* Home Icon */}
								<HiOutlineHomeModern className='w-7 h-7' />
								<h2 className='py-4 text-lg font-bold font-syne'>Moyens de paiements</h2>
							</div>
	
							<div className='max-[925px]:flex max-[925px]:flex-col max-[925px]:gap-6 min-[925px]:grid min-[925px]:grid-cols-2 gap-2 w-full justify-around'>
								{paymentMethods.map((method, index) => (
									<PaymentMethod key={index} method={method} handlePaymentMethod={handlePaymentMethod} />
								))}
							</div>
						</div>
	
						{/* ACOMPTE */}
						<div className='flex flex-col justify-start items-start gap-6 border border-taupe p-6 pt-2 pb-6 rounded-md transition-all duration-300 '>
							{/* SUBTITLE */}
							<div className='flex flex-row flex-wrap justify-between items-center gap-2 w-full'>
								{/* Clock Icon */}
								<div className='flex flex-row items-center gap-2'>
									<HiOutlineClock className='w-7 h-7' />
									<h2 className='text-lg font-bold py-4 font-syne'>Acompte</h2>
								</div>
								<label htmlFor='institute_deposit' className='relative inline-flex items-center cursor-pointer'>
									<input checked={wantADeposit} onChange={handleDepositDisplay} id='institute_deposit' type='checkbox' className='sr-only peer' />
									<div className="w-11 h-6 bg-transparent peer-focus:outline-none rounded-full peer border border-dark_grey peer-checked:after:translate-x-full peer-checked:after:bg-beige peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-dark_grey"></div>
									<span className='ml-3 text-sm font-medium dark:peer-checked:text-beige peer-checked:text-black text-gray-400'>Demander un acompte</span>
								</label>
							</div>
							{wantADeposit && (
								<ul className='flex felx-row flex-wrap justify-start items-baseline gap-6 w-full'>
									<li className='relative'>
										<input type='radio' name='deposit' value={"10%"} id='deposit_10%' className='w-4 h-4 absolute peer top-1.5 left-2.5' onChange={handleDepositRadioOption} />{" "}
										<label
											htmlFor='deposit_10%'
											className='peer-checked:bg-transparent dark:peer-checked:border-taupe peer-checked:border peer-checked:border-dark_grey dark:bg-dark_grey  bg-light_grey p-2 pl-8 rounded-md'>
											10%
										</label>
									</li>
									<li className='relative'>
										<input type='radio' name='deposit' value={"20%"} id='deposit_20%' className='w-4 h-4 absolute peer top-1.5 left-2.5' onChange={handleDepositRadioOption} />{" "}
										<label
											htmlFor='deposit_20%'
											className='peer-checked:bg-transparent dark:peer-checked:border-taupe peer-checked:border peer-checked:border-dark_grey dark:bg-dark_grey  bg-light_grey p-2 pl-8 rounded-md'>
											20%
										</label>
									</li>
									<li className='relative'>
										<input
											defaultChecked
											type='radio'
											name='deposit'
											value={"30%"}
											id='deposit_30%'
											className='w-4 h-4 absolute peer top-1.5 left-2.5'
											onChange={handleDepositRadioOption}
										/>{" "}
										<label
											htmlFor='deposit_30%'
											className='peer-checked:bg-transparent dark:peer-checked:border-taupe peer-checked:border peer-checked:border-dark_grey dark:bg-dark_grey  bg-light_grey p-2 pl-8 rounded-md'>
											30%
										</label>
									</li>
									<li className='relative'>
										<input type='radio' name='deposit' value={"40%"} id='deposit_40%' className='w-4 h-4 absolute peer top-1.5 left-2.5' onChange={handleDepositRadioOption} />{" "}
										<label
											htmlFor='deposit_40%'
											className='peer-checked:bg-transparent dark:peer-checked:border-taupe peer-checked:border peer-checked:border-dark_grey dark:bg-dark_grey  bg-light_grey p-2 pl-8 rounded-md'>
											40%
										</label>
									</li>
									<li className='relative'>
										<input type='radio' name='deposit' value={"50%"} id='deposit_50%' className='w-4 h-4 absolute peer top-1.5 left-2.5' onChange={handleDepositRadioOption} />{" "}
										<label
											htmlFor='deposit_50%'
											className='peer-checked:bg-transparent dark:peer-checked:border-taupe peer-checked:border peer-checked:border-dark_grey dark:bg-dark_grey  bg-light_grey p-2 pl-8 rounded-md'>
											50%
										</label>
									</li>
								</ul>
							)}
						</div>
					</div>
					<Nav handleStepChange={handleStepChange} disableSkip />
				</form>
			</section>}
  </>
  );
};

export default InstitutePaymentMethods;
