import React, { useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { FiX } from "react-icons/fi";
const FullImageModal = ({
  slides,
  setShowModal,
  activeIndex,
  setFullshowModal,
}) => {
  const [currentIndex, setCurrentIndex] = useState(activeIndex);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <>
      <div className="  justify-center items-center hidden md:flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative  my-6 mx-auto ">
          {/*content*/}
          <div className=" lg:w-[1000px] md:w-[800px]  h-screen mx-auto border-0 rounded-lg shadow-lg relative flex flex-col  bg-black outline-none focus:outline-none">
            {/*header*/}
            <div className="mt-2 flex flex-row justify-between  items-center mx-3">
              <button
                className="  bg-[#F6F0E8]  flex  flex-row justify-center items-center gap-2 px-4 py-[10.5px]   p-1  border  rounded-[30px] text-black   text-sm  font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="  block outline-none focus:outline-none">
                  <FiX size={20} color="#0F0F0F" />
                </span>
                Fermer
              </button>

              <button
                className="  bg-[#F6F0E8]  flex  flex-row justify-center items-center gap-2 px-4 py-[10.5px]   p-1  border  rounded-[30px] text-black   text-sm  font-semibold outline-none focus:outline-none"
                onClick={() => setFullshowModal(false)}
              >
                <span className="  block outline-none focus:outline-none">
                  <BsChevronCompactLeft size={20} />
                </span>
                Toutes les photos
              </button>
            </div>
            {/*body*/}
            <div>
              <div className="max-w-[1400px] h-[780px] w-full m-auto py-16 px-4 relative group">
                <div
                  style={{
                    
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${slides[currentIndex]})`,
                  }}
                  className="w-full h-full rounded-2xl bg-center bg-cover duration-500"
                  
                ></div>
                {/* Left Arrow */}
                <div className="hidden  group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
                  <BsChevronCompactLeft onClick={prevSlide} size={30} />
                </div>
                {/* Right Arrow */}
                <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
                  <BsChevronCompactRight onClick={nextSlide} size={30} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black hidden md:block"></div>
    </>
  );
};

export default FullImageModal;
