import { useEffect } from "react";
import SmSpinner from "Component/ui/SmSpinner";
import "../../../styles/Agenda.css"

import { HiArrowSmLeft, HiOutlineGift } from "react-icons/hi";
import { useModalContext } from "Context/ModalContext";
const PrestationView = ({ returnNav, eventChanged }) => {
  const {
    FetchPrestation,
    Prestation,
    Loading,
    setEditBody,
    editBody,
    SelectedPrestation,
  } = useModalContext();
  useEffect(() => {
    FetchPrestation();
  }, []);
  let categories = [];

  const doesExist = (list, item) => {
    let exist = -1;
    list.forEach((single, index) => {
      if (single.category === item.category) {
        exist = index;
      }
    });
    return exist;
  };

  Prestation.forEach((event) => {
    if (doesExist(categories, event) === -1) {
      let holder = {};
      holder.category = event.category;
      holder.events = [event];
      categories.push(holder);
    } else categories[doesExist(categories, event)].events.push(event);
  });

  return (
    <div className="overflow-y-auto max-h-[700px]">
      <div className="flex items-center ">
        <button
          onClick={returnNav}
          className="mr-8 relative h-10 mx-2 w-10 rounded-full border-2 border-white  hover:text-black hover:bg-white flex items-center">
          <HiArrowSmLeft size={40} />
        </button>
        <h4>Choisir une prestation</h4>
      </div>
      {Loading ? (
        <div className="flex justify-center items-center">
          {" "}
          <SmSpinner w={10} h={10} />{" "}
        </div>
      ) : (
        <div className=" my-8 flex flex-col justify-center">
          {categories.map((cat, sindex) => {
            return (
              <div key={`p_${sindex}`}>
                <div className="bg-[#4A4A49] text-[#FFFFFF] text-lg px-2 rounded-md my-1">
                  {cat.category}
                </div>
                {cat.events.map((event, index) => {
                  return (
                    <div
                      key={event._id}
                      onClick={() => {
                        eventChanged("prestation", event);
                        setEditBody({ ...editBody, prestationid: event._id });
                      }}
                      className={`  hover:bg-sand hover:text-black hover:border-black rounded-lg p-3 w-auto my-4 cursor-pointer flex flex-row justify-between items-center${
                        event._id === SelectedPrestation._id ? " shadow border-2 " : "border-sand border-2"
                      }`}>
                      <div className="flex flex-col">
                        <b>{event.name}</b>
                        <p className="text-[#A29689]">{event.duration}mn</p>
                      </div>
                      {event.discount ? (
                        <div className="flex items-center gap-x-1">
                          <button className="bg-green-300 text-white rounded-full p-[0.1rem]">
                            <HiOutlineGift size={24} />
                          </button>
                          <b className=" line-through text-[#A29689]">{event.price} €</b>
                          <b>{event.discount} €</b>
                        </div>
                      ) : (
                        <div className="flex">
                          <b>{event.price} €</b>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PrestationView;
