// UserAuthContext.js
import React, { createContext, useContext, useState } from "react";

const UserAuthContext = createContext();

const defaultUser = {
  firstName: "",
  lastName: "",
  email: "",
  phone:"",
  isPhoneValidated: true,
};

export const UserAuthProvider = ({ children }) => {
  const [newUser, setNewUser] = useState(defaultUser);
  const [signupLod,setSignupLod] = useState(false)

  return (
    <UserAuthContext.Provider value={{ newUser, setNewUser , signupLod  , setSignupLod}}>
      {children}
    </UserAuthContext.Provider>
  );
};

export const useNewUser = () => {
  return useContext(UserAuthContext);
};
