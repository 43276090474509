import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/homepage.css";
import App from "./App";
import { Provider } from "./Context/isLogged";
import { AxiosInterceptor } from "config/Interceptor";
import { ErrorBoundary } from "react-error-boundary";
import fallbackRender from "config/ErrorBoundaryFallbackRender";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary fallbackRender={fallbackRender}>
    <GoogleOAuthProvider clientId="12235583529-3q48fpfubv1rue2ojlfktjo0ts2fakqt.apps.googleusercontent.com">
      <Provider>
        <AxiosInterceptor>
          <App />
        </AxiosInterceptor>
      </Provider>
    </GoogleOAuthProvider>
  </ErrorBoundary>
);
