import React, { useEffect, useState } from "react";
// import Background from "../assets/signInUp.png";
import LoginForm from "Component/utils/LoginForm";
import { Link, Navigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

const LoginPage = () => {
  const isAuthenticated = !!Cookies.get("authToken");
  // console.log(isAuthenticated)
  const location = useLocation();
  const [prologin, setProLogin] = useState(
    location.pathname === "/prologin" ? true : false
  );
  useEffect(() => {
    location.pathname === "/prologin" ? setProLogin(true) : setProLogin(false);
  }, [location]);
  if (isAuthenticated) {
    // console.log('IS AUTHHHHHH')
    const decoded = jwt_decode(Cookies.get("authToken"));

    if (decoded) {
      if (decoded.isPro) {
        return <Navigate to="/ProConnected/caisseDash" state={{ from: location }} />;
      } else return <Navigate to="/profile" state={{ from: location }} />;
    }
  }

  return (
    <>
      {isAuthenticated ? null : (
        <>
         
          <div className="min-h-[calc(100dvh_-_80px)] flex flex-col justify-center gap-14 my-4 px-8">
            <LoginForm prologin={prologin ? true : false} />
            <div className={`mx-auto flex flex-col justify-center items-center ${prologin? 'glass-effect' : 'bg-black' } w-10/12 sm:w-8/12 lg:w-6/12 xl:w-[800px] rounded-xl p-6`}>
              <h2 className="text-xl my-3 self-start text-beige">
                {prologin ? "Accès Client" : "Accès Professionnels"}
              </h2>
              <Link
                to={prologin ? "/login" : "/prologin"}
                className={`${prologin? 'btn-dark' : 'btn-light'} w-full text-center`}
                type="button">
                S'inscrire / Se connecter
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LoginPage;
