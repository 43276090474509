import { useModalContext } from "Context/ModalContext";
import { useEffect, useState } from "react";
import SmSpinner from "Component/ui/SmSpinner";
import { HiArrowSmLeft, HiSearch } from "react-icons/hi";
import InfiniteScroll from "react-infinite-scroll-component";

const ClientView = ({ returnNav, eventChanged }) => {
  const {
    ClientList,
    FetchClient,
    SelectedClient,
    fetchMoreClient,
    hasMoreClient,
    setHasMoreClient
  } = useModalContext();

  const [Search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const fetchMoreData = () => {
    fetchMoreClient(hasMoreClient, page + 1, perPage, true);
    setPage(page + 1);
  };

  useEffect(() => {
    FetchClient(false, true, 0, perPage);
    return (() => {
      setPage(0);
      setHasMoreClient(true);
    })
  }, []);

  return (
    <div className="overflow-y-auto  max-h-[700px]">
      <div className="flex items-center p-3">
        <button
          onClick={returnNav}
          className="mr-8 relative h-10 mx-2 w-10 rounded-full border-2 border-white  hover:text-black hover:bg-white flex items-center"
        >
          <HiArrowSmLeft size={40} />
        </button>

        <h4>Choisir un client</h4>
      </div>
      <div className="w-[90%] p-3 rounded-full bg-white mt-4 h-14 mx-4 flex items-center gap-2">
        <HiSearch className="text-black" size={20} />
        <input
          value={Search || ""}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="Recherchez un client, e-mail, téléphone"
          className="w-full text-black"
        ></input>
      </div>
      <div className="my-8">
        <div
          id="scrollableDiv"
          style={{ height: "420px", overflow: "auto", width: "100%" }}
        >
          <InfiniteScroll
            dataLength={ClientList.length}
            next={fetchMoreData}
            hasMore={hasMoreClient}
            loader={
              <h5 className="text-center text-white mt-2 overflow-hidden">
                <SmSpinner w={7} h={7} />
              </h5>
            }
            scrollableTarget="scrollableDiv"
          >
            {ClientList.filter((item) => {
              return (
                item.firstName
                  .toLowerCase()
                  .includes(Search.toLowerCase().replace(/\s/g, "")) ||
                item.lastName
                  .toLowerCase()
                  .includes(Search.toLowerCase().replace(/\s/g, "")) ||
                item.email
                  .toLowerCase()
                  .includes(Search.toLowerCase().replace(/\s/g, "")) ||
                item.phone
                  .toLowerCase()
                  .includes(Search.toLowerCase().replace(/\s/g, ""))
              );
            }).map((client, i) => {
              return (
                <div
                  key={client._id}
                  onClick={() => eventChanged("client", client)}
                  className={`border-2 w-[90%] border-sand hover:bg-sand hover:text-black hover:border-black rounded-lg p-4  m-4 cursor-pointer flex flex-col ${
                    client._id === SelectedClient._id
                      ? " shadow border-2 "
                      : "border-sand border-2"
                  }`}
                >
                  <div className="flex gap-2 mb-3">
                    <p>{client.firstName}</p>
                    <p>{client.lastName}</p>
                  </div>
                  <p className="opacity-40">{client.email}</p>
                  <p className="opacity-40">{client.phone}</p>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default ClientView;
