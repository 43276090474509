import axiosInstance from "config/Interceptor";
import { createContext, useState, useMemo, useContext } from "react";

const StatsContext = createContext();

function StatsProvider({ children }) {
  const [stats, setStats] = useState();
  const [fetching, setFetching] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [sortBy, setSortBy] = useState("mostUsed");

  const fetchStats = async (year = new Date().getFullYear(), sortBy = "mostUsed") => {
    try {
      setFetching(true);
      let url = `/api/v1/secure/institute/stat/base/prestation?year=${year}&sortBy=${sortBy}`;
      let response = await axiosInstance.get(url);
      setStats(response.data);
      setFetching(false);
    } catch (error) {
      setFetching(false);
      console.error("Error Fetching ", error);
    }
  };

  const valueToShare = useMemo(
    () => ({
      stats,
      fetching,
      fetchStats,
      selectedYear,
      setSelectedYear,
      sortBy,
      setSortBy,
    }),
    [stats, sortBy, selectedYear, fetching]
  );

  return (
    <StatsContext.Provider value={valueToShare}>
      {children}
    </StatsContext.Provider>
  );
}
function useStatsContext() {
  return useContext(StatsContext);
}
export { StatsProvider, useStatsContext };
export default StatsContext;
