import Cookies from "js-cookie";
import moment from "moment";
import { createContext, useState, useContext } from "react";
import AgendaContext from "./AgendaContext";
import toast from "react-hot-toast";
import axiosInstance from "config/Interceptor";

const ModalContext = createContext();

function ModalProvider({ children }) {
  const { FetchAllEmployeeData, Employee, FetchEmployeeData, SelectedEmpl, closeModal } =
    useContext(AgendaContext);

  const [deletePosting, setDeletePosting] = useState(false);
  const [ToggleDate, SetToggleDate] = useState(true);
  const [Date, setDate] = useState({});
  const [currentTime, setCurrentTime] = useState();
  const [eventTime, setEventTime] = useState();
  const [postLoading, setPostloading] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [SelectedDate, SetSelctedDate] = useState(moment());
  const [ClientList, SetClient] = useState([]);
  const [SelectedClient, SetSelectedclient] = useState({});
  const [freeSlots, setFreeslots] = useState([]);
  const [FiltredData, setFiltredData] = useState([]);
  const [Prestation, SetPrestation] = useState([]);
  const [slotPicked, setslotPicked] = useState("");
  const [SelectedPrestation, SetselectedPres] = useState("");
  const [EmployeeSelected, SetEmployee] = useState();
  const [editBody, setEditBody] = useState({
    selectedslotid: "",
    prestationid: "",
    clientid: "",
    slotids: "",
  });
  const [Body, SetBody] = useState({
    staffid: EmployeeSelected,
    prestationid: "",
    clientid: "",
    slotids: "",
  });

  const [fetching, setFetching] = useState(false);
  const [hasMoreClient, setHasMoreClient] = useState(true);

  const authToken = Cookies.get("authToken");

  const Postreservation = async () => {
    try {
      setPostloading(true);
      let url = `/api/v1/secure/reservation/agenda/new`;
      let response = await axiosInstance.post(url, Body);

      if (SelectedEmpl === "All") {
        await FetchAllEmployeeData();
      } else {
        await FetchEmployeeData(SelectedEmpl);
      }

      setPostloading(false);
      closeModal();
      SetBody({
        staffid: EmployeeSelected,
        prestationid: "",
        clientid: "",
        slotids: "",
      });
      SetSelctedDate(moment());
      SetSelectedclient({});
      SetselectedPres("");

      toast.success(
        `Votre rendez-vous a été ajouté avec succès ${moment(
          response.data.slotNew.startTimer
        )
          .utcOffset(0)
          .format("dddd DD MMMM hh:mm")} `
      );
    } catch (error) {
      setPostloading(false);
      console.error("Error Fetching ", error);
      toast.error(
        "Désolé, une erreur est survenue. Votre rendez-vous n'a pas été ajouté. "
      );
    }
  };
  const deleteReservation = async () => {
    try {
      setDeletePosting(true);
      let url = `/api/v1/secure/reservation/agenda/delete`;
      // console.log(editBody);

      let response = await axiosInstance.delete(url, {
        data: {
          selectedslotid: editBody.selectedslotid,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (SelectedEmpl === "All") {
        await FetchAllEmployeeData();
      } else {
        await FetchEmployeeData(SelectedEmpl);
      }
      setDeletePosting(false);
      closeModal();
      setEditBody({
        selectedslotid: "",
        prestationid: "",
        clientid: "",
        slotids: "",
      });

      SetSelctedDate(moment());
      SetSelectedclient({});
      SetselectedPres("");
      toast.success("Votre rendez-vous a été supprimé avec succès !");
    } catch (error) {
      console.error("Error Fetching ", error);
      setDeletePosting(false);
      toast.error(
        "Une erreur s'est produite lors de supprimation  de votre rendez-vous."
      );
    }
  };
  const updateReservation = async () => {
    try {
      setPostloading(true);
      let url = `/api/v1/secure/reservation/agenda/update`;
      console.log(editBody);
      let response = await axiosInstance.put(url, editBody);

      if (SelectedEmpl === "All") {
        await FetchAllEmployeeData();
      } else {
        await FetchEmployeeData(SelectedEmpl);
      }
      setPostloading(false);
      closeModal();
      setEditBody({
        selectedslotid: "",
        prestationid: "",
        clientid: "",
        slotids: "",
      });
      SetBody({
        staffid: EmployeeSelected,
        prestationid: "",
        clientid: "",
        slotids: "",
      });
      setslotPicked("");
      SetSelctedDate(moment());
      SetSelectedclient({});
      SetselectedPres("");
      toast.success(
        `Votre rendez-vous a été mis à jour avec succès ! ${moment(
          response.data.slotNew.startTimer
        )
          .utcOffset(0)
          .format("dddd DD MMMM hh:mm")} `
      );
    } catch (error) {
      console.error("Error Fetching ", error);
      setPostloading(false);
      toast.error(
        "Une erreur s'est produite lors de la mise à jour de votre rendez-vous."
      );
    }
  };

  const FetchPrestation = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/api/v1/secure/institute/prestations`);

      SetPrestation(response.data.prestations);
      setLoading(false);
    } catch (error) {
      console.error("Error Fetching ", error);
    }
  };
  const FetchFreeSlot = async (idstaff, client) => {
    setFreeslots([]);
    if (EmployeeSelected) {
      try {
        setLoading(true);

        const response = await axiosInstance.get(
          `/api/v1/secure/slots/free?staff=${idstaff}${
            client.id ? "customer=" + client.id : ""
          }`
        );
        setFreeslots(response.data);
        setFiltredData(filterFreeslot(response.data, SelectedPrestation.duration));

        setLoading(false);
      } catch (error) {
        console.error("Error Fetching ", error);
      }
    }
  };

  const FetchClient = async (
    fetchMore = false,
    hasMore = hasMoreClient,
    page = 0,
    perPage = 10
  ) => {
    if (hasMore) {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `/api/v1/secure/client/agenda/list?page=${page}&perPage=${perPage}`
        );

        if (fetchMore) {
          SetClient((clients) => clients.concat(response.data.clients));
        } else {
          SetClient(response.data.clients);
        }
        if (response.data.pages - 1 === page || response.data.pages === 0)
          setHasMoreClient(false);
        setLoading(false);
      } catch (error) {
        console.error("Error Fetching ", error);
      }
    }
  };

  const fetchMoreClient = (hasMore, page, perPage, fetchMore = true) => {
    FetchClient(fetchMore, hasMore, page, perPage);
  };

  const handleEditBodySlots = (duration, slot) => {
    if (slot !== "" && duration)
      setEditBody({
        ...editBody,
        slotids: SetSlotid(duration, slot),
      });
  };
  const SetSlotid = (duration, id) => {
    const filtred = freeSlots.filter((item) => {
      return moment(item.startTimer).isSame(SelectedDate, "day");
    });
    const index = filtred.findIndex((item) => item._id === id);

    const Numduration = +duration.split(":")[1] + +duration.split(":")[0] * 60;
    const count = Numduration / 20;
    let slotids = [];
    if (index !== -1) {
      for (let i = 0; i < count; i++) {
        slotids.push(filtred[index + i]._id);
      }
    }

    return slotids;
  };

  const saveClient = async (body) => {
    let url = `/api/v1/secure/client/addClient`;
    return axiosInstance.post(url, { user: body });
  };

  const updateClient = async (body) => {
    let url = `/api/v1/secure/client/institute/update`;
    return axiosInstance.put(url, { user: body });
  };

  const filterConsecutiveSlots = (data, n) => {
    const result = [];

    for (let i = 0; i <= data.length - (n + 1); i++) {
      let isConsecutive = true;

      for (let j = i; j < i + n; j++) {
        const endTimerCurr = moment(data[j].endTimer);
        const startTimerNext = moment(data[j + 1].startTimer);

        if (!endTimerCurr.isSame(startTimerNext)) {
          isConsecutive = false;
          break;
        }
      }

      if (isConsecutive) {
        result.push(data[i]);
      }
    }

    return result;
  };
  const filterFreeslot = (data, duration) => {
    if (duration) {
      const d = +duration.split(":")[1] + +duration.split(":")[0] * 60;

      if (d > 20) {
        let temp = data
          .filter((item) => {
            return moment(item.startTimer).isSame(SelectedDate, "day");
          })
          .slice(0, -(Math.ceil(d / 20) - 1));

        return filterConsecutiveSlots(temp, Math.ceil(d / 20) - 1);
      }
    }
    return data.filter((item) => {
      return moment(item.startTimer).isSame(SelectedDate, "day");
    });
  };
  const geteventDuration = (timePeriod) => {
    let period = [timePeriod[0].split(":"), timePeriod[1].split(":")];
    let a = (Number(period[1][0]) - Number(period[0][0])) * 60;
    let b = Number(period[1][1]) - Number(period[0][1]);
    return a + b;
  };
  const getPrestationDuration = (duration) => {
    return +duration?.split(":")[1] + +duration?.split(":")[0] * 60;
  };

  const valueToShare = {
    handleEditBodySlots,
    slotPicked,
    setslotPicked,
    updateClient,
    deletePosting,
    deleteReservation,
    closeModal,
    postLoading,
    getPrestationDuration,
    geteventDuration,
    updateReservation,
    FiltredData,
    ToggleDate,
    SetToggleDate,
    editBody,
    setEditBody,
    filterConsecutiveSlots,
    Date,
    setDate,
    Loading,
    SetSlotid,
    Postreservation,
    SelectedDate,
    SetSelctedDate,
    SelectedClient,
    SetSelectedclient,
    setFreeslots,
    SetClient,
    ClientList,
    FetchClient,
    freeSlots,
    FetchFreeSlot,
    Body,
    SelectedPrestation,
    SetselectedPres,
    FetchPrestation,
    Prestation,
    EmployeeSelected,
    SetEmployee,
    FetchAllEmployeeData,
    SetBody,
    saveClient,
    fetchMoreClient,
    hasMoreClient,
    setHasMoreClient,
  };

  return <ModalContext.Provider value={valueToShare}>{children}</ModalContext.Provider>;
}
function useModalContext() {
  return useContext(ModalContext);
}
export { ModalProvider, useModalContext };
export default ModalContext;
