/* eslint-disable no-lone-blocks */

import React, { useState, useEffect } from "react";
import "styles/instituteRegistration.css";
import InstituteInfoProfil from "Component/pro/institute-registration/InstituteInfoProfil";
import InstituteInfoCreateAnAccount from "Component/pro/institute-registration/InstituteInfoCreateAnAccount";
import InstituteTeamInfo from "Component/pro/institute-registration/InstituteTeamInfo";
import InstituteInfoPrestations from "Component/pro/institute-registration/InstituteInfoPrestations";
import InstituteInfoPhotos from "Component/pro/institute-registration/InstituteInfoPhotos";
import InstitutePaymentMethods from "Component/pro/institute-registration/InstitutePaymentMethods";
import Resume from "Component/pro/institute-registration/Resume";
import { InstituteAuthProvider } from "Context/InstituteAuthContext";

const Registration = () => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    function handleStepChangeSideEffect() {
      const insitute_registration_progression_bar = document.getElementById(
        "insitute_registration_progression_bar"
      );
      // eslint-disable-next-line default-case
      switch (step) {
        case 2:
          {
            insitute_registration_progression_bar.style.width = "5%";
          }
          break;
        case 3:
          {
            insitute_registration_progression_bar.style.width = "20%";
          }
          break;
        case 4:
          {
            insitute_registration_progression_bar.style.width = "40%";
          }
          break;
        case 5:
          {
            insitute_registration_progression_bar.style.width = "60%";
          }
          break;
        case 6:
          {
            insitute_registration_progression_bar.style.width = "80%";
          }
          break;
        case 7:
          {
            insitute_registration_progression_bar.style.width = "100%";
          }
          break;
      }
    }
    handleStepChangeSideEffect();
  }, [step]);

  const handleStepChange = (e, arg) => {
    e.preventDefault();
    if (arg === "prev") {
      setStep((prev) => {
        return prev - 1;
      });
    } else if (arg === "next") {
      setStep((prev) => {
        return prev + 1;
      });
    }
  };

  return (
    <div
      className={`overflow-y-scroll overflow-x-hidden h-[calc(100vh_-_104px)] bg-[#909090] flex flex-col justify-start relative ${
        step === 7 && "salonbg1"
      }`}
      id={`institute_registration_layout_step_${step}`}>
      {/* don't display the background photo on resume step */}
      {step !== 7 && (
        <img
          src="https://bucket-stunify-app.s3.eu-north-1.amazonaws.com/static/assets/bgProRegistration10-2023.jpg"
          alt=""
          className="fixed xs:top-[230px] sm:top-[-180px] md:top-[-230px] lg:top-[0] left-0 right-0 w-full h-auto object-cover scale-[2] z-[0] "
        />
      )}
      {/* <div className='fixed w-full h-full inset-0 z-[0] overflow-y-scroll overflow-x-hidden'></div> */}
      <section className="z-[10] px-6 max-w-[1920px] mx-auto relative w-full">
        {step > 1 && (
          <div className="hidden sm:block w-full relative ">
            <span className="left-0 right-0 -top-4 absolute w-full mb-4 border-b-4 dark:border-dark_grey"></span>
            <span
              id="insitute_registration_progression_bar"
              className="left-0 right-0 -top-4 absolute transition-width duration-500 mb-4 border-b-4 dark:border-beige border-dark_grey"></span>
            <ul className="flex flex-row items-center justify-between mt-10 mx-4 whitespace-nowrap xs:max-md:text-xs">
              <li className="text-sm">Votre profil</li>
              <li className="text-sm">Votre équipe</li>
              <li className="text-sm">Prestations</li>
              <li className="text-sm">Vos photos</li>
              <li className="text-sm">Méthodes de paiement</li>
              <li className="text-sm">Résumé</li>
            </ul>
          </div>
        )}
      </section>
      {/* Profil */}
      <section
        id={`institute_registration_content_step_${step}`}
        className={`${
          step === 1 && "animate-sliding-form"
        }  mt-6 mb-4 mx-7 px-6 h-fit flex flex-col justify-start z-[1] ${
          step !== 7 ? "form-bg " : "border border-black"
        } rounded-md max-w-[1921px] w-auto min-[1921px]:mx-auto min-[1921px]:w-full`}>
        <InstituteAuthProvider>
          {step === 1 && (
            <InstituteInfoCreateAnAccount handleStepChange={handleStepChange} />
          )}
          {step === 2 && <InstituteInfoProfil handleStepChange={handleStepChange} />}
          {step === 3 && <InstituteTeamInfo handleStepChange={handleStepChange} />}
          {step === 4 && <InstituteInfoPrestations handleStepChange={handleStepChange} />}
          {step === 5 && <InstituteInfoPhotos handleStepChange={handleStepChange} />}
          {step === 6 && <InstitutePaymentMethods handleStepChange={handleStepChange} />}
          {step === 7 && <Resume handleStepChange={handleStepChange} />}
        </InstituteAuthProvider>
      </section>
    </div>
  );
};

export default Registration;
