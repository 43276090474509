import { createContext, useState, useEffect, useContext, useMemo } from "react";
import SalonDataContext from "Context/SalonDataContext";
import axiosInstance from "config/Interceptor";
const prestationContext = createContext();

const PrestationDataProvider = ({ children }) => {
  const { salon } = useContext(SalonDataContext);
  const [employees, setEmployees] = useState([]);
  const [employeesForUpdate, setEmployeesForUpdate] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDuration, setTotalDuration] = useState(null);
  const [amountToPayNow, setAmountToPayNow] = useState(0);
  const [amountToPayLater, setAmountToPayLater] = useState(0);
  const [selectedStaff, setSelectedStaff] = useState("");

  function getInitialState() {
    const selectedPrestation = localStorage.getItem("selectedPrestation");
    return selectedPrestation ? JSON.parse(selectedPrestation) : [];
  }
  const [selectedPrestation, setSelectedPrestation] = useState(getInitialState);

  useEffect(() => {
    if (selectedPrestation) {
      localStorage.setItem(
        "selectedPrestation",
        JSON.stringify(selectedPrestation)
      );
      handleComputeTotalPrice();
      handleComputeTotalDuration();
    }
  }, [selectedPrestation]);

  useEffect(() => {
    if (salon) {
      handleSetEmployeesArray();
    }
  }, [salon]);

  const handleComputeTotalPrice = () => {
    let total = 0;
    selectedPrestation.map((item) => {
      total += item.price;
    });
    setTotalPrice(total);
    handleComputeAmountToPayNow(total);
  };

  const handleComputeTotalDuration = () => {
    let total = 0;
    const timeStrings = selectedPrestation?.map((item) => item?.duration) || [];
    let arrayOfMilliseconds = [];
    if (timeStrings.length) {
      arrayOfMilliseconds =
        timeStrings.map((timeString) => {
          if (timeString) {
            const [hours, minutes] = timeString.split(":").map(Number) || 0;
            const totalMilliseconds = (hours * 60 + minutes) * 60000; // 60,000 milliseconds in a minute
            return totalMilliseconds;
          }
          return 0; // Handle undefined or null timeString
        }) || [];
    }

    // Calculate the total duration
    total = arrayOfMilliseconds.reduce((acc, item) => acc + item, 0);

    setTotalDuration(total);
  };

  const handleComputeAmountToPayNow = (prestationTotal) => {
    let depositWantedBySalon = parseInt(salon?.wantADeposit?.split("%")[0]);
    let total = (prestationTotal * depositWantedBySalon) / 100;
    setAmountToPayNow(total);
    hanldeComputeAmoutToPayLater(prestationTotal, total);
  };
  const hanldeComputeAmoutToPayLater = (prestationtotal, amountToPayNow) => {
    let WhatToPayLater = prestationtotal - amountToPayNow;
    setAmountToPayLater(WhatToPayLater);
  };

  const handleSetEmployeesArray = () => {
    if (salon.staff) setEmployees([...salon.staff]);
  };

  const selectedStaffIds = selectedPrestation.map(
    (prestation) => prestation?.idStaff
  );

  const [freeSlots, setFreeSlots] = useState([]);
  const [loadingSlots, setLoadingSlots] = useState(true);
  


 
  // State to track the selected slot and its background color
  const [selectedFreeSlot, setSelectedFreeSlot] = useState({});

  // Function to clear the style from localStorage
  const clearSlotStyleInLocalStorage = () => {
    localStorage.removeItem("selectedFreeSlotStyle");
  };
  const [selectedDurationOfPrestation, setSelectedDurationOfPrestation] =
    useState("");
  const [selectedindexOfPrestation, setSelectedindexOfPrestation] = useState(0);
  const handleCheckboxChangeopen = async (item, index) => {

    setSelectedStaff(item.idStaff);
    setSelectedFreeSlot(null);
    setSelectedDurationOfPrestation(item?.duration);
    setSelectedindexOfPrestation(index);
  };

  const handleCheckboxChangeSave = (selectedindexOfPrestation) => {
    const selectedFreeSlot = JSON.parse(
      localStorage.getItem("selectedFreeSlot")
    );
    const newSlot = { ...selectedFreeSlot };

    const updatedPrestationArray = selectedPrestation.map((prestation, i) => {
      if (i === selectedindexOfPrestation) {
        return {
          ...prestation,
          newSlot,
        };
      }
      return prestation;
    });
    setSelectedPrestation(updatedPrestationArray);
  };

  
const fetchFreeSlotListData = async () => {
  try {
    
    setLoadingSlots(true);

    const apiUrl = `/api/v1/secure/slots/free/list/available/${salon._id}`;
    const   staffQueryParam =  selectedStaff !== "" ? `?staff=${selectedStaff}` : "";
    
    const finalApiUrl = apiUrl + staffQueryParam;

    const res = await axiosInstance.get(finalApiUrl);
    const data = res.data;
    setFreeSlots(data);
    setLoadingSlots(false);
  } catch (error) {
    // Handle error here
    console.error("Error fetching data with staff:", error);
  } finally {
    setLoadingSlots(false);
  }
};


const checkIfAvailableTime = async (body) => {
  try {
    const res = await axiosInstance.put(
      `/api/v1/secure/reservation/check`,
      body
    );
    return res.data; // Return the result -true or false (false is available time ) 
  } catch (error) {
    // Handle error here
    console.error("Error fetching data:", error);
    return null; 
  }
};

  const valueToShare = useMemo(
    () => ({
      totalPrice,checkIfAvailableTime,
      handleCheckboxChangeSave,
      setTotalPrice,
      selectedindexOfPrestation,
      setSelectedindexOfPrestation,
      handleCheckboxChangeopen,
      selectedDurationOfPrestation,
      setSelectedDurationOfPrestation,
      totalDuration,
      amountToPayNow,
      setAmountToPayNow,
      amountToPayLater,
      setAmountToPayLater,
      selectedPrestation,
      setSelectedPrestation,
      employees,
      freeSlots,
      loadingSlots,
      clearSlotStyleInLocalStorage,
      selectedFreeSlot,
      setSelectedFreeSlot,
      employeesForUpdate,
      setEmployeesForUpdate,
      selectedStaff,
      setSelectedStaff,
      fetchFreeSlotListData,
      setFreeSlots
    }),
    [
      totalPrice,
      totalDuration,
      fetchFreeSlotListData,
  
      amountToPayNow,
      amountToPayLater,
      selectedPrestation,
      employees,
      
      selectedFreeSlot,
      employeesForUpdate,

    ]
  );

  return (
    <prestationContext.Provider value={valueToShare}>
      {children}
    </prestationContext.Provider>
  );
};
export { PrestationDataProvider };
export default prestationContext;
