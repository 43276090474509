import { useChatContext } from "Context/ChatContext";
import { useEffect, useState, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import SmSpinner from "../ui/SmSpinner";
import { IoCloseSharp } from "react-icons/io5";

import ChatCard from "./ChatCard";
import { IoSearchSharp } from "react-icons/io5";
import NoChatList from "./NoChatList";

const ChatList = () => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [newChat, setNewChat] = useState(true);
  const { type } = useParams();

  const {
    toggleChatDisplay,
    isInstitute,
    setCreatedChat,
    sortByLastMessageDate,
    createdChat,
    setChatList,
    socket,
    chatList,
    setChatHasMore,
    chatHasMore,
    fetchMoreChats,
    fetchChatlist,
    receiverId,
    createChat,
  } = useChatContext();
  useEffect(() => {
    if (receiverId) {
      createChat(receiverId);
    }
    setPage(0);
    setChatHasMore(true);
    fetchChatlist(false, true);
  }, []);

  const fetchMoreData = () => {
    fetchMoreChats(chatHasMore, page + 1, perPage);
    setPage(page + 1);
  };
  useEffect(() => {
    if (socket) {
      socket.on("isTyping", (payload) => {
        setChatList((prev) =>
          prev.map((item) => {
            if (item._id === payload.chatId) {
              return { ...item, isTyping: payload.userTyping };
            }
            return item;
          })
        );
      });
    }
  }, [socket]);
  const chatListRef = useRef(chatList);
  chatListRef.current = chatList;
  useEffect(() => {
    const handleNewMessage = (message) => {
      const updatedChatList = chatListRef.current.map((item) => {
        if (item._id === message.chatId) {
          return {
            ...item,
            unseenMessages: item.unseenMessages + 1,
            lastMessage: { text: message.text, createdAt: message.date },
          };
        }

        return item;
      });

      setChatList(sortByLastMessageDate(updatedChatList));
    };

    socket.on("getNotification", handleNewMessage);
    socket.on("newChat", (chat) => {
      setChatList([{ ...chat, unseenMessages: 0 }, ...chatList]);
    });
  }, [socket]);

  useEffect(() => {
    if (createdChat && chatList[0]?._id !== createdChat?._id) {
      setChatList([createdChat, ...chatList]);
    }
  }, [createdChat, chatList]);

  return (
    <div
      className={`${
        toggleChatDisplay ? "xxs:hidden" : "xxs:flex xxs:flex-col"
      } md:min-w-[256px] md:border-[1px] border-[#46494D] md:flex md:flex-col  md:rounded-[13px] md:h-[91%] md:w-[30%]  md:gap-2 xxs:w-full ${
        isInstitute ? "bg-transparent" : "bg-slate-500"
      }`}>
      <div className="flex items-center [-webkit-text-stroke:1px_#46494d] [font-family:'Sora-Bold',Helvetica] font-bold text-white text-[14px] tracking-[0] leading-[normal]  md:text-[1.4rem] md:px-[20px] md:py-[22px] xxs:h-[10%] xxs:px-3 xxs:flex xxs:items-center xxs:justify-between xxs:text-lg ">
        Messages
        <div className="md:hidden xxs:bg-[#696969] xxs:rounded-[4px] xxs:px-2 xxs:py-1 ">
          <IoCloseSharp />
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none">
          <path
            d="M15.625 17.5V12.5M13.125 15H18.125M18.125 5.625V10M18.125 5.625C18.125 4.93464 17.5654 4.375 16.875 4.375H3.125C2.43464 4.375 1.875 4.93464 1.875 5.625M18.125 5.625L10.7115 10.7574C10.2835 11.0538 9.71654 11.0538 9.28849 10.7574L1.875 5.625M1.875 5.625V14.375C1.875 15.0654 2.43464 15.625 3.125 15.625H9.375"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className="flex items-center md:px-5 md:py-[6px] md:h-[10%] xxs:px-2 xxs:h-[6%] xxs:mb-4 ">
        <div className="z-20 md:px-4 gap-3  border-[1px] border-[#46494D] rounded-[21px] h-[70%] w-full [background:linear-gradient(93deg,rgba(255,255,255,0.2)_0.68%,rgba(194.44,194.44,194.44,0)_120%)]   flex items-center xxs:px-2 text-white ">
          <IoSearchSharp size={30} />
          <input
            className="focus:outline-none bg-transparent w-full md:h-[100%]  placeholder:text-white"
            placeholder="Recherche"></input>
        </div>
      </div>
      {chatList.filter((chat) => chat.lastMessage?.text).length > 0 ? (
        <div
          className="grow flex flex-col w-full h-full"
          id="scrollableDiv"
          style={{ overflow: "auto" }}>
          <InfiniteScroll
            style={{ height: "100%" }}
            dataLength={chatList?.length}
            next={fetchMoreData}
            hasMore={chatHasMore}
            loader={
              <h5 className="text-center text-white mt-2 ">
                <SmSpinner w={7} h={7} />
              </h5>
            }
            scrollableTarget="scrollableDiv">
            {chatList.map((chat) => {
              return chat.lastMessage?.text && <ChatCard key={chat._id} chat={chat} />;
            })}
          </InfiniteScroll>
        </div>
      ) : (
        <NoChatList />
      )}
    </div>
  );
};

export default ChatList;
