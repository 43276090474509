import React from "react";

const NoChatList = () => {
  return (
    <div className="flex flex-col justify-center w-full items-center h-full -translate-y-20">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="118"
        height="118"
        viewBox="0 0 118 118"
        fill="none">
        <path
          d="M61.4583 49.1667C61.4583 41.0542 54.8208 34.4167 46.7083 34.4167C38.5958 34.4167 31.9583 41.0542 31.9583 49.1667C31.9583 57.2792 38.5958 63.9167 46.7083 63.9167C54.8208 63.9167 61.4583 57.2792 61.4583 49.1667ZM46.7083 54.0833C44.0042 54.0833 41.7917 51.8708 41.7917 49.1667C41.7917 46.4625 44.0042 44.25 46.7083 44.25C49.4125 44.25 51.625 46.4625 51.625 49.1667C51.625 51.8708 49.4125 54.0833 46.7083 54.0833ZM78.6667 63.9167C84.1242 63.9167 88.5 59.5408 88.5 54.0833C88.5 48.6258 84.1242 44.25 78.6667 44.25C73.2092 44.25 68.7842 48.6258 68.8333 54.0833C68.8333 59.5408 73.2092 63.9167 78.6667 63.9167ZM58.9508 9.8825C31.8108 9.8825 9.78418 31.9092 9.78418 59.0492C9.78418 86.1892 31.8108 108.216 58.9508 108.216C86.0908 108.216 108.118 86.1892 108.118 59.0492C108.118 31.9092 86.0908 9.8825 58.9508 9.8825ZM28.7133 84.1733C32.0567 81.5183 39.8742 78.7158 46.7083 78.7158C47.0525 78.7158 47.4458 78.765 47.8392 78.765C49.0192 75.6183 51.1333 72.4225 54.2308 69.62C51.7502 69.1448 49.2336 68.8816 46.7083 68.8333C40.3167 68.8333 30.0408 71.0458 23.4525 75.8642C20.9942 70.7508 19.6175 65.0475 19.6175 59C19.6175 37.3175 37.2683 19.6667 58.9508 19.6667C80.6333 19.6667 98.2842 37.3175 98.2842 59C98.2842 64.9 96.9567 70.505 94.5967 75.5692C89.68 72.6683 82.9933 71.2917 78.6667 71.2917C71.1933 71.2917 56.5417 75.2742 56.5417 84.5667V98.235C51.1856 97.9304 45.9499 96.5245 41.1617 94.105C36.3736 91.6855 32.1358 88.3046 28.7133 84.1733Z"
          fill="#A29688"
        />
      </svg>
      <span className="text-[#828282] mt-4">No Client In Your Network Yet</span>

    </div>
  );
};

export default NoChatList;
