import axiosInstance from "config/Interceptor";

export const initiatePhoneCheck = async (phoneNumber) => {
  return fetch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/phone/initiate`, {
    method: "POST",
    body: JSON.stringify({ phoneNumber }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const requestCodeChallenge = async (code, phoneNumber) => {
  try {
    const res = await axiosInstance.post(`/api/v1/phone/challenge`, {
      code,
      phoneNumber,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const resgisterUser = async (user) => {
  try {
    if (user.code) {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/auth/client/loginGoogle`,
        { googleToken: user.code }
      );
      // console.log(res);
      return res;
    } else {
      const res = await axiosInstance.post(`/api/v1/auth/client/register`, {
        user,
      });
      return res;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
