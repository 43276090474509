import { HiOutlineCalendar, HiOutlineTrash } from "react-icons/hi";
import { useFormContext } from "../../Context/FormContext";
import toast from "react-hot-toast";
import { useContext, useState } from "react";
import Datacontext from "Context/Datacontext";
import { Link } from "react-router-dom";
import moment from "moment";

const DashboardRDV = ({
  name,
  address,
  prestations,
  img,
  IdStaff,
  slotToUpdate,
  instituteId,
  idreservation,
  idPrestation,
}) => {
  const { setSelectedName, setShowModal, setStaffId, setInstituteIdForUpdate } =
    useFormContext();
  const {
    setStaffIdForUpdate,
    SetprestationToUpdate,
    setIdPrestation,
    handleDeleteRnd,
  } = useContext(Datacontext);

  const handleCancelClick = () => {
    handleDeleteRnd(idreservation, slotToUpdate._id);
    toast.success("Votre rendez-vous a été annulé avec succès");
  };

  const [imgErr, setimgErr] = useState("");

  const handleError = () => {
    setimgErr("https://via.assets.so/img.jpg?w=400&h=300");
  };

  const handleModifierClick = () => {
    setSelectedName(name); // Set the selected name
    setStaffId(IdStaff);
    setStaffIdForUpdate(IdStaff); // Set the selected STAFF id
    setInstituteIdForUpdate(instituteId); // set salon id for update staff of  prestation
    SetprestationToUpdate(prestations); // set prestation to update
    setIdPrestation(idreservation);

    setShowModal(true);
    localStorage.setItem("slotToUpdate", JSON.stringify(slotToUpdate));
  };

  const [hours, minutes] = prestations?.duration.split(":").map(Number);
  const totalMinutes = hours * 60 + minutes;
  return (
    <div
      className={`rounded-md flex  md:flex-row  my-3  flex-col  sm:w-96 md:w-full  `}
    >
      <div className=" md:w-1/5 md:h-auto w-full trans-bg md:rounded-l-lg overflow-hidden h-44 ">
        <Link to={`/salon/${instituteId}`}>
          <img
            onError={handleError}
            alt="alternative text ?"
            className="  h-full  "
            style={{ aspectRatio: "9/16", width: "100%", objectFit: "contain" }}
            src={
              imgErr
                ? "https://via.assets.so/img.jpg?w=400&h=300"
                : img
                ? img
                : "https://via.assets.so/img.jpg?w=400&h=300"
            }
          />
        </Link>
      </div>
      <div className="md:w-4/5 w-full trans-bg md:rounded-r-lg flex flex-col p-5">
        <div className="flex justify-between items-center ">
          <Link
            to={`/salon/${instituteId}`}
            className="leading-5 w-[280px] capitalize"
          >
            {name} <br />{" "}
            <span className="text-[#82786E] text-base">{address}</span>
          </Link>
          <button
            className="   hover:scale-110  hover:-translate-y-1  ease-in-out delay-150 duration-300 bg-black rounded-full md:p-2 md:w-[120px] md:h-9  p-1 w-24 h-6 text-sm text-white font-roboto"
            onClick={handleModifierClick}
          >
            Modifier
          </button>
        </div>
        <div className="flex my-4 items-baseline">
          <h6 className="leading-5 w-[280px]">
            {prestations?.name} <br />{" "}
            <span className="text-base text-[#82786E] ">
              <span className="capitalize  text-[#82786E]">
                {" "}
                {moment(slotToUpdate.startTimer)
                  .subtract(1, "hour")
                  .locale("fr")
                  .format("dddd D MMMM HH:mm")}
              </span>{" "}
              {"H"}
              <br /> {totalMinutes ? totalMinutes : "no data"} mn
            </span>
          </h6>
          <p>{prestations?.price}€</p>
        </div>

        <div className="flex justify-center gap-8">
          {/* <div className="flex gap-2 items-center cursor-pointer">
            <HiOutlineCalendar size={20} />{" "}
            <p className="underline underline-offset-1 md:font-semibold text-sm ">
              Ajouter au calendrier
            </p>
          </div> */}
          <div
            className="flex gap-2 items-center cursor-pointer hover:opacity-50 "
            onClick={handleCancelClick}
          >
            <HiOutlineTrash size={20} />{" "}
            <p className="underline underline-offset-1 md:font-semibold text-sm ">
              Annuler le RDV
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardRDV;
