import React from "react";

const AgendaIcon = () => {
  return (
    <div>
      <svg
        width="100%"
        height="60%"
        viewBox="0 0 186 138"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M144.614 36.0717C142.535 36.0717 140.844 34.3882 140.844 32.3195C140.844 30.2508 142.535 28.5673 144.614 28.5673C146.693 28.5673 148.384 30.2508 148.384 32.3195C148.384 34.3882 146.693 36.0717 144.614 36.0717ZM144.614 30.9066C143.831 30.9066 143.194 31.5402 143.194 32.3195C143.194 33.0988 143.831 33.7324 144.614 33.7324C145.397 33.7324 146.034 33.0988 146.034 32.3195C146.034 31.5402 145.397 30.9066 144.614 30.9066Z"
          fill="#FE6257"></path>
        <path
          d="M138.684 53.6993C139.651 53.6993 140.434 52.9197 140.434 51.9581C140.434 50.9964 139.651 50.2168 138.684 50.2168C137.718 50.2168 136.935 50.9964 136.935 51.9581C136.935 52.9197 137.718 53.6993 138.684 53.6993Z"
          fill="#131032"></path>
        <path
          d="M27.1416 25.5576C27.9065 25.5576 28.5266 24.9405 28.5266 24.1794C28.5266 23.4182 27.9065 22.8011 27.1416 22.8011C26.3768 22.8011 25.7567 23.4182 25.7567 24.1794C25.7567 24.9405 26.3768 25.5576 27.1416 25.5576Z"
          fill="#131032"></path>
        <path
          d="M166.734 55.9245L165.941 55.3402C164.999 54.6453 164.435 53.5336 164.434 52.3664L164.433 51.1166C164.433 50.487 164.106 49.9236 163.557 49.6095C163.009 49.2954 162.356 49.2958 161.808 49.6117C160.503 50.3639 158.94 50.2764 157.728 49.3829C156.516 48.4898 155.976 47.0267 156.318 45.5649C156.461 44.9522 156.272 44.3293 155.81 43.8992C155.348 43.4687 154.711 43.322 154.106 43.5042L152.904 43.8677C151.782 44.2071 150.55 43.9952 149.607 43.3003L148.814 42.716L149.988 41.1378L150.781 41.7221C151.224 42.0482 151.803 42.1477 152.331 41.9887L153.533 41.6252C154.821 41.2364 156.176 41.5501 157.159 42.4654C158.141 43.3811 158.545 44.7061 158.24 46.0106C158.077 46.7077 158.324 47.3782 158.902 47.8042C159.48 48.2299 160.196 48.2703 160.819 47.9118C161.984 47.2404 163.376 47.2391 164.542 47.9073C165.709 48.576 166.405 49.7748 166.407 51.1144L166.408 52.3642C166.408 52.9129 166.673 53.435 167.116 53.7616L167.909 54.3458L166.734 55.9245Z"
          fill="#614EFA"></path>
        <path
          d="M23.3134 54.4383L21.6021 53.4609L22.0932 52.6091C22.3678 52.1337 22.3999 51.5494 22.1793 51.0465L21.6771 49.901C21.1386 48.6734 21.2945 47.2969 22.0936 46.219C22.8932 45.1411 24.1697 44.5879 25.5064 44.7381C26.2216 44.8181 26.8618 44.496 27.2199 43.8748C27.5779 43.2541 27.5346 42.541 27.1042 41.9674C26.2988 40.8939 26.1354 39.5184 26.6672 38.2881C27.1989 37.0578 28.3142 36.2291 29.6509 36.0723L30.8983 35.9257C31.4456 35.8612 31.9363 35.5391 32.2104 35.0637L32.7015 34.2119L34.4128 35.1894L33.9217 36.0412C33.3382 37.0538 32.2939 37.7398 31.1291 37.8766L29.8817 38.0232C29.2535 38.097 28.7294 38.4862 28.4798 39.0643C28.2302 39.6423 28.307 40.2888 28.6852 40.7931C29.5875 41.9954 29.6817 43.5514 28.9316 44.8528C28.1807 46.1546 26.7832 46.8579 25.2854 46.69C24.6554 46.6198 24.0572 46.8793 23.6817 47.3858C23.3062 47.8923 23.233 48.5388 23.4861 49.1159L23.9884 50.2614C24.4576 51.3313 24.3893 52.5744 23.8058 53.587L23.3134 54.4383Z"
          fill="#FE6257"></path>
        <path
          d="M40.6714 30.8845C40.1267 30.8845 39.6847 30.4446 39.6847 29.9025V23.0619C39.6847 22.5194 40.1267 22.08 40.6714 22.08C41.2161 22.08 41.6581 22.5194 41.6581 23.0619V29.9025C41.6581 30.445 41.2161 30.8845 40.6714 30.8845Z"
          fill="#131032"></path>
        <path
          d="M44.1089 27.4646H37.2347C36.6901 27.4646 36.2481 27.0251 36.2481 26.4826C36.2481 25.9401 36.6896 25.5007 37.2347 25.5007H44.1084C44.6531 25.5007 45.0951 25.9401 45.0951 26.4826C45.0951 27.0251 44.6536 27.4646 44.1089 27.4646Z"
          fill="#131032"></path>
        <path
          d="M64.1854 77.2328C63.8372 77.8771 63.4622 78.5258 63.0559 79.1834C62.7032 79.761 62.3281 80.343 61.9263 80.9339C61.7299 81.2227 61.529 81.516 61.3191 81.8092C61.3191 81.8092 60.9664 81.7515 60.3548 81.6137C59.9173 81.5115 59.3413 81.3694 58.6716 81.1739C54.4838 79.9653 46.4208 76.7441 42.7732 69.2796C42.3848 68.4888 42.0767 67.6801 41.8312 66.8537C41.7776 66.6671 41.724 66.476 41.6749 66.2894C41.5008 65.6141 41.3668 64.9298 41.2731 64.2367C40.907 61.522 41.1213 58.7228 41.6526 56.0525C41.7731 55.4482 41.9071 54.8484 42.0589 54.2575C42.3312 53.1867 42.6437 52.1426 42.983 51.1473L45.9654 51.4894L43.8492 48.8191C45.0234 45.8955 46.2779 43.5762 46.9699 42.381C47.3048 41.8034 47.5057 41.488 47.5057 41.488C47.9075 41.7012 48.3004 41.9101 48.6933 42.1233C53.2561 44.6159 57.118 47.2862 60.1226 50.1654C60.0155 51.8315 59.9396 53.1911 59.9396 53.1911L61.846 51.9471C61.8504 51.9515 61.8504 51.9515 61.8549 51.9559C62.3058 52.458 62.73 52.969 63.1318 53.4844C63.5916 54.0798 64.0202 54.6796 64.4086 55.2927C65.9623 57.7187 66.9624 60.2779 67.3419 62.9971C67.9491 67.3247 66.9847 72.0477 64.1854 77.2328Z"
          fill="#FE6257"></path>
        <g opacity="0.5">
          <path
            d="M101.681 111.192C130.686 108.554 153.881 102.953 153.486 98.6804C153.092 94.4082 129.259 93.083 100.254 95.7205C71.2485 98.3581 48.0544 103.96 48.4485 108.232C48.8426 112.504 72.6756 113.829 101.681 111.192Z"
            fill="#131032"></path>
        </g>
        <path
          d="M63.06 79.1833C62.5332 80.0452 61.9528 80.9205 61.3233 81.8092C61.3233 81.8092 47.8268 79.6098 42.7774 69.2796C42.3889 68.4887 42.0809 67.6801 41.8353 66.8536C44.9025 67.2979 47.9116 68.0888 50.769 69.2929C55.6845 71.3723 60.1179 74.7846 63.06 79.1833Z"
          fill="#131032"></path>
        <path
          d="M67.3472 62.997L64.1908 77.2327C63.8425 77.877 63.4675 78.5257 63.0612 79.1833C62.5344 80.0452 61.954 80.9205 61.3245 81.8091C61.3245 81.8091 60.2842 81.6403 58.677 81.1738L64.414 55.2926C65.9677 57.7186 66.9677 60.2778 67.3472 62.997Z"
          fill="#131032"></path>
        <path
          d="M63.154 84.1017L61.2029 93.9432L60.8502 95.7249L59.8591 100.741C58.4929 101.114 56.9705 101.461 55.3275 101.728C49.9388 102.603 43.3043 102.594 36.9959 99.657C35.1698 98.804 33.5001 97.7198 32 96.4758C32.8929 94.9607 33.9376 93.219 34.6921 91.966C35.2993 90.953 35.719 90.2599 35.719 90.2599C35.719 90.2599 35.6029 90.2554 35.3931 90.2421H35.3752C34.0314 90.1799 29.0266 89.9266 25.9594 89.6556C24.4414 87.3807 23.1913 84.9859 22.1689 82.6532C21.9189 82.0978 21.6868 81.5425 21.4725 80.996C19.4455 75.9619 18.4633 71.4566 18.0927 69.4705C17.9633 68.7818 17.9097 68.3953 17.9097 68.3953C18.3696 68.3242 18.8249 68.2575 19.2759 68.1953C23.8342 67.5511 28.1336 67.2223 32.1428 67.2667C32.8393 69.1017 33.7769 71.4299 33.7769 71.4299L35.2636 67.36C37.9647 67.5155 40.5184 67.8488 42.9204 68.3775C43.5946 68.5241 44.2553 68.6885 44.9027 68.8707C51.1263 70.5857 56.207 73.7404 59.9082 78.6144C61.1449 80.2451 62.2298 82.0712 63.154 84.1017Z"
          fill="#23D6BA"></path>
        <path
          d="M62.1308 81.5382C62.2826 81.9825 62.046 82.4668 61.5996 82.6179C61.5058 82.649 61.412 82.6623 61.3228 82.6623C60.9656 82.6623 60.6352 82.4401 60.5147 82.0847L60.3539 81.6137L57.6037 73.5539L41.674 66.2894C41.4999 65.6141 41.366 64.9298 41.2722 64.2367L56.8492 71.3368L54.7643 65.232L41.6517 56.0525C41.7723 55.4482 41.9062 54.8484 42.058 54.2575L53.8312 62.4995L46.9691 42.381C47.3039 41.8034 47.5048 41.488 47.5048 41.488C47.9066 41.7012 48.2995 41.9101 48.6924 42.1233L56.8983 66.1961L61.854 51.9559C62.305 52.458 62.7291 52.969 63.1309 53.4844L57.8538 68.6532C57.8359 68.702 57.8091 68.742 57.7823 68.7865L59.0369 72.4654C59.1351 72.5986 59.1887 72.7586 59.1931 72.923L61.9255 80.9339L62.1308 81.5382Z"
          fill="#131032"></path>
        <path
          d="M67.3462 98.7152C67.181 98.9729 66.9042 99.1151 66.614 99.1151C66.4622 99.1151 66.306 99.0751 66.1676 98.9907L60.8502 95.725L53.9301 91.4729L34.6921 91.9661C35.2993 90.9531 35.719 90.26 35.719 90.26C35.719 90.26 35.6029 90.2555 35.3931 90.2422L51.2647 89.8379L37.7013 81.5115L22.1689 82.6533C21.9189 82.098 21.6868 81.5426 21.4725 80.9961L35.219 79.9875L18.0927 69.4706C17.9633 68.7819 17.9097 68.3954 17.9097 68.3954C18.3696 68.3243 18.8249 68.2577 19.2759 68.1954L49.3182 86.6433L42.9204 68.3776C43.5946 68.5242 44.2553 68.6886 44.9027 68.8708L51.5281 87.7851C51.5549 87.8651 51.5683 87.9451 51.5683 88.0251L61.2029 93.9433L67.065 97.5422C67.4668 97.7866 67.5918 98.3109 67.3462 98.7152Z"
          fill="#131032"></path>
        <path
          d="M63.1431 84.1018L59.8482 100.741C58.482 101.115 56.9596 101.461 55.3166 101.728L59.8973 78.6146C61.134 80.2452 62.2189 82.0713 63.1431 84.1018Z"
          fill="#131032"></path>
        <path
          d="M67.0651 56.3363L77.6234 110.131L143.247 99.2888L131.575 52.2531L67.0651 56.3363Z"
          fill="#131032"></path>
        <path
          d="M77.6203 110.983C77.2185 110.983 76.8613 110.7 76.7818 110.295L66.2235 56.4994C66.1766 56.259 66.2356 56.0102 66.386 55.8165C66.536 55.6223 66.7633 55.5023 67.0088 55.4872L131.519 51.4045C131.932 51.3751 132.304 51.6497 132.404 52.0496L144.075 99.0853C144.133 99.3154 144.091 99.5589 143.959 99.7571C143.829 99.9553 143.62 100.09 143.385 100.129L77.7614 110.972C77.714 110.979 77.6667 110.983 77.6203 110.983ZM68.0888 57.1236L78.3011 109.157L142.193 98.5997L130.914 53.147L68.0888 57.1236Z"
          fill="#131032"></path>
        <path
          d="M67.0705 56.3363L57.6198 104.758L77.6288 110.131L129.187 53.6806L67.0705 56.3363Z"
          fill="#131032"></path>
        <path
          d="M77.6304 110.983C77.5558 110.983 77.4812 110.973 77.4076 110.953L57.3986 105.58C56.9655 105.463 56.6963 105.034 56.782 104.596L66.2331 56.1741C66.3086 55.7876 66.6407 55.5032 67.0359 55.4864L129.152 52.8307C129.494 52.8223 129.817 53.0089 129.963 53.3199C130.11 53.6309 130.054 53.9992 129.822 54.2534L78.2634 110.704C78.0987 110.884 77.8679 110.983 77.6304 110.983ZM58.6125 104.143L77.3491 109.174L127.177 54.6182L67.7828 57.1574L58.6125 104.143Z"
          fill="#131032"></path>
        <path
          d="M68.7725 53.5954L78.2107 103.106L144.046 94.6306L129.93 50.0018L68.7725 53.5954Z"
          fill="white"></path>
        <path
          d="M80.6328 104.713C80.2444 104.713 79.893 104.448 79.801 104.055L68.0868 54.172C67.9792 53.7144 68.2649 53.2568 68.7248 53.1497C69.1869 53.0413 69.645 53.327 69.7521 53.7846L81.4663 103.668C81.5739 104.125 81.2882 104.583 80.8283 104.69C80.7627 104.706 80.6971 104.713 80.6328 104.713Z"
          fill="#131032"></path>
        <path
          d="M68.7725 53.5954L84.7299 103.862L146.258 93.5359L132.248 49.0114L68.7725 53.5954Z"
          fill="white"></path>
        <path
          d="M84.7271 104.713C84.3601 104.713 84.0262 104.477 83.9123 104.118L67.955 53.8522C67.8759 53.6038 67.9161 53.3337 68.0626 53.1182C68.2094 52.9027 68.4474 52.7663 68.7081 52.7472L132.185 48.1632C132.581 48.1339 132.944 48.3805 133.062 48.7577L147.072 93.2817C147.146 93.5163 147.114 93.7714 146.985 93.9806C146.855 94.1903 146.641 94.3343 146.398 94.3752L84.8696 104.701C84.8222 104.709 84.7745 104.713 84.7271 104.713ZM69.9118 54.3663L85.3191 102.899L145.147 92.8587L131.633 49.9089L69.9118 54.3663Z"
          fill="#131032"></path>
        <path
          d="M86.7039 67.2229C87.4892 66.125 87.0892 64.4859 85.8061 63.5546C84.5162 62.6185 82.8179 62.7478 82.0179 63.8519C81.2151 64.9591 81.6259 66.6173 82.9309 67.5481C84.2292 68.4736 85.9163 68.3243 86.7039 67.2229Z"
          fill="#FE6257"></path>
        <path
          d="M94.4448 66.4071C95.206 65.3279 94.8198 63.7141 93.5782 62.7957C92.3299 61.8725 90.686 61.9973 89.9105 63.0823C89.1328 64.1704 89.5292 65.8028 90.7918 66.7203C92.0477 67.6334 93.6813 67.4899 94.4448 66.4071Z"
          fill="#FE6257"></path>
        <path
          d="M101.942 65.6172C102.68 64.5562 102.308 62.9673 101.106 62.0614C99.8972 61.1505 98.3051 61.2709 97.5533 62.3377C96.7992 63.4076 97.1818 65.0147 98.4042 65.9197C99.6195 66.8204 101.202 66.6817 101.942 65.6172Z"
          fill="#614EFA"></path>
        <path
          d="M109.204 64.8516C109.92 63.8079 109.56 62.243 108.396 61.3491C107.225 60.4507 105.682 60.5671 104.953 61.6157C104.222 62.6678 104.591 64.2504 105.775 65.1435C106.952 66.0321 108.486 65.8984 109.204 64.8516Z"
          fill="#614EFA"></path>
        <path
          d="M116.248 64.1091C116.943 63.0823 116.594 61.5406 115.466 60.6586C114.331 59.7722 112.836 59.8846 112.128 60.9163C111.418 61.9511 111.776 63.5102 112.922 64.3913C114.063 65.2683 115.551 65.139 116.248 64.1091Z"
          fill="#614EFA"></path>
        <path
          d="M123.068 63.389C123.743 62.3786 123.406 60.8595 122.312 59.9891C121.213 59.1142 119.762 59.2227 119.075 60.2379C118.386 61.2558 118.731 62.7918 119.843 63.6618C120.949 64.5273 122.391 64.4024 123.068 63.389Z"
          fill="#614EFA"></path>
        <path
          d="M129.696 62.6905C130.351 61.6961 130.026 60.1988 128.964 59.3395C127.898 58.4762 126.49 58.581 125.822 59.5799C125.153 60.5818 125.487 62.0955 126.565 62.954C127.637 63.8088 129.038 63.6875 129.696 62.6905Z"
          fill="#614EFA"></path>
        <path
          d="M90.2925 76.2548C91.0658 75.152 90.6724 73.5356 89.4099 72.6368C88.1406 71.7331 86.469 71.893 85.681 73.002C84.8903 74.1141 85.2944 75.7496 86.5784 76.6472C87.8562 77.5398 89.517 77.3607 90.2925 76.2548Z"
          fill="#FE6257"></path>
        <path
          d="M97.9024 75.3026C98.652 74.219 98.2725 72.627 97.0505 71.7402C95.8219 70.8484 94.2035 71.003 93.4396 72.0925C92.6734 73.1855 93.0632 74.7957 94.3057 75.6812C95.5419 76.5623 97.1505 76.3894 97.9024 75.3026Z"
          fill="#FE6257"></path>
        <path
          d="M104.461 70.8711C103.271 69.9909 101.703 70.1402 100.963 71.2114C100.22 72.2858 100.596 73.8715 101.799 74.7459C102.996 75.6159 104.555 75.4488 105.284 74.3802C106.012 73.3143 105.645 71.7464 104.461 70.8711Z"
          fill="#614EFA"></path>
        <path
          d="M112.433 73.4859C113.139 72.4377 112.785 70.8933 111.638 70.0291C110.485 69.1605 108.965 69.3045 108.246 70.3579C107.526 71.4145 107.889 72.9762 109.055 73.8395C110.214 74.698 111.725 74.5367 112.433 73.4859Z"
          fill="#614EFA"></path>
        <path
          d="M119.371 72.6185C120.056 71.5877 119.713 70.0655 118.601 69.2124C117.484 68.3549 116.01 68.494 115.313 69.5301C114.613 70.5694 114.964 72.108 116.094 72.9602C117.218 73.8084 118.683 73.6524 119.371 72.6185Z"
          fill="#614EFA"></path>
        <path
          d="M126.104 71.7775C126.769 70.7632 126.438 69.2632 125.359 68.4207C124.276 67.5739 122.846 67.7085 122.169 68.7282C121.49 69.7506 121.829 71.267 122.924 72.1085C124.014 72.9456 125.436 72.7945 126.104 71.7775Z"
          fill="#614EFA"></path>
        <path
          d="M132.632 70.9608C133.279 69.9624 132.958 68.4838 131.912 67.6516C130.861 66.8154 129.473 66.9456 128.814 67.9488C128.155 68.9547 128.483 70.4494 129.546 71.2807C130.603 72.108 131.983 71.9618 132.632 70.9608Z"
          fill="#614EFA"></path>
        <path
          d="M93.8099 85.1384C94.5715 84.0312 94.1849 82.4365 92.9424 81.5692C91.6928 80.6971 90.0476 80.8868 89.2716 81.9998C88.493 83.1163 88.8899 84.7292 90.1538 85.5951C91.4106 86.4567 93.046 86.2487 93.8099 85.1384Z"
          fill="#FE6257"></path>
        <path
          d="M101.304 84.0543C102.043 82.9662 101.669 81.3955 100.466 80.5393C99.2565 79.6783 97.6626 79.8613 96.9104 80.9552C96.1558 82.0527 96.5389 83.6411 97.7622 84.4959C98.9793 85.3463 100.563 85.1455 101.304 84.0543Z"
          fill="#FE6257"></path>
        <path
          d="M108.569 83.0035C109.286 81.9336 108.925 80.3865 107.76 79.5405C106.588 78.6901 105.044 78.8674 104.314 79.9426C103.582 81.0214 103.952 82.5858 105.137 83.4305C106.315 84.2707 107.85 84.0765 108.569 83.0035Z"
          fill="#614EFA"></path>
        <path
          d="M115.62 81.9852C116.315 80.933 115.967 79.4086 114.837 78.5733C113.702 77.7335 112.204 77.9046 111.496 78.9621C110.786 80.0226 111.144 81.564 112.291 82.3984C113.433 83.2279 114.922 83.04 115.62 81.9852Z"
          fill="#614EFA"></path>
        <path
          d="M122.462 80.997C123.138 79.9622 122.801 78.4595 121.706 77.6345C120.605 76.8049 119.152 76.9707 118.465 78.0108C117.775 79.054 118.121 80.5727 119.234 81.3969C120.341 82.2166 121.785 82.0349 122.462 80.997Z"
          fill="#614EFA"></path>
        <path
          d="M129.086 80.0382C129.742 79.0198 129.416 77.5385 128.354 76.7232C127.286 75.9039 125.876 76.0638 125.208 77.0871C124.539 78.1134 124.873 79.6103 125.952 80.4247C127.026 81.2352 128.427 81.0592 129.086 80.0382Z"
          fill="#614EFA"></path>
        <path
          d="M135.534 79.1073C136.172 78.1054 135.855 76.6445 134.825 75.839C133.788 75.0295 132.42 75.1845 131.771 76.1913C131.12 77.2012 131.444 78.6772 132.491 79.4819C133.533 80.2825 134.894 80.1124 135.534 79.1073Z"
          fill="#614EFA"></path>
        <path
          d="M97.2645 93.8776C98.015 92.7664 97.6347 91.193 96.4113 90.3564C95.1814 89.5153 93.5616 89.733 92.7968 90.8505C92.0302 91.971 92.42 93.5626 93.6643 94.3974C94.9019 95.2278 96.5122 94.9919 97.2645 93.8776Z"
          fill="#FE6257"></path>
        <path
          d="M104.65 92.6659C105.377 91.5738 105.01 90.024 103.826 89.1972C102.634 88.3659 101.065 88.5769 100.323 89.6748C99.5801 90.7762 99.9565 92.3433 101.161 93.1684C102.359 93.9891 103.919 93.7611 104.65 92.6659Z"
          fill="#FE6257"></path>
        <path
          d="M111.809 91.4916C112.515 90.4182 112.16 88.8911 111.013 88.074C109.859 87.2529 108.337 87.4564 107.618 88.5361C106.897 89.6184 107.261 91.1628 108.428 91.9781C109.588 92.7886 111.101 92.5682 111.809 91.4916Z"
          fill="#614EFA"></path>
        <path
          d="M118.754 90.3524C119.44 89.2967 119.096 87.7913 117.984 86.984C116.865 86.1723 115.39 86.3695 114.692 87.431C113.992 88.4956 114.343 90.0169 115.474 90.8229C116.599 91.6244 118.066 91.4111 118.754 90.3524Z"
          fill="#614EFA"></path>
        <path
          d="M125.494 89.2474C126.16 88.209 125.828 86.725 124.749 85.927C123.665 85.1251 122.233 85.3157 121.555 86.3598C120.875 87.4066 121.215 88.9061 122.312 89.7028C123.403 90.4954 124.826 90.2888 125.494 89.2474Z"
          fill="#614EFA"></path>
        <path
          d="M76.1403 58.1251C75.6934 58.1251 75.3174 57.7799 75.288 57.3293C75.2572 56.8601 75.6143 56.4554 76.0858 56.4247C77.0908 56.3598 77.9712 55.924 78.5645 55.1971C79.1329 54.5009 79.3735 53.6091 79.2423 52.6858C78.9548 50.6682 77.0069 49.1145 74.9 49.2309C73.8794 49.2851 72.9851 49.7187 72.3824 50.4518C71.806 51.153 71.5641 52.0531 71.7016 52.988C71.7699 53.4527 71.4466 53.885 70.9792 53.953C70.5109 54.0197 70.0778 53.6993 70.0095 53.2341C69.8014 51.8203 70.1742 50.4492 71.0591 49.3735C71.9703 48.2658 73.3017 47.6114 74.8085 47.5314C77.7855 47.3732 80.5267 49.5783 80.9353 52.4468C81.1344 53.8446 80.7638 55.2029 79.8914 56.2705C78.9945 57.3689 77.6823 58.0269 76.197 58.1229C76.1778 58.1247 76.159 58.1251 76.1403 58.1251Z"
          fill="#131032"></path>
        <path
          d="M76.1467 59.3092C76.0128 59.3092 75.8789 59.2959 75.7494 59.2692C75.6154 59.2426 75.486 59.207 75.3654 59.1537C75.2404 59.1048 75.1243 59.0382 75.0127 58.9671C74.9011 58.8916 74.794 58.8072 74.7002 58.7138C74.3207 58.3362 74.1019 57.8119 74.1019 57.2743C74.1019 57.141 74.1153 57.0077 74.1421 56.8744C74.1689 56.7455 74.2091 56.6167 74.2582 56.4967C74.3073 56.3723 74.3743 56.2524 74.4457 56.1457C74.5216 56.0302 74.6064 55.928 74.7002 55.8347C74.794 55.7414 74.9011 55.657 75.0127 55.5815C75.1243 55.5059 75.2404 55.4437 75.3654 55.3948C75.486 55.3415 75.6154 55.306 75.7494 55.2793C76.4146 55.146 77.12 55.3637 77.5933 55.8347C77.687 55.928 77.7718 56.0347 77.8477 56.1457C77.9236 56.2524 77.9861 56.3723 78.0353 56.4967C78.0888 56.6167 78.1246 56.7455 78.1513 56.8744C78.1781 57.0077 78.1915 57.141 78.1915 57.2743C78.1915 57.4076 78.1781 57.5409 78.1513 57.6742C78.1246 57.803 78.0888 57.9319 78.0353 58.0518C77.9861 58.1762 77.9236 58.2962 77.8477 58.4028C77.7718 58.5139 77.687 58.6205 77.5933 58.7138C77.4995 58.8072 77.3924 58.8916 77.2807 58.9671C77.1736 59.0382 77.053 59.1048 76.928 59.1537C76.8075 59.207 76.678 59.2426 76.5486 59.2692C76.4146 59.2959 76.2807 59.3092 76.1467 59.3092Z"
          fill="#131032"></path>
        <path
          d="M86.4402 57.2938C85.9933 57.2938 85.6174 56.9486 85.5879 56.4981C85.5571 56.0293 85.9143 55.6241 86.3857 55.5939C87.326 55.533 88.1466 55.1234 88.6966 54.4409C89.2373 53.7695 89.4583 52.9045 89.3176 52.0043C89.0127 50.0502 87.13 48.5391 85.12 48.6542C84.165 48.7048 83.3315 49.1118 82.7725 49.8005C82.2238 50.4763 82.0024 51.3498 82.1484 52.2611C82.2229 52.725 81.905 53.1613 81.4389 53.2355C80.9746 53.3115 80.5344 52.9938 80.4599 52.5295C80.2362 51.1357 80.5853 49.7867 81.4425 48.731C82.31 47.6625 83.5837 47.0316 85.0294 46.9547C87.8907 46.8112 90.5726 48.9505 91.0079 51.743C91.2227 53.1208 90.8762 54.4569 90.0311 55.5055C89.177 56.5652 87.922 57.1996 86.4964 57.292C86.4777 57.2934 86.4589 57.2938 86.4402 57.2938Z"
          fill="#131032"></path>
        <path
          d="M86.4462 58.4428C86.3168 58.4428 86.1828 58.4295 86.0534 58.4028C85.9283 58.3762 85.7989 58.3406 85.6783 58.2917C85.5578 58.2384 85.4417 58.1762 85.3301 58.1051C85.2229 58.034 85.1202 57.9496 85.0265 57.8563C84.6515 57.4831 84.4372 56.9677 84.4372 56.4434C84.4372 55.9147 84.6515 55.3993 85.0265 55.0261C85.1202 54.9372 85.2229 54.8528 85.3301 54.7817C85.4417 54.7061 85.5578 54.6439 85.6783 54.5951C85.7989 54.5462 85.9283 54.5062 86.0534 54.4795C86.7052 54.3507 87.4017 54.564 87.8704 55.0261C88.241 55.3993 88.4598 55.9147 88.4598 56.4434C88.4598 56.9677 88.241 57.4875 87.8704 57.8563C87.4954 58.2295 86.9775 58.4428 86.4462 58.4428Z"
          fill="#131032"></path>
        <path
          d="M96.7341 56.4625C96.2871 56.4625 95.9112 56.1168 95.8818 55.6667C95.8514 55.1975 96.2081 54.7928 96.6796 54.7626C97.5551 54.7061 98.3163 54.3231 98.8235 53.6851C99.3365 53.0391 99.5369 52.2002 99.3878 51.3231C99.0655 49.4322 97.243 47.9686 95.3344 48.0775C94.445 48.1245 93.6713 48.5053 93.1561 49.1491C92.6355 49.7992 92.4346 50.646 92.5896 51.5338C92.6704 51.9967 92.3587 52.4375 91.8935 52.5179C91.4283 52.5979 90.9854 52.2882 90.9046 51.8252C90.6644 50.4514 90.989 49.1242 91.8181 48.0886C92.6422 47.0591 93.8588 46.4513 95.2429 46.3775C97.9953 46.2273 100.611 48.3218 101.074 51.0379C101.305 52.3966 100.982 53.7113 100.165 54.7403C99.3539 55.7618 98.1551 56.3727 96.7903 56.4607C96.7716 56.4621 96.7528 56.4625 96.7341 56.4625Z"
          fill="#131032"></path>
        <path
          d="M96.7424 57.5764C96.2245 57.5764 95.7155 57.3676 95.3494 56.9988C95.2556 56.9099 95.1753 56.8077 95.1038 56.7011C95.0324 56.5945 94.9699 56.4834 94.9208 56.3634C94.8717 56.2435 94.836 56.119 94.8092 55.9946C94.7824 55.8702 94.769 55.7414 94.769 55.6125C94.769 55.0927 94.9788 54.5862 95.3494 54.2218C95.4387 54.133 95.5414 54.0486 95.6485 53.9775C95.7557 53.9064 95.8718 53.8442 95.9878 53.7953C96.1084 53.7464 96.2334 53.7109 96.3584 53.6842C96.6129 53.6353 96.8763 53.6353 97.1308 53.6842C97.2558 53.7109 97.3808 53.7464 97.5013 53.7953C97.6174 53.8442 97.7335 53.9064 97.8406 53.9775C97.9478 54.0486 98.0505 54.133 98.1398 54.2218C98.5059 54.5862 98.7202 55.0927 98.7202 55.6125C98.7202 55.7414 98.7068 55.8702 98.68 55.9946C98.6532 56.119 98.6175 56.2435 98.5684 56.3634C98.5193 56.4834 98.4568 56.5945 98.3853 56.7011C98.3139 56.8077 98.2291 56.9099 98.1398 56.9988C98.0505 57.0921 97.9478 57.1721 97.8406 57.2432C97.7335 57.3142 97.6174 57.3765 97.4969 57.4253C97.3808 57.4742 97.2558 57.5142 97.1308 57.5364C97.0013 57.5631 96.8718 57.5764 96.7424 57.5764Z"
          fill="#131032"></path>
        <path
          d="M107.042 55.6312C106.595 55.6312 106.219 55.2855 106.19 54.8354C106.159 54.3662 106.516 53.9614 106.988 53.9312C107.798 53.8788 108.501 53.5234 108.965 52.9293C109.451 52.3086 109.631 51.496 109.473 50.6416C109.133 48.8132 107.389 47.4039 105.563 47.4998C104.739 47.5434 104.026 47.897 103.554 48.496C103.061 49.1202 102.881 49.9404 103.045 50.8051C103.132 51.2671 102.828 51.7119 102.364 51.799C101.899 51.8874 101.452 51.5826 101.365 51.121C101.108 49.7667 101.408 48.4613 102.209 47.4452C102.99 46.4548 104.149 45.8705 105.473 45.8003C108.116 45.6639 110.666 47.6935 111.155 50.3323C111.404 51.6715 111.106 52.9653 110.316 53.9748C109.547 54.9571 108.405 55.545 107.1 55.6294C107.08 55.6307 107.061 55.6312 107.042 55.6312Z"
          fill="#131032"></path>
        <path
          d="M107.031 56.71C106.906 56.71 106.781 56.6966 106.656 56.67C106.531 56.6478 106.41 56.6122 106.29 56.5634C106.174 56.5145 106.062 56.4523 105.955 56.3856C105.852 56.3145 105.754 56.2346 105.66 56.1457C105.303 55.7858 105.093 55.2882 105.093 54.7817C105.093 54.2707 105.303 53.7731 105.66 53.4132C106.022 53.0577 106.522 52.8489 107.031 52.8489C107.544 52.8489 108.044 53.0577 108.406 53.4132C108.495 53.5065 108.576 53.6042 108.647 53.7109C108.714 53.8131 108.777 53.9241 108.821 54.0441C108.87 54.1596 108.91 54.2796 108.933 54.404C108.96 54.5284 108.973 54.6528 108.973 54.7817C108.973 55.2882 108.763 55.7858 108.402 56.1457C108.312 56.2346 108.214 56.3145 108.107 56.3856C108.004 56.4523 107.893 56.5145 107.776 56.5634C107.656 56.6122 107.535 56.6478 107.41 56.67C107.285 56.6966 107.16 56.71 107.031 56.71Z"
          fill="#131032"></path>
        <path
          d="M117.329 54.7998C116.882 54.7998 116.505 54.4541 116.476 54.0036C116.446 53.5349 116.803 53.1301 117.275 53.0994C118.02 53.0514 118.663 52.7227 119.086 52.1744C119.544 51.5794 119.704 50.7935 119.536 49.9608C119.18 48.1947 117.487 46.8391 115.77 46.9226C115.012 46.9626 114.358 47.2892 113.929 47.8423C113.464 48.4404 113.304 49.2335 113.477 50.0759C113.572 50.5362 113.274 50.9858 112.811 51.0805C112.35 51.1729 111.897 50.8779 111.802 50.4176C111.531 49.1015 111.813 47.7837 112.575 46.8018C113.314 45.8505 114.416 45.2898 115.679 45.2231C118.25 45.0823 120.687 47.0208 121.212 49.6262C121.479 50.9472 121.206 52.2197 120.443 53.2101C119.716 54.1542 118.63 54.7181 117.384 54.798C117.366 54.7994 117.347 54.7998 117.329 54.7998Z"
          fill="#131032"></path>
        <path
          d="M117.335 55.8435C117.21 55.8435 117.085 55.8302 116.96 55.8035C116.84 55.7813 116.719 55.7458 116.607 55.6969C116.491 55.6525 116.38 55.5903 116.277 55.5236C116.174 55.457 116.076 55.377 115.987 55.2881C115.634 54.9371 115.429 54.4484 115.429 53.9508C115.429 53.4487 115.634 52.9599 115.987 52.6089C116.344 52.2579 116.831 52.0535 117.335 52.0535C117.835 52.0535 118.326 52.2579 118.679 52.6089C118.768 52.6978 118.849 52.7911 118.916 52.8977C118.983 52.9999 119.045 53.111 119.09 53.2265C119.139 53.3376 119.175 53.4576 119.201 53.5775C119.224 53.7019 119.237 53.8263 119.237 53.9508C119.237 54.4484 119.032 54.9371 118.679 55.2881C118.326 55.6391 117.835 55.8435 117.335 55.8435Z"
          fill="#131032"></path>
        <path
          d="M127.629 53.9686C127.183 53.9686 126.806 53.6229 126.777 53.1724C126.747 52.7032 127.104 52.2984 127.575 52.2682C128.256 52.2242 128.841 51.9234 129.222 51.4205C129.652 50.8517 129.792 50.0924 129.614 49.2815C129.241 47.5772 127.615 46.2607 125.992 46.346C125.3 46.3824 124.706 46.6814 124.318 47.1884C123.881 47.7602 123.742 48.5267 123.925 49.3464C124.027 49.8054 123.736 50.2599 123.275 50.3617C122.815 50.4643 122.358 50.1742 122.255 49.7152C121.966 48.4174 122.221 47.1213 122.956 46.1585C123.652 45.2467 124.698 44.7096 125.901 44.646C128.366 44.5243 130.731 46.3931 131.285 48.919C131.566 50.203 131.312 51.488 130.587 52.4446C129.902 53.3497 128.871 53.8899 127.685 53.9664C127.667 53.9681 127.648 53.9686 127.629 53.9686Z"
          fill="#131032"></path>
        <path
          d="M127.624 54.9772C127.133 54.9772 126.651 54.7772 126.302 54.4307C125.954 54.0841 125.753 53.6042 125.753 53.1199C125.753 52.6268 125.954 52.1513 126.302 51.8048C126.651 51.4582 127.133 51.2583 127.624 51.2583C128.115 51.2583 128.597 51.4582 128.945 51.8048C129.289 52.1513 129.49 52.6268 129.49 53.1199C129.49 53.6087 129.289 54.0841 128.945 54.4307C128.856 54.5195 128.762 54.595 128.66 54.6617C128.557 54.7283 128.45 54.7861 128.338 54.835C128.222 54.8794 128.106 54.915 127.99 54.9416C127.869 54.9638 127.744 54.9772 127.624 54.9772Z"
          fill="#131032"></path>
        <path
          d="M75.9708 108.284C75.7521 108.284 75.5333 108.201 75.3663 108.035L67.1823 99.8941L60.9863 104.131C60.597 104.398 60.0648 104.298 59.7974 103.912C59.5299 103.524 59.6286 102.995 60.0179 102.728L66.7996 98.0906C67.1385 97.8578 67.5966 97.9 67.8881 98.1902L76.5749 106.832C76.9088 107.164 76.9088 107.702 76.5749 108.035C76.4083 108.202 76.1896 108.284 75.9708 108.284Z"
          fill="white"></path>
        <path
          d="M67.2925 99.643C67.2768 99.643 67.2608 99.6426 67.2447 99.6417C66.7732 99.6159 66.4121 99.2147 66.4379 98.7451L68.1358 68.0836C68.1617 67.6144 68.5577 67.2518 69.0368 67.2807C69.5083 67.3065 69.8694 67.7077 69.8435 68.1773L68.1457 98.8388C68.1207 99.2925 67.7434 99.643 67.2925 99.643Z"
          fill="white"></path>
        <path
          d="M144.34 106.082C144.038 106.082 143.733 106.073 143.427 106.055C139.372 105.815 135.652 104.019 132.955 100.996C130.257 97.9728 128.904 94.0851 129.144 90.049C129.62 82.0629 136.284 75.8075 144.315 75.8075C144.617 75.8075 144.922 75.8164 145.228 75.8346C149.284 76.0741 153.003 77.8709 155.701 80.894C158.398 83.9171 159.752 87.8048 159.511 91.8409C159.036 99.827 152.372 106.082 144.341 106.082C144.34 106.082 144.34 106.082 144.34 106.082Z"
          fill="#23D6BA"></path>
        <path
          d="M144.317 77.2777C144.59 77.2777 144.866 77.2857 145.143 77.3021C152.714 77.7487 158.487 84.2192 158.039 91.7538C157.606 99.0134 151.556 104.612 144.342 104.612C144.069 104.612 143.793 104.604 143.517 104.588C135.946 104.141 130.172 97.6707 130.621 90.1361C131.054 82.876 137.104 77.2777 144.317 77.2777ZM144.317 74.3372C135.505 74.3372 128.194 81.2005 127.672 89.9619C127.127 99.1032 134.157 106.981 143.342 107.523C143.675 107.542 144.011 107.552 144.342 107.552C153.154 107.552 160.466 100.689 160.988 91.9275C161.533 82.7863 154.503 74.9086 145.317 74.3666C144.985 74.347 144.649 74.3372 144.317 74.3372Z"
          fill="white"></path>
        <path
          d="M142.357 97.8578C142.342 97.8578 142.326 97.8578 142.311 97.8574C141.852 97.8432 141.426 97.6175 141.158 97.2474L136.705 91.099C136.228 90.4405 136.378 89.5217 137.04 89.0467C137.702 88.5722 138.625 88.7215 139.102 89.3799L142.432 93.9777L149.951 84.8533C150.468 84.2255 151.399 84.1335 152.03 84.6489C152.661 85.1639 152.753 86.0903 152.235 86.7181L143.499 97.3202C143.218 97.661 142.799 97.8578 142.357 97.8578Z"
          fill="white"></path>
      </svg>
    </div>
  );
};

export default AgendaIcon;
