import React, { useEffect, useState, useMemo, useContext } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import ProfileModal from "../../Component/Dashboard/ProfileModal";
import DashboardRDV from "../../Component/Client/DashboardRDV";
import { useFormContext } from "../../Context/FormContext";
import Datacontext from "../../Context/Datacontext";
import DashboardCalendar from "./DashboardCalendar";
import toast from "react-hot-toast";
import { HiChevronLeft, HiOutlineLocationMarker, HiSearch } from "react-icons/hi";
import { BsFillBookmarkPlusFill } from "react-icons/bs";
import { HiCalendarDays } from "react-icons/hi2";
import ContactForm from "./ContactForm";
import "../../styles/reactCalendar.css";
import "../../styles/animate.css";
import LoggedContext from "Context/isLogged";
import { FcInfo } from "react-icons/fc";
import SmSpinner from "Component/ui/SmSpinner";
import { FaCalendarAlt } from "react-icons/fa";
import {
  ConnectUserInFront,
  connectUserInBackend,
} from "Component/utils/connectionHandler";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";

const DashBoard = () => {
  const { FilterList, setFilterlist } = useContext(Datacontext);
  const { userGlobal } = useContext(LoggedContext);

  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const Navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterlist({ ...FilterList, name, city });
    Navigate("/search");
  };
  const [viewMode, setViewMode] = useState(false);
  const {
    showModalDeleteacc,
    setShowModalDeleteacc,
    formData,
    selectedName,
    showModal,
    setShowModal,
    fetchData,
    setImagePreview,
    removeUser,
  } = useFormContext();
  const { fetchPrestationList, loadingPrestation, prestationList } =
    useContext(Datacontext);

  const { firstName, lastName } = formData;

  useEffect(() => {
    localStorage.removeItem("startTimersToSkip");

    fetchData();
    fetchPrestationList();
    localStorage.removeItem("slotToUpdate");
  }, [userGlobal]);

  useEffect(() => {
    if (formData.imageUrl) {
      setImagePreview(formData.imageUrl);
    }
  }, [formData.imageUrl]);

  const [currentDate, setCurrentDate] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(moment());
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  const dateFormat = "D MMMM YYYY [et il est] HH:mm";
  const formattedDate = useMemo(() => {
    return moment(currentDate).locale("fr").format(dateFormat);
  }, [currentDate]);

  const switchView = (view) => {
    setViewMode(view);
  };

  const HandelDeleteAcc = () => {
    removeUser();
    toast.success("Vous avez supprimé votre compte avec succès ");
    setShowModalDeleteacc(false);
    setViewMode(false);
    window.location.href = "/login";
  };

  const fadeLeft = `animate__animated animate__fadeOutLeft hidden `;
  const fadeRight = `animate__animated animate__fadeOutRight hidden `;
  const fadeDown = `animate__animated animate__fadeOutDown hidden `;
  const fadeUp = `animate__animated animate__fadeOutUp hidden`;

  const fadeInLeft = `animate__animated  animate__fadeInLeft`;
  const fadeInRight = `animate__animated  animate__fadeInRight`;
  const fadeInDown = `animate__animated  animate__fadeInDown`;
  const fadeInUp = `animate__animated  animate__fadeInUp`;

  // const a = [
  //   "animate__animated",
  //   "animate__animated",
  //   "animate__animated",
  //   "animate__animated",
  // ];

  const menuSection = [
    "Mes informations",
    "Mot de passe",
    // "Méthode de paiement",
    "Mes avis",
  ];

  // get list of rdv date from prestationList
  const rendezVousList =
    prestationList && prestationList?.map((item) => item.startDateTime);

  // Count the occurrences of each date
  const dateCounts =
    rendezVousList &&
    rendezVousList?.reduce((counts, rendezVous) => {
      const formattedDate = moment(rendezVous).format("YYYY-MM-DD");
      counts[formattedDate] = (counts[formattedDate] || 0) + 1;
      return counts;
    }, {});
  const [selectedDate, setSelectedDate] = useState(new Date());

  const navigate = useNavigate();
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  const connectUserInFrontEnd = ConnectUserInFront();
  const handleError = (message) => {
    setError(message);
    setTimeout(() => {
      setError("");
    }, 8000);
  };

  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [googleToken, setgoogleToken] = useState(null);
  const handleLogin = async (googleToken) => {
    if (googleToken) {
      setLoading(true);

      connectUserInBackend(form, googleToken, false)
        .then((res) => {
          if (!res.ok) {
            setLoading(false);
            return Promise.reject(res.status);
          } else return res.json();
        })
        .then((data) => {
          connectUserInFrontEnd(data, false);
          setLoading(false);
        })
        .catch((error) => {
          // Handle any errors that occurred in the previous steps

          console.error("An error occurred:", error);
          switch (error) {
            case 422:
              setLoading(false);
              googleLogout();
              toast.error("Les informations d'identification ne sont pas valides !");
              handleError("Identifiants incorrects");
              break;

            default:
              setLoading(false);
              toast.error("Les informations d'identification ne sont pas valides !");
              handleError("Identifiants incorrects");
              break;
          }
        });
    } else {
      toast.error("Les informations d'identification ne sont pas valides !");

      handleError("Identifiants incorrects");
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      handleLogin(codeResponse);
    },
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/calendar.app.created https://www.googleapis.com/auth/calendar.calendarlist.readonly openid profile email",
  });

  return (
    <div className="backdrop-blur-md  px-6">
      {!userGlobal.hasGoogleCalendarAccess && userGlobal.isConnectedGoogle && (
        <div className="px-4 translate-y-4 z-30">
          <div
            className={`animate__animated  animate__fadeInDown w-full  py-2 px-4  trans-bg justify-between rounded-3xl items-baseline md:flex mb-1  gap-4`}>
           <div className="flex gap-3"><FcInfo className="translate-y-[3px]"/>
             <p className="md:text-base xs:text-xs font-syne ">Connectez votre calendrier pour synchroniser vos réservations dans votre
              agenda Google.</p> </div>
              <Link to={'/connect-calendar'}  className="w-fit md:text-base xs:text-xs md:mt-0 xs:mt-3 text-white flex items-baseline gap-3"><FaCalendarAlt className="translate-y-[2px]" />
Connecter Calendrier</Link>
             
           
          </div>
        </div>
      )}
      <div className="w-full  min-h-screen pt-0   justify-center xs:translate-y-4   mx-auto flex items-center ">
        <div
          className={`flex -translate-y-3 lg:flex-row md:flex-col w-full  flex-wrap lg:flex-nowrap items-center mx-4 !max-w-[1920px] px-4 pb-4  ${
            showModal ? fadeUp : fadeInRight
          } `}>
          <div className="flex flex-col md:mr-[1rem] mr-0 w-full lg:w-7/12 ">
            <div className="flex md:flex-row gap-4 flex-col  mt-0 lg:mt-12 items-center md:items-end ">
              <div
                className={`text-white flex flex-col gap-4 md:w-[57%] w-full  items-center md:items-start  `}>
                <div className={`${viewMode ? fadeUp : fadeInDown} mt-1 mb-12`}>
                  <Link
                    to="/search"
                    className="hover:text-black rounded-full border-2 border-white px-2 py-1 w-[12.5rem]  text-white flex  mb-3 mt-3 items-center ">
                    <HiChevronLeft size={26} className="mr-2  font-roboto" />
                    Faire une recherche
                  </Link>
                  <span className="text-[1.75rem] md:text-4xl font-syne leading-[30px]">
                    Hello <span className="capitalize">{firstName}</span>, <br />
                    Bienvenue chez Stunify <br />
                    Voici ton Dasboard.
                  </span>
                  <p className="text-[0.75rem] md:text-[0.95rem] leading-none font-roboto mt-3">
                    aujourd’hui nous sommes le {formattedDate ? formattedDate : null}
                  </p>
                </div>
                <div
                  className={`  h-auto w-full  rounded-3xl ${
                    viewMode ? fadeLeft : fadeInLeft
                  }`}>
                  <Calendar
                    onChange={setSelectedDate}
                    value={selectedDate}
                    tileClassName={({ date, view }) => {
                      const formattedDate = moment(date).format("YYYY-MM-DD");
                      const rendezVousCount = dateCounts[formattedDate] || 0;

                      if (rendezVousCount >= 2) {
                        // Two or more appointments on the same day, render a yellow marker with 2 dots
                        return "react-calendar__Rdv--multiple ";
                      } else if (rendezVousCount === 1) {
                        // One appointment on the day, render a green marker with one dots
                        return "react-calendar__Rdv   ";
                      }
                      return null;
                    }}
                  />
                </div>
              </div>
              <div
                className={`flex md:flex-row w-full md:w-96  items-end justify-center     ${
                  viewMode ? `opacity-0` : `lg:w-[43%] animate__animated animate__zoomIn`
                }`}>
                <div
                  className={`p-9 trans-bg cus-radius  lg:mx-0 flex flex-col md:w-[26.5rem] w-full  items-center md:items-start `}>
                  <div className="flex flex-col mx-auto   mb-6">
                    {userGlobal.imageUrl && (
                      <img
                        src={userGlobal.imageUrl}
                        alt="Selected "
                        className="rounded-full aspect-square w-[8rem] object-cover mx-auto"
                      />
                    )}

                    <div className=" capitalize w-fit  mt-3 mx-auto text-center text-white text-opacity-50 font-roboto text-sm md:text-base">
                      {firstName} {lastName}
                    </div>
                  </div>
                  {menuSection.map((item, index) => {
                    return (
                      <a
                        key={index}
                        href="#/"
                        className="profile-link hover:bg-black hover:rounded-3xl  rounded-3xl hover:text-white mt-3 py-3 px-6"
                        onClick={() => switchView(index + 1)}>
                        {item}
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className={`trans-bg h-44 lg:p-3 md:p-2    md:h-auto  cus-radius flex flex-col     text-white mt-[1rem]  ${
                viewMode ? fadeDown : fadeInUp
              }`}>
              <h5 className="mb-3 text-center lg:text-left ">
                Trouve ton prochain rendez-vous
              </h5>
              <div className="flex md:flex-row flex-col  rounded-full border-[1px] border-opacity-30 h-20   border-white md:h-10 items-center justify-between md:p-2 p-0 py-[15px]">
                <div className="flex flex-row gap-2 items-center text-white text-opacity-50 w-full">
                  <HiSearch size={20} />{" "}
                  <input
                    className="bg-transparent text-white text-opacity-100 w-full"
                    placeholder="Prestation, nom du salon..."
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="flex flex-row gap-2 items-center w-full md:border-l-[1px] text-white text-opacity-50 border-opacity-30 border-white px-3">
                  <HiOutlineLocationMarker size={20} />{" "}
                  <input
                    className="bg-transparent text-white text-opacity-100 w-full"
                    placeholder="Ville..."
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <button
                  onClick={handleSubmit}
                  className="bg-white text-black hover:bg-black  hover:text-white rounded-full md:px-3  px-7  py-1  mt-6 mb-4 md:mb-0 md:mt-0 md:text-sm  text-base flex items-center">
                  Rechercher
                </button>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col lg:w-5/12 mt-[1rem] justify-center w-full lg:m-auto`}
            style={{ marginBottom: "0px" }}>
            {prestationList && prestationList?.length > 0 ? (
              <div
                className={`px-1 py-4 trans-bg cus-radius flex flex-col justify-center  items-center  h-[1000px] md:h-[600px] lg:h-[500px]    ${
                  viewMode ? fadeUp : fadeInDown
                }`}>
                <h4 className="text-white text-[1.5rem] ml-2 md:ml-0 mb-5">
                  Mes rendez-vous à venir
                </h4>

                <div className="overflow-y-auto   overflow-x-hidden  xs:w-72 sm:w-96 md:w-full  ">
                  {prestationList &&
                    prestationList?.map((item, index) => {
                      return (
                        <DashboardRDV
                          key={index}
                          name={item.institute?.name}
                          address={item.institute?.address}
                          eventName={item.institute?.eventName}
                          prestations={item.prestation}
                          img={item.institute?.photos[0]}
                          instituteId={item.institute?._id}
                          IdStaff={item.staff?._id}
                          slotToUpdate={item.slots}
                          idreservation={item.idReservation}
                          idPrestation={item.prestation._id}
                        />
                      );
                    })}
                </div>
              </div>
            ) : (
              <>
                {loadingPrestation ? (
                  <div className=" h-[500px] md:h-[600px] lg:h-[500px] px-1 py-4 trans-bg cus-radius flex flex-col justify-center  items-center   ">
                    <SmSpinner w={10} h={10} />
                  </div>
                ) : (
                  <div
                    className={`    px-1   py-5 trans-bg cus-radius flex flex-col justify-center  items-center h-auto lg:h-[500px]    ${
                      viewMode ? fadeUp : fadeInDown
                    }`}>
                    <Link
                      to="/search"
                      className=" flex flex-row  items-center justify-items-center  text-white md:text-[1.5rem] text-sm px-4  ml-2 md:ml-0 mb-5 md:px-8 mt-2  hover:bg-white hover:text-black py-2 bg-black  rounded-full">
                      Prendre rendez-vous! <BsFillBookmarkPlusFill className="ml-2 " />
                    </Link>
                  </div>
                )}
              </>
            )}

            <div
              className={`p-5 trans-bg cus-radius  flex flex-col h-auto lg:h-[33%] my-[1rem] justify-center items-center ${
                viewMode ? fadeRight : fadeInRight
              }`}>
              <h4 className="text-white text-[1.5rem]  text-center lg:text-left  mb-5">
                Historiques
              </h4>
              <div className="self-center flex flex-col h-[117px] mb-8 ">
                <HiCalendarDays className="text-white self-center" size={90} />
                <p className="text-black text-lg">Pas de rendez-vous passé</p>
              </div>
            </div>
            <div
              className={`p-5  trans-bg cus-radius flex flex-row h-[9%] justify-between gap-2 mb-4 md:mb-1 ${
                viewMode ? fadeDown : fadeInUp
              }`}>
              <ContactForm />
            </div>
          </div>
        </div>
        <ProfileModal viewMode={viewMode} setViewMode={setViewMode} />
        {showModal ? (
          <DashboardCalendar
            setShowModal={setShowModal}
            showModal={showModal}
            selectedName={selectedName}
          />
        ) : null}
        {/* modal delete acc */}
        {showModalDeleteacc ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden   fixed inset-0 z-50 outline-none focus:outline-none  bg-black bg-opacity-50 ">
              <div className="relative w-auto my-6  max-w-3xl  mx-4 md:mx-0 ">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-sand  outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold font-roboto">
                      Supprimer le compte{" "}
                    </h3>
                    <button
                      className="p-1 ml-auto border-0 text-black  float-right text-3xl leading-none font-semibold"
                      onClick={() => setShowModalDeleteacc(false)}>
                      ×
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <p className="my-4 text-black text-lg leading-relaxed font-syne">
                      Etes-vous sûr de vouloir supprimer votre compte ?
                    </p>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="   background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModalDeleteacc(false)}>
                      Close
                    </button>
                    <button
                      className="text-red-500 hover:bg-red-700 hover:text-white hover:duration-75  active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={HandelDeleteAcc}>
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DashBoard;
