import ChatList from "Component/Chat/ChatList";
import ChatwWindow from "Component/Chat/ChatwWindow";
import { useChatContext } from "Context/ChatContext";
import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useLocation, Navigate } from "react-router-dom";

import Cookies from "js-cookie";
import { useParams } from "react-router-dom";

const Chat = () => {
  const location = useLocation();
  const decoded = jwt_decode(Cookies.get("authToken"));

  const {
    chatList,
    socket,
    setSocket,
    setSelectedChat,
    selectedChat,
    setIsInstitute,
    isInstitute,
    newMessage,
    setMessages,
  } = useChatContext();
  const { type } = useParams();
  const [onlineUser, setOnlineuser] = useState();
  const [chatExist, setChatExist] = useState(false);

  const isAuthenticated = !!Cookies.get("authToken");

  useEffect(() => {
    if (isAuthenticated && type === "institute") {
      const decoded = jwt_decode(Cookies.get("authToken"));
      if (decoded) {
        if (decoded.isPro) {
          setIsInstitute(true);
        }
      }
    } else if (isAuthenticated && type === "client") {
      const decoded = jwt_decode(Cookies.get("authToken"));
      if (decoded) {
        setIsInstitute(false);
      }
    }
  }, []);

  /////////////////////////////////////// add user to the online users /////////////////////////////////////////

  useEffect(() => {
    if (socket) {
      socket.emit("addNewUser", decoded.id);

      socket.on("getOnlineUsers", (res) => {
        setOnlineuser(res);
      });
    }
  }, [socket]);

  //////////////////////////////////////send message //////////////////////////////////////////////////////

  useEffect(() => {
    if (socket && newMessage) {
      socket.emit("sendMessage", newMessage);
      setMessages((prev) => [...prev, newMessage]);
    } else if (socket) {
      socket.on("getMessage", (res) => {
        setMessages((prev) => [...prev, res]);
      });
    }
  }, [socket, newMessage]);
  useEffect(() => {
    if (selectedChat) {
      localStorage.setItem("selectedChat", JSON.stringify(selectedChat));
    } else {
      setSelectedChat(JSON.parse(localStorage.getItem("selectedChat")));
    }
  }, [selectedChat]);

  useEffect(() => {
    chatList.some((item) => {
      if (item?._id === JSON.parse(localStorage.getItem("selectedChat"))?._id) {
        
        setChatExist(true);
      }
    });
  }, [selectedChat,chatList]);

  if (type === "institute") {
    const isAuthenticated = !!Cookies.get("authToken");
    if (isAuthenticated) {
      const decoded = jwt_decode(Cookies.get("authToken"));

      if (decoded) {
        if (!decoded.isPro) {
          return <Navigate to="/profile" state={{ from: location }} />;
        }
      }
    }
  } else if (type !== "institute" && type !== "client") {
    return <Navigate to="/profile" state={{ from: location }} />;
  }
  return (
    <div className={`md:h-screen  xxs:h-screen  flex gap-7 w-full   md:p-11 ${isInstitute? 'bg-black': 'bg-white'}`} >
     
      {isInstitute !== null && <ChatList />}
      {selectedChat && chatList && chatExist && <ChatwWindow socket={socket} />}
    </div>
  );
};

export default Chat;
