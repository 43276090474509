import React from "react";
import { useNavigate } from "react-router-dom";

function Hero() {
  const navigate = useNavigate();

  const animationToInscription = () => {
    const Pro_home_hero = document.getElementById("Pro_home_hero");
    Pro_home_hero.style.height = "100vh";
    setTimeout(() => {
      navigate("institute-registration");
    }, 500);
  };

  return (
    <>
      <div
        className={`h-[0vh] bg-[#909090]  relative transition-height duration-500`}
        id="Pro_home_hero">
        {/* <img
          src="https://bucket-stunify-app.s3.eu-north-1.amazonaws.com/static/assets/bgProRegistration10-2023.jpg"
          alt="background"
          className=" xs:top-[230px] sm:top-[-180px] md:top-[-230px] lg:top-[0] left-0 right-0 w-full h-auto object-cover scale-[2]"
        /> */}
      </div>
      <div className="">
        <section className="text-gray-300  h-[100vh]  w-full md:h-[120svh]  flex flex-col justify-center items-center hero ">
          <div className="p-3     md:mx-auto text-center black-glass-effect flex flex-col justify-center items-center mx-3">
            <h1 className="md:pb-9  pb-2  md:text-4xl text-3xl  md:text-[60px]  md:w-[750px] w-80  font-syne text-[#0F0F0F] text-center lg:text-6xl not-italic font-bold leading-[normal]   ">
              Simplifiez vous la vie et gagnez des clients
            </h1>
            <p className="md:text-2xl text-sm  xs:w-72 sm:w-80   md:w-[750px] lg:w-[600px] text-[#0F0F0F] lg:text-lg not-italic font-normal font-roboto leading-[19px]">
              Découvrez une nouvelle façon de développer votre activité : simplifiez-vous
              la vie et attirez plus de clients grâce à notre plateforme dédiée à l'essor
              de votre entreprise. Connectez-vous avec des clients qui cherchent
              exactement ce que vous offrez. Commencez dès aujourd'hui à bâtir des
              relations durables et à propulser votre activité vers de nouveaux horizons !
            </p>
            <button
              type="button"
              aria-label="inscription professsionnel"
              onClick={animationToInscription}
              className="text-[#0F0F0F] text-sm not-italic font-normal leading-[normal]; mb-4 bg-[#F6F0E8] md:py-[14px] py-2 text-center md:px-6 md:text-xl px-3 w-50 border border-white-500 rounded-[30px] font-roboto mt-9">
              S'inscrire gratuitement
            </button>
          </div>
        </section>
      </div>
    </>
  );
}

export default Hero;
