import { useState, useEffect, useContext } from "react";
import ChooseAnEmployeeCard from "Component/Reservation/ChooseAnEmployeeCard";
import SlotPicker from "Component/Reservation/SlotPicker";
import PrestationModal from "./PrestationModal";
import { Link, useNavigate } from "react-router-dom";
import prestationContext from "Context/prestationContext";
import SalonDataContext from "Context/SalonDataContext";
import MapGoogle from "Component/utils/MapGoogle";
import Stepbar from "./Stepbar";
import { toast } from "react-hot-toast";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "Component/ui/dialog";
//icons
import { HiPlus  } from "react-icons/hi2";
import { HiOutlineX } from "react-icons/hi";
import { FaStar } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";

const DashBoard = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { salon } = useContext(SalonDataContext);
  const [salonLocation, setSalonLocation] = useState([]);
  const {
    setSelectedPrestation,
    employees,
    amountToPayNow,
    totalPrice,
    amountToPayLater,
    selectedPrestation,
    selectedFreeSlot,
    setSelectedFreeSlot,
    selectedStaff,
    selectedDurationOfPrestation,
    setSelectedDurationOfPrestation,
    setSelectedStaff,
    setFreeSlots,
    setSelectedindexOfPrestation,
    selectedindexOfPrestation,
    handleCheckboxChangeopen,
  } = useContext(prestationContext);
  const [preView, setPreView] = useState(false);
  const [step, setStep] = useState(1);

  // set salon GPS position to display a Google Map
  useEffect(() => {
    if (salon.position) {
      setSalonLocation([
        {
          address: salon.address,
          lat: salon.position.lat,
          lng: salon.position.lng,
        },
      ]);
    }
  }, [salon]);

  const deletePrestation = (prestationUid) => {
    let indexToDelete = selectedPrestation.findIndex(
      (item) => item._id === prestationUid
    );
    if (selectedPrestation.length === 1) {
      let newSelectedPrestation = [...selectedPrestation];
      newSelectedPrestation.splice(indexToDelete, 1);
      setSelectedPrestation(newSelectedPrestation);
      setStep(1);
    }
    if (indexToDelete !== -1) {
      let newSelectedPrestation = [...selectedPrestation];
      newSelectedPrestation.splice(indexToDelete, 1);
      setSelectedPrestation(newSelectedPrestation);
    }
  };

  const getEmployeeName = (id) => {
    let employee = employees.find((item) => item._id === id);
    return employee?.fullName;
  };

  // Function to convert duration to minutes
  const durationToMinutes = (duration) => {
    if (typeof duration === "string" && duration.includes(":")) {
      const [hours, minutes] = duration.split(":");
      return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
    } else {
      // Handle the case when the duration is not in the expected format
      console.error("Invalid duration format:", duration);
      // You might return a default value or throw an error, depending on your requirements.
      return 0; // Return 0 minutes as a default, for example.
    }
  };

  // Function to convert minutes back to duration format
  const minutesToDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
  };

  // Sum the durations in minutes
  const totalMinutes = selectedPrestation.reduce(
    (acc, item) => acc + durationToMinutes(item?.duration),
    0
  );

  const showButton =
    step === 2 &&
    selectedPrestation &&
    selectedPrestation.length > 0 &&
    selectedPrestation.every((item) => item.newSlot);

  // Convert the total duration back to the desired format => (hh:mm)
  const totalDuration = minutesToDuration(totalMinutes);

  // reset data when user go back to step 1
  useEffect(() => {
    const removeNewSlotFromAllItems = () => {
      const updatedData = selectedPrestation.map((item) => {
        // Create a new object for each item without the newSlot property
        const newItem = { ...item, newSlot: undefined };
        return newItem;
      });

      // Update the state with the modified array
      setSelectedPrestation(updatedData);
    };
    if (selectedPrestation.length > 0 && step === 1) {
      removeNewSlotFromAllItems();
      setSelectedindexOfPrestation(0);
    }
    if (step === 1) {
      setFreeSlots([]);
    }
  }, [step]);

  return (
    <div className="h-full bg-cover ">
      <div className="w-full backdrop-blur-md xl:py-[2rem] xl:px-[7.5rem] py-[1rem] md:px-[2rem] px-0 min-h-screen">
        <div className="linear-background rounded-3xl md:p-[3.4rem] p-4 max-w-[75rem] m-auto  md:bg-white md:bg-opacity-20 md:backdrop-blur-lg drop-shadow-lg  relative">
          
          <Stepbar step={step}/>

          <h1 className="text-beige font-bold font-syne text-2xl ">
            Prestation sélectionnée
          </h1>
          <div
            className={`flex xl:flex-row  justify-between gap-10 my-[2rem] ${
              step === 2 ? "flex-col-reverse" : "flex-col"
            }`}>
            {/* Choose an Employee  for your prestation*/}
            {step === 1 && (
              <div className="flex flex-col grow gap-10  ">
                {salon?.staff?.length > 0 &&
                  selectedPrestation?.map((prestation, index) => (
                    <ChooseAnEmployeeCard
                      key={prestation._id + prestation.uid}
                      {...prestation}
                      index={index}
                      onDelete={deletePrestation}
                    />
                  ))}
                <div>
                  {/* Ajouter une prestation */}
                  <Dialog
                    open={isModalOpen}
                    onOpenChange={setIsModalOpen}
                    className="!bg-slate-400">
                    <DialogTrigger aria-label="Ajouter une prestation">
                      <div className="hidden md:flex w-52 text-beige underline underline-offset-2 cursor-pointer  md:gap-2  items-center   p-2 rounded-3xl ">
                        <HiPlus size={20} /> Ajouter un prestation
                      </div>
                    </DialogTrigger>

                    <DialogContent className="bg-beige w-full md:w-10/12 max-w-[1400px] max-h-[calc(100dvh_-_140px)] overflow-y-scroll  !rounded-3xl">
                      <DialogTitle>
                        <p className="hidden md:block font-roboto text-base font-bold  ">
                          Ajouter prestation
                        </p>
                      </DialogTitle>
                      {/* Add a prestation modal*/}

                      <PrestationModal setIsModalOpen={setIsModalOpen} />
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            )}
            {/* Choose a slot for your prestation */}
            {step === 2 && (
              <SlotPicker selectedDurationOfPrestation={selectedDurationOfPrestation} />
            )}

            {/* Display all the prestation in a resume card */}
            <div className="bg-beige text-black rounded-3xl p-6 h-fit overflow-y-auto grow md:w-[500px] mx-auto w-full">
              <h2 className="font-bold text-2xl  font-syne">{salon.name}</h2>
              <div className="flex flex-row gap-2 mb-6 text-[#82786E]  ">
                {salon.rating?.length > 0 && (
                  <>
                    <span>
                      <FaStar size={18} />
                    </span>
                    <span> {(salon?.rating?.length && salon.rating) || "?"}/5</span>
                    <span> ({salon?.reviews?.length})</span>
                    <span>&#x2022;</span>
                  </>
                )}

                <span>
                  <Dialog className="!bg-slate-400">
                    <DialogTrigger aria-label="Ajouter une prestation">
                      <p>{salon.address}</p>
                    </DialogTrigger>

                    <DialogContent className="p-0 no-scrollbar gap-0 flex flex-start flex-col bg-beige w-full h-full md:w-10/12 max-w-[1400px] max-h-[calc(100dvh_-_140px)] overflow-y-scroll !rounded-3xl">
                      <DialogTitle className="h-fit p-6">
                        <p className="font-bold text-2xl  font-syne">{salon.name}</p>
                        <p>{salon.address}</p>
                      </DialogTitle>
                      <div className="w-full h-full rounded-3xl overflow-hidden">
                        <MapGoogle markers={salonLocation} />
                      </div>
                      {/* Add a prestation modal*/}
                    </DialogContent>
                  </Dialog>
                </span>
              </div>
              <hr className="bg-[#D0CBC4] h-[1px] mt-3" />
              {selectedPrestation?.map((item, index) => {
                const isItemNewSlot = item.newSlot;

                const [hours, minutes] = item.duration.split(":").map(Number);
                const totalMinutes = hours * 60 + minutes;

                return (
                  <div
                    className={`flex flex-col w-full mt-6    ${
                      step === 2 && "hover:font-bold cursor-pointer"
                    }  ${
                      step === 2 && index === selectedindexOfPrestation
                        ? "font-extrabold"
                        : ""
                    } `}
                    key={index}
                    onClick={() => handleCheckboxChangeopen(item, index)}>
                    <div className="flex flex-row gap-2 font-roboto w-full items-start justify-between ">
                      <div className="flex flex-row gap-2 items-center">
                        {step === 2 && (
                          <div>
                            {isItemNewSlot ? (
                              <FaCheckCircle color="green " />
                            ) : (
                              index === selectedindexOfPrestation && (
                                <CiEdit color="green " size={20} />
                              )
                            )}
                          </div>
                        )}

                        <div className="flex flex-col">
                          <p className=" flex-auto text-base capitalize">{item.name}</p>
                          <div className="font-roboto text-sm flex flex-row justify-start text-[#82786E] gap-2">
                            <p>{totalMinutes} mn </p>
                            {employees.length > 0 && (
                              <>
                                {" "}
                                <span>&#x2022;</span>
                                <p className="capitalize">
                                  {item.idStaff
                                    ? getEmployeeName(item.idStaff)
                                    : "Pas de préférence"}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between">
                        <p className="text-right mt-3">{item.price} CHF </p>
                        <HiOutlineX
                          color="#4A4A49"
                          size={20}
                          className="cursor-pointer hover:border-b-slate-900 mt-3 hover:border-b-2 hover:duration-200 "
                          onClick={() => deletePrestation(item._id)}
                        />
                      </div>
                    </div>
                    <hr className="bg-[#D0CBC4] h-[1px] mt-4 mb-4" />
                  </div>
                );
              })}
              <div className=" flex justify-between  font-roboto font-bold text-lg">
                <p>Total</p>
                <p> {totalPrice} CHF</p>
              </div>
              <hr className="bg-[#D0CBC4] h-[1px] mt-4 mb-4" />
              {salon.wantADeposit ? (
                <>
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-col">
                      <p className="text-lg font-bold font-roboto">
                        À payer maintenant ({salon.wantADeposit})
                      </p>
                      <p className="text-taupe text-sm -mt-1">
                        Accompte demandé par le salon
                      </p>
                    </div>
                    <p className="text-lg font-bold font-roboto text-right">
                      {amountToPayNow} CHF
                    </p>
                  </div>
                  <hr className="bg-[#D0CBC4] h-[1px] mt-4 mb-4" />
                  <div className="flex flex-row justify-between">
                    <p className="text-lg font-roboto">Reste à payer sur place</p>
                    <p className="text-lg font-roboto text-right">
                      {amountToPayLater} CHF
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="flex justify-between gap-2">
            {/* Ajouter une prestation Mobile */}
            {step === 1 && (
              <div
                className=" flex md:hidden text-beige cursor-pointer items-center md:clear-effect  glass-effect p-2 pr-4 rounded-3xl"
                onClick={() => setPreView(!preView)}>
                <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
                  <DialogTrigger aria-label="Ajouter une prestation">
                    <div className="md:hidden flex cursor-pointer gap-2  items-center rounded-3xl ">
                      <HiPlus size={20} /> Ajouter une prestation
                    </div>
                  </DialogTrigger>

                  <DialogContent className="bg-beige w-full md:w-10/12 max-w-[1400px] max-h-[calc(100dvh_-_140px)] overflow-y-scroll !rounded-3xl">
                    <DialogTitle>
                      <p className="hidden md:block font-roboto text-base font-bold  ">
                        Ajouter prestation
                      </p>
                    </DialogTitle>
                    {/* Add a prestation modal*/}

                    <PrestationModal setIsModalOpen={setIsModalOpen} />
                  </DialogContent>
                </Dialog>
              </div>
            )}
            <div className="flex gap-2 justify-between grow">
              {step === 1 && (
                <button
                  type="button"
                  onClick={() => navigate(`/salon/${salon._id || ""}`)}
                  className="  md:flex  text-black  bg-[#F6F0E8]  cursor-pointer    items-center px-3 py-2   md:px-6 md:py-3 rounded-3xl  border-2 border-black font-roboto text-base">
                  Précédent
                </button>
              )}
              {step === 2 && (
                <button
                  type="button"
                  onClick={() => setStep(1)}
                  className="  flex  text-black  bg-[#F6F0E8]  cursor-pointer    items-center  px-3 py-2   md:px-6 md:py-3 rounded-3xl  border-2 border-black font-roboto text-base">
                  Précédent
                </button>
              )}
              {step === 1 && selectedPrestation.length > 0 && (
                <button
                  type="button"
                  onClick={() => setStep(2)}
                  className="md:flex text-beige bg-black  cursor-pointer    items-center  px-3 py-2   md:px-6 md:py-3 rounded-3xl  border-2 border-black font-roboto text-base">
                  Suivant
                </button>
              )}
              {showButton && step === 2 && (
                <Link
                  className="px-10 py-3 text-beige bg-black rounded-3xl text-roboto cursor-pointer"
                  to="/stp">
                  Suivant
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
