import React, { useState, useEffect, useRef, useContext } from "react";
import institute from "../../dummyData/instituteType.json";
import ProCard from "../utils/ProCard";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import Datacontext from "../../Context/Datacontext";
import LoadingCard from "./LoadingCard";

const ProPartners = () => {
  const cardContainerRef = useRef(null);
  const categoriesContainerRef = useRef(null);

  const handleHorizontalScroll = (direction) => {
    const container = cardContainerRef.current;
    if (container) {
      const scrollAmount = direction === "next" ? 300 : -300;
      container.scrollLeft += scrollAmount;
    }
  };
  const handleCatHorizontalScroll = (direction) => {
    const container = categoriesContainerRef.current;
    if (container) {
      const scrollAmount = direction === "next" ? 200 : -200;
      container.scrollLeft += scrollAmount;
    }
  };

  const [activeType, setActiveType] = useState("coiffeur");
  const categoryDescription = useRef(null);

  const handleActiveTab = (e, arg) => {
    const procard = document.querySelector(".procard");
    setActiveType(arg);
    if(procard)  procard.scrollLeft -= 100000;
  };
  const { fetch, institutes, fetching } = useContext(Datacontext);
  useEffect(() => {
    fetch(true,{ type: "coiffeur" }, false, true, 0, 5);
  }, [0]);
  useEffect(() => {
    fetch(true,{ type: activeType }, false, true, 0, 5);
  }, [activeType]);

  return (
    <section className="blur-effect pb-4 w-screen overflow-hidden h-fit">
      <h3 className="font-syne text-2xl pt-10 pb-4 px-6 text-white tracking-widest mt-12">
        Nos professionnels
      </h3>

      <article className="h-[90%] mt-4 ">
        <div className="flex flex-col">
          <nav className="">
            <div id="institute_type" className="relative ">
              <div
                ref={categoriesContainerRef}
                style={{ scrollBehavior: "smooth" }}
                className="flex flex-row overflow-scroll no-scrollbar  gap-3 mx-16 self-center ">
                {institute.categories.map((cat, index) => (
                  <button
                    type="button"
                    onClick={(e) => handleActiveTab(e, cat.type)}
                    key={index}
                    data-type={cat.type}
                    className={`text-white font-syne text-center px-6 cursor-pointer  linear-background rounded-3xl whitespace-nowrap snap-start ${
                      activeType === cat.type ? "btn-dark " : ""
                    }`}>
                    {cat.name}
                  </button>
                ))}
              </div>
              <button
                onClick={() => handleCatHorizontalScroll("next")}
                className="text-white flex justify-center items-center absolute z-10 right-5 top-[-1px]  aspect-square   max-h-[100%] min-h-[100%] rounded-full  ">
                <GrFormNextLink
                  
                  style={{ "--tw-backdrop-blur": "35px", zIndex: 10 }}
                  className="cursor-pointer glass-effect  w-fit h-fit rounded-full p-2 flex items-center gap-1 active:border"
                />
              </button>

              <button
                onClick={() => handleCatHorizontalScroll("previous")}
                className="text-white flex justify-center items-center absolute z-10 left-5 top-[-1px]  aspect-square   max-h-[100%] min-h-[100%] rounded-full ">
                <GrFormPreviousLink
                 
                  style={{ "--tw-backdrop-blur": "35px", zIndex: 10 }}
                  className="cursor-pointer glass-effect w-fit h-fit rounded-full p-2 flex items-center gap-1 active:border   "
                />
              </button>
            </div>
          </nav>

          {institute.categories.map((cat, index) => {
            return (
              activeType === cat.type && (
                <div
                  key={index}
                  className="p-6 flex flex-col font-syne  text-opacity-70 my-5 mx-6 rounded-3xl  relative linear-background text-white max-h-48 overflow-auto ">
                  <span
                    ref={categoryDescription}
                    className="font-medium xs:max-sm:max-h-30  transition-all duration-300">
                    {cat.description}
                  </span>
                </div>
              )
            );
          })}
        </div>
        {institutes.length > 0 && <div className="relative flex justify-center h-fit">
          <ul
            className="flex flex-row justify-start snap-x snap-mandatory self-center w-[65%]  overflow-x-auto pt-6 gap-6 procard cs px-3 transition duration-300 z-0 no-scrollbar"
            ref={cardContainerRef}
            style={{ scrollBehavior: "smooth" }}>
            {fetching ? (
              <LoadingCard />
            ) : (
              institutes.map((shop, index) => {
                return activeType === shop.type && <ProCard {...shop} key={index} />;
              })
            )}
          </ul>
          <button
            onClick={() => handleHorizontalScroll("next")}
            className="text-white flex justify-center items-center absolute z-10 right-5 top-[45%]">
            <GrFormNextLink
            
              style={{ "--tw-backdrop-blur": "35px", zIndex: 10 }}
              className="cursor-pointer glass-effect  w-fit h-fit rounded-full p-3 flex items-center gap-1 active:border"
            />
          </button>

          <button
            onClick={() => handleHorizontalScroll("previous")}
            className="text-white flex justify-center items-center absolute z-10 left-5 top-[45%]">
            <GrFormPreviousLink
              
              style={{ "--tw-backdrop-blur": "35px", zIndex: 10 }}
              className="cursor-pointer glass-effect w-fit h-fit rounded-full p-3 flex items-center gap-1 active:border   "
            />
          </button>
        </div>}
      </article>
    </section>
  );
};

export default ProPartners;
