import CalendarAgenda from "./CalendarAgenda";
import { useContext, useEffect } from "react";
import AgendaContext from "Context/AgendaContext";
import SingleCalendarAgenda from "./SingleCalendarAgenda";

const DayAgenda = () => {
  const {
    Employee,
    SelectedEmpl,
    FetchEmployeeData,
    FetchAllEmployeeData,
    getFullNameById,
    RulerData,
  } = useContext(AgendaContext);
  useEffect(() => {
    if (SelectedEmpl === "All") {
      FetchAllEmployeeData();
    } else {
      FetchEmployeeData(SelectedEmpl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SelectedEmpl]);
  


  let ruler = [];
  if (RulerData && RulerData[0]) {
    for (let x = RulerData[0]; x <= RulerData[1]; x++) {
      ruler.push(
        <div key={x} className="border-t-2 border-white h-[300px] px-4 py-1 text-white">
          <h6>{x}h</h6>
        </div>
      );
    }
  } else {
    for (let x = 8; x < 24; x++) {
      ruler.push(
        <div key={x} className="h-[300px] px-4 py-1 text-white">
          <h6>{x}h</h6>
        </div>
      );
    }
  }

  return (
    <div id='test' className="  overflow-x-scroll">
      <div className="text-sand my-4  text-2xl font-extrabold flex gap-4  w-full pl-[4.55rem]  ">
        {SelectedEmpl === "All" ? (
          Employee.map((emp) => (
            <span
              key={emp._id}
              className="grow text-center pt-3 border-sand border-t-2 border-opacity-30 min-w-[490px]">
              {emp.fullName}
            </span>
          ))
        ) : (
          <span className="grow text-center pt-3 border-sand border-t-2 border-opacity-30">
            {getFullNameById(Employee, SelectedEmpl)}
          </span>
        )}
      </div>
      <div className="w-full py-16 flex">
        <div className="flex flex-col w-[4.55rem]">{ruler.map((rule) => rule)}</div>
        <div className="bg-black grow flex gap-4 ">
          {SelectedEmpl === "All" ? (
            Employee.map((item) => (
              <CalendarAgenda key={item._id} Employee={item._id} />
            ))
          ) : (
            <SingleCalendarAgenda />
          )}
        </div>
      </div>
    </div>
  );
};

export default DayAgenda;
