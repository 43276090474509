import React, { useContext, useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
// import TempImg from "../../assets/TempSearch.jpeg";
import { useNavigate, Link } from "react-router-dom";
import SalonDataContext from "Context/SalonDataContext";
import prestationContext from "Context/prestationContext";
import { v4 as uuidv4 } from "uuid";

const InstituteCard = ({ institute, handleSelectedCard, selectedCard, setMiddle }) => {
  const navigate = useNavigate();

  const { fetchSalonByid } = useContext(SalonDataContext);
  const { setSelectedPrestation } = useContext(prestationContext);

  const { name, address, prestations, _id, rating, reviews } = institute;
  const [imgErr, setimgErr] = useState("");

  const handleError = () => {
    setimgErr("https://via.assets.so/img.jpg?w=400&h=300");
  };
  const handleSetMiddle = (item) => {
    setMiddle([
      {
        address: item.address,
        lat: item.position.lat,
        lng: item.position.lng,
      },
    ]);
  };

  const handleSelectedPrestation = async (prestationId) => {
    // if a prestations is selected, fetch the salon by id to populate the localStorage
    if (_id) await fetchSalonByid(_id);
    let newId = uuidv4() + Date.now().toString();
    let _prestationArray = [...institute.prestations];
    let newPrestation = _prestationArray.find((item) => item.id === prestationId);
    let _prestation = { ...newPrestation, idStaff: "", uid: newId };
    setSelectedPrestation((prev) => [...prev, _prestation]);
    navigate("/reservation");
  };
  const getPrestationDuration = (duration) => {
    return +duration?.split(":")[1] + +duration?.split(":")[0] * 60;
  };

  const [randomIndex, setRandomIndex] = useState(0);

  // Function to generate a random image [index]
  const getRandomIndex = () => {
    return Math.ceil(Math.random() * (institute?.photos.length || 0));
  };

  useEffect(() => {
    // Update the random index when the component mounts
    setRandomIndex(getRandomIndex());
  }, [institute]);

  // calculate the average rating of the institutes
  const averageRatingInstitutes = () => {
    if (!institute || !institute.reviews || institute.reviews.length === 0) {
      return null; // return nothing  if no reviews are available
    }

    const totalRating = institute.reviews.reduce(
      (sum, review) => sum + review.ratingRev,
      0
    );
    return (totalRating / institute.reviews.length).toFixed(1);
  };

  return (
    <div
      onClick={(e) => {
        handleSelectedCard(address, e);
        handleSetMiddle(institute);
      }}
      className={` h-[30%]   mb-4 rounded-3xl glass-effect flex flex-row min-h-[212px] ${
        address === selectedCard ? "border-2 border-white" : ""
      }`}>
      {/* picture div */}
      <div className="w-[40%] flex justify-center items-center overflow-hidden">
        <img
          src={
            imgErr
              ? "https://via.assets.so/img.jpg?w=400&h=300"
              : institute?.photos[0]
              ? institute?.photos[0]
              : "https://via.assets.so/img.jpg?w=400&h=300"
          }
          alt="institute"
          style={{ aspectRatio: "16/9", width: "100%", objectFit: "contain" }}
          onError={handleError}
        />
      </div>
      {/* info div */}
      <div className="flex flex-col p-5  w-[60%] ">
        <div className="flex flex-row text-white font-syne text-xl justify-between">
          <div>{name}</div>
          <div className="flex items-center font-roboto text-sm gap-1">
            <AiFillStar /> {rating} ({institute && institute.reviews})
          </div>
        </div>
        <div className="text-xs text-beige font-syne mb-5">{address}</div>
        {prestations &&
          prestations?.map(
            (item, index) =>
              index < 2 && (
                <div
                  aria-label={`choisir ${item.name}, durée: ${item.duration}mn, prix: ${item.price} CHF `}
                  className="flex flex-col text-xs text-beige mb-4"
                  key={item._id}>
                  <div className="flex flex-row justify-between w-full">
                    <span
                      className="hover:underline cursor-pointer"
                      onClick={() => handleSelectedPrestation(item.id)}>
                      {item.name}
                    </span>
                    <span className="cursor-default">{item.price} €</span>
                  </div>
                  <span className="cursor-default">
                    {getPrestationDuration(item.duration)} mn
                  </span>
                </div>
              )
          )}

        <Link
          to={`/salon/${_id}`}
          className="self-start -translate-y-2 font-roboto text-sm text-beige underline underline-offset-2">
          Plus de prestations
        </Link>
      </div>
    </div>
  );
};

export default InstituteCard;
