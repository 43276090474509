import React from 'react'
import HeaderCGU from 'Component/Privacy/HeaderCGU';
import FooterCGU from 'Component/Privacy/FooterCGU';

const Privacy = () => {
  return (
	<>
	<HeaderCGU />
	<div className="h-full bg-[#FAF8F6] mt-[4rem]">
		<div className="w-full p-4 md:p-12 px-8 md:px-20  text-[#212128]">
			<div className="my-6">
				<p>Accueil {'>'} Privacy Policy</p>
			</div>
			<div className="my-6">
				<h3>Politique de Confidentialité de Stunify</h3>
			</div>
			<div className='h-full rounded  p-8 border-solid border-[2px] border-[#eeedec] bg-white font-syne'>
				<div className="my-4 gap-2">
					<h5 className='font-bold my-2'> 1. Introduction</h5>
					<p className="ml-6">
						Chez Stunify, respecter la vie privée et sécuriser les informations personnelles de nos utilisateurs sont nos priorités absolues.
						Cette politique explique les pratiques de Stunify en matière de traitement des données personnelles conformément à la
						législation suisse sur la protection des données.
					</p>
				</div>
				<div className="my-4 gap-2">
					<h5 className='font-bold my-2'> 2. Collecte des Données</h5>
					<p className="ml-6">Nous collectons les informations suivantes des utilisateurs:</p>
					<ul className='list-disc list-inside my-2'>
						<li>Informations fournies par l'utilisateur lors de l'inscription (par exemple, nom, adresse e-mail, numéro de téléphone).</li>
						<li>Informations transactionnelles et de paiement, si applicable.</li>
						<li>Informations relatives à l'utilisation de la plateforme, collectées automatiquement (telles que les logs de connexion,
							les adresses IP, et les interactions avec le site).
						</li>
					</ul>
				</div>
				<div className="my-4 gap-2">
					<h5 className='font-bold my-2'> 3. Utilisation des Données</h5>
					<p className="ml-6">Les données collectées sont utilisées pour:</p>
					<ul className='list-disc list-inside my-2'>
						<li>Fournir et gérer les services demandés.</li>
						<li>Communiquer avec vous concernant votre compte ou nos services.</li>
						<li>Améliorer et personnaliser l'expérience utilisateur.</li>
						<li>Respecter nos obligations légales et réglementaires.</li>
					</ul>
				</div>
				<div className="my-4 gap-2">
					<h5 className='font-bold my-2'> 4. Partage et Divulgation des Données</h5>
					<p className="ml-6">Nous ne partageons vos informations personnelles avec des tiers que dans les cas suivants:</p>
					<ul className='list-disc list-inside my-2'>
						<li>Prestataires de services qui travaillent pour notre compte, sous des accords de confidentialité stricts.</li>
						<li>Pour se conformer à la loi ou en réponse à une procédure judiciaire.</li>
						<li>Dans le cadre de transactions d'entreprise, comme une fusion ou une vente d'actifs.</li>
					</ul>
				</div>
				<div className="my-4 gap-2">
					<h5 className='font-bold my-2'> 5. Sécurité des Données</h5>
					<p className="ml-6">Stunify met en œuvre des mesures de sécurité technologiques et organisationnelles avancées pour protéger les données
						personnelles contre l'accès non autorisé, la modification, la divulgation ou la destruction.</p>
				</div>
				<div className="my-4 gap-2">
					<h5 className='font-bold my-2'> 6. Droits des Utilisateurs</h5>
					<p className="ml-6">Conformément à la législation suisse, vous avez le droit de:</p>
					<ul className='list-disc list-inside my-2'>
						<li>Accéder à vos données personnelles.</li>
						<li>Demander la correction ou la suppression de vos données personnelles</li>
						<li>Opposer-vous au traitement de vos données personnelles</li>
						<li>Demander la limitation du traitement de vos données personnelles</li>
						<li>Porter plainte auprès de l'autorité de protection des données si vous estimez que vos droits ne sont pas respectés.</li>
					</ul>
				</div>
				<div className="my-4 gap-2">
					<h5 className='font-bold my-2'> 7. Modifications de la Politique de Confidentialité</h5>
					<p className="ml-6">Nous pouvons mettre à jour cette politique de confidentialité de temps en temps. Les modifications seront publiées sur notre plateforme 
						avec une indication de la date de la dernière mise à jour.</p>
				</div>
				<div className="my-4 gap-2">
					<h5 className='font-bold my-2'> 8. Contact</h5>
					<p className="ml-6">Pour toute question relative à cette politique ou vos données personnelles, veuillez contacter notre délégué à la protection des
						données à <a className="font-bold" href="mailto:sav@stunify.io">sav@stunify.io</a>.</p>
				</div>
			</div>
		</div>
	</div>
	<FooterCGU />

	</>
	
  );
}

export default Privacy