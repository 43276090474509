import React, { useState } from "react";
import { VscClose } from "react-icons/vsc";

const SortMob = ({ Sort, setSort, SortType, setSortType }) => {

  return (
    Sort && (
      <div
        className={`bg-[#F6F0E8] h-[50%] w-[95%] absolute bottom-0 left-2 p-2 gap-2 text-black px-6 z-10 flex flex-col rounded-t-3xl max-h-[272px]  `}>
        <div className="flex justify-between pl-8 py-3 pr-6 text-black">
          <h6 className="font-bold">Filter</h6>
          <button onClick={() => setSort(!Sort)}>
            <VscClose size={24} />
          </button>
        </div>
        <hr />
        <div className="py-6 px-3 gap-2 flex flex-col mb-3">
          <button
            onClick={() => setSortType("Pertinence")}
            className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 border-black rounded-3xl w-fit h-fit 
            ${SortType === "Pertinence" ? "text-white bg-black" : " text-black"}`}>
            Pertinence
          </button>
          <button
            onClick={() => setSortType("rating")}
            className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 border-black rounded-3xl w-fit h-fit 
                ${SortType === "rating" ? "text-white bg-black" : " text-black"}`}>
            Les mieux notés
          </button>
          <button
            onClick={() => setSortType("prince")}
            className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 border-black rounded-3xl w-fit h-fit 
                ${SortType === "prince" ? "text-white bg-black" : " text-black"}`}>
            Du plus cher au moins cher
          </button>
        </div>
      </div>
    )
  );
};

export default SortMob;
