import { createContext, useState, useMemo } from "react";
import axiosInstance from "config/Interceptor";
const institutesContext = createContext();
function DataProvider({ children }) {
  const [FilterList, setFilterlist] = useState({
    name: "all",
    city: "all",
    type: "all",
    maxPrice: 0,
    clientGender: "all",
    address: "all",
    payment: "all",
    promotion: false,
  });
  const [institutes, setInstitutes] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  //  list prestation for profile
  const [loadingPrestation, setLoadingPrestation] = useState(true); // Introduce a loading state for prestation list
  const [prestationList, setPrestationList] = useState([]);
  const fetchPrestationList = async () => {
    try {
      setLoadingPrestation(true);
      const res = await axiosInstance.get(
        `/api/v1/secure/reservation/client/list/idclient`
      );
      setPrestationList(res.data);
      setLoadingPrestation(false);
    } catch (error) {
      // Handle error here
      console.error("Error fetching data:", error);
      setLoadingPrestation(false);
    }
  };
  // get staff for update prstation from profile page
  const [AllstaffForUpdate, setAllStaffForUpdate] = useState([]);
  const [loading, setLoading] = useState(true); // Introduce a loading state

  const getStaffForUpdate = async (institute, startTimer) => {
    try {
      setLoading(true);
      if (institute && startTimer) {
        const res = await axiosInstance.get(
          `/api/v1/secure/slots/staff/free?idInstitute=${institute}&startTime=${
            startTimer ? startTimer : ""
          }`
        );
        setAllStaffForUpdate(res.data);
        setLoading(false); // Set loading state to false when data is fetched
      }
    } catch (error) {
      // Handle error here
      console.error("Error fetching data:", error);
      setLoading(false); // Set loading state to false in case of an error
    }
  };

  const [staffIdForUpdate, setStaffIdForUpdate] = useState("");
  const [selectedStaff, setSelectedStaff] = useState(""); // State to store the selected staff
  const [prestationToUpdate, SetprestationToUpdate] = useState({}); // State to store the selected prestation
  const [idPrestation, setIdPrestation] = useState(""); // State to store the selected prestation
  const [fetching, setFetching] = useState(false);
  // send update reservation req to backend
  const updatePrestationOFreservation = async (paylaod) => {
    try {
      const res = await axiosInstance.put(
        `/api/v1/secure/reservation/update/${idPrestation}`,
        paylaod
      );
    } catch (error) {
      // Handle error here
      console.error("Error fetching data:", error);
    }
  };

  const fetchInstitute = async (
    valid,
    FilterList,
    fetchMore = false,
    hasMore = hasMore,
    page = 0,
    perPage = 10
  ) => {
    if (hasMore) {
      setFetching(true);
      let url = valid ? `/api/v1/institute/valid/` : `/api/v1/secure/institute/invalid/`;
      const queryParams = [];
      for (const [key, value] of Object.entries(FilterList)) {
        if (value && value !== "all") {
          queryParams.push(`${key}=${encodeURIComponent(value)}`);
        }
      }
      if (queryParams.length > 0) {
        url += "filter?" + queryParams.join("&") + `&page=${page}&perPage=${perPage}`;
      } else url += `?page=${page}&perPage=${perPage}`;
      const res = await axiosInstance.get(url);
      if (fetchMore) {
        setInstitutes((institutes) => institutes.concat(res.data.list));
      } else setInstitutes(res.data.list);
      if (
        res.data.pages - 1 === page ||
        res.data.pages === 0 ||
        res.data.list?.length === 0
      ) {
        setHasMore(false);
      }

      setFetching(false);
    }
  };

  const fetchMore = (FilterList, hasMore, page, perPage, fetchMore = true) => {
    fetchInstitute(FilterList, fetchMore, hasMore, page, perPage);
  };

  // delete reservation (cancel reservation)
  const handleDeleteRnd = (idReservation, idSlot) => {
    let body = { idSlot: idSlot };

    axiosInstance
      .put(`/api/v1/secure/reservation/canceled/${idReservation}`, body)
      .then((response) => {
        setPrestationList((prevList) => {
          return prevList.filter(
            (item) => item.idReservation !== idReservation && item.slots._id !== idSlot
          );
        });
        fetchPrestationList();
      })
      .catch((error) => {
        console.error("API request failed:", error);
      });
  };

  const valueToShare = useMemo(
    () => ({
      fetching,
      setFilterlist,
      FilterList,
      fetch: fetchInstitute,
      setIdPrestation,
      institutes,
      staffIdForUpdate,
      setStaffIdForUpdate,
      setSelectedStaff,
      selectedStaff,
      updatePrestationOFreservation,
      prestationToUpdate,
      SetprestationToUpdate,
      prestationList,
      fetchPrestationList,
      getStaffForUpdate,
      AllstaffForUpdate,
      setAllStaffForUpdate,
      loading,
      fetchMore,
      hasMore,
      setHasMore,
      fetching,
      loadingPrestation,
      setLoading,
      handleDeleteRnd,
    }),
    [
      fetching,
      prestationToUpdate,
      staffIdForUpdate,
      institutes,
      prestationList,
      FilterList,
      hasMore,
      fetching,
      AllstaffForUpdate,
      getStaffForUpdate,
    ]
  );
  return (
    <institutesContext.Provider value={valueToShare}>
      {children}
    </institutesContext.Provider>
  );
}
export { DataProvider };
export default institutesContext;
