import { useContext, useEffect } from "react";
import HeaderPro from "Component/Header/HeaderPro";
import LoggedHeader from "../Component/Header/LoggedHeader";
import LoggedContext from "../Context/isLogged";
import { Outlet, useMatch } from "react-router-dom";
import NotLoggedFooter from "../Component/Footer/NotLoggedFooter";
import LoggedFooter from "Component/Footer/LoggedFooter";

const UserLayout = () => {
  const { isLogged } = useContext(LoggedContext);
  const homepagePro = useMatch("/pro");
  //set darkTheme
  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.add("dark");
  }, []);

  return (
    <div className="pro-background bg-cover h-screen overflow-y-###disableByDamien###-scroll overflow-x-hidden xs:bg-center">
      {isLogged ? <LoggedHeader /> : <HeaderPro />}

      <main className="dark:text-beige h-fit pt-[107px]">
        <Outlet />
      </main>

      {isLogged && !homepagePro ? <LoggedFooter /> : <NotLoggedFooter />}
    </div>
  );
};

export default UserLayout;
