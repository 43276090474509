import React, { useEffect, useState } from "react";
import { LiaClockSolid } from "react-icons/lia";
import moment from "moment";

function Card({ reservation, selectedReservation, setSelectedReservation, showAll }) {
  const getTotalPrice = () => {
    if (
      reservation.payment &&
      reservation.payment.length > 0 &&
      reservation?.payment[0]?.amountPaid
    ) {
      return reservation.totalPrice - (reservation.payment[0].amountPaid/100);
    } else if(reservation.totalPrice) {
      return reservation.totalPrice;
    } else return 0
  };
  return (
    <div
      onClick={() => setSelectedReservation(reservation)}
      className={
        "flex flex-row cursor-pointer reservation-card mb-2 " +
        (selectedReservation && selectedReservation._id === reservation._id
          ? "active-reservation-card"
          : "")
      }
    >
      {selectedReservation && selectedReservation._id === reservation._id && (
        <span className="bg-[#F6F0E8] w-2"></span>
      )}

      <div className="p-5 flex flex-row w-full font-roboto[#F6F0E8] h-[100px]">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-row items-center justify-between space-x-5  ">
            <LiaClockSolid size={30} />
            <p className="md:text-sm text-xs font-roboto text-center">
              {showAll ? <span>{moment(reservation.startDateTime).format("DD/MM/YY")}<br/></span> : null}
              {moment(reservation.startDateTime).format("HH:mm")}
            </p>
            <p className="lg:text-base text-sm w-fit lg:font-bold font-roboto">
              {reservation.prestation && reservation.prestation.length > 0 ? (
                <span>
                  <span>{reservation.prestation[0].name}</span>{" "}
                  {reservation.prestation.length > 1 ? (
                    <span className="text-xs right-1">
                      (+{reservation.prestation.length - 1})
                    </span>
                  ) : null}
                </span>
              ) : null}
              <br />
              <span className="text-[#A29689] text-xs">
                {reservation.institute?.client?.firstName}{" "}
                {reservation.institute?.client?.lastName}
              </span>
            </p>
          </div>
          <div className="flex flex-row items-center justify-between space-x-5">
            <p className=" font-roboto text-xs ">
              {reservation.customer.firstName} {reservation.customer.lastName}
            </p>
            <p className="text-sm font-bold font-roboto ">
              {getTotalPrice()} CHF
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
