import { useContext, useEffect } from "react";
import UserHeader from "../Component/Header/UserHeader";
import LoggedHeader from "../Component/Header/LoggedHeader";
import LoggedContext from "../Context/isLogged";
import { Outlet } from "react-router-dom";


const HeaderUser = () => {
  const { isLogged } = useContext(LoggedContext);

//set lightTheme
useEffect(() => {
	const root = window.document.documentElement;
	root.classList?.remove('dark');
}, []);


  return (

        <div className="background bg-cover  no-scrollbar overflow-y-scroll overflow-x-hidden  xs:bg-center">
    {isLogged ? <LoggedHeader /> : <UserHeader />} 
          <main className="h-fit mt-20" >
            <Outlet />
          </main>
        </div>
  
  );
};

export default HeaderUser;
