import React from "react";
import { Link } from "react-router-dom";

function AgendaPrevPro() {
  const data = [
    {
      id: 1,
      title: "Système de Prise de Rendez-vous 24/7",
      text: "• Permettez à vos clients de réserver vos services à tout moment, sans intervention manuelle de votre part.",
    },
    {
      id: 2,
      title: "Rappels Automatiques",
      text: "• Intégrez des systèmes de rappels pour réduire le taux de rendez-vous oubliés.",
    },
    {
      id: 3,
      title: "Optimisation du Planning",
      text: "• Utilisez des outils pour une gestion de planning plus efficace, libérant du temps précieux chaque jour.",
    },
    {
      id: 4,
      title: "Plateforme de Feedback",
      text: "• Profitez d'une plateforme qui facilite et valorise les retours positifs de vos clients.",
    },
    {
      id: 5,
      title: "Automatisation Administrative",
      text: "• Réduisez le temps passé sur l'administration grâce à des processus automatisés.",
    },
    {
      id: 6,
      title: "Analyse et Suivi des Performances",
      text: "• Accédez à des tableaux de bord intuitifs pour suivre vos performances, comprendre les préférences de vos clients et ajuster vos offres en conséquence pour maximiser votre succès.",
    },
  ];
  return (
    // <div
    //   className="   backdrop-blur-[25px] h-full w-full trans-bg "
    //   style={{ backgroundColor: "rgba(217, 217, 217, 0.1)" }}
    // >
    <div className="backdrop-blur-[25px] h-full w-full bg-inpro px-4">
      <div className="container mx-auto py-[76px] w-full  ">
        <h2 className="text-[#F6F0E8] text-xl md:text-[35px] lg:text-[45px] not-italic font-bold leading-[normal] font-syne px-2  md:px-0">
          Gérer votre agenda, vos clients, votre caisse et plus 24/24 de où vous le
          souhaitez.
        </h2>

        <div className="flex justify-center items-center   flex-col-reverse md:flex-row mt-20 w-full  h-full ">
          <div className="  flex flex-col-reverse lg:flex-row justify-center   ">
            <img
              src="https://bucket-stunify-app.s3.eu-north-1.amazonaws.com/static/assets/Pro/agenda.png"
              alt=""
              className="   object-fill md:object-contain   w-full  xs:px-2 sm:px-3 md:px-0 lg:w-[70%] h-[564px]  rounded-[30px] mb-8"
            />
            <div className="  pl-0 md:pl-16  ">
              {data.map((item, idx) => (
                <div
                  key={idx}
                  className="grid grid-cols-2 lg:grid-cols-1  px-3  md:px-0 md:items-start items-center justify-center  gap-4 md:gap-6 lg:mb-3  mb-0">
                  <h1 className="text-[#F6F0E8] lg:text-2xl  md:text-xl text-lg  not-italic font-bold leading-[normal] font-syne text-left ">
                    {item.title}
                  </h1>

                  <p className=" text-[#D0CBC4)] text-sm md:text-xs mb-3 md:mb-1 not-italic font-normal leading-[normal]font-roboto">
                    {item.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="  md:mt-0 lg:mt-20 mt-8 w-full  ">
          <Link
            to="/pro/institute-registration"
            className="text-[#0F0F0F]   text-xs not-italic font-normal leading-[normal] mb-4 bg-[#F6F0E8] md:py-[14px] py-2 text-center md:px-6 md:text-xl px-3 w-50 border border-white-500 rounded-[30px] font-roboto ">
            S'inscrire gratuitement
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AgendaPrevPro;
