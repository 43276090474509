// UserAuthContext.js
import axiosInstance from "config/Interceptor";
import moment from "moment";
import React, { createContext, useContext, useEffect, useState, useMemo } from "react";
import toast from "react-hot-toast";

const InstituteAuthContext = createContext();

const defaultInstitute = {
  name: "",
  type: "",
  email: "",
  password: "",
  password_confirmation: "",
  address: "",
  city: "",
  homeService: false,
  position: {},
  businessHours: [
    {
      name: "Lundi",
      isOpen: false,
      lunchBreak: false,
      morningStart: "09:00",
      morningEnd: "",
      eveningStart:"",
      eveningEnd: "19:00",
    },
    {
      name: "Mardi",
      isOpen: false,
      lunchBreak: false,
      morningStart: "09:00",
      morningEnd: "",
      eveningStart:"",
      eveningEnd: "19:00",
    },
    {
      name: "Mercredi",
      isOpen: false,
      lunchBreak: false,
      morningStart: "09:00",
      morningEnd: "",
      eveningStart:"",
      eveningEnd: "19:00",
    },
    {
      name: "Jeudi",
      isOpen: false,
      lunchBreak: false,
      morningStart: "09:00",
      morningEnd: "",
      eveningStart:"",
      eveningEnd: "19:00",
    },
    {
      name: "Vendredi",
      isOpen: false,
      lunchBreak: false,
      morningStart: "09:00",
      morningEnd: "",
      eveningStart:"",
      eveningEnd: "19:00",
    },
    {
      name: "Samedi",
      isOpen: false,
      lunchBreak: false,
      morningStart: "09:00",
      morningEnd: "",
      eveningStart:"",
      eveningEnd: "19:00",
    },
    {
      name: "Dimanche",
      isOpen: false,
      lunchBreak: false,
      morningStart: "09:00",
      morningEnd: "",
      eveningStart:"",
      eveningEnd: "19:00",
    },
  ],
  clientGender: {
    allGender: false,
    femmes: true,
    hommes: false,
    enfants: false,
  },
  description: "",
  photos: [],
  photosFromGgAPI: [],
  prestationsCategories: [
    "forfaits",
    "brushing",
    "Coupe et coiffure",
    "Soin du cheveux et cuir chevelu",
    "Coloration",
    "Mèches",
    "Décoloration",
    "Permanente",
    "Lissage",
  ],
  prestations: [
    {
      name: "",
      description: "",
      category: "",
      duration: "00:00",
      price: 25,
    },
  ],
  payementMethode: ["twint", "paypal", "card", "postfinance" , "klarna"],
  wantADeposit: "30%",
  instalmentPaymentsInMonth: "",
  phoneNumber: "",
  staff: [],
};

export const InstituteAuthProvider = ({ children }) => {
  const [newInstitute, setNewInstitute] = useState();
  const [loading, setLoading] = useState(false);
  const [phoneAvailability, setphoneAvail] = useState(false);
  const checkPhone = async (phone) => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/api/v1/phone/check?phoneNumber=${phone}`);
      setphoneAvail(true);
      setLoading(false);
      // console.log(phoneAvailability);
    } catch (error) {
      setphoneAvail(false);

      setLoading(false);
      // Handle error here
      // console.log(phoneAvailability);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!newInstitute) {
      setNewInstitute(defaultInstitute);
    }
  }, [newInstitute]);

  const valueToShare = useMemo(
    () => ({
      defaultInstitute,
      setphoneAvail,
      phoneAvailability,
      checkPhone,
      loading,
      setLoading,
      newInstitute,
      setNewInstitute,
    }),
    [newInstitute, loading, phoneAvailability]
  );

  return (
    <InstituteAuthContext.Provider value={valueToShare}>
      {children}
    </InstituteAuthContext.Provider>
  );
};

export const useNewInstitute = () => {
  return useContext(InstituteAuthContext);
};
