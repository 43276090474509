import SmSpinner from "Component/ui/SmSpinner";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const AddPrestationModal = ({
  showModal,
  setShowModal,
  prestations,
  updatePrestations,
  idReservation,
}) => {
  const [newPrestations, setNewPrestations] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleOnChange = (e, prestation) => {
    if (e.target.checked) {
      setNewPrestations((newPrestations) => [...newPrestations, prestation]);
    } else {
      setNewPrestations((newPrestations) =>
        newPrestations.filter((pres) => pres.id !== prestation.id)
      );
    }
  };

  const addPrestations = async () => {
    updatePrestations(idReservation, newPrestations);
    setNewPrestations([]);
    setShowModal(false);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-2 border-[#fff] pro-background rounded-lg shadow-lg relative flex flex-col sm:w-[390px] md:w-[70vh] bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold">
                    Ajouter une autre prestation
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-white float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-white h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <h5 className="mb-4 font-roboto dark:text-white">
                    Prestations:
                  </h5>
                  {prestations && prestations.length > 0 ? (
                    <>
                      <ul className="text-sm font-medium border border-gray-200 rounded-lg p-2 bg-[#4A4A4966]">
                        {prestations.map((prestation, i) => (
                          <li
                            key={prestation.uid + prestation.name + i}
                            className="w-full border-gray-200 rounded-t-lg dark:border-gray-600"
                          >
                            <div className="flex items-center pl-3">
                              <input
                                id={"checkbox_" + prestation.name + i}
                                type="checkbox"
                                onChange={(e) => handleOnChange(e, prestation)}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                              />
                              <label
                                htmlFor={"checkbox" + prestation.name + i}
                                className="w-3/4 py-3 ml-4 text-sm font-medium"
                              >
                                {prestation.name}
                              </label>
                              <div className="flex">{prestation.price} CHF</div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : null}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Annuler
                  </button>
                  <button
                    disabled={loading}
                    className="rounded-[30px] bg-[#F6F0E8] text-black font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={addPrestations}
                  >
                    {loading ? (
                      <>
                        <SmSpinner w={5} h={5}/> Ajouter
                      </>
                    ) : (
                      "Ajouter"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};
export default AddPrestationModal;
