import React, { useEffect, useState } from "react";
import {
  BsArrowUpRight,
  BsRocketTakeoff,
  BsRocketTakeoffFill,
} from "react-icons/bs";
import { HiOutlineBanknotes } from "react-icons/hi2";
import { LiaCalendarAltSolid } from "react-icons/lia";
import HalfDoughnutChart from "./HalfDoughnutChart";
import moment from "moment";

function StatsWidget({
  dataProStats,
  monthlyGoal,
  setMonthlyGoal,
  calculateMonthlyGoalPercentage,
  setSelectedYear,
  selectedYear
}) {
  const [years, setYears] = useState([]);

  // Load the value from local storage when the component mounts
  useEffect(() => {
    const savedGoal = localStorage.getItem("monthlyGoal");
    if (savedGoal) {
      setMonthlyGoal(savedGoal);
    }
  }, []);

  useEffect(() => {
    if (dataProStats?.years) setYears(dataProStats.years);
  }, [dataProStats]);

  const handleInputChange = (event) => {
    const newGoal = event.target.value;
    setMonthlyGoal(newGoal);

    // Save the input value to local storage
    localStorage.setItem("monthlyGoal", newGoal);
  };

  const getYearFromData = () => {
    if (!dataProStats) {
      return "Loading..."; // Display a loading message while data is being fetched
    }

    const firstStat = dataProStats.stat?.[0];
    const year = firstStat?._id.year || "N/A";

    return year;
  };

  const handleSelectChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const currentMonth = moment().format("MMM YYYY");
  const currentYear = moment().format("YYYY");
  return (
    <>
      {/* row 1 */}
      <div className="flex flex-row justify-between items-center ">
        <div className="bg-black border border-[#F6F0E8)]  lg:mt-0 rounded-[30px] border-solid  py-3 px-6 place-self-end ">
          <select
            name="year"
            className="bg-black text-white outline-none focus:border-white  md:w-[150px] w-[90px] "
            onChange={handleSelectChange}
            value={selectedYear}
          >
            {years?.length > 0 &&
              years.map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
          </select>
        </div>
        <div className="flex flex-col justify-center items-center ">
          <p>Objectif CA/mois</p>

          <div className="flex justify-between items-center self-stretch border border-[#F6F0E8] p-3 rounded-md border-solid w-[150px] ">
            <input
              type="number"
              value={monthlyGoal}
              onChange={handleInputChange}
              className="w-full bg-transparent outline-none text-white"
            />
            <span>€</span>
          </div>
        </div>
      </div>
      <div className="flex md:flex-row flex-col flex-wrap w-full gap-3 mt-4">
        <div className=" flex flex-row justify-center items-center flex-[1_0_0] px-2 py-5 text-[#FFF] bg-[#2D2E2F] md:w-1/3 w-full">
          <div className="flex h-[80px] items-center gap-[15px]  py-0  self-stretch p-2 w-full">
            <div className="flex items-center self-stretch pr-0">
              <HiOutlineBanknotes size={30} />
            </div>
            <div className=" flex flex-col items-start flex-[1_0_0] whitespace-nowrap">
              <p className=" text-xs not-italic font-normal leading-[normal] font-roboto ">
                CA annuel en cours
              </p>
              <p className="font-syne text-white text-2xl not-italic font-bold leading-[normal]">
                {dataProStats?.totalRevenueCurrentYear}
              </p>
              <p className="text-[#B5B5B5] text-xs not-italic font-normal leading-[normal] font-roboto">
                {currentYear}
              </p>
            </div>
            <div className=" flex justify-center items-center gap-1 self-stretch  py-0">
              <BsArrowUpRight size={30} />
              <span className=" text-[#F6F0E8] text-md not-italic font-bold leading-[normal] font-syne">
                {dataProStats?.growthRate} %
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-center items-center flex-[1_0_0] px-2 py-5 text-[#FFF] bg-[#2D2E2F] md:w-1/3 w-full">
          <div className="flex h-[80px] items-center gap-[15px] py-0 self-stretch p-2 w-full">
            <div className="flex items-center self-stretch pr-0">
              <LiaCalendarAltSolid size={30} />
            </div>
            <div className="flex flex-col items-start flex-[1_0_0] whitespace-nowrap">
              <p className="text-xs not-italic font-normal leading-[normal] font-roboto">
                CA mensuel en cours
              </p>
              <p className="font-syne text-white text-2xl not-italic font-bold leading-[normal]">
                {dataProStats?.totalRevenueCurrentMonth}
              </p>
              <p className="text-[#B5B5B5] text-xs not-italic font-normal leading-[normal] font-roboto">
                {currentMonth}
              </p>
            </div>
          </div>

          <div className="flex flex-col mt-[-8%] mr-3  items-center gap-1">
            <div className="w-[100px] h-[50px] ">
              <HalfDoughnutChart
                calculateMonthlyGoalPercentage={calculateMonthlyGoalPercentage}
              />
            </div>
            <span className="text-[#F6F0E8] text-sm not-italic font-bold leading-[normal] font-syne text-center">
              {calculateMonthlyGoalPercentage()} %
            </span>
          </div>
        </div>

        <div className=" flex flex-row justify-center items-center flex-[1_0_0] px-2 py-5 text-[#FFF] bg-[#2D2E2F] md:w-1/3 w-full">
          <div className="flex h-[80px] items-center gap-[15px]  py-0  self-stretch p-2 w-full">
            <div className="flex items-center self-stretch pr-0">
              <BsRocketTakeoff size={30} />
            </div>
            <div className=" flex flex-col items-start flex-[1_0_0] whitespace-nowrap">
              <p className=" text-xs not-italic font-normal leading-[normal] font-roboto ">
                L’objectif du mois est à
              </p>
              <p className="font-syne text-white text-2xl not-italic font-bold leading-[normal]">
                {monthlyGoal} €
              </p>
              <p className="text-[#B5B5B5] text-xs not-italic font-normal leading-[normal] font-roboto">
                {currentYear}
              </p>
            </div>
            <div className=" flex justify-center items-center gap-1 self-stretch  py-0">
              {/* <BsArrowUpRight size={30} />
            <span className=" text-[#F6F0E8] text-sm not-italic font-bold leading-[normal] font-syne">
              67.5%
            </span> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StatsWidget;
