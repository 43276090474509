import React, { useContext, useEffect, useState } from "react";
import Datacontext from "../Context/Datacontext";
import { Link } from "react-router-dom";
import { useChatContext } from "Context/ChatContext";
import axiosInstance from "config/Interceptor";

const TestChat = () => {
  const {
    institutes,
    setFilterlist,
    FilterList,
    fetchMore,
    hasMore,
    setHasMore,
    fetch,
    fetching,
  } = useContext(Datacontext);
  const { setReceiverId, receiverId, setToggleChatDisplay } = useChatContext();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [client, setclient] = useState([]);
  useEffect(() => {
    //initialize the prestation selected Array and salon in local storage:
    localStorage.removeItem("startTimersToSkip");

    setPage(0);
    setHasMore(true);

    fetch(true,FilterList, false, true);
  }, [FilterList]);
  const fetchClientreserved = async () => {
    let url = "/api/v1/secure/chat/reservedClients";
    let res = await axiosInstance.get(url);
    // console.log(res);
    setclient(res.data.clientsWithReservations);
  };
  useEffect(() => {
    fetchClientreserved();
  }, []);

  return (
    <div className="h-screen flex flex-col items-center">
      <h1 className="text-white ">page to test Chat</h1>
      <div className="flex w-[100%] gap-4">
        <div className="w-1/2 h-fit bg-slate-500 ">
          <h2 className="text-lg text-center">Pro Chat</h2>
          <ul className="flex flex-col">
            {client.map((client) => (
              <Link
                to="/chat/institute"
                onClick={() => {
                  setReceiverId(client._id);
                  setToggleChatDisplay(true);
                }}
                key={client._id}>
                {client.lastName}
              </Link>
            ))}
          </ul>
        </div>
        <div className="w-1/2 h-fit bg-slate-500">
          <h2 className="text-lg text-center">client Chat</h2>
          <ul className="flex flex-col">
            {institutes.map((institute) => (
              <Link
                to="/chat/client"
                onClick={() => setReceiverId(institute._id)}
                key={institute._id}>
                {institute.name}
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TestChat;
