import React, { useContext, useEffect, useState } from "react";
import SalonDataContext from "Context/SalonDataContext";
import "styles/chooseAnEmployeeCard.css";
import MapGoogle from "Component/utils/MapGoogle";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "Component/ui/dialog";
import { FaStar } from "react-icons/fa6";
import Datacontext from "Context/Datacontext";
import moment from "moment";
import SmSpinner from "Component/ui/SmSpinner";

const UpdateAnEmployeeCard = ({
  index,
  prestation,
  salonLocation,
  selectedStaff,
  setSelectedStaff,
}) => {
  const { salonForReservation } = useContext(SalonDataContext);
  const { AllstaffForUpdate, loading, getStaffForUpdate, setLoading } =
    useContext(Datacontext);
  const [staffimgerr, setstaffimgerr] = useState("");

  const handlestaffimgError = () => {
    setstaffimgerr(true);
  };
  const handleChange = (e) => {
    setSelectedStaff(e.target.value);
  };

  const [institute, setInstitute] = useState("");
  const [startTimer, setStartTimer] = useState("");
  useEffect(() => {
    let paramsFromLocalStorage = localStorage.getItem("setSelectedSlotToUpdate");

    if (paramsFromLocalStorage) {
      const { institute: instituteValue, startTimer: startTimerValue } =
        JSON.parse(paramsFromLocalStorage);
      setInstitute(instituteValue);
      setStartTimer(startTimerValue);
    }
  }, []);

  useEffect(() => {
    getStaffForUpdate(institute, startTimer);
  }, [institute, startTimer]);

  const renderStaffList = () => {
    const selectedSlot =
      localStorage.getItem("setSelectedSlotToUpdate") &&
      JSON.parse(localStorage.getItem("setSelectedSlotToUpdate"));

    // Extract day, start time, and end time from the timerPeriod
    const NotcapitalizedDayName =
      selectedSlot.startTimer && moment(selectedSlot.startTimer).format("dddd");
    const selectedDay =
      NotcapitalizedDayName.charAt(0).toUpperCase() + NotcapitalizedDayName.slice(1);

    const timerPeriod = selectedSlot.timerPeriod;
    const selectedTimeStart = timerPeriod[0]; // Start time from the slot
    const selectedTimeEnd = timerPeriod[1]; // End time from the slot

    if (loading) {
      return (
        <div className=" flex flex-row justify-center items-center  w-80 md:w-96 md:h-40 h-60">
          <SmSpinner w={10} h={10} />{" "}
        </div>
      );
    }

    const filteredStaff =
      AllstaffForUpdate &&
      AllstaffForUpdate?.filter((employee) => {
        const workingHours = employee.businessHours.find(
          (hours) => hours.name === selectedDay
        );

        if (workingHours && workingHours.isOpen) {
          const morningStart = workingHours.morningStart;
          const morningEnd = workingHours.morningEnd;
          const eveningStart = workingHours.eveningStart;
          const eveningEnd = workingHours.eveningEnd;

          if (
            (morningStart <= selectedTimeStart && selectedTimeStart < morningEnd) ||
            (eveningStart <= selectedTimeStart && selectedTimeStart < eveningEnd) ||
            (morningStart <= selectedTimeEnd && selectedTimeEnd < morningEnd) ||
            (eveningStart <= selectedTimeEnd && selectedTimeEnd < eveningEnd)
          ) {
            return true;
          }
        }

        return false;
      });

    return (
      filteredStaff &&
      filteredStaff?.map((employee) => (
        <label
          onClick={() => handleChange({ target: { value: employee._id } })}
          title={prestation.idStaff}
          htmlFor={employee._id}
          key={employee._id}
          className="checkbox-label lg:w-[45%] w-full border p-4 rounded-lg flex mx-auto items-center space-x-2">
          <input
            id={employee._id}
            type="radio"
            value={employee._id}
            checked={selectedStaff === employee._id}
            onChange={handleChange}
          />
          {!employee.imgStaff || staffimgerr ? (
            <div className="lg:w-10 lg:h-10 w-8 h-8 p-2 rounded-full bg-[#D0CBC4] text-[#4A4A49] lg:text-xl text-sm font-syne flex items-center justify-center capitalize shrink-0">
              {employee.fullName[0]}
            </div>
          ) : (
            <div className="h-11 w-11 rounded-full overflow-hidden relative shrink-0">
              <img
                src={employee.imgStaff}
                alt={`${employee.fullName}`}
                className="h-full w-full object-cover absolute"
                onError={handlestaffimgError}
              />
            </div>
          )}
          <div className="flex flex-col">
            <p className="text-base font-bold text-black font-syne capitalize">
              {employee.fullName}
            </p>
            <p className="lg:text-[#82786E] text-white font-roboto text-sm">
              {employee.post}
            </p>
          </div>
        </label>
      ))
    );
  };

  return (
    <div className="backdrop-blur-lg bg-white/30 lg:bg-[#F6F0E8] lg:backdrop-filter-none cus-radius lg:p-[1.7rem]  w-[350px] lg:max-w-[500px]  lg:w-auto h-max p-9 flex flex-col rounded-3xl items-start mx-auto">
      <div className="lg:flex-row justify-between mb-2 w-full font-roboto text-base">
        <p className="font-bold text-2xl font-syne">{salonForReservation.name}</p>
      </div>
      <div className="flex flex-row gap-2 mb-6 text-[#82786E]">
        {salonForReservation.rating?.length > 0 && (
          <>
            <span>
              <FaStar size={18} />
            </span>
            <span>
              {(salonForReservation?.rating?.length && salonForReservation.rating) || "?"}
              /5
            </span>
            <span> ({salonForReservation?.reviews?.length})</span>
            <span>&#x2022;</span>
          </>
        )}
        <span>
          <Dialog className="!bg-slate-400">
            <DialogTrigger aria-label="Ajouter une prestation">
              <p>{salonForReservation.address}</p>
            </DialogTrigger>
            <DialogContent className="p-0 no-scrollbar gap-0 flex flex-start flex-col bg-beige w-full h-full md:w-10/12 max-w-[1400px] max-h-[calc(100dvh-_140px)] overflow-y-scroll !rounded-3xl">
              <DialogTitle className="h-fit p-6">
                <p className="font-bold text-2xl font-syne">{salonForReservation.name}</p>
                <p>{salonForReservation.address}</p>
              </DialogTitle>
              <div className="w-full h-full rounded-3xl overflow-hidden">
                <MapGoogle markers={salonLocation} />
              </div>
            </DialogContent>
          </Dialog>
        </span>
      </div>
      {!loading && (
        <h5 className="font-roboto text-xl font-semibold text-white lg:text-black md:mb-2 mb-1">
          Avec qui ?
        </h5>
      )}
      <div className="flex flex-wrap items-stretch gap-[0.7rem] w-full">
        {!loading && (
          <label
            onClick={() => handleChange({ target: { value: "" } })}
            htmlFor={"pas_de_preference" + index}
            className="checkbox-label lg:w-[45%] w-full border p-4 rounded-lg flex mx-auto items-center space-x-2">
            <input
              onChange={handleChange}
              id={"pas_de_preference" + index}
              type="radio"
              checked={selectedStaff === ""}
              value={""}
            />
            &nbsp; Pas de préférence
          </label>
        )}

        {renderStaffList()}
      </div>
    </div>
  );
};

export default UpdateAnEmployeeCard;
