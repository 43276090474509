import React, { useState } from "react";
import { UserAuthProvider } from "../Context/UserAuthContext";
// import Background from "../assets/signInUp.png";
import UserSignupForm from "../Component/UserSignup/UserSignupForm";
import UserComfirmPhoneForm from "../Component/UserSignup/UserComfirmPhoneForm";
import Cookies from "js-cookie";
import { Navigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";

const Signup = () => {
  const [step, setStep] = useState(1);
  const isAuthenticated = !!Cookies.get("authToken");
  const location = useLocation();

  if (isAuthenticated) {
    const decoded = jwt_decode(Cookies.get("authToken"));

    if (decoded) {
      if (decoded.isPro) {
        return <Navigate to="/ProConnected/caisseDash" state={{ from: location }} />;
      } else return <Navigate to="/profile" state={{ from: location }} />;
    }
  }

  return (
    <div className=" flex my-9  h-screen items-center justify-center">
      {/* Background image */}
      <img
        src={
          "https://bucket-stunify-app.s3.eu-north-1.amazonaws.com/static/assets/signInUp.png"
        }
        alt="backgroundImage"
        style={{ objectFit: "cover" }}
        className="!fixed top-0 z-[-1] w-screen h-screen"
      />

      <UserAuthProvider>
        <UserSignupForm isStep={step} setStep={setStep} />
        {step === 2 && <UserComfirmPhoneForm setStep={setStep} />}
      </UserAuthProvider>
    </div>
  );
};

export default Signup;
