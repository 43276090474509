import React, { useState } from "react";
import { SlLocationPin } from "react-icons/sl";
import MapGoogle from "Component/utils/MapGoogle";

function AboutResume({ salon, location }) {
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };
  return (
    <>
      <h5 className="font-syne font-bold text-[28px] py-8 mx-8 md:mx-0   ">
        À propos
      </h5>
      <div className=" text-[#F6F0E8] btnsalonmobile rounded-[25px] p-4 md:p-0 mx-4 md:mx-0 mb-4  ">
        <div>
          <p
            className={` text-base not-italic font-normal leading-[23px] font-roboto  ${
              showFullText ? "hidden" : "block md:hidden"
            }`}
          >
            {salon && salon.description && salon.description.substring(0, 150)}{" "}
            {/* display the first 150 char*/}
          </p>
          <p
            className={`text-base font-roboto  leading-[23px] ${
              showFullText ? "block" : "hidden md:block"
            }`}
          >
            {salon && salon.description && salon.description}
          </p>
          <div
            className="text-[#F6F0E8] text-base font-roboto underline underline-offset-1 mt-2 md:hidden block mb-[14px]"
            onClick={toggleText}
          >
            {showFullText ? "Moins" : "Voir plus"}
          </div>

          <div className="flex flex-col md:flex-row gap-2 md:space-x-7 md:h-[500px]  md:mt-6">
            <div className="rounded-md md:w-3/5  w-full object-cover">
              <MapGoogle markers={location} />
            </div>

            <div className="md:w-2/5 flex justify-around flex-col">
              <h5 className="font-syne font-bold text-18px] pt-4 md:mx-0 md:pt-0 mb-4">
                Adresse
              </h5>
              <div className="flex flex-row  w-full space-x-1">
                <SlLocationPin size={24} color="#F6F0E8" />
                <p>{salon?.address}</p>
              </div>
              <h5 className="font-syne font-bold text-18px] pt-4 md:mx-0 md:pt-10   mb-4">
                Horaires
              </h5>
              <div className="w-full grow flex flex-col gap-4">
                {salon?.businessHours.map((day, index) => {
                  if (!day.isOpen) {
                    return (
                      <p
                        key={day + "_" + index}
                        className="grid-cols-2 grid justify-between"
                      >
                        {" "}
                        <span>{day.name}</span>
                        <span className="text-end"> Fermé</span>
                      </p>
                    );
                  }
                  if (day.isOpen && !day.lunchBreak) {
                    return (
                      <p key={day + "_" + index} className="grid-cols-2 grid">
                        <span>{day.name}&nbsp;:&nbsp;</span>
                        <span className="text-end">
                          {day.morningStart}-{day.eveningEnd}
                        </span>
                      </p>
                    );
                  }
                  if (day.isOpen && day.lunchBreak) {
                    return (
                      <div key={day + "_" + index} className="grid-cols-2 grid">
                        <p className="whitespace-nowrap"> {day.name}</p>
                        <div className="text-end">
                          <span>
                            {day.morningStart}-{day.morningEnd} 
                          </span> /{" "}
                          <span className="inline-block">
                            {day.eveningStart}-{day.eveningEnd}
                          </span>
                        </div>
                      </div>
                    );
                  } else return null;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutResume;
