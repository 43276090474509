import React, { useState } from "react";
import { useNewInstitute } from "../../../Context/InstituteAuthContext";
//icons
import { HiOutlineTrash } from "react-icons/hi";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

const BusinessHours = ({ index, name, isOpen, lunchBreak }) => {
  const { newInstitute, setNewInstitute, defaultInstitute } = useNewInstitute();
  const clearIcon = null;

  const [morningStartValue, setMorningStartValue] = useState(
    moment(newInstitute.businessHours[index].morningStart, "HH:mm").hour()
  );
  
  const [morningEndValue, setMorningEndValue] = useState(
    moment(newInstitute.businessHours[index].morningEnd, "HH:mm").hour()
  );
  const [eveningStartValue, setEveningStartValue] = useState(
    moment(newInstitute.businessHours[index].eveningStart, "HH:mm").hour()
  );

  const handleDayCheckbox = (e) => {
    setNewInstitute((prev) => {
      const updatedBusinessHours = prev.businessHours.map((item, i) => {
        if (i === index) {
          if (e.target.checked) {
            // Copy values from the first index
            const {
              morningStart,
              morningEnd,
              eveningStart,
              eveningEnd,
              lunchBreak,
            } = prev.businessHours[0] || prev.businessHours[1];
            return {
              ...item,
              isOpen: true,
              morningStart,
              morningEnd,
              eveningStart,
              eveningEnd,
              lunchBreak,
            };
          } else {
            // Keep original values if unchecked
            return { ...item, isOpen: false };
          }
        }
        return item;
      });

      return {
        ...prev,
        businessHours: updatedBusinessHours,
      };
    });

    if (!e.target.checked) {
      hideAfterLunchOptions(e);
    }
  };

  const handleTimeChange = (value, name) => {
    setNewInstitute((prev) => {
      const updatedBusinessHours = prev.businessHours.map((item, i) => {
        if (i === index) {
          return { ...item, [name]: value.format("HH:mm") };
        }

        return item;
      });

      return {
        ...prev,
        businessHours: updatedBusinessHours,
      };
    });
    switch (name) {
      case "morningStart":
        setMorningStartValue(value.hour());
        break;
      case "morningEnd":
        setMorningEndValue(value.hour());
        break;
      case "eveningStart":
        setEveningStartValue(value.hour());
        break;
      default:
        break;
    }
  };

  const showAfterLunchOptions = (e) => {
    const evening_options = document.querySelector("#evening_options_" + index);
    const add_lunch_break = document.querySelector(
      `#add_lunch_break_to_${name}`
    );
    add_lunch_break.classList.toggle("opacity-30");
    evening_options.classList.remove("hidden");
    evening_options.classList.add("flex");

    setNewInstitute((prev) => {
      const updatedBusinessHours = prev.businessHours.map((item, i) => {
        if (i === index) {
          return { ...item, lunchBreak: true };
        }
        return item;
      });
      return {
        ...prev,
        businessHours: updatedBusinessHours,
      };
    });
  };

  const hideAfterLunchOptions = (e) => {
    const evening_options = document.querySelector("#evening_options_" + index);
    const add_lunch_break = document.querySelector(
      `#add_lunch_break_to_${name}`
    );
    add_lunch_break.classList.toggle("opacity-30");
    evening_options.classList.remove("flex");
    evening_options.classList.add("hidden");
    setNewInstitute((prev) => {
      const updatedBusinessHours = prev.businessHours.map((item, i) => {
        if (i === index) {
          return { ...item, lunchBreak: false };
        }
        return item;
      });
      return {
        ...prev,
        businessHours: updatedBusinessHours,
      };
    });
  };

  return (
    <li className="flex flex-row flex-wrap gap-2 items-center py-6 border-b dark:border-taupe border-dark_grey last:border-none first:pt-0 first:-mt-2">
      {/* DAY */}
      <input
        type="checkbox"
        name={`${name}_business`}
        id={`${name}_business`}
        checked={isOpen}
        className="peer/day mt-4 h-4 w-4 checkbox-dark"
        onChange={(e) => {
          handleDayCheckbox(e, index);
        }}
      />
      <label
        htmlFor={`${name}_business`}
        className="dark:peer-checked/day:text-white peer-checked/day:text-black text-dark_grey dark:text-light_grey mt-4 mr-4 text-sm"
      >
        {name.slice(0, 3)}
      </label>
      <div className="flex-col flex-wrap gap-6 hidden peer-checked/day:flex">
        {/* MORNING  OR WHOLE DAY OPTIONS*/}
        <div className="flex flex-row flex-wrap gap-4 justify-start items-center">
          <label
            htmlFor={`${name}_morningStart`}
            className="w-[110px] text-sm  "
          >
            <div className=" relative top-[44px] !left-20 text-xs bg-transparent w-6 h-5 z-50">
              {" "}
            </div>
            De
            <TimePicker
              clearIcon={clearIcon}
              minuteStep={20}
              showSecond={false}
              name="morningStart"
              id={`${name}_morningStart`}
              value={moment(
                newInstitute.businessHours[index].morningStart,
                "HH:mm"
              )}
              onChange={(value) => handleTimeChange(value, "morningStart")}
              className="input rounded-md check-validity  "
            />
          </label>
          {/* MORNING END OR EVENING END  */}
          <label
            htmlFor={lunchBreak ? `${name}_morningEnd` : `${name}_eveningEnd`}
            className="w-[110px] text-sm "
          >
            <div className=" relative top-[44px] !left-20 text-xs bg-transparent w-6 h-5 z-50">
              {" "}
            </div>
            à
          <TimePicker
              clearIcon={clearIcon}
              minuteStep={20}
              showSecond={false}
              disabledHours={() => {
                // Create an array of hours to be disabled
                const disabledHours = [];

                for (let i = 0; i < morningStartValue + 1; i++) {
                  disabledHours.push(i);
                }

                return disabledHours;
              }}
              name={lunchBreak ? "morningEnd" : "eveningEnd"}
              id={lunchBreak ? `${name}_morningEnd` : `${name}_eveningEnd`}
              value={
                lunchBreak
                  ? moment(
                      newInstitute.businessHours[index].morningEnd ?  newInstitute.businessHours[index].morningEnd: "00:00",
                      "HH:mm"
                    )
                  : moment(
                      newInstitute.businessHours[index].eveningEnd,
                      "HH:mm"
                    )
              }
              onChange={(value) =>
                handleTimeChange(
                  value,
                  lunchBreak ? "morningEnd" : "eveningEnd"
                )
              }
              className="input rounded-md check-validity"
            />
          </label>
          <button
            type="button"
            aria-label="ajouter une pause midi"
            onClick={showAfterLunchOptions}
            id={`add_lunch_break_to_${name}`}
            className="mt-9 cursor-pointer text-3xl"
          >
            +
          </button>
        </div>
        {/* AFTER LUNCH OPTIONS */}
        <div
          className={`${
            lunchBreak ? "flex" : "hidden"
          }  transition-all duration-300 flex-row flex-wrap gap-4 justify-start items-center`}
          id={"evening_options_" + index}
        >
          {/* EVENING */}
          <label
            htmlFor={`${name}_EveningStart`}
            className="w-[110px] text-sm  "
          >
            <div className=" relative top-[44px] !left-20 text-xs bg-transparent w-6 h-5 z-50">
              {" "}
            </div>
            De
            <TimePicker
              clearIcon={clearIcon}
              minuteStep={20}
              showSecond={false}
              disabledHours={() => {
                // Create an array of hours to be disabled
                const disabledHours = [];

                for (let i = 0; i < morningEndValue + 1; i++) {
                  disabledHours.push(i);
                }

                return disabledHours;
              }}
              name="eveningStart"
              id={`${name}_EveningStart`}
              value={
                lunchBreak
                  ? moment(
                      newInstitute.businessHours[index].eveningStart ?   newInstitute.businessHours[index].eveningStart :"18:00",
                      "HH:mm"
                    )
                  : ""
              }
         
              
              onChange={(value) => handleTimeChange(value, "eveningStart")}
              className="input rounded-md check-validity"
            />
          </label>
          {/* EVENING END */}
          <label
            htmlFor={lunchBreak ? `${name}_eveningEnd` : `${name}_end `}
            className="w-[110px] text-sm "
          >
            <div className=" relative top-[44px] !left-20 text-xs bg-transparent w-6 h-5 z-50">
              {" "}
            </div>
            à
            <TimePicker
              clearIcon={clearIcon}
              minuteStep={20}
              showSecond={false}
              disabledHours={() => {
                // Create an array of hours to be disabled
                const disabledHours = [];

                for (let i = 0; i < eveningStartValue + 1; i++) {
                  disabledHours.push(i);
                }

                return disabledHours;
              }}
              name={lunchBreak ? "eveningEnd" : ""}
              id={lunchBreak ? `${name}_eveningEnd` : `${name}_end `}
              value={
                lunchBreak
                  ? moment(
                      newInstitute.businessHours[index].eveningEnd,
                      "HH:mm"
                    )
                  : ""
              }
             
              onChange={(value) =>
                handleTimeChange(value, lunchBreak ? "eveningEnd" : "end")
              }
              className="input rounded-md check-validity  "
            />
          </label>
          <button
            type="button"
            aria-label="Enlever la pause de midi"
            className="h-6 w-6 mt-3 cursor-pointer"
            onClick={hideAfterLunchOptions}
          >
            <HiOutlineTrash className="h-6 w-6 mt-3" />
          </button>
        </div>
      </div>
      <div className="hidden peer-checked/day:flex flex-row justify-start items-baseline"></div>
    </li>
  );
};

export default BusinessHours;
