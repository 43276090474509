import React from "react";
import { Link } from "react-router-dom";
import {
  HiScissors,
  HiAdjustmentsVertical,
  HiOutlineBeaker,
  HiOutlinePaintBrush,
  HiOutlineRocketLaunch,
  HiOutlineHandRaised,
  HiFingerPrint,
  HiOutlineFaceSmile,
} from "react-icons/hi2";
import "styles/animate.css";

import Searchbar from "../../Component/utils/Searchbar";
import ProPartners from "../../Component/utils/ProPartners";

const HomeDesktop = () => {
  const fadeLeft = `animate__animated animate__fadeOutLeft `;
  const fadeRight = `animate__animated animate__fadeOutRight `;
  const fadeDown = `animate__animated animate__fadeOutDown `;
  const fadeUp = `animate__animated animate__fadeOutUp `;

  const fadeInLeft = `animate__animated  animate__fadeInLeft`;
  const fadeInRight = `animate__animated  animate__fadeInRight`;
  const fadeInDown = `animate__animated  animate__fadeInDown`;
  const fadeInUp = `animate__animated  animate__fadeInUp`;

  return (
    <div className=" min-h-[840px] no-scrollbar justify-center items-center h-fit  overflow-hidden grow ">
      {/* first page */}
      <div className=" md:container md:mx-auto flex flex-col items-center pt-12 md:h-screen  text-white overflow-y-scroll   no-scrollbar max-w-[1920px] m-auto">
        {/* new quick search */}
        <div className=" animate__animated  animate__fadeInDown flex  justify-evenly linear-background mt-5 h-fit flex-wrap rounded-full  py-4  font-inter text-xs lg:text-sm w-[95%] ">
          <Link
            to="/search/coiffeur"
            className="flex  underline  items-center justify-center gap-1  ">
            <HiScissors size={16} />
            Coiffure
          </Link>
          <Link
            to="/search/barbier"
            className="flex   underline   items-center justify-center gap-1">
            <HiAdjustmentsVertical size={16} />
            Barbiers
          </Link>
          <Link
            to="/search/esthetique"
            className="flex  underline   items-center justify-center gap-1">
            <HiOutlineBeaker size={16} />
            Médecine esthétique
          </Link>
          <Link
            to="/search/esthetique"
            className="flex  underline   items-center justify-center gap-1">
            <HiOutlinePaintBrush size={16} />
            Institut de beauté
          </Link>
          <Link
            to="/search/tatoueur"
            className="flex  underline   items-center justify-center gap-1">
            <HiOutlineRocketLaunch size={16} />
            Tattoo
          </Link>
          <Link
            to="/search/onglerie"
            className="flex  underline   items-center justify-center gap-1">
            <HiOutlineHandRaised size={16} />
            Manucure
          </Link>
          <Link
            to="/search/esthetique"
            className="flex  underline   items-center justify-center gap-1">
            <HiFingerPrint size={16} />
            épilation
          </Link>
          <Link
            to="/search/massage"
            className="flex  underline   items-center justify-center gap-1">
            <HiOutlineFaceSmile size={16} />
            Médecine douce
          </Link>
        </div>
        {/* <h1 className='animate__animated  animate__fadeInLeft text-2xl xl:text-5xl lg:text-xl font-syne tracking-wider  text-center mt-24'>Un rendez-vous tout de suite</h1> */}
        <h1 className="animate__animated  animate__fadeInLeft font-bold mb-4 text-2xl xl:text-5xl lg:text-xl my-2 font-syne tracking-wider  text-center mt-24">
          Un rendez-vous tout de suite <br />
          Dans votre salon de beauté & coiffure
        </h1>
        <Searchbar />
        <div className="animate__animated  animate__fadeInUp grow justify-center xl:py-20 = flex flex-col items-center  z-10">
          <div className=" w-ful glass-effect rounded-3xl text-center mt-16 lg:py-11 md:py-7 lg:mx-0 md:px-4 md:text-base mb-10 text-2xl xl:text-3xl lg:text-xl font-bold font-syne items-center justify-center flex">
            Prenez un rendez-vous 24/24 en 2min avec un choix de plus de 2000
            établissements
          </div>
        </div>
      </div>
      {/* second section */}
      <div className="backgroundHome bg-cover w-[100%] pt-16  flex flex-row justify-center items-center xl:p-40 h-screen ">
        <div className="md:container md:mx-auto ">
          <ProPartners />
        </div>
      </div>
      <section className="bg-black pt-10 pb-16  text-beige snap-center ">
        <div className="w-11/12 mx-auto flex flex-col gap-4 justify-start">
          <h2>Vous êtes professionnels ?</h2>
          <p>Facilitez-vous la vie et multipliez vos rendez-vous avec Stunify Pro</p>
          <Link to="/pro" className="btn-light w-fit text-black">
            En savoir plus
          </Link>
        </div>
      </section>
    </div>
  );
};

export default HomeDesktop;
