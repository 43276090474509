import { createContext, useMemo, useState } from "react";
const LoggedContext = createContext();
function Provider({ children }) {
  const [isAdmin, setIsadmin] = useState(false);
  const [isPro, setIsPro] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [userGlobal, setUserGlobal] = useState({
    _id: "",
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    imageUrl: "",
  });
  const valueToShare = useMemo(() => {
    return {
      isAdmin,
      setIsadmin,
      isPro,
      setIsPro,
      isLogged,
      setIsLogged,
      toggleUserState: () => {
        setIsLogged(!isLogged);
      },
      toggleProState: () => {
        setIsPro(!isPro);
      },
      userGlobal,
      setUserGlobal,
    };
  }, [isPro, setIsPro, isLogged, setIsLogged, userGlobal, setUserGlobal, isAdmin]);
  return <LoggedContext.Provider value={valueToShare}>{children}</LoggedContext.Provider>;
}
export { Provider };
export default LoggedContext;
