import toast from "react-hot-toast";
import { useFormContext } from "../../Context/FormContext";
import { useState } from "react";

const InformationSection = ({ setViewMode }) => {
  const { setPassword, handleUpdatePsw , password } = useFormContext();
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(null);

  const validatePassword = (password) => {
    const checkUpercase = /[A-Z]/.test(password);
    const checkNum = /\d/.test(password);
    const checkSymbole = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  
    if (password.trim() === "") {
      setPasswordError("Mot de passe est requis");
      setIsPasswordValid(false);
    } else if (password.length < 8) {
      setPasswordError("Le mot de passe doit comporter 8 caractères ou plus");
      setIsPasswordValid(false);
    } else if (!checkUpercase) {
      setPasswordError("Le mot de passe doit contenir au moins une majuscule");
      setIsPasswordValid(false);
    } else if (!checkNum) {
      setPasswordError("Le mot de passe doit contenir au moins un chiffre");
      setIsPasswordValid(false);
    } else if (!checkSymbole) {
      setPasswordError("Le mot de passe doit contenir au moins caractère spécial");
      setIsPasswordValid(false);
    } else {
      setPasswordError("");
      setIsPasswordValid(true);
    }
  };

  const handleChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    validatePassword(password);
  };

  const handlesubmitPsw = async (e) => {
    e.preventDefault();

    if (!isPasswordValid) {
      return;
    }

    try {
      await handleUpdatePsw();
      setViewMode(false);
      toast.success("Votre mot de passe a été modifié avec succès");
    } catch (error) {
      console.error("Error updating password:", error);
      toast.error("Une erreur s'est produite lors de la mise à jour du mot de passe");
    }
  };

  return (
    <div className="bg-sand flex flex-col rounded-3xl p-4 mx-auto md:w-[80%] w-full md:mt-[-20px]">
      <h5 className="text-[#0F0F0F] text-base not-italic font-bold leading-[normal]">
        Modifier mon mot de passe
      </h5>

      <div className="flex flex-col mt-10">
        <p className="mb-3">
          Pour modifier votre mot de passe, saisissez votre nouveau mot de passe
        </p>
        <label>Nouveau mot de passe</label>
        <span className='text-xs text-taupe '>Au moins une majuscule, un caractère spécial et un chiffre</span>
        <input
          placeholder="*******"
          onChange={handleChangePassword}
          type="password"
          className={`rounded-xl border-[2px] border-mud border-opacity-30 bg-opacity-0 px-3 py-2 bg-white focus:outline-none focus:ring-0 ${ password? !isPasswordValid? 'focus:border-red-400' : 'focus:border-green-400' : 'focus:border-mud' } ` }
        />
        
      </div>
      <div className="text-red-500 mt-1">{passwordError}</div>

      <div className="flex justify-end mt-20">
        <div className="flex gap-5 items-center">
          <button
            className={`bg-black text-white rounded-full lg:px-8 px-3 py-2 ${
              !isPasswordValid
                ? "opacity-30 cursor-not-allowed"
                : "cursor-pointer opacity-100"
            }`}
            onClick={handlesubmitPsw}
            disabled={!isPasswordValid}>
            Modifier
          </button>
        </div>
      </div>
    </div>
  );
};

export default InformationSection;
