import React, { useState } from "react";
import { useNewInstitute } from "Context/InstituteAuthContext";
import ToggleButtonDark from "./ToggleButtonDark";
import DisplayTeamMembers from "./DisplayTeamMembers";
import AddTeamMemberForm from "./AddTeamMemberForm";
import toast from "react-hot-toast";

const InstituteTeamInfo = ({ handleStepChange }) => {
  const { newInstitute, setNewInstitute } = useNewInstitute();
  const [isAddingMember, setIsAddingMember] = useState(false);

  return (
		<>
			{/* Header */}
			<header className='flex flex-row  gap-4 justify-between pb-4'>
				<div className='flex flex-row xs:max-sm:flex-wrap justify-center sm:justify-between gap-4 w-full items-end'>
					<div className='title mb-'>
						<h1 className='font-syne text-2xl mt-8 font-bold text-white'>
							Mon équipe 
						</h1>
					
					</div>
					<ToggleButtonDark isAddingMember={isAddingMember} setIsAddingMember={setIsAddingMember} />
				</div>
			</header>
			{/* Team Member card list */}
			<section className='grow flex flex-col justify-between'>
				{isAddingMember ? <AddTeamMemberForm setIsAddingMember={setIsAddingMember} /> : <DisplayTeamMembers handleStepChange={handleStepChange} />}
			</section>
		</>
  );
};

export default InstituteTeamInfo;
