import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ dataProStats }) => {
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const [chartOptions, setChartOptions] = useState({});

  const calculateBarThickness = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1280) {
      return 43;
    } else if (windowWidth >= 1024) {
      return 32;
    } else if (windowWidth >= 768) {
      return 24;
    } else {
      return 20;
    }
  };

  //         backgroundColor: [
  //           "#A98B8B",
  //           "#A9A08B",
  //           "#91A582",
  //           "#A98B8B",
  //           "#F6F0E8",

  //         ],

  useEffect(() => {
    if (!dataProStats || !dataProStats.stat) {
      // Handle the case where dataProStats or dataProStats.stat is undefined or empty
      return;
    }

    const labels = [
      "Janv",
      "Fev",
      "Mars",
      "Avr",
      "Mai",
      "Juin",
      "Juil",
      "Aout",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    const data = Array(12).fill(0); // Initialize data with 0

    dataProStats.stat.forEach((item) => {
      const { _id, totalRevenue } = item;
      const { month } = _id;
      data[month - 1] = totalRevenue;
    });

    setChartData({
      labels,
      datasets: [
        {
          label: "CA / mois",
          barThickness: calculateBarThickness, // Adjust thickness based on screen size
          data,
          borderColor: "rgb(235, 53, 53)",
          backgroundColor: labels.map((_, index) =>
            data[index] > 0 ? "#A9A08B" : "rgba(35, 74, 229, 0.366)"
          ),
          borderRadius: 10,
        },
      ],
    });

    setChartOptions({
      plugins: {
        legend: {
          display: false,
        },
      },
      maintainAspectRatio: false,
      responsive: true,
    });
  }, [dataProStats]);

  return (
    <div
      className="w-full md:col-span-2 relative lg:h-[70vh]   m-auto p-4"
      style={{ height: "calc(60vh)" }}
    >
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default BarChart;
