import React from "react";
import { Dialog, DialogContent, DialogTrigger, DialogCLose } from "Component/ui/dialog";
import { useNewInstitute } from "Context/InstituteAuthContext";
//icons
import { HiOutlineClipboard, HiOutlinePhoto, HiChevronLeft, HiChevronRight, HiOutlineUsers } from "react-icons/hi2";
import { BiGridAlt } from "react-icons/bi";

const ShowAllPhotosModal = ({photos}) => {
	const { newInstitute } = useNewInstitute();

	return (
		<Dialog className='hello1'>
			<DialogTrigger aria-label='voir toutes les photos'>
				<div className='bg-[#F6F0E8] absolute cursor-pointer  left-3 bottom-7 flex flex-row gap-2 items-center py-[10.5px] px-4 rounded-[30px] border border-black shadow-[0px_2px_4px_-1px_rgba(0,0,0,0.06),0px_4px_6px_-1px_rgba(0,0,0,0.10)]'>
					<BiGridAlt size={20} color='#0F0F0F' />

					<p className='text-sm font-roboto text-black '>Toutes les photos</p>
				</div>
			</DialogTrigger>
			
			<DialogContent id='show_all_photos_pro_registration' className='p-0 pl-2.5 gap-4 flex flex-center flex-row flex-wrap bg-beige w-full h-full md:w-10/12 max-w-[1400px] max-h-[calc(100dvh_-_140px)] overflow-y-scroll !rounded-3xl'>
				{photos.map((photoUrl, index) => (
					<img key={index} src={photoUrl} alt='photo_salon+index' width={400} height={400} className='grow aspect-video object-cover object-center rounded-md  bg-slate-200' />
				))}
			</DialogContent>
		</Dialog>
	);
};

export default ShowAllPhotosModal;
