import { useState, useEffect, useContext } from "react";
import prestationContext from "Context/prestationContext";
import SalonDataContext from "Context/SalonDataContext";

import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";

const PrestationModal = ({ setIsModalOpen }) => {
  const { setSelectedPrestation } = useContext(prestationContext);
  const { prestationArray } = useContext(SalonDataContext);
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    handleSetCategory();
  }, []);

  const handleSetCategory = () => {
    let categoryArray = [];
    prestationArray.map((item) => {
      if (!categoryArray.includes(item.category)) {
        categoryArray.push(item.category);
      }
    });
    setCategories(categoryArray);
  };

  const handleAddPrestation = (prestationId) => {
    let uniqueId = uuidv4() + Date.now().toString();
    let _prestationArray = [...prestationArray];
    let newPrestation = _prestationArray.find(
      (item) => item._id  === prestationId
    );
    let _prestation = { ...newPrestation, idStaff: "", uid: uniqueId};
    setSelectedPrestation((prev) => [...prev, _prestation]);
    setIsModalOpen(false);
    toast.success("Prestation ajoutée");
  };

  //  Add a state variable to keep track of expanded items
  const [expanded, setExpanded] = useState([]);

  //  Modify the toggleExpand function
  const toggleExpand = (item) => {
    const temp = [...expanded];
    const index = temp.indexOf(item.id);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(item.id);
    }
    setExpanded(temp);
  };

  return (
    <div className="w-full md:overflow-y-scroll h-[620px] md:h-[660px] lg:h-auto  xl:py-[4rem] py-[1rem]">
      <div className="cus-radius max-w-[75rem] m-auto rounded-t-3xl ">
        <div className="flex flex-row items-center px-6 py-3 justify-between ">
          <h1 className="md:hidden font-roboto text-base font-bold ">
            Filtrer
          </h1>
        </div>
        <div className="md:flex ">
          <div className="w-full p-4 py-2 md:w-1/3">
            <div className="overflow-x-auto   no-scrollbar py-2">
              <div className="mx-auto flex flex-row  md:flex-col gap-2 flex-nowrap">
                {categories.map((pre, index) => {
                  return (
                    <button
                      type="button"
                      aria-label={`Selectionner la catégorie de prestation ${pre}`}
                      className={` border border-gray-300 rounded-3xl	text-sm  h-11  py-3 px-4 mx min-w-fit md:text-left md:border-0  ${
                        index === selected
                          ? "bg-black text-beige "
                          : "text-black"
                      }`}
                      key={index}
                      onClick={() => setSelected(index)}
                    >
                      {pre}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          {/* section in Rghit  */}

          <div className="w-full p-4 md:w-2/3 ">
            <ul>
              {prestationArray.map((item, index) => {
                        
          const [hours, minutes] = item.duration.split(":").map(Number);
          const totalMinutes = hours * 60 + minutes;
                {
                  if (item.category === categories[selected])
                    return (
                      <li
                        key={index}
                        className="border-b last:border-none py-4 first:py-2"
                      >
                        <p className="text-base font-roboto capitalize">
                          {item.name}
                        </p>
                        <div className="flex flex-row items-center justify-between flex-wrap">
                          <div className="flex md:flex-row md:gap-1 items-center flex-wrap justify-between w-full">
                            <div className="flex flex-row gap-2 ">
                              <p className="text-sm font-roboto font-bold text-[#82786E] hidden md:block">
                                {totalMinutes} mn
                              </p>
                              <span className="text-[#82786E] text-sm font-roboto  font-medium hidden md:block ">
                                •
                              </span>
                           
                               {expanded.includes(item.id) ? (
                              <p className="text-[#4A4A49]  text-justify max-w-[400px] text-sm font-roboto   duration-300  ease-in-out">
                                {item.description}
                              </p>
                            ) : (
                              <p className="text-[#4A4A49] duration-300  max-w-[400px]  ease-in-out text-base font-roboto text-justify  ">
                                {item.description?.length > 50
                                  ? item.description.slice(0, 50) + "..."
                                  : item.description}
                              </p>
                            )}
                            </div>
                         
                             {item.description.length > 50 ? (
                            !expanded.includes(item.id) ? (
                              <span
                                className="font-roboto text-base font-normal underline text-black md:hidden cursor-pointer"
                                onClick={() => toggleExpand(item)}
                                id="lire"
                              >
                                Tout lire
                              </span>
                            ) : (
                              <span
                                className="font-roboto text-base font-normal underline text-black md:hidden cursor-pointer"
                                onClick={() => toggleExpand(item)}
                                id="lire"
                              >
                                Voir moins
                              </span>
                            )
                          ) : null}

                            <div className="flex flex-row gap-6 items-center">
                              <p className=" md:text-left hidden md:block text-[#4A4A49] text-base font-roboto">
                                {item.price}€
                              </p>
                              <button
                                className="ml-2  md:ml-0 hidden md:block  px-4 py-3 text-[#0F0F0F] text-sm   hover:bg-black hover:text-white  border-2 border-solid border-black rounded-3xl "
                                onClick={() => handleAddPrestation(item._id )}
                              >
                                Sélectionner
                              </button>
                            </div>
                          </div>
                          <div className="flex justify-between w-full md:w-1/2 ">
                            <div className="flex md:hidden items-center justify-between w-full">
                              <div className="flex flex-row items-center gap-3">
                                <p className="text-sm font-roboto font-bold text-gray-600 ">
                                  {item.duration}
                                </p>
                                <span className="text-[#82786E] text-sm font-roboto  font-medium  ">
                                  •
                                </span>
                                <p className="text-right  md:text-left text-[#4A4A49]">
                                  {item.price}€
                                </p>
                              </div>
                              <button
                                className="ml-2  px-3 py-2 text-[#0F0F0F] text-xs rounded-3xl border border-black hover:opacity-50 "
                                onClick={() => handleAddPrestation(item._id)}
                              >
                                Sélectionner
                              </button>
                            </div>
                          </div>
                        </div>
                      
                         {item.description.length > 50 ? (
                        !expanded.includes(item.id) ? (
                          <label
                            onClick={() => toggleExpand(item)}
                            htmlFor="lire"
                            className="font-roboto text-base font-normal underline text-black hidden md:block cursor-pointer"
                          >
                            Tout lire
                          </label>
                        ) : (
                          <label
                            className="font-roboto text-base font-normal underline text-black hidden md:block cursor-pointer"
                            onClick={() => toggleExpand(item)}
                          >
                            Voir moins
                          </label>
                        )
                      ) : null}
                      </li>
                    );
                }
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrestationModal;
