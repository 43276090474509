import { useState, useEffect, useContext } from "react";
import UpdateAnEmployeeCard from "Component/Reservation/UpdateAnEmployeeCard";
import { Link, useNavigate } from "react-router-dom";
import SalonDataContext from "Context/SalonDataContext";
import Datacontext from "Context/Datacontext";
import moment from "moment";
import toast from "react-hot-toast";

function UpdateReservation() {
  const navigate = useNavigate();
  const [salonLocation, setSalonLocation] = useState([]);
  const { salonForReservation, fetchSalonByidForReservationUpdate } =
    useContext(SalonDataContext);
  const {
    fetchPrestationList,
    prestationList,
    getStaffForUpdate,
    staffIdForUpdate,
    updatePrestationOFreservation,
    prestationToUpdate,setLoading
  } = useContext(Datacontext);

  const slotDataForUpdatePrestation = JSON.parse(
    localStorage.getItem("slotToUpdate") //old slot
  );
  const newSlotDataForUpdatePrestation = JSON.parse(
    localStorage.getItem("setSelectedSlotToUpdate") //new slot
  );
  const id = slotDataForUpdatePrestation&&slotDataForUpdatePrestation?.institute;
  useEffect(() => {
    fetchSalonByidForReservationUpdate(id);
  }, [id]);
  useEffect(() => {
    localStorage.removeItem("salon");
  }, []);
  // set salon GPS position to display a Google Map
  useEffect(() => {
    if (salonForReservation.position) {
      setSalonLocation([
        {
          address: salonForReservation.address,
          lat: salonForReservation.position.lat,
          lng: salonForReservation.position.lng,
        },
      ]);
    }
  }, [salonForReservation]);
  useEffect(() => {
    fetchPrestationList();
    localStorage.removeItem("salon");

  }, []);

  // useEffect(() => {
  //   getStaffForUpdate();
  // }, []);

  const [selectedStaff, setSelectedStaff] = useState(staffIdForUpdate); // State to store the selected staff

  const sendUpdateReservationsToDB = async () => {
    let startTimer = moment(newSlotDataForUpdatePrestation.startTimer);
    let calculateendTimer = moment(startTimer)
      .add(prestationToUpdate.duration, "minutes")
      .utcOffset(0)
      .format();
    let data = {
      staff: selectedStaff,
      institute: slotDataForUpdatePrestation.institute,
      startTimer: newSlotDataForUpdatePrestation.startTimer,
      endTimer: calculateendTimer,
      prestations: prestationToUpdate,
      oldSlot: slotDataForUpdatePrestation._id,
    };
    
    await updatePrestationOFreservation(data);
    // DELETE FROM STORAGE
    localStorage.removeItem("slotToUpdate");
    toast.success("Votre rendez-vous a été modifié avec succès");
    navigate("/profile");
  };

  const handelPrecedentClick = () => {
    localStorage.removeItem("slotToUpdate");
    navigate("/profile");
  };
  return (
    <div className="h-[100vh] bg-cover ">
      <div className="w-full backdrop-blur-md xl:py-[4rem] xl:px-[7.5rem] py-[1rem] md:px-[2rem] px-0 min-h-full">
        <div className="linear-background rounded-3xl md:p-[3.4rem] p-4 max-w-[75rem] m-auto  md:bg-white md:bg-opacity-20 md:backdrop-blur-lg drop-shadow-lg  relative">
          <h1 className="text-beige font-bold font-syne text-2xl ">
            Prestation Update Staff
          </h1>
          <div className="flex xl:flex-row flex-col justify-between gap-10 my-[2rem]">
            <UpdateAnEmployeeCard
              prestation={prestationList}
              salonLocation={salonLocation}
              index={0}
              selectedStaff={selectedStaff}
              setSelectedStaff={setSelectedStaff}
            />
          </div>

          <div className="flex justify-between">

            <button
              type="button"
              onClick={handelPrecedentClick}
              className="  md:flex hidden text-black  bg-[#F6F0E8]  cursor-pointer    items-center  px-6 py-3 rounded-3xl  border-2 border-black font-roboto text-base"
            >
              Précédent
            </button>

            <button
              className="px-10 py-3 text-beige bg-black rounded-3xl text-roboto cursor-pointer"
              onClick={sendUpdateReservationsToDB}
            >
              Terminé
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UpdateReservation;
