import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SalonDataContext from "Context/SalonDataContext";
import prestationContext from "Context/prestationContext";
import LoadSpinner from "Component/utils/LoadSpinner";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { HiOutlineGift, HiOutlineLockClosed } from "react-icons/hi2";
import { storeReservationInDataBase } from "./apiServices";
import toast from "react-hot-toast";
import LoggedContext from "Context/isLogged";
import moment from "moment";
import { RdvNew } from "Component/utils/NotificationCards";

const Checkout = () => {
  const { salon, prestationArray } = useContext(SalonDataContext);
  const {
    setSelectedPrestation,
    selectedPrestation,
    totalPrice,
    totalDuration,
	loadingcheck,
	checkTime,
	checkIfAvailableTime 
  } = useContext(prestationContext);
  const { userGlobal } = useContext(LoggedContext);
  const selectedFreeSlot = JSON.parse(localStorage.getItem("selectedFreeSlot"));
  const timeStartOfSlot = selectedFreeSlot.startTimer;
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const computeEndDateTime = () => {
    let startTime = new Date(timeStartOfSlot);
    const starTimeInMiliseconds = startTime.getTime();
    const endTimeInMiliseconds = starTimeInMiliseconds + totalDuration;
    const endTime = new Date(endTimeInMiliseconds).toISOString();
    return endTime;
  };

  const computeTotalDuration = () => {
    const total = moment.duration(totalDuration);
    let sum = moment.utc(total.asMilliseconds()).format("HH:mm");
    return sum;
  };


  const sendReservationsToDB = async (result) => {
    let endDateTimeComputedFromSumOfDuration = computeEndDateTime();
    let totalDurationOfselectedPrestations = computeTotalDuration();
    let paymentStatus = {
      method: paymentMethod,
      amountPaid: result?.amount,
      datePaid: result?.created,
      description: "some description about the payment",
      status: result?.status,
    };
    let dataToSend = {
      institute: salon._id,
      totalDuration: totalDurationOfselectedPrestations,
      totalPrice: totalPrice,
      payment: [paymentStatus],
      prestations: selectedPrestation,
      customer: {
        _id: userGlobal._id,
        email: userGlobal.email,
        phone: userGlobal.phone,
      },
    };


 	await storeReservationInDataBase(dataToSend)
      .then((res) => {
        if (!res.ok) {
          toast.error("Une erreur est survenue, veuillez réessayer plus tard");
          return;
        }
      })
      .catch((e) => {
        // console.log(e);
      });
    //empty the prestation context and localStorage
    setSelectedPrestation([]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements ) {
      setLoading(false);
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "http://localhost/profile",
      },
      redirect: "if_required",
    });
	
    if (result.error ) {
      // Show error to your customer (for example, payment details incomplete)
      // console.log(result.error.message);
      toast.error(result.error.message);
    } else  {




    let endDateTimeComputedFromSumOfDuration = computeEndDateTime();
    let totalDurationOfselectedPrestations = computeTotalDuration();
    let paymentStatus = {
      method: paymentMethod,
      amountPaid: result?.amount,
      datePaid: result?.created,
      description: "some description about the payment",
      status: result?.status,
    };
    let dataToSend = {
      institute: salon._id,
      totalDuration: totalDurationOfselectedPrestations,
      totalPrice: totalPrice,
      payment: [paymentStatus],
      prestations: selectedPrestation,
      customer: {
        _id: userGlobal._id,
        email: userGlobal.email,
        phone: userGlobal.phone,
      },
    };

    setLoading(true);

    const checkTimeResult = await checkIfAvailableTime(dataToSend);
    if (checkTimeResult) {
    toast.custom(
      <RdvNew
        message={`Vous avez déjà un rendez-vous à cette heure ${moment(selectedPrestation[0].newSlot.startTimer)
          .subtract(1, "hour")
          .locale("fr")
          .format("dddd D MMMM HH:mm")} H`}
       
        toast
      />
    );
    setLoading(false);
    setSelectedPrestation([]);
    setTimeout(() => {
      navigate("/profile");
    }, 2000);
    return;
  }

	
	await	sendReservationsToDB(result.paymentIntent);
  // console.log("payment confirmed", result);
      toast.success("Paiement effectué avec succès");
		setTimeout(() => {
		  navigate("/profile");
		}, 2000);
		// Your customer will be redirected to your `return_url`. For some payment
		// methods like iDEAL, your customer will be redirected to an intermediate
		// site first to authorize the payment, then redirected to the `return_url`.
	  }
    setLoading(false);
  };

  const getPaymentMethod = (e) => {
    setPaymentMethod(e.value.type);
  };

  return (
    <>
      <form
        id="payment-form"
        className="md:w-[530px] w-full p-6 mx-auto border rounded-3xl "
        onSubmit={handleSubmit}
      >
        <div className="flex flex-row items-center bg-[#248E35] text-white text-left md:text-lg p-3 gap-2 mb-2">
          <HiOutlineGift />
          <p className="font-syne text-sm text-justify">
            Payez sur Stunfiy pour cumuler des points sur votre compte
          </p>
        </div>
        <div className="font-syne text-lg text-beige font-bold flex flex-row justify-between pb-2">
          <span>Carte bancaire</span>
          <span className="flex flex-row items-center gap-2">
            <span>
              <HiOutlineLockClosed size={18} />
            </span>
            <span className="font-normal text-sm font-roboto">
              Paiement sécurisé
            </span>
          </span>
        </div>
        <PaymentElement onChange={(e) => getPaymentMethod(e)} />
        <button
          onClick={handleSubmit}
          disabled={loading|| loadingcheck}
          className={`cursor-pointer px-12 py-2 mt-6 float-right text-white bg-black rounded-3xl text-roboto`}
        >
          {loading ? "loading..." : `${loadingcheck ? "check..." : "Confirmer et payer"}`}
        </button>
      </form>
      {loading && <LoadSpinner />}
    </>
  );
};

export default Checkout;
