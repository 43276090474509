import React, { useContext, useState, useEffect, useRef } from "react";
import MapGoogle from "Component/utils/MapGoogle";
import { useParams, useNavigate } from "react-router-dom";
import Avis from "../../Component/Salons/Avis";
import About from "../../Component/Salons/About";
import GalleryModal from "../../Component/Salons/GalleryModal ";
import { FaStar } from "react-icons/fa6";
import { BsGift } from "react-icons/bs";
import { BiGridAlt } from "react-icons/bi";
import { IoIosArrowRoundBack } from "react-icons/io";
import SalonDataContext from "Context/SalonDataContext";
import prestationContext from "Context/prestationContext";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import { NewInfo } from "Component/utils/NotificationCards";
import LoggedContext from "Context/isLogged";

function Salon() {
  const { isAdmin } = useContext(LoggedContext);
  const [imgErr, setimgErr] = useState(["", "", ""]);
  const [staffimgerr, setstaffimgerr] = useState("");

  const handlestaffimgError = () => {
    setstaffimgerr(true);
  };

  const handleError = (n) => {
    let temp = [...imgErr];
    temp[n] = "https://via.assets.so/img.jpg?w=400&h=300";
    setimgErr(temp);
  };

  const { setSelectedPrestation } = useContext(prestationContext);
  const navigate = useNavigate();
  const {
    validateSalon,
    prestationArray,
    salon,
    calculateAverageRating,
    fetchSalonByid,
    openingHoursText,
  } = useContext(SalonDataContext);
  const [salonLocation, setSalonLocation] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    fetchSalonByid(id);
    localStorage.removeItem("selectedFreeSlot");
    localStorage.removeItem("startTimersToSkip");
  }, [id]);

  useEffect(() => {
    if (salon.position) {
      setSalonLocation([
        {
          address: salon.address,
          lat: salon.position.lat,
          lng: salon.position.lng,
        },
      ]);
    }
  }, [salon]);

  const [showModal, setShowModal] = useState(false);

  //  Add a state variable to keep track of expanded items
  const [expanded, setExpanded] = useState([]);

  //  Modify the toggleExpand function
  const toggleExpand = (item) => {
    const temp = [...expanded];
    const index = temp.indexOf(item._id);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(item._id);
    }
    setExpanded(temp);
  };

  //Populate the prestation context with the selected prestation id
  const handleSelectedPrestation = (prestationId) => {
    let uid = uuidv4() + Date.now().toString();
    let _prestationArray = [...prestationArray];
    let newPrestation = _prestationArray.find((item) => item._id === prestationId);
    let _prestation = { ...newPrestation, idStaff: "", uid: uid };
    setSelectedPrestation((prev) => [...prev, _prestation]);
    navigate("/reservation");
  };

  //   track of the selected category
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showAllPrestations, setShowAllPrestations] = useState(false);

  const handleShowAllPrestations = () => {
    setSelectedCategory(null); // Reset selected category
    setShowAllPrestations(true); // Set showAllPrestations to true
  };

  //  handle category selection
  const handleCategorySelect = (category) => {
    setShowAllPrestations(false);
    setSelectedCategory(category);
  };

  // open first Category available
  useEffect(() => {
    const firstAvailableCategory = salon?.prestationsCategories?.find((category) =>
      salon.prestations.some((prestation) => prestation.category === category)
    );

    if (firstAvailableCategory) {
      handleCategorySelect(firstAvailableCategory);
    }
  }, [salon]);

  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const imageWidth = sliderRef.current.scrollWidth / salon.photos.length;
    const newIndex = Math.round(scrollLeft / imageWidth);
    setCurrentIndex(newIndex);
  };
  const scrollToPrestations = () => {
    document.getElementById("prestationsSectionId").scrollIntoView({
      behavior: "smooth",
    });
    // Display the toast message
    toast.custom(
      <NewInfo
        message="Veuillez choisir une prestation pour prendre un rendez-vous"
        toast
      />
    );
  };
  const cardContainerRef = useRef(null);

  const handleHorizontalScroll = (direction) => {
    const container = cardContainerRef.current;
    if (container) {
      const scrollAmount = direction === "next" ? 200 : -200;
      container.scrollLeft += scrollAmount;
    }
  };
  // format time fro hh:mm to minutes
  function calculateTotalMinutes(duration) {
    const [hours, minutes] = duration.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    return totalMinutes;
  }

  return (
    <div className="h-screen">
      <div className="w-full pt-8 pb-4 min-h-screen xl:py-[4rem] xl:px-[7.5rem]  md:px-[2rem] px-0 text-[#F6F0E8]  salonbg1">
        {/* Hero pc */}
        <div className="hidden md:block ">
          <button
            type="button"
            aria-label="Revenir à la page précédente"
            onClick={() => navigate("/search")}
            className=" font-Inter text-base underline-offset-1	flex flex-row gap-1">
            <IoIosArrowRoundBack size={25} color="#D0CBC4" />
            Revenir à ma recherche
          </button>

          <h5 className="font-bold font-syne text-[40px] mb-4 mt-4">
            {salon && salon.name}
          </h5>
          {/* Hero imgs */}
          <div className="flex flex-row  justify-between mb-5">
            <div className="flex flex-row items-center text-[#F6F0E8] gap-1">
              {salon && salon.reviews && salon.reviews.length ? (
                <>
                  <FaStar size={16} color="#F6F0E8" />

                  <span className=" text-sm font-roboto">{calculateAverageRating()}</span>

                  <span className=" text-sm font-roboto ">
                    ({salon && salon.reviews && salon.reviews.length})
                  </span>
                </>
              ) : null}

              <div className=" text-sm font-roboto ml-2">
                {salon && salon.businessHours && openingHoursText}
              </div>
              <li className=" text-sm font-roboto ml-2">{salon?.address}</li>
              {/* <li className=" text-sm font-roboto ml-2"></li> */}
              {/* <li className="bg-[#248E35]  flex flex-row gap-3 items-center lg:py-[6px] lg:px-3  py-2.5 px-3  rounded-[30px]  ">
                <BsGift />
                <p className="lg:text-sm text-xs font-roboto ">Promotions disponibles</p>
              </li> */}
            </div>
            {salon.isValidated ? (
              <button
                onClick={scrollToPrestations}
                className="bg-black hover:bg-white hover:text-black text-[#F6F0E8] rounded-[30px] lg:py-[14px] lg:px-6 py-3 px-3 lg:text-base text-xs ml-2">
                Prendre rendez-vous
              </button>
            ) : (
              isAdmin && (
                <button
                  onClick={() => validateSalon(salon._id)}
                  className="bg-black hover:bg-white hover:text-black text-[#F6F0E8] rounded-[30px] lg:py-[14px] lg:px-6 py-3 px-3 lg:text-base text-xs ml-2">
                  Valider
                </button>
              )
            )}
          </div>

          <div className="flex flex-row h-[390px] gap-2  ">
            {/* Left */}
            <div className="w-1/2  flex flex-row gap-2">
              {salon && salon.photos && salon.photos[0] ? (
                <div className=" w-3/5 relative ">
                  {/* <div className="bg-[#248E35] absolute left-0 top-7 flex flex-row gap-2 items-center py-2 px-3 rounded-br-[30px] rounded-tr-[30px] ">
                    <BsGift />
                    <p className="text-sm font-roboto ">Promotions disponibles</p>
                  </div> */}

                  {salon && salon.photos && salon.photos.length > 2 ? (
                    <div
                      className="bg-[#F6F0E8] absolute cursor-pointer  left-3 bottom-7 flex flex-row gap-2 items-center py-[10.5px] px-4 rounded-[30px] border border-black shadow-[0px_2px_4px_-1px_rgba(0,0,0,0.06),0px_4px_6px_-1px_rgba(0,0,0,0.10)]"
                      onClick={() => setShowModal(true)}>
                      <BiGridAlt size={20} color="#0F0F0F" />

                      <p className="text-sm font-roboto text-black ">Toutes les photos</p>
                    </div>
                  ) : null}

                  <img
                    src={!imgErr[0] ? salon.photos[0] : imgErr[0]}
                    className="  w-full h-full object-cover  rounded-tl-[25px] rounded-bl-[25px] "
                    alt=""
                    onError={() => handleError(0)}
                  />
                </div>
              ) : (
                <div>
                  <img
                    src="https://via.assets.so/img.jpg?w=400&h=300  "
                    className="  w-full h-full object-cover  rounded-tl-[25px] rounded-bl-[25px] "
                    alt=""
                  />
                </div>
              )}

              <div className="flex flex-col w-2/5 gap-2   ">
                {salon && salon.photos && salon.photos[1] ? (
                  <img
                    src={!imgErr[1] ? salon.photos[1] : imgErr[1]}
                    className="  h-[49%] w-full rounded-tr-[25px] object-cover  "
                    alt=""
                    onError={() => handleError(1)}
                  />
                ) : (
                  <img
                    src="https://via.assets.so/img.jpg  "
                    className="  w-full h-full object-cover  rounded-tr-[25px] rounded-br-[25px] "
                    alt=""
                  />
                )}

                {salon && salon.photos && salon.photos[2] ? (
                  <img
                    src={!imgErr[2] ? salon.photos[2] : imgErr[2]}
                    onError={() => handleError(2)}
                    className="w-full h-[49%] rounded-br-[25px] object-cover "
                    alt=""
                  />
                ) : (
                  <img
                    src="https://via.assets.so/img.jpg  "
                    className="  w-full h-full object-cover  rounded-tr-[25px] rounded-br-[25px] "
                    alt=""
                  />
                )}
              </div>
            </div>
            {/* Right */}
            <div className="w-1/2 rounded-3xl overflow-hidden">
              <MapGoogle markers={salonLocation} />
            </div>
          </div>
        </div>
        {/* Hero mobile */}

        <div className=" md:hidden  mx-4 ">
          <div className="flex flex-col w-full ">
            {/* img */}

            <div className="relative">
              <div
                ref={sliderRef}
                className="flex flex-row overflow-x-scroll no-scrollbar snap-x"
                onScroll={handleScroll}
                style={{ scrollSnapType: "x mandatory" }}>
                {salon && salon.photos && salon.photos[1] ? (
                  salon.photos.map((image, index) => {
                    return (
                      <img
                        key={"image" + index}
                        src={image}
                        alt={index}
                        className="snap-center w-full h-[300px]  object-cover "
                      />
                    );
                  })
                ) : (
                  <img
                    src="https://via.assets.so/img.jpg  "
                    className="snap-center w-full h-[300px] rounded-t-lg object-cover   "
                    alt=""
                  />
                )}
              </div>
              {/* Image counter */}
              <div className="bg-black text-white p-[6px] rounded-[30px] text-xs font-roboto absolute right-2 bottom-2  gap-[10px] flex">
                <span>{currentIndex + 1}</span> / <span>{salon.photos?.length}</span>
              </div>
            </div>
            {/* bottom of img */}
            <div className="flex flex-col space-y-2 text-[#F6F0E8] pl-4 pt-2  py-7 salonmobile h-full">
              <div className="flex justify-between pr-3 ">
                <span className="font-syne text-2xl text-black flex items-center  ">
                  {salon && salon.name}
                </span>
                {(isAdmin && !salon.isValidated) && <button
                  onClick={() => validateSalon(salon._id)}
                  className="bg-black px-2 py-2 rounded-full">
                  Validate
                </button>}
              </div>
              <div className="flex flex-row gap-1 items-center ">
                {salon && salon.reviews && salon.reviews.length ? (
                  <>
                    <FaStar size={16} color="#F6F0E8" />

                    <span className=" text-sm font-roboto">
                      {calculateAverageRating()}
                    </span>

                    <span className=" text-sm font-roboto ">
                      ({salon && salon.reviews && salon.reviews.length})
                    </span>
                  </>
                ) : null}
              </div>

              <div className=" text-sm font-roboto ">
                {salon && salon.businessHours && openingHoursText}
              </div>
              <p className=" text-sm font-roboto ">{salon?.address}</p>
            </div>
          </div>
        </div>
        {/* Prestations section*/}
        <h5
          className="font-syne font-bold text-[28px] mx-4 pt-8 md:mx-0 md:pt-4 hidden md:block mt-10  mb-4 "
          id="prestationsSectionId">
          Prestations
        </h5>
        <div className=" flex-row items-center text-[#F6F0E8] gap-1 text-[16px] font-roboto mt-3  hidden md:flex mb-6">
          <p>Paiement en ligne ou sur place</p>
          {salon && salon.wantADeposit && (
            <li className="  ml-2"> Acompte de {salon && salon.wantADeposit}</li>
          )}
          <li className="  ml-2">Confirmation immédiate</li>
        </div>
        <div className="md:flex">
          <div className=" relative w-full p-4 md:w-1/3 md:bgprestations">
            <div
              className="  overflow-x-auto no-scrollbar  mx-auto  w-[70%] md:w-full "
              ref={cardContainerRef}
              style={{ scrollBehavior: "smooth" }}>
              <div className="  md:mx-auto flex flex-row p-2 md:p-0 md:flex-col gap-2 flex-nowrap ">
                {salon?.prestationsCategories
                  ?.filter((category) =>
                    salon.prestations.some(
                      (prestation) => prestation.category === category
                    )
                  )
                  .map((category) => (
                    <button
                      type="button"
                      aria-label={`Selectionner la catégorie de prestation ${category}`}
                      key={category}
                      onClick={() => handleCategorySelect(category)}
                      className={`btnsalonmobile capitalize border truncate border-gray-300 rounded-3xl font-roboto text-xs lg:text-base text-[#0F0F0F] h-11 py-3 px-4 mx min-w-fit md:text-left md:border-0 ${
                        selectedCategory === category
                          ? "md:bg-white md:text-[#0F0F0F] md:font-bold md:rounded-3xl"
                          : "md:text-[#F6F0E8]"
                      }`}>
                      {category}
                    </button>
                  ))}
                <button
                  type="button"
                  onClick={handleShowAllPrestations} // Add this event handler
                  className={`btnsalonmobile capitalize border border-gray-300 rounded-3xl font-roboto text-sm md:text-base text-[#0F0F0F] h-11 py-3 px-4 mx min-w-fit md:text-left md:border-0 ${
                    showAllPrestations
                      ? "md:bg-white md:text-[#0F0F0F] md:font-bold md:rounded-3xl"
                      : "md:text-[#F6F0E8]"
                  }`}>
                  Toutes les prestations
                </button>
                <button
                  onClick={() => handleHorizontalScroll("next")}
                  className="text-white flex md:hidden justify-center items-center absolute z-50 right-5 top-[25%]">
                  <GrFormNextLink
                    size={22}
                    style={{ "--tw-backdrop-blur": "35px", zIndex: 10 }}
                    className="cursor-pointer glass-effect  w-fit h-fit rounded-full p-3 flex items-center gap-1 active:border"
                  />
                </button>

                <button
                  onClick={() => handleHorizontalScroll("previous")}
                  className="text-white flex md:hidden justify-center items-center absolute z-50 left-5 top-[25%]">
                  <GrFormPreviousLink
                    size={22}
                    style={{ "--tw-backdrop-blur": "35px", zIndex: 10 }}
                    className="cursor-pointer glass-effect w-fit h-fit rounded-full p-3 flex items-center gap-1 active:border   "
                  />
                </button>
              </div>
            </div>
          </div>
          {/* list of Prestations section filter-category )  */}

          <div className="  p-4  mx-4  md:mx-0  md:w-2/3 bgprestations rounded-[25px] md:rounded-s-lg mt-2 md:mt-0  ">
            <ul>
              {salon &&
                salon.prestationsCategories &&
                (showAllPrestations
                  ? salon.prestations
                  : salon.prestations.filter(
                      (prestation) => prestation.category === selectedCategory
                    )
                ).map((item, index) => (
                  <li
                    key={item._id}
                    className={`py-4 ${
                      index !==
                      (showAllPrestations
                        ? salon.prestations
                        : salon.prestations.filter(
                            (prestation) => prestation.category === selectedCategory
                          )
                      ).length -
                        1
                        ? "border-b border-black md:border-beige"
                        : ""
                    }`}>
                    <div>
                      <p className="text-sm text-black font-roboto mb-3 capitalize  md:text-base lg:text-lg md:text-beige">
                        {item.name}
                      </p>
                      <div className="flex flex-row items-center justify-between flex-wrap">
                        <div className="flex md:flex-row md:gap-1 items-center flex-wrap justify-between w-full">
                          <div className="flex flex-row gap-2 items-center">
                            <p className="text-sm font-roboto font-bold text-[#82786E] hidden md:block   md:text-[#F6F0E8] ">
                              {calculateTotalMinutes(item.duration)} mn
                            </p>
                            <span className="text-[#82786E]    md:text-[#F6F0E8] text-sm font-roboto  font-medium hidden md:block ">
                              •
                            </span>
                            {expanded.includes(item._id) ? (
                              // Show full description if expanded
                              <p className="text-white text-sm font-roboto  md:text-[#F6F0E8] duration-300  ease-in-out">
                                {item.description}
                              </p>
                            ) : (
                              <p className="text-white duration-300  ease-in-out text-xs font-roboto text-justify md:text-[#F6F0E8]">
                                {item.description?.length > 50
                                  ? item.description.slice(0, 50) + "..."
                                  : item.description}
                              </p>
                            )}
                          </div>
                          {item.description.length > 50 ? (
                            !expanded.includes(item._id) ? (
                              <span
                                className="font-roboto text-base font-normal underline text-black md:hidden cursor-pointer"
                                onClick={() => toggleExpand(item)}
                                id="lire">
                                Tout lire
                              </span>
                            ) : (
                              <span
                                className="font-roboto text-base font-normal underline text-black md:hidden cursor-pointer"
                                onClick={() => toggleExpand(item)}
                                id="lire">
                                Voir moins
                              </span>
                            )
                          ) : null}
                          <div className="flex flex-row gap-6 items-center">
                            <p className=" md:text-left hidden md:block text-[#F6F0E8] text-base font-roboto font-bold">
                              {item.price} CHF <br />
                              {item.oldprice && (
                                <span className="text-[#F6F0E8] line-through">
                                  {item.oldprice} CHF
                                </span>
                              )}
                            </p>
                            <button
                              onClick={() => handleSelectedPrestation(item._id)}
                              className="ml-2 hover:bg-[#F6F0E8] hover:border-[#F6F0E8] hover:text-black  md:ml-0 hidden md:block  px-4 py-3 text-[#F6F0E8]  bg-black text-sm   border-2 border-solid border-black rounded-3xl ">
                              Sélectionner
                            </button>
                          </div>
                        </div>
                        {/* for mobile */}
                        <div className="flex justify-between w-full md:w-1/2 ">
                          <div className="flex md:hidden items-center justify-between w-full">
                            <div className="flex flex-row items-center gap-3">
                              <p className="text-sm font-roboto text-[#F6F0E8]  ">
                                {item.duration}mn
                              </p>
                              <span className="text-sm font-roboto text-[#F6F0E8] ">
                                •
                              </span>
                              <p className=" text-sm font-roboto text-[#F6F0E8]">
                                {item.price}CHF
                              </p>
                            </div>
                            <button
                              onClick={() => handleSelectedPrestation(item._id)}
                              className="  px-3 py-2 text-[#0F0F0F] text-xs rounded-[30px] border bg-[#F6F0E8] border-black">
                              Sélectionner
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* for desktop (Tout lire)*/}
                      {item.description.length > 50 ? (
                        !expanded.includes(item._id) ? (
                          <label
                            onClick={() => toggleExpand(item)}
                            htmlFor="lire"
                            className="font-roboto text-base font-normal underline text-black hidden md:block cursor-pointer">
                            Tout lire
                          </label>
                        ) : (
                          <label
                            className="font-roboto text-base font-normal underline text-black hidden md:block cursor-pointer"
                            onClick={() => toggleExpand(item)}>
                            Voir moins
                          </label>
                        )
                      ) : null}
                    </div>
                  </li>
                ))}
              {selectedCategory && (
                <p
                  onClick={handleShowAllPrestations}
                  className=" cursor-pointer text-base not-italic font-normal leading-[normal] pt-2 underline text-black font-roboto md:text-[#F6F0E8]">
                  Toutes les prestations
                </p>
              )}
            </ul>
          </div>
        </div>
        {salon && salon.reviews?.length > 0 ? <Avis /> : null}
        {/* Equipe  */}
        {salon && salon.staff?.length > 0 ? (
          <div className="mx-4 md:mx-0 ">
            <h5 className=" font-syne bg-bl font-bold text-[28px] mx-4  md:mx-0 md:pt-4 pt-2   mb-5">
              Equipe
            </h5>
            {salon && salon.staff && (
              <div className="glass-effect rounded-3xl p-5  md:mx-0 md:pt-4 grid  grid-cols-2 md:flex-row md:justify-center md:gap-28  md:flex  gap-3">
                {/* item */}
                {salon.staff.map((staffMember) => (
                  <div
                    className="flex flex-col  items-center  justify-center  "
                    key={staffMember._id}>
                    {!staffMember.imgStaff || staffimgerr ? (
                      <div className="bg-[#D0CBC4] mb-2  font-bold capitalize w-[100px] h-[100px] flex flex-col items-center  justify-center rounded-full   text-[32px] font-syne text-[#4A4A49]">
                        {staffMember?.fullName?.charAt(0)}
                      </div>
                    ) : (
                      <div className="w-[100px] h-[100px] rounded-full mb-2">
                        <img
                          src={staffMember.imgStaff}
                          alt={`image de ${staffMember.fullName}`}
                          className=" rounded-full w-[100px] h-[100px] object-cover absolute"
                          onError={handlestaffimgError}
                        />
                      </div>
                    )}
                    <p className="text-[#000] font-bold font-syne text-sm md:text-2xl capitalize  ">
                      {staffMember?.fullName}
                    </p>
                    <p className=" capitalize font-roboto text-[#F6F0E8] text-sm md:text-[#000] ">
                      {staffMember?.post}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null}
        {/* Equipe-end  */}
        {/* À propos (About)  */}

        <About location={salonLocation || ""} salon={salon} />
      </div>
      {showModal ? (
        <GalleryModal setShowModal={setShowModal} slides={salon.photos} />
      ) : null}
    </div>
  );
}

export default Salon;
