import React from "react";

const RdvReminder = ({ message, toast, isNew,timeAgo }) => {
  return (
    <div
      className={`flex flex-row justify-start items-start ${
        toast
          ? " p-5 border-l-8 border-blue-800 dark:bg-black bg-beige max-w-[380px]"
          : "bg-transparent w-full "
      } gap-6 rounded-md`}>
      {/* icon agenda */}
      <span className="relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={`w-8 h-8 p-1 ${(!isNew && !toast) && "text-black bg-beige rounded-full"} ${(toast)&& "dark:text-black text-beige dark:bg-beige bg-black rounded-full"}`}>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
          />
        </svg>
        {(isNew || toast) && <span className="bg-[#FFC77C] w-2 h-2 absolute top-0 right-0 rounded-full"></span>}
      </span>
      <div  className={`flex flex-col justify-start relative ${!toast && 'w-full'} `}>
        {!toast && <span className="absolute -top-2 right-0 text-xs">{timeAgo} </span>}
        <h3 className="text-base">Rappel de rendez-vous</h3>
        <p className="text-sm">{message} </p>
      {toast && <p className="self-end text-xs">maintenant</p>}
      </div>
    </div>
  );
};
const RdvModified = ({ message, toast, isNew,timeAgo }) => {
  return (
    <div
      className={`flex flex-row justify-start items-start ${
        toast
          ? " p-5 border-l-8 border-blue-800 dark:bg-black bg-beige max-w-[380px]"
          : "bg-transparent w-full "
      } gap-6 rounded-md`}>
      {/* icon qrroz pqth */}
      <span className="relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={`w-8 h-8 p-1 ${(!isNew && !toast) && "text-black bg-beige rounded-full"} ${(toast)&& "dark:text-black text-beige dark:bg-beige bg-black rounded-full"}`}>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
          />
        </svg>

        {(isNew || toast) && <span className="bg-[#FFC77C] w-2 h-2 absolute top-0 right-0 rounded-full"></span>}
      </span>
      <div  className={`flex flex-col justify-start relative ${!toast && 'w-full'} `}>
        {!toast && <span className="absolute -top-2 right-0 text-xs">{timeAgo} </span>}
        <h3 className="text-base">Rendez-vous modifié</h3>
        <p className="text-sm">{message} </p>
      {toast && <p className="self-end text-xs">maintenant</p>}
      </div>
    </div>
  );
};
const RdvAborted = ({ message, toast, isNew,timeAgo }) => {
  return (
    <div
      className={`flex flex-row justify-start items-start ${
        toast
          ? " p-5 border-l-8 border-blue-800 dark:bg-black bg-beige max-w-[380px]"
          : "bg-transparent w-full "
      } gap-6 rounded-md`}>
      {/* icon agenda */}
      <span className="relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={`w-8 h-8 p-1 ${(!isNew && !toast) && "text-black bg-beige rounded-full"} ${(toast)&& "dark:text-black text-beige dark:bg-beige bg-black rounded-full"}`}>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>

        {(isNew || toast) && <span className="bg-[#FFC77C] w-2 h-2 absolute top-0 right-0 rounded-full"></span>}
      </span>
      <div  className={`flex flex-col justify-start relative ${!toast && 'w-full'} `}>
        {!toast && <span className="absolute -top-2 right-0 text-xs"> {timeAgo} </span>}
        <h3 className="text-base">Rendez-vous annulé</h3>
        <p className="text-sm">{message} </p>
      {toast && <p className="self-end text-xs">maintenant</p>}
      </div>
    </div>
  );
};
const NewReview = ({ message, toast, isNew,timeAgo }) => {
  return (
    <div
      className={`flex flex-row justify-start items-start ${
        toast
          ? " p-5 border-l-8 border-blue-800 dark:bg-black bg-beige max-w-[380px]"
          : "bg-transparent w-full "
      } gap-6 rounded-md`}>
      {/* icon agenda */}
      <span className="relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={`w-8 h-8 p-1 ${(!isNew && !toast) && "text-black bg-beige rounded-full"} ${(toast) && "dark:text-black text-beige dark:bg-beige bg-black rounded-full"}`}>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
          />
        </svg>

        {(isNew || toast) && <span className="bg-[#FFC77C] w-2 h-2 absolute top-0 right-0 rounded-full"></span>}
      </span>
      <div  className={`flex flex-col justify-start relative ${!toast && 'w-full'} `}>
        {!toast && <span className="absolute -top-2 right-0 text-xs">{timeAgo} </span>}
        <h3 className="text-base">Nouvel avis</h3>
        <p className="text-sm">{message} </p>
      {toast && <p className="self-end text-xs">maintenant</p>}
      </div>
    </div>
  );
};
const NewMessage = ({ message, toast, isNew,timeAgo }) => {
  return (
    <div
      className={`flex flex-row justify-start items-start ${
        toast
          ? " p-5 border-l-8 border-blue-800 dark:bg-black bg-beige max-w-[380px]"
          : "bg-transparent w-full "
      } gap-6 rounded-md`}>
      {/* icon agenda */}
      <span className="relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={`w-8 h-8 p-1 ${(!isNew && !toast) && "text-black bg-beige rounded-full"} ${(toast)&& "dark:text-black text-beige dark:bg-beige bg-black rounded-full"}`}>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
          />
        </svg>

        {(isNew || toast) && <span className="bg-[#FFC77C] w-2 h-2 absolute top-0 right-0 rounded-full"></span>}
      </span>
      <div
        className={`flex flex-col justify-start relative ${!toast && 'w-full'} `}>
        {!toast && <span className="absolute -top-2 right-0 text-xs">{timeAgo} </span>}
        <h3 className="text-base">Nouveau message</h3>
        <p className="text-sm">{message} </p>
        {toast && <p className="self-end text-xs">maintenant</p>}
      </div>
    </div>
  );
};

export { RdvReminder, RdvModified, RdvAborted, NewReview, NewMessage };
