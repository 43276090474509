import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import { HiChevronDown, HiOutlineEmojiSad } from "react-icons/hi";
import { HiOutlineX } from "react-icons/hi";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import FormContext from "Context/FormContext";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Datacontext from "../../Context/Datacontext";
import LoadSpinner from "Component/utils/LoadSpinner";
import SmSpinner from "Component/ui/SmSpinner";

moment.locale("fr");

const DashboardCalendar = ({ setShowModal, selectedName }) => {
  const navigate = useNavigate();

  const {
    slotList,
    fetchSlotList,
    setSelectedSlot,
    selectedSlot,
    clearSlotStyleInLocalStorage,
    loadingSlots,
  } = useContext(FormContext);
  const { prestationToUpdate } = useContext(Datacontext);
  // State variables to track whether there is data for the previous and next weeks
  const [hasDataForPreviousWeek, setHasDataForPreviousWeek] = useState(true);
  const [hasDataForNextWeek, setHasDataForNextWeek] = useState(true);

  const [selectedSlotStyle, setSelectedSlotStyle] = useState(null);

  // Function to handle the "Déplacer mon rendez-vous" button click
  const handleUpdateClick = () => {
    navigate("/updatereservation");
  };

  const closeModale = () => {
    localStorage.removeItem("slotToUpdate");
    setShowModal(false);
    setSelectedSlot(null);
  };
  // Current week's start date using state
  const [currentWeekStartDate, setCurrentWeekStartDate] = useState(
    moment().startOf("week")
  );

  const [myslotToUpdate, setMyslotToUpdate] = useState(null);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("slotToUpdate"));
    setMyslotToUpdate(localStorageData);
  }, []);
  // Fetch data
  useEffect(() => {
    fetchSlotList();
  }, []);

  useEffect(() => {
    // Update hasDataForPreviousWeek and hasDataForNextWeek based on data availability
    if (slotList.length > 0) {
      // Check if there are slots for the previous and next weeks
      const previousWeekStartDate = currentWeekStartDate
        .clone()
        .subtract(1, "week");
      const nextWeekStartDate = currentWeekStartDate.clone().add(1, "week");

      const hasDataForPrevious = slotList.some((slot) =>
        moment(slot.startTimer).isBetween(
          previousWeekStartDate,
          currentWeekStartDate,
          undefined,
          "[]"
        )
      );

      const hasDataForNext = slotList.some((slot) =>
        moment(slot.startTimer).isBetween(
          currentWeekStartDate,
          nextWeekStartDate,
          undefined,
          "[]"
        )
      );

      setHasDataForPreviousWeek(hasDataForPrevious);
      setHasDataForNextWeek(hasDataForNext);
    } else {
      // No data, disable both previous and next weeks
      setHasDataForPreviousWeek(false);
      setHasDataForNextWeek(false);
    }
  }, [slotList, currentWeekStartDate]);
  // Generate an array of dates for the current week (based on the currentWeekStartDate)
  const weekDates = Array.from({ length: 7 }, (_, i) =>
    currentWeekStartDate.clone().add(i, "days")
  );

  const goToPreviousWeek = () => {
    const previousWeekStartDate = currentWeekStartDate
      .clone()
      .subtract(1, "week");

    // Check if there are slots for the previous week
    const hasDataForPreviousWeek = slotList.some((slot) =>
      moment(slot.startTimer).isBetween(
        previousWeekStartDate,
        currentWeekStartDate,
        undefined,
        "[]"
      )
    );

    if (hasDataForPreviousWeek) {
      setCurrentWeekStartDate(previousWeekStartDate);
    }
  };

  // Function to go to the next week
  const goToNextWeek = () => {
    const nextWeekStartDate = currentWeekStartDate.clone().add(1, "week");

    // Check if there are slots for the next week
    const hasDataForNextWeek = slotList.some((slot) =>
      moment(slot.startTimer).isBetween(
        currentWeekStartDate,
        nextWeekStartDate,
        undefined,
        "[]"
      )
    );

    if (hasDataForNextWeek) {
      setCurrentWeekStartDate(nextWeekStartDate);
    }
  };

  // Function to handle slot selection
  const handleSlotSelect = (selectedSlot) => {
    // Toggle the 'isEvent' property and update the selected slot
    if (selectedSlotStyle && selectedSlotStyle.id === selectedSlot._id) {
      // If the selected slot is already blue, clear the style
      clearSlotStyleInLocalStorage();
    } else {
      // Set the style in the state to make it blue
      const slotStyle = {
        id: selectedSlot._id,
        backgroundColor: "green",
      };
      setSelectedSlotStyle(slotStyle);
      localStorage.setItem("selectedSlotStyle", JSON.stringify(slotStyle));
      setSelectedSlot(selectedSlot);
      // save selected slot to local storage for update
      localStorage.setItem(
        "setSelectedSlotToUpdate",
        JSON.stringify(selectedSlot)
      );
    }
  };

  const renderHoursForDay = (day, date, myslotToUpdate) => {
    const formattedDate = date.format("YYYY-MM-DD");
    const slotsForDay = slotList.filter(
      (slot) =>
        moment(slot.startTimer).format("dddd").toLowerCase() === day &&
        moment(slot.startTimer).format("YYYY-MM-DD") === formattedDate &&
        !(slot.isEvent === false && slot.type === "pause")
    );

    if (slotsForDay.length === 0) {
      // No slots available for the day
      return (
        <div className="md:p-1 p-0 md:text-[12px] text-[8px] flex flex-col justify-center">
          <HiOutlineEmojiSad color="#82786E" className="mx-auto" size={20} />
          <span className="xs hidden sm:block">Pas de disponibilité</span>
        </div>
      );
    }

    // Sort the slots by start time
    slotsForDay.sort((slot1, slot2) =>
      moment(slot1.startTimer).isBefore(moment(slot2.startTimer)) ? -1 : 1
    );

    // Calculate the number of slots to skip based on prestationToUpdate.duration
    let slotsToSkip = 0;
    if (prestationToUpdate && prestationToUpdate?.duration) {
      const timeParts = prestationToUpdate.duration.split(":");
      if (timeParts.length === 2) {
        const hours = parseInt(timeParts[0]);
        const minutes = parseInt(timeParts[1]);
        const dynamicDuration = hours * 60 + minutes;
        // Each slot has a fixed duration of 20 minutes
        slotsToSkip = Math.ceil(dynamicDuration / 20);
      }
    }

    // Ensure you don't slice more slots than available
    if (slotsToSkip >= slotsForDay.length) {
      return null; // Return nothing if there are no slots to display
    }

    // Filter out the last slots based on slotsToSkip
    const filteredSlots = slotsForDay.slice(
      0,
      slotsForDay.length - slotsToSkip
    );

    // Group slots by hours
    const uniqueTimePeriods = new Set();
    const groupedSlots = {};

    filteredSlots.forEach((slot) => {
      const startTime = moment(slot.startTimer)
        .subtract(1, "hour")
        .format("HH:00");

      if (!uniqueTimePeriods.has(slot.timerPeriod[0])) {
        uniqueTimePeriods.add(slot.timerPeriod[0]);

        if (!groupedSlots[startTime]) {
          groupedSlots[startTime] = [];
        }
        groupedSlots[startTime].push(slot);
      }
    });

    return (
      <Accordion
        allowZeroExpanded
        preExpanded={[]}
        className="grid grid-cols-1 gap-2 text-xs lg:text-lg bg-[#FFFFFF] overflow-y-scroll h-96 font-roboto"
      >
        {Object.keys(groupedSlots).map((startTime, index) => (
          <AccordionItem key={startTime} uuid={`panel-${index}`}>
            <div className="text-center text-xs lg:text-lg text-black font-semibold">
              <AccordionItemButton className={`group `}>
                <p
                  style={{
                    color:
                      myslotToUpdate &&
                      groupedSlots[startTime]
                        .map((slot) => slot.startTimer)
                        .includes(myslotToUpdate.startTimer)
                        ? "#FFA500"
                        : "",
                  }}
                  className={`flex flex-row  justify-between items-center cursor-pointer  sm:w-9 md:w-20 text-[12px] md:text-[14px] rounded-[10px] md:p-3 p-0 border-t border-r md:h-11 h-8 hover:text-[16px] hover:duration-100 bg-white hover-bg-[#F2F2F2]`}
                >
                  {startTime}
                  <HiChevronDown className="hidden md:block w-6 h-6 transition duration-500 ease-in-out transform scale-y-100 group-data-[state=open]:-scale-y-100" />
                </p>
              </AccordionItemButton>

              <AccordionItemPanel className="border-2 border-[#F2F2F2] w-10 md:w-20 text-[12px] md:text-[14px] rounded-b-[10px] border-t mt-[-7px] trans-bg p-1 z-50">
                {groupedSlots[startTime].map((slot) => (
                  <div
                    key={slot._id}
                    className={` hover-bg-blue-400 cursor-pointer rounded-lg ${ selectedSlotStyle && selectedSlotStyle.id === slot._id?"text-white" :"text-black"}  `}
                    onClick={() => handleSlotSelect(slot)}
                    style={{
                      backgroundColor:
                        selectedSlotStyle && selectedSlotStyle.id === slot._id
                          ? "#44aa42 "
                          : "",
                      color:
                        myslotToUpdate &&
                        myslotToUpdate.startTimer === slot.startTimer
                          ? "#FFA500"
                          : "",
                    }}
                  >
                    {slot.timerPeriod[0]}
                  </div>
                ))}
              </AccordionItemPanel>
            </div>
          </AccordionItem>
        ))}
      </Accordion>
    );
  };

  return (
    <div className={`flex items-center justify-center flex-col`}>
      <div className="absolute w-full h-full"></div>
      <div className="flex-col lg:flex-row py-5 flex">
        <div
          className={`p-1 md:p-4 animate__animated animate__fadeInLeft font-roboto text-base text-[#0F0F0F] text-center bg-[#FFFFFF] flex flex-col rounded-3xl`}
        >
          <div className="flex justify-between mb-4">
            <h5 className="md:text-[18px] not-italic font-bold text-sm py-4">
              Déplacer mon rdv / {selectedName}
            </h5>
            <HiOutlineX
              size={20}
              color="#0F0F0F"
              onClick={closeModale}
              className="cursor-pointer"
            />
          </div>
          <div className="flex justify-between mb-4">
            <div className="mx-auto cursor-pointer" onClick={goToPreviousWeek}>
              <GrFormPrevious size={30} />
            </div>
            <h2 className="text-2xl font-semibold mx-auto">
              {currentWeekStartDate.format("MMMM YYYY")}
            </h2>
            <div className="mx-auto cursor-pointer" onClick={goToNextWeek}>
              <GrFormNext size={30} />
            </div>
          </div>
          {loadingSlots ? (
            <div className="lg:w-[850px] lg:h-[470px]  md:w-[510px]  sm:w-[310px]  p-32 md:p-0  flex items-center justify-center">
              {" "}
              <SmSpinner w={20} h={20} />{" "}
            </div>
          ) : (
            <div className="grid grid-cols-7 gap-x-1 lg:gap-2 p-2 ">
              {weekDates.map((date) => (
                <div key={date.format("YYYY-MM-DD")}>
                  <div className="text-center text-xs lg:text-lg text-black font-semibold">
                    {date.format("dddd")} {/* Full day name */}
                  </div>
                  <div className="text-center text-xs lg:text-lg text-[#82786E]">
                    {/* Day number and abbreviated month name */}
                    {date.format("D MMM")}{" "}
                  </div>
                  <div
                    className={`grid grid-cols-1 gap-2 mt-5 text-xs lg:text-lg bg-[#FFFFFF]  `}
                  >
                    {renderHoursForDay(
                      date.format("dddd").toLowerCase(),
                      date,
                      myslotToUpdate
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="flex justify-between py-4 my-1 mt-1">
            <button
              className="px-7 py-3 bg-white text-black rounded-3xl cursor-pointer border-black border-2 hover:bg-black hover:text-white "
              onClick={closeModale}
            >
              Exit
            </button>
            {selectedSlot ? (
              <button
                className="px-6 py-3 bg-black text-white rounded-3xl cursor-pointer"
                onClick={handleUpdateClick}
              >
                Suivant
              </button>
            ) : (
              <button
                className="px-6 py-3 bg-black text-white rounded-3xl cursor-not-allowed opacity-50 "
                disabled
              >
                Suivant
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCalendar;
