import React, { useState, useEffect, useContext } from "react";
import "styles/dashboard.css";
import { useFormContext } from "../../Context/FormContext";
import toast from "react-hot-toast";
import { PiArrowFatLinesRightDuotone } from "react-icons/pi";
import LoggedContext from "Context/isLogged";

const InformationSection = ({ setViewMode, setSelected }) => {
  const {
    formData,
    submitForm,
    selectedFile,
    setSelectedFile,
    loading,
    error,
    imagePreview,
    setShowModalDeleteacc,
    editedFormData,
    setEditedFormData,
  } = useFormContext();
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phoneLerror, setPhoneLError] = useState("");
  const [imageSelected, setImageSelected] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [loadingform, setLoadingform] = useState(false);
  const [displayLogo, setDisplayLogo] = useState(false);
  const [preview, setPreview] = useState();
  const [formChanged, setFormChanged] = useState(false);
  useEffect(() => {
    setSelectedFile();
  }, []);
  useEffect(() => {
    // Initialize editedFormData
    setEditedFormData({ ...formData });
  }, [formData]);
  useEffect(() => {
    validateForm();
  }, [editedFormData]);
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      setPreview(undefined);
      setImageSelected(false); // No image selected
      return;
    }

    const newSelectedFile = e.target.files[0];
    setSelectedFile(newSelectedFile);
    setDisplayLogo(true);
    setPreview(URL.createObjectURL(newSelectedFile));
    setImageSelected(true); // Image selected
  };
  const isValidEmail = (email) => {
    // Use a more comprehensive email validation regular expression
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    // Validate the form fields and set error messages
    let isFormValid = true;

    if (editedFormData.firstName.trim().length === 0) {
      setFirstNameError("Prénom est requis");
      isFormValid = false;
    } else {
      setFirstNameError("");
    }

    if (editedFormData.lastName.trim().length === 0) {
      setLastNameError("Nom de famille est requis");
      isFormValid = false;
    } else {
      setLastNameError("");
    }

    if (editedFormData.email.trim().length === 0) {
      setEmailError("Email est requis");
      isFormValid = false;
    } else if (!isValidEmail(editedFormData.email)) {
      setEmailError("Email invalide");
      isFormValid = false;
    } else {
      setEmailError("");
    }

    if (editedFormData.phone?.trim().length < 8) {
      setPhoneLError("Numéro de téléphone doit avoir au moins 8 chiffres");
      isFormValid = false;
    } else {
      setPhoneLError("");
    }
    if (editedFormData.phone?.trim().length === 0) {
      setPhoneError("Numéro de téléphone est requis");
      isFormValid = false;
    } else {
      setPhoneError("");
    }

    setFormValid(isFormValid);
    return isFormValid;
  };

  const handleSubmitInfo = async () => {
    // Validate the form fields before submitting
    if (!validateForm()) {
      return;
    }

    // Set loading to true before submitting
    setLoadingform(true);

    try {
      // Submit the form with editedFormData
      await submitForm();
      // If submitForm is successful, show a success toast
      setViewMode(false);
      setSelected(0);
      toast.success("Vos informations ont été modifiées avec succès");
      setDisplayLogo(false);
    } catch (error) {
      // Handle any errors that may occur during form submission
      console.error("Error submitting form:", error);
      toast.error("Une erreur s'est produite");
    } finally {
      // Set loading back to false after submission, whether successful or not
      setLoadingform(false);
    }
  };

  return (
    <div
      id="dashboard_info_section"
      className="bg-sand flex flex-col h-[90%] rounded-3xl p-4 mx-auto md:w-[80%] overflow-y-scroll w-full md:mt-[-20px]">
      <h5 className="text-[#0F0F0F] text-base not-italic font-bold leading-[normal]">
        Coordonnées
      </h5>
      <div className={`flex flex-row items-center lg:mt-10 mt-2`}>
        {imagePreview && (
          <div className="md:w-20 w-10 rounded-full aspect-square ">
            <img
              alt="image de profil"
              src={imagePreview}
              className="md:w-20 w-10 md:h-20 h-10 rounded-full border-[1px] border-mud border-opacity-30"
            />
          </div>
        )}
        {selectedFile && displayLogo && (
          <span>
            {" "}
            <PiArrowFatLinesRightDuotone size={30} />
          </span>
        )}
        {selectedFile && (
          <div className="md:w-20 w-10 rounded-full aspect-square ">
            <img
              alt="nouvelle image de profil"
              src={preview}
              className="md:w-20 w-10 md:h-20 h-10 rounded-full border-[1px] border-mud border-opacity-30"
            />
          </div>
        )}
        <div
          className={` ml-2 rounded-xl border-[1px] border-mud border-opacity-30 py-1 text-black px-4 cursor-pointer  ${
            !selectedFile ? "self-center " : "ml-8"
          }`}>
          <label htmlFor="img" className="cursor-pointer">
            Change Image
          </label>
          <input
            type="file"
            id="img"
            onChange={onSelectFile}
            className="hidden"
            accept="image/*"
          />
        </div>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : (
        <>
          <div className="flex lg:flex-row flex-wrap gap-4 lg:mt-10 mt-2">
            <div className="flex grow flex-col">
              <label>Nom de famille</label>
              <input
                placeholder="Valentin"
                value={editedFormData.lastName}
                onChange={(e) => {
                  setEditedFormData({
                    ...editedFormData,
                    lastName: e.target.value,
                  });
                  setLastNameError(
                    e.target.value.trim() === "" ? "Nom de famille est requis" : ""
                  );
                  setFormChanged(true); // User made changes
                }}
                className={`rounded-xl border-[1px] border-mud border-opacity-30 bg-opacity-0 px-3 py-2 bg-white capitalize ${
                  lastNameError ? "border-red" : ""
                }`}
              />
              {lastNameError && <p className="text-red-500">{lastNameError}</p>}
            </div>
            <div className="flex grow flex-col">
              <label>Prénom</label>
              <input
                placeholder="Coulé"
                value={editedFormData.firstName}
                onChange={(e) => {
                  setEditedFormData({
                    ...editedFormData,
                    firstName: e.target.value,
                  });
                  setFirstNameError(
                    e.target.value.trim() === "" ? "Prénom est requis" : ""
                  );
                  setFormChanged(true); // User made changes
                }}
                className={`rounded-xl border-[1px] border-mud border-opacity-30 bg-opacity-0 px-3 py-2 bg-white capitalize ${
                  firstNameError ? "border-red" : ""
                }`}
              />
              {firstNameError && <p className="text-red-500">{firstNameError}</p>}
            </div>
          </div>
          <div className="flex flex-col lg:mt-10 mt-2">
            <label>Email</label>
            <input
              placeholder="Valentin@email.com"
              value={editedFormData.email}
              onChange={(e) => {
                setEditedFormData({
                  ...editedFormData,
                  email: e.target.value,
                });
                if (e.target.value.trim() === "") {
                  setEmailError("Email est requis");
                } else {
                  setEmailError(!isValidEmail(e.target.value) ? "Email invalide" : "");
                }
                setFormChanged(true); // User made changes
              }}
              type="email"
              className={`rounded-xl border-[1px] border-mud border-opacity-30 bg-opacity-0 px-3 py-2 bg-white ${
                emailError ? "border-red" : ""
              }`}
            />
            {emailError && <p className="text-red-500">{emailError}</p>}
          </div>
          <div className="flex flex-col mt-10 md:mt-2">
            <label>Numéro de téléphone</label>
            <input
              placeholder="+216 23333333"
              value={editedFormData.phone}
              onChange={(e) => {
                const result = e.target.value.replace(/[^\d+]/g, "");

                setEditedFormData({
                  ...editedFormData,
                  phone: result,
                });
                setPhoneError(
                  e.target.value.trim() === "" ? "Numéro de téléphone est requis" : ""
                );
                setFormChanged(true); // User made changes
              }}
              type="tel"
              className={`my-hidden-input rounded-xl border-[1px] border-mud border-opacity-30 bg-opacity-0 px-3 py-2 bg-white ${
                phoneError ? "border-red" : ""
              }`}
            />
            {(phoneError || phoneLerror) && (
              <p className="text-red-500"> {phoneLerror}</p>
            )}
          </div>
          <div className="flex md:flex-row flex-col-reverse gap-3 justify-between lg:mt-10 mt-2">
            <button
              className="rounded-full border-[2px] border-black   px-1.5 py-1 mr-2  md:px-3 md:py-2 hover:opacity-80 hover:bg-black hover:text-white "
              onClick={() => setShowModalDeleteacc(true)}>
              Supprimer mon compte
            </button>
            <div className="flex flex-row justify-between lg:gap-5 gap-2 items-center">
              <p
                className="underline underline-offset-1 cursor-pointer "
                onClick={() => {
                  setViewMode(false);
                  setSelected(0);
                }}>
                Annuler
              </p>

              {selectedFile ? (
                <button
                  className={`bg-black text-white rounded-full lg:px-8 px-3 py-2 ${
                    formValid ? "opacity-100" : "cursor-not-allowed opacity-30"
                  }  ${loadingform ? "cursor-not-allowed opacity-30" : "opacity-100"}`}
                  onClick={handleSubmitInfo}>
                  {loadingform ? "En cours..." : "Sauvegarder"}
                </button>
              ) : (
                <button
                  className={`bg-black text-white rounded-full lg:px-8 px-3 py-2 
                  ${formValid ? "opacity-100" : "cursor-not-allowed opacity-30"}
                    ${
                      loadingform || !formChanged ? "cursor-not-allowed opacity-30" : ""
                    }`}
                  onClick={handleSubmitInfo}
                  disabled={loadingform || !formChanged}>
                  {loadingform ? "En cours..." : "Sauvegarder"}
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InformationSection;
