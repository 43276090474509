import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import { HiOutlineEmojiSad } from "react-icons/hi";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import prestationContext from "Context/prestationContext";
import SmSpinner from "Component/ui/SmSpinner";
import toast from "react-hot-toast";
moment.locale("fr");
const SlotPicker = ({ selectedDurationOfPrestation }) => {
  const {
    freeSlots,
    clearSlotStyleInLocalStorage,
    setSelectedFreeSlot,
    handleCheckboxChangeSave,
    selectedStaff,
    loadingSlots,
    setSelectedindexOfPrestation,
    selectedindexOfPrestation,
    handleCheckboxChangeopen,
    selectedPrestation,
    fetchFreeSlotListData,
    setSelectedStaff,
    setFreeSlots,
  } = useContext(prestationContext);
  const [selectedSlotStyle, setSelectedSlotStyle] = useState(null);
  // Current week's start date using state
  const [currentWeekStartDate, setCurrentWeekStartDate] = useState(
    moment().startOf("week")
  );
  // Get the selected prestations from local storage
  const selectedPrestations = JSON.parse(localStorage.getItem("selectedPrestation"));

  // load first one
  useEffect(() => {
    const firstItem = selectedPrestations[0];

    handleCheckboxChangeopen(firstItem, 0);
  }, []);

  useEffect(() => {
    if (selectedindexOfPrestation === 0) {
      const timerId = setTimeout(() => {
        fetchFreeSlotListData();
      }, 700);

      return () => clearTimeout(timerId);
    } else {
      fetchFreeSlotListData();
      return () => {
        setFreeSlots([]);
      };
    }
  }, [selectedStaff, selectedindexOfPrestation]);

  // Generate an array of dates for the current week (based on the currentWeekStartDate)
  const weekDates = Array.from({ length: 7 }, (_, i) =>
    currentWeekStartDate.clone().add(i, "days")
  );

  // Create an array to store the `idStaff` values
  const selectedStaffIds = selectedPrestations.map((prestation) => prestation?.idStaff);
  const goToPreviousWeek = () => {
    setCurrentWeekStartDate(currentWeekStartDate.clone().subtract(1, "week"));
  };
  const goToNextWeek = () => {
    setCurrentWeekStartDate(currentWeekStartDate.clone().add(1, "week"));
  };
  // Function to handle slot selection
  const handleSlotSelect = (selectedFreeSlot) => {
    if (selectedSlotStyle && selectedSlotStyle.id === selectedFreeSlot._id) {
      // If the selected slot is already blue, clear the style
      clearSlotStyleInLocalStorage();
      setSelectedFreeSlot(null);
    } else {
      const slotStyle = {
        id: selectedFreeSlot._id,
        backgroundColor: "blue",
      };
      setSelectedSlotStyle(slotStyle);
      localStorage.setItem("selectedFreeSlotStyle", JSON.stringify(slotStyle));
      setSelectedFreeSlot(selectedFreeSlot);
      localStorage.setItem("selectedFreeSlot", JSON.stringify(selectedFreeSlot));
      handleCheckboxChangeSave(selectedindexOfPrestation);
      const nextIndex = selectedindexOfPrestation + 1;

      if (nextIndex < selectedPrestations.length) {
        const item = selectedPrestations[nextIndex];
        handleCheckboxChangeopen(item, nextIndex);
        setSelectedindexOfPrestation(nextIndex);
        toast.success("Créneau sélectionné avec succès pour cette présentation !");
      } else {
        toast.success("Heure choisie avec succès pour votre rendez-vous! ");
      }
    }
  };

  const renderHoursForDay = (day, date) => {
    // get prev data
    let prevStartTimer;
    if (
      selectedindexOfPrestation > 0 &&
      selectedindexOfPrestation < selectedPrestation.length
    ) {
      prevStartTimer =
        selectedPrestation[selectedindexOfPrestation - 1].newSlot?.startTimer;
    }

    let slotsToSkipFromUptime = 0;
    if (
      selectedindexOfPrestation > 0 &&
      selectedindexOfPrestation < selectedPrestation.length
    ) {
      // Get the previous startTimer value

      const timeParts =
        selectedPrestation[selectedindexOfPrestation - 1].duration.split(":");
      if (timeParts.length === 2) {
        const hours = parseInt(timeParts[0]);
        const minutes = parseInt(timeParts[1]);
        const dynamicDuration = hours * 60 + minutes;
        // Each slot has a fixed duration of 20 minutes
        slotsToSkipFromUptime = Math.ceil(dynamicDuration / 20);
      }
    }
    //
    const formattedDate = date.format("YYYY-MM-DD");
    const slotsForDay = freeSlots.filter(
      (slot) =>
        moment(slot.startTimer).format("dddd").toLowerCase() === day &&
        moment(slot.startTimer).format("YYYY-MM-DD") === formattedDate &&
        !(slot.isEvent === false && slot.type === "pause")
    );
    if (slotsForDay.length === 0) {
      // No slots available for the day
      return (
        <div className="md:p-1 p-0 md:text-[12px] text-[8px] flex flex-col justify-center text-[#F6F0E8]">
          <HiOutlineEmojiSad color="#F6F0E8" className="mx-auto" size={20} />
          <span className="xs:hidden sm:block">Pas de disponibilité</span>
        </div>
      );
    }
    // // Sort the slots by start time
    slotsForDay.sort((slot1, slot2) =>
      moment(slot1.startTimer).isBefore(moment(slot2.startTimer)) ? -1 : 1
    );
    let holDobj = {};
    let newFilteredSlots = [];
    slotsForDay.forEach((slot) => {
      if (!holDobj[slot.startTimer]) {
        newFilteredSlots.push(slot);
        holDobj[slot.startTimer] = true;
      }
    });
    // Calculate the number of slots to skip based on duration

    let slotsToSkip = 0;
    if (selectedDurationOfPrestation) {
      const timeParts = selectedDurationOfPrestation.split(":");
      if (timeParts.length === 2) {
        const hours = parseInt(timeParts[0]);
        const minutes = parseInt(timeParts[1]);
        const dynamicDuration = hours * 60 + minutes;
        // Each slot has a fixed duration of 20 minutes
        slotsToSkip = Math.ceil(dynamicDuration / 20);
      }
    }
    // Ensure you don't slice more slots than available
    if (slotsToSkip >= newFilteredSlots.length) {
      return null; // Return nothing if there are no slots to display
    }
    // Filter out the last slots based on slotsToSkip
    const length = newFilteredSlots.length;
    const slotsToSkipParm = length - slotsToSkip;
    const filteredSlots = newFilteredSlots.slice(0, slotsToSkipParm);

    // Find the index of the slot with the specified start timer
    const indexToSkipFrom = filteredSlots.findIndex(
      (slot) => moment(slot.startTimer).toISOString() === prevStartTimer
    );

    const startTimersToSkip = JSON.parse(localStorage.getItem("startTimersToSkip")) || [];

    // Assuming this is where you update startTimersToSkip
    const updatedStartTimersToSkip = filteredSlots
      .slice(indexToSkipFrom, indexToSkipFrom + slotsToSkipFromUptime)
      .map((slot) => slot.startTimer);

    // Combine the existing and updated arrays without duplicates
    const newStartTimersToSkip = Array.from(
      new Set([...startTimersToSkip, ...updatedStartTimersToSkip])
    );
    // Save the new array to localStorage
    localStorage.setItem("startTimersToSkip", JSON.stringify(newStartTimersToSkip));
    const uniqueTimePeriods = new Set();
    const groupedSlots = {};
    filteredSlots.forEach((slot) => {
      const startTime = moment(slot.startTimer).subtract(1, "hour").format("HH:00");
      // Skip slots if the startTimer is in the startTimersToSkip array of selected rdv
      if (startTimersToSkip.includes(slot.startTimer)) {
        return;
      }
      if (!uniqueTimePeriods.has(slot.timerPeriod[0])) {
        uniqueTimePeriods.add(slot.timerPeriod[0]);
        if (!groupedSlots[startTime]) {
          groupedSlots[startTime] = [];
        }
        groupedSlots[startTime].push(slot);
      }
    });
    return (
      <Accordion
        allowZeroExpanded
        preExpanded={[]}
        className={`grid grid-cols-1 gap-2 text-xs lg:text-lg  overflow-y-scroll h-96 overflow-x-hidden font-roboto`}>
        {Object.keys(groupedSlots).map((startTime, index) => (
          <AccordionItem key={startTime} uuid={`panel-${index}`}>
            <div className="text-center text-xs lg:text-lg text-black font-semibold">
              <AccordionItemButton className={`group `}>
                <div
                  className={`flex flex-row   justify-center items-center cursor-pointer w-9 md:w-16 text-[11px] md:text-[14px]  bg-[#F6F0E8]  rounded-[10px] md:p-3 p-0  border-t border-r md:h-11 h-7    hover:text-[16px] hover:duration-100`}>
                  {startTime}
                </div>
              </AccordionItemButton>
              <AccordionItemPanel className="  border-2 border-[#F2F2F2] w-9 md:w-16 text-[12px] md:text-[14px]  bg-[#F6F0E8] rounded-b-[10px] border-t-dark_grey mt-[-7px]">
                {groupedSlots[startTime].map((slot) => (
                  <div
                    key={slot._id}
                    className="cursor-pointer hover:bg-blue-400 rounded-[30px]"
                    onClick={() => handleSlotSelect(slot)}
                    style={{
                      backgroundColor:
                        selectedSlotStyle && selectedSlotStyle.id === slot._id
                          ? "#1A43BF"
                          : "",
                      color: `${
                        selectedSlotStyle && selectedSlotStyle.id === slot._id
                          ? "#F2F2F2"
                          : "black"
                      }`,
                      fontWeight: "normal",
                    }}>
                    {slot.timerPeriod[0]}
                  </div>
                ))}
              </AccordionItemPanel>
            </div>
          </AccordionItem>
        ))}
      </Accordion>
    );
  };
  return (
    <div className={`flex items-center justify-center flex-col`}>
      <div className="flex-col lg:flex-row  flex">
        {loadingSlots ? (
          <div className="w-[600px] h-[40%] flex items-center justify-center">
            {" "}
            <SmSpinner w={10} h={10} />{" "}
          </div>
        ) : (
          <div
            className={`p-1  font-roboto text-base text-[#0F0F0F] text-center  flex flex-col rounded-3xl`}>
            <div className="flex flex-col mb-2">
              <h1 className="md:text-[24px] not-italic font-bold text-base font-syne py-1 md:text-left text-center text-white ">
                Date & heure{" "}
              </h1>
              <h2 className="text-xs  md:text-base font-syne py-1 text-[#F34848] md:text-left text-center ">
                Veuillez choisir un horaire pour votre prestation{" "}
              </h2>
              <hr></hr>
            </div>
            <div className="flex justify-between mb-4  ">
              <div
                className="mx-auto text-[#F6F0E8] cursor-pointer hover:border-b-2 hover:border-[#F6F0E8}"
                onClick={goToPreviousWeek}>
                <GrFormPrevious size={30} color="#F6F0E8" />
              </div>
              <h2 className="text-2xl font-semibold mx-auto">
                {currentWeekStartDate.format("MMMM YYYY")}
              </h2>
              <div
                className="mx-auto text-[#F6F0E8] cursor-pointer hover:border-b-2 hover:border-[#F6F0E8}"
                onClick={goToNextWeek}>
                <GrFormNext size={30} color="#F6F0E8" />
              </div>
            </div>
            <div className="grid grid-cols-7 gap-x-1 lg:gap-2 p-2">
              {weekDates.map((date) => (
                <div key={date.format("YYYY-MM-DD")}>
                  <div className="text-center text-xs lg:text-lg text-[#F6F0E8] font-semibold">
                    {date.format("dddd")} {/* Full day name */}
                  </div>
                  <div className="text-center text-xs lg:text-lg text-[#F6F0E8]">
                    {/* Day number and abbreviated month name */}
                    {date.format("D MMM")}{" "}
                  </div>
                  <div className={`grid grid-cols-1 gap-2 mt-5 text-xs lg:text-lg   `}>
                    {renderHoursForDay(date.format("dddd").toLowerCase(), date)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default SlotPicker;
