import { useState, useEffect, useContext } from "react";
import AgendaContext from "Context/AgendaContext";
import { HiOutlineUserCircle, HiOutlineClipboard, HiX } from "react-icons/hi";
import { useModalContext } from "Context/ModalContext";
import Newclient from "./Newclient";
import DateView from "./DateView";
import moment from "moment";
import SmSpinner from "Component/ui/SmSpinner";

const NewRDV = ({ selectionMode, bodySelector, setSelectionMode }) => {
  const [ToggleClient, SetToggle] = useState(false);
  const [Condition, setCondition] = useState(true);

  const { closeModal, Employee, generatesixDays } = useContext(AgendaContext);
  const {
    postLoading,
    Postreservation,
    SetEmployee,
    SelectedPrestation,
    SetSelctedDate,
    SetselectedPres,
    SetSelectedclient,
    SelectedClient,
    EmployeeSelected,
    FetchClient,
    Body,
    SetBody,
  } = useModalContext();
  useEffect(() => {
    SetSelectedclient("");
    SetselectedPres("");
    SetSelctedDate(moment());
    SetEmployee(Employee[0]?._id);
    generatesixDays(moment());
  }, []);
  useEffect(() => {
    if (Body.slotids && Body.staffid && Body.clientid && Body.prestationid) {
      setCondition(false);
    } else {
      setCondition(true);
    }
  }, [Body]);

  return (
   
        <div>
          {(selectionMode && bodySelector) || (
            <div className="flex flex-col  gap-3">
              <div className="flex justify-end">
                <HiX
                  onClick={() => closeModal()}
                  className="translate-x-5 cursor-pointer -translate-y-9"
                  size={25}
                />
              </div>
              {(SelectedClient.firstName && (
                <div className="modalElement_eventModal bg-black bg-opacity-60 border-[1px] border-[#4A4A49] p-4 rounded-md max-h-[9.3rem] grid grid-cols-4  row-span-2 items-center">
                  <div className="flex gap-2 col-span-3">
                    <HiOutlineUserCircle size={24} />
                    <p className="text-xl">
                      {SelectedClient.firstName + " " + SelectedClient.lastName || ""}
                    </p>
                  </div>
                  <p
                    className="col-start-4 flex justify-end underline underline-offset-2"
                    onClick={() => setSelectionMode("client")}>
                    Modifier
                  </p>
                  <div
                    style={{ background: "#252525" }}
                    className="p-2 px-4 col-span-4 my-2 rounded-md">
                    <p>{SelectedClient.email || ""}</p>
                    <p>{SelectedClient.phone || ""}</p>
                  </div>
                </div>
              )) || (
                <div className="modalElement_eventModal bg-black bg-opacity-60 border-[1px] border-[#4A4A49] p-4 rounded-md items-center">
                  <div className="flex flex-col justify-between">
                    <div className="flex justify-between">
                      <div className="flex gap-2 col-span-3 items-center ">
                        <HiOutlineUserCircle size={24} />{" "}
                        <p className="text-xl ">Client</p>
                      </div>
                      <p
                        className="cursor-pointer col-start-4 flex justify-end underline underline-offset-2"
                        onClick={() => SetToggle(!ToggleClient)}>
                        {!ToggleClient ? "Nouveau client" : "Annuler"}
                      </p>
                    </div>

                    {ToggleClient && (
                      <Newclient
                        SetSelectedclient={SetSelectedclient}
                        Body={Body}
                        SetBody={SetBody}
                        EmployeeSelected={EmployeeSelected}
                      />
                    )}
                  </div>
                  <button
                    className=" rounded-full bg-black text-sand border-2 border-sand px-4 py-2 mt-4 "
                    onClick={() => {
                      setSelectionMode("client");
                    }}>
                    Selectionner un client
                  </button>
                </div>
              )}

              <div className="modalElement_eventModal bg-black bg-opacity-60 border-[1px] border-[#4A4A49] p-4 rounded-md items-center">
                <div className="flex justify-between items-center">
                  <div className="flex gap-3 col-span-3 items-center ">
                    <HiOutlineUserCircle size={24} /> <p className="text-xl ">Staff</p>
                  </div>
                  <select
                    value={EmployeeSelected}
                    onChange={(e) => {
                      SetBody({ ...Body, staffid: e.target.value });
                      SetEmployee(e.target.value);
                    }}
                    className="hover:cursor-pointer border-2 my-1 rounded-full w-2/5 bg-black  p-2 px-4 flex justify-between ">
                    {Employee.map((item, i) => (
                      <option className="cursor-pointer" key={item._id} value={item._id}>
                        {item.fullName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {(SelectedPrestation.name && (
                <div className="modalElement_eventModal bg-black bg-opacity-60 border-[1px] border-[#4A4A49] px-4 py-4 rounded-md flex flex-col gap-2">
                  <div className="flex gap-2 justify-between">
                    <div className="flex gap-3 items-center">
                      {" "}
                      <HiOutlineClipboard size={24} />{" "}
                      <p className="text-xl ">Prestation</p>
                    </div>
                    <p
                      onClick={() => setSelectionMode("prestation")}
                      className="cursor-pointer text-sm underline  ">
                      Modifier
                    </p>
                  </div>
                  <div className="bg-[#252525] flex justify-between  p-2">
                    <div>
                      <p className="text-base">{SelectedPrestation.name}</p>
                      <p className="text-[#A29689] text-sm">
                        {SelectedPrestation.duration}mn
                      </p>
                    </div>
                    <div className="flex items-center">
                      <p className="-translate-x-2">{SelectedPrestation.price}£</p>
                    </div>
                  </div>
                </div>
              )) || (
                <div className="modalElement_eventModal bg-black bg-opacity-60 border-[1px] border-[#4A4A49] px-2 py-4 rounded-md items-center">
                  <div className="flex gap-2 col-span-3">
                    <HiOutlineClipboard size={24} />{" "}
                    <p className="text-xl ">Prestation</p>
                  </div>
                  <button
                    className=" rounded-full bg-black text-sand border-2 border-sand px-4 py-2 mt-4 "
                    onClick={() => setSelectionMode("prestation")}>
                    Choisir une prestation
                  </button>
                </div>
              )}

              {SelectedPrestation !== "" && SelectedClient !== "" && <DateView />}

              <div className=" flex justify-between items-center">
                <button
                  onClick={() => closeModal()}
                  className="rounded-full bg-black border-2 border-slate-300 py-4 px-6 text-white">
                  Annuler
                </button>
                <button
                  onClick={() => {
                    Postreservation();
                    // console.log(Body)
                  }}
                  disabled={Condition}
                  className={`rounded-full bg-sand py-4 px-6 text-black ${
                    Condition ? "opacity-40" : "opacity-100"
                  }`}>
                 {postLoading && <SmSpinner w={5} h={5} />} Valider
                </button>
              </div>
            </div>
          )}
        </div>
      
    
  );
};

export default NewRDV;
