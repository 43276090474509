import React from 'react'

const PrestationAutoComplete = () => {

  return (
    <div className='absolute rounded-2xl top-6 left-8 xs:min-w-fit md:min-w-[43%]  md:w-[43%] overflow-hidden' >
      <p className=' cursor-pointer     px-2 py-1 top-44 hover:bg-black hover:text-white h-fit bg-beige text-[#714940] text-sm w-[100%] flex flex-wrap'>Test</p>
      <p className=' cursor-pointer     px-2 py-1 top-44 hover:bg-black hover:text-white h-fit bg-beige text-[#714940] text-sm w-[100%] flex flex-wrap'>Test</p>
      <p className=' cursor-pointer     px-2 py-1 top-44 hover:bg-black hover:text-white h-fit bg-beige text-[#714940] text-sm w-[100%] flex flex-wrap'>Test</p>
      <p className=' cursor-pointer     px-2 py-1 top-44 hover:bg-black hover:text-white h-fit bg-beige text-[#714940] text-sm w-[100%] flex flex-wrap'>Test</p>

      
      
     
    </div>
  )
}

export default PrestationAutoComplete
