import React, { useState, useEffect } from "react";
import "styles/phoneInput.css";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { useNewInstitute } from "Context/InstituteAuthContext";
import SmSpinner from "Component/ui/SmSpinner";

const PhoneNumberInput = ({ setIsPossiblePhoneNumber, checking }) => {
  const [value, setValue] = useState("");
  const { newInstitute, setNewInstitute, loading, phoneAvailability, checkPhone } =
    useNewInstitute();

  useEffect(() => {
    if (!loading) {
      if (value !== undefined || newInstitute.phoneNumber) {
        if (
          !value &&
          newInstitute.phoneNumber &&
          !phoneAvailability &&
          isPossiblePhoneNumber(newInstitute.phoneNumber)
        )
          checkPhone(newInstitute.phoneNumber);
        else if (value && isPossiblePhoneNumber(value)) checkPhone(value);
      }
      if (
        value !== undefined &&
        isPossiblePhoneNumber(value) &&
        phoneAvailability &&
        !newInstitute.phoneNumber
      ) {
        setNewInstitute((user) => {
          return { ...user, phoneNumber: value };
        });
        setIsPossiblePhoneNumber(true);
      } else if (
        (newInstitute.phoneNumber && !phoneAvailability) ||
        (value && !isPossiblePhoneNumber(value)) ||
        (newInstitute.phoneNumber && !isPossiblePhoneNumber(newInstitute.phoneNumber))
      ) {
        setIsPossiblePhoneNumber(false);
      } else if (
        (newInstitute.phoneNumber && phoneAvailability) ||
        (value && isPossiblePhoneNumber(value))
      ) {
        setIsPossiblePhoneNumber(true);
        setNewInstitute((user) => {
          return { ...user, phoneNumber: value };
        });
      } else {
        setNewInstitute((user) => {
          return { ...user, phoneNumber: "" };
        });
      }
    }
  }, [value, phoneAvailability, newInstitute.phoneNumber]);

  const checkPhoneStyle = () => {
    let isPhoneNumberValid =
      (newInstitute.phoneNumber || value) &&
      ((value && isPossiblePhoneNumber(value)) ||
        (newInstitute.phoneNumber && isPossiblePhoneNumber(newInstitute.phoneNumber)));
    isPhoneNumberValid =
      value && newInstitute.phoneNumber
        ? isPossiblePhoneNumber(value)
        : isPhoneNumberValid;
    if ((isPhoneNumberValid && !phoneAvailability) || (!isPhoneNumberValid && value)) {
      return "border-orange-700 border";
    } else if (
      isPhoneNumberValid &&
      phoneAvailability &&
      ((!newInstitute.phoneNumber && value) ||
        (newInstitute.phoneNumber && value && phoneAvailability))
    ) {
      return "border-green-300 border-[1px]";
    } else if (checking) {
      return "blink border";
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="flex grow items-center ">
        {loading && <SmSpinner h={5} w={5} />}
        <PhoneInput
          style={{ backgroundColor: "black" }}
          id="telephone"
          placeholder={"Numéro de téléphone"}
          value={value || newInstitute.phoneNumber}
          defaultCountry={"CH"}
          onChange={setValue}
          // onBlur={() => value && isPossiblePhoneNumber(value) && checkPhone(value) }
          rules={{ required: true, validate: isPossiblePhoneNumber }}
          // style={
          //   phoneAvailability
          //       ? { outline: "1px solid green", flexGrow: 1 }
          //       : { outline: "1px solid rgb(252 165 165)", flexGrow: 1 }

          // }
          className={`grow  ` + checkPhoneStyle()}
        />
      </div>
      {(phoneAvailability === false &&
        value !== undefined &&
        isPossiblePhoneNumber(value)) ||
        (phoneAvailability === false && newInstitute.phoneNumber && (
          <>
            <span className="text-xs text-red-400">Ce numéro existe déjà </span>
            <br />
          </>
        ))}
      {value !== undefined && value !== "" && !isPossiblePhoneNumber(value) && (
        <span className="text-xs text-red-400">
          Renseignez un numéro de téléphone valide{" "}
        </span>
      )}
    </>
  );
};

export default PhoneNumberInput;
