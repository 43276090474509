import React from 'react'
//icons
import { HiOutlineTrash } from "react-icons/hi2";

const ImageFromUrl = ({ deletePhotosUrl, index, photoUrl}) => {
  return (
		<div className='aspect-video rounded-md relative  w-60 min-[500px]:w-72 sm:w-96 shrink-0'>
			<img src={photoUrl} alt=' salon' width={400} height={400} className='aspect-video object-cover object-center rounded-md bg-slate-200 skeleton' />
			{/* trashbin */}
			<HiOutlineTrash className='w-7 h-7 absolute top-1 right-1 dark:bg-black bg-beige rounded-full p-1 cursor-pointer ' onClick={() => deletePhotosUrl(index)} />
		</div>
  );
}

export default ImageFromUrl