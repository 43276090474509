import { disconnectUserInBackend } from "Component/utils/connectionHandler";
import axios from "axios";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import LoggedContext from "Context/isLogged";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}`,
});

const UNAUTHORIZED = 401;

const AxiosInterceptor = ({ children }) => {
  const [isSet, setIsSet] = useState(false);
  const token = Cookies.get("authToken");

  const { setIsLogged, setUserGlobal, setIsPro, isPro } =
    useContext(LoggedContext);

  useEffect(() => {
    const handleUserDisconnection = () => {
      disconnectUserInBackend(token)
        .then((res) => {
          if (!res.ok) {
            console.error("error desconnecting the user");
          }
          Cookies.remove("authToken");
          setIsLogged(false);
          setUserGlobal({});
          if (isPro) setIsPro(false);
          window.location.href = "/login";
        })
        .catch((err) => {
          console.error("err", err);
          toast.error(err);
        });
    };

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === UNAUTHORIZED) {
          handleUserDisconnection();
          toast.error("Unauthorized or token expired");
          console.error("err 401", error.response);
        }
        console.error("error", error);
        if (error.response?.data && typeof error.response?.data === 'string' || error.response?.data instanceof String) {
          toast.error(error.response?.data);
        }
        return Promise.reject(error);
      }
    );

    axiosInstance.interceptors.request.use((config) => {
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    });

    setIsSet(true);
  }, [token]);

  return isSet && children;
};

export default axiosInstance;
export { AxiosInterceptor };
