import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { useContext, useState } from "react";
import Datacontext from "../../Context/Datacontext";
import { useNavigate } from "react-router-dom";
import PrestationAutoComplete from "Component/Home/PrestationAutoComplete";

const Searchbar = () => {
  const { setFilterlist, FilterList } = useContext(Datacontext);
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");

  const Navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterlist({ ...FilterList, name, city: address.split(",")[0] });
    Navigate("/search");
  };
  return (
    <div className="md:container md:mx-auto max-h-[70%] flex flex-row justify-center items-center mt-20 z-20 overflow-visible  ">
      {/* tablet and desktop Form */}
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="animate__animated md:max-h-[60%] md:h-[60%] overflow-visible   animate__fadeInRight w-2/3 max-w-[805px]  m-3 hidden sm:flex flex-col md:flex-row  justify-between items-center bg-beige   rounded-full p-2">
        <div
          className={`relative flex flex-row md:border-r border-dark_grey w-full md:w-1/2`}>
          {/* SearchIcon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="text-[#716960]  w-6 h-6  top-1/2  mr-3">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>

          <label htmlFor="what" className="sr-only">
            <span className="sr-only ml-2">prestation, nom du salon</span>
          </label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="what"
            className=" bg-transparent xs:p-0 py-3 md:py-0 pl-10 pr-10 placeholder:text-[#716960] text-[#716960] w-full"
            placeholder="Prestation, nom du salon..."
          />
           {name && <PrestationAutoComplete/>}
        </div>
      
        <div className="z-40 relative w-full md:w-1/3">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="text-[#716960] w-6 h-6 absolute top-1/2 left-0 md:left-2 transform -translate-y-1/2">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
              />
            </svg>
          </span>
          <label htmlFor="where" className="sr-only">
            <span className="sr-only">Adresse, ville ...</span>
          </label>
          <PlacesAutocomplete
            searchOptions={{ types: ["(cities)"] }}
            value={address}
            onChange={setAddress}
            onSelect={setAddress}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="text-[#716960] w-6 h-6 absolute top-1/2 left-0 md:left-2 transform -translate-y-1/2">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                    />
                  </svg>
                </span>
                <input
                  className="w-full bg-transparent py-3 md:py-0  pl-10 text-[#716960] l placeholder:text-[#716960] "
                  {...getInputProps({ placeholder: "Genève" })}></input>
                <div className="absolute rounded-2xl min-w-full overflow-hidden">
                  {suggestions.map((suggestion) => {
                    return (
                      <div
                        key={suggestion._id}
                        {...getSuggestionItemProps(suggestion)}
                        className=" cursor-pointer min-w-full  px-2 py-1 top-44 hover:bg-black hover:text-white h-fit bg-beige text-[#716960] text-sm w-[100%] flex flex-wrap">
                        {suggestion.terms[0].value}, {suggestion.terms[1]?.value}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          {/* <input
            value={city}
            onChange={(e) => setCity(e.target.value)}
            id="where"
            placeholder="ville..."
            className="w-full bg-transparent py-3 md:py-0  pl-10 text-[#716960] placeholder:text-[#716960] "
          /> */}
        </div>
        <input type="submit" className="btn-dark px-3 w-full md:w-fit cursor-pointer" />
      </form>
    </div>
  );
};

export default Searchbar;
