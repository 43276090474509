import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ProPartnersMob from "../../Component/utils/ProPartnersMob";
import { FaArrowRight } from "react-icons/fa6";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { useContext, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";

import Datacontext from "../../Context/Datacontext";
import PrestationAutoComplete from "./PrestationAutoComplete";

const HomeMob = () => {
  const { setFilterlist, FilterList } = useContext(Datacontext);
  const [address, setAddress] = useState("");

  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const Navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterlist({ ...FilterList, name : address.split(',')[0] });
    Navigate("/search");
  };
  return (
    <div className="h-fit w-screen overflow-hidden">
      {/* first page */}
      <div className="h-[60vh] w-screen justify-center flex flex-col items-center px-3 ">
        <div>
          <h1 className="text-3xl font-syne tracking-wider text-white  text-center ">
            Un rendez-vous <br /> tout de suite
          </h1>
          <h3 className="font-bold  text-xl my-2 font-syne text-white tracking-wider mb-0 text-center">
            Dans votre salon de beauté & coiffure
          </h3>
        </div>
        <div className="flex flex-row items-center justify-between w-[86%] h-[10%] p-2 glass-effect rounded-full mt-4 min-h-[53px] text-beige pr-0 ">
          <HiMagnifyingGlass size={20} className="ml-2" />
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-[40%] bg-transparent placeholder:text-white px-3 border-r-2 placeholder:font-syne border-white text-white"
            type="text"
            placeholder="Coiffeurs"
          />
          {name && <PrestationAutoComplete/>}
          <PlacesAutocomplete
            searchOptions={{ types: ["(cities)"] }}
            value={address}
            onChange={setAddress}
            onSelect={setAddress}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="w-[40%]">
                <input
                  type="text"
                  placeholder="Genève "
                  className="w-[90%] bg-transparent placeholder:text-white px-3 placeholder:font-syne"
                  {...getInputProps({ placeholder: "Genève" })}></input>
                <div className="absolute rounded-2xl  overflow-hidden">
                  {suggestions.slice(0, 4).map((suggestion) => {
                    return (
                      <div
                        key={suggestion._id}
                        {...getSuggestionItemProps(suggestion)}
                        className=" cursor-pointer   px-2 py-1 top-44 hover:bg-black hover:text-white h-fit bg-beige text-[#716960] text-xs w-[100%] flex flex-wrap">
                        {suggestion.terms[0].value}, {suggestion.terms[1]?.value}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          {/* <input
            value={city}
            onChange={(e) => setCity(e.target.value)}
            className="w-[40%] bg-transparent placeholder:text-white px-3 placeholder:font-syne"
         
          /> */}
          <button
            onClick={(e) => handleSubmit(e)}
            className="bg-black aspect-square mr-2  max-h-[100%] min-h-[100%] rounded-full text-white flex justify-center items-center ">
            <FaArrowRight />
          </button>
        </div>
      </div>
      {/* white div */}
      <div className="h-48 bg-[#ffffff40]  glass-effect  py-11 px-5 text-center flex items-center justify-center text-base font-syne font-bold  text-white">
        Prenez un rendez-vous 24/24 <br />
        en 2min avec un choix de plus de 2000 établissements
      </div>
      {/* partner Div */}
      <div className="backgroundHome">
        <ProPartnersMob />
      </div>
      <section className="bg-black pt-10 pb-16 w-full text-beige snap-center">
        <div className="w-11/12 mx-auto flex flex-col gap-4 justify-start">
          <h2>Vous êtes professionnels ?</h2>
          <p>Facilitez-vous la vie et multipliez vos rendez-vous avec Stunify Pro</p>
          <Link to="/pro" className="btn-light w-fit text-black">
            En savoir plus
          </Link>
        </div>
      </section>
    </div>
  );
};

export default HomeMob;
