import { Link, useLocation } from "react-router-dom";
import React from "react";
import { Sheet, SheetClose, SheetContent, SheetFooter, SheetTrigger } from "../ui/sheet";
import { HiBars3 } from "react-icons/hi2";
const SideNavUserConnected = () => {
  const location = useLocation()
 
  return (
    <>
      <Sheet>
        <SheetTrigger asChild>
          {/* burger Icon */}
          <button aria-label="burger menu" type="button">
            <HiBars3 className="w-10 h-10 md:hidden dark:text-beige" />
          </button>
        </SheetTrigger>
        <SheetContent
          side={"left"}
          className="linear-background verflow-y-auto w-1/2 sm:w-2/5 md:w-[300px]">
          <div className="flex flex-col justify-between h-full pt-10">
            <div className="flex flex-col gap-6 text-black">
              <SheetClose asChild>
                <Link to="/login" className="w-fit hover:underline ">
                  <span className="w-full">Connexion</span>
                </Link>
              </SheetClose>
              <SheetClose asChild>
                <Link to={location.pathname === '/login' ?"/signup" : '/pro/institute-registration'} className="hover:underline w-fit">
                  Inscription
                </Link>
              </SheetClose>
              <SheetClose asChild>
                <Link to="/pro" className="hover:underline w-fit">
                  Professionnels
                </Link>
              </SheetClose>
            </div>
          </div>
          <SheetFooter></SheetFooter>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default SideNavUserConnected;
