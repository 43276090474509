import { useNewInstitute } from "Context/InstituteAuthContext";
import React from "react";
//icons
import { HiChevronRight, HiChevronLeft } from "react-icons/hi2";
const Nav = ({
  disableBack,
  handleStepChange,
  disableSkip,
  skipThisForm,
  isAlreadySubmitted,
  handleNextStepForAlreadySubmittedInfo,
  disableNextButton,
}) => {
  const {newInstitute} = useNewInstitute()
  return (
    <div className="flex flex-row justify-between items-end gap-4 mb-6 sm:gap-6 ">
      <button
        disabled={disableBack}
        type="button"
        className={`${
          disableBack ? "opacity-20" : "opacity-100"
        } flex flex-row btn-outline !pl-2 border-black dark:border-beige xs:max-sm:!px-4`}
        value="prev"
        onClick={(e) => handleStepChange(e, "prev")}>
        {/* arrow left */}
        <HiChevronLeft className="h-6 w-6 xs:max-sm:hidden" />
        <span>Retour</span>
      </button>
      <div className="flex flex-row gap-4 sm:gap-6 items-center">
        <button
          type="button"
          className={`${
            disableSkip && "text-dark_grey pointer-events-none select-none"
          } hidden  sm:flex flex-row btn-outline  border-black dark:border-beige`}
          value="next"
          onClick={(e) => {
            skipThisForm(e);
          }}>
          Passer cette étape
        </button>
        <button
          type="button"
          className={`${
            disableSkip && "text-dark_grey pointer-events-none select-none"
          }  sm:hidden flex flex-row btn-outline  border-black dark:border-beige`}
          value="next"
          onClick={(e) => {
            skipThisForm(e);
          }}>
          Passer
        </button>

        <button
          disabled={disableNextButton}
          onClick={(e) => {
            if (isAlreadySubmitted) handleNextStepForAlreadySubmittedInfo(e)
              
          }}
          type="submit"
          className={`${
            disableNextButton ? "opacity-20" : "opacity-100"
          } btn-light dark:text-black dark:bg-beige !pr-2 border border-dark_grey dark:border-beige self-end w-fit flex flex-row  xs:max-sm:!px-4`}>
          Suivant
          {/* arrow right */}
          <HiChevronRight className="w-6 h-6 xs:max-sm:hidden" />
        </button>
      </div>
    </div>
  );
};

export default Nav;
