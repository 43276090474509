import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import { createContext, useContext, useMemo, useState } from "react";

const ProConnectedContext = createContext();

function ProConnectedProvider({ children }) {
  const [sideBar, setSideBar] = useState(false);
  const [fetching, setFetching] = useState(false);

  // test
  const [dataProStats, setDataProStats] = useState({});
  const currentYear = moment().format('YYYY');

  const [selectedYear, setSelectedYear] = useState(currentYear);


  const fetchdataProStats = async () => {
    setFetching(true);
    const token = Cookies.get("authToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.get(
       `${process.env.REACT_APP_BASE_API_URL}/api/v1/secure/institute/stat/base?year=${selectedYear}`,
        { headers }
               
      );
      setDataProStats(res.data);
      setFetching(false);
    } catch (error) {
      // Handle error here
      console.error("Error fetching data:", error);
      setFetching(false);
    }
    
  };
  /////////////////////////// monthlyGoal
  const [monthlyGoal, setMonthlyGoal] = useState("");

  const calculateMonthlyGoalPercentage = () => {
    if (dataProStats?.total) {
      const goal = parseFloat(monthlyGoal);
      const total = parseFloat(dataProStats.total);
      if (!isNaN(goal) && !isNaN(total)) {
        const percentage = (total / goal) * 100;
        return parseInt(percentage, 10); // Convert to an integer
      }
    }
    return 0; // Return 0 if any of the values are missing or invalid
  };



  const valueToShare = useMemo(() => ({
    sideBar,
    setSideBar,
    fetchdataProStats,
    dataProStats,
    monthlyGoal,
    setMonthlyGoal,
    calculateMonthlyGoalPercentage,
    setSelectedYear,
    selectedYear,
    fetching
  }), [sideBar, fetchdataProStats, dataProStats, monthlyGoal, selectedYear, fetching]);

  return (
    <ProConnectedContext.Provider value={valueToShare}>
      {children}
    </ProConnectedContext.Provider>
  );
}

function useProConnectedContext() {
  return useContext(ProConnectedContext);
}

export { ProConnectedProvider, useProConnectedContext };
export default ProConnectedContext;
