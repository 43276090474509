import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Agenda from "./Pages/Pro/Agenda";
import ProLayout from "./Layout/ProLayout";
import LoggedContext from "./Context/isLogged";
import { useContext, useEffect, useState } from "react";
import Signup from "./Pages/Signup";
import Pro from "./Pages/Pro/ProHome/Index";
import Search from "./Pages/User/Search";
import Homelayout from "./Layout/Homelayout";
import UserLayout from "./Layout/UserLayout";
import InstituteRegistration from "./Pages/Pro/Institute-registration";
import Cookies from "js-cookie";
import GoogleMapEx from "./Pages/User/GoogleMapEx";
import jwt_decode from "jwt-decode";
import Salon from "Pages/User/Salon";
import StepThree from "./Component/Reservation/StepThree";
import DashboardClient from "./Pages/User/DashboardClient";
import Reservation from "./Component/Reservation/Reservation";
import CustomToaster from "Component/utils/CustomToaster";
import { DataProvider } from "Context/Datacontext";
import Chat from "./Pages/User/Chat";
import Privacy from "./Pages/User/Privacy";
import CGU from "./Pages/User/CGU"
import ProConnectedlayout from "Layout/ProConnectedlayout";
import CaiseDash from "Pages/Pro/ProConnected/CaisseDash";
import CaisseDetails from "Pages/Pro/ProConnected/CaisseDetails";
import StatsDash from "Pages/Pro/ProConnected/StatsDash";
import StatsDetails from "Pages/Pro/ProConnected/StatsDetails";
import { ProConnectedProvider } from "Context/ProConnectedContext";
import { CaisseProvider } from "Context/CaisseContext";
import { StatsProvider } from "Context/StatsContext";
import { SalonDataProvider } from "Context/SalonDataContext";
import { PrestationDataProvider } from "Context/prestationContext";
import UpdateReservation from "Component/Reservation/UpdateReservation";
import PrivateRoute from "config/PrivateRoute";
import { ChatProvider } from "Context/ChatContext";
import TestChat from "Pages/TestChat";
import InputTest from "Component/test/InputTest";
import Verification from "Pages/User/Verification";
import CalendarAccess from "Pages/CalendarAccess";



function App() {
  const { setIsLogged, setIsPro, setUserGlobal, isAdmin, setIsadmin, isPro , userGlobal } =
    useContext(LoggedContext);
  const isAuthenticated = !!Cookies.get("authToken");
  const authToken = Cookies.get("authToken");
  
  const decoded =authToken? jwt_decode(authToken) : null;
  useEffect(() => {
    const checkForLoggeState = () => {
      const authToken = Cookies.get("authToken");
      if (authToken) {
        const decoded = jwt_decode(authToken);
       

        if (decoded) {
          decoded.isAdmin && setIsadmin(true);
          decoded.isPro && setIsPro(true);
        }
        let { lastName, firstName, id, email, phone, imageUrl ,hasGoogleCalendarAccess , isConnectedGoogle } = decoded;
        setUserGlobal({ lastName, firstName, email, phone, imageUrl, _id: id , hasGoogleCalendarAccess , isConnectedGoogle});
        setIsLogged(true);
      }
    };
    checkForLoggeState();
  }, []);

  return (
    <>
      <ChatProvider>
      
        <BrowserRouter>
          {/* User Layout */}
          <DataProvider>
            <SalonDataProvider>
              <PrestationDataProvider>
                <Routes>
                  <Route element={<UserLayout />}>
                    <Route path="/stp" element={<StepThree />} />
                    <Route path="/reservation" element={<Reservation />} />
                    <Route path="/salon/:id" element={<Salon />} />
                    <Route path="/salon" element={<Navigate to="/search" />} />{" "}
                    <Route
                      path="/dashboard"
                      element={
                        !decoded?.isPro ? (
                          <Navigate to="/profile" />
                        ) : (
                          <Navigate to="/proConnected/caisseDash" />
                        )
                      } //protected
                    />
                    <Route
                      path="/updatereservation"
                      element={<UpdateReservation />} //protected
                    />
                  </Route>
                </Routes>
                {/*Home Layout */}
                <Routes>
                  <Route element={<Homelayout />}>
                    <Route index element={<Home />} />
                    <Route path="/testinput" element={<InputTest />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/prologin" element={<Login />} />

                    <Route path="/signup" element={<Signup />} />
                    <Route
                      path="/profile"
                      element={
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                          {!decoded?.isPro ? (
                            <DashboardClient />
                          ) : (
                            <Navigate to="/proConnected/caisseDash" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route path="/chat" element={<Navigate to="/search" />} />
                    <Route
                      path="/chat/:type"
                      element={
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                          <Chat />
                        </PrivateRoute>
                      }
                    />
                    <Route path="/test" element={<TestChat />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/verification" element={<Verification />} />
                    <Route path="/search/:typeParams" element={<Search />} />
                    <Route path="/search/:cityParams/:typeParams" element={<Search />} />
                  </Route>
                </Routes>
              </PrestationDataProvider>
            </SalonDataProvider>
          </DataProvider>

          {/* Pro Layout */}
          <Routes>
            <Route path="/pro" element={<ProLayout />}>
              <Route path="/pro" element={<Pro />} />
              <Route
                path="/pro/institute-registration"
                element={<InstituteRegistration />}
              />
              <Route path="/pro/*" element={<Navigate to="/pro" />} />
            </Route>
          </Routes>
          {/* Pro Connected Layout */}
          <ProConnectedProvider>
            <CaisseProvider>
              <StatsProvider>
                <Routes>
                  <Route path="/ProConnected" element={<ProConnectedlayout />}>
                    <Route
                      path="/ProConnected/caisseDash"
                      element={
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                          <CaiseDash />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/ProConnected/agenda"
                      element={
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                          <Agenda />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/ProConnected/caissedetails"
                      element={
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                          <CaisseDetails />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/ProConnected/statsdash"
                      element={
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                          <StatsDash />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/ProConnected/statsdetails"
                      element={
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                          <StatsDetails />
                        </PrivateRoute>
                      }
                    />
                  </Route>
                  <Route path="/privacy" element={<Privacy />}/>
                  <Route path="/CGU" element={<CGU />}/>
                  <Route path="/connect-calendar" element={<CalendarAccess/>} />


                </Routes>
              </StatsProvider>
            </CaisseProvider>
          </ProConnectedProvider>
        </BrowserRouter>
      </ChatProvider>
      <CustomToaster />
    </>
  );
}

export default App;
