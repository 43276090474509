import React from "react";
import { HiStar } from "react-icons/hi";

const ReviewsSection = () => {
  const reviews = [
    {
      name: "Au long Court",
      date: "22 Juin 2023",
      review:
        " (hard-coded) Une première fois dans ce salon et je reviendrais ! Super satisfait de la prestation, la gérante est super accueillante et très a l’écoute.",
    },
    {
      name: "Au long Court",
      date: "22 Juin 2023",
      review:
        " (hard-coded) Une première fois dans ce salon et je reviendrais ! Super satisfait de la prestation, la gérante est super accueillante et très a l’écoute.",
    },
  ];

  return (
		<div id='dashboard_review_section' className='bg-sand flex flex-col rounded-3xl p-4  mx-auto md:w-[80%]  w-full overflow-y-scroll md:mt-[-20px]'>
			<h5 className='text-[#0F0F0F] text-base not-italic font-bold leading-[normal]'>Mes avis</h5>
			{reviews.map((review, index) => {
				return (
					<div key={index} className='flex flex-col gap-2 mt-10 pb-5 border-b-[1px] border-[#D0CBC4]'>
						<div className='flex items-center'>
							<HiStar size={20} className='fill-[#82786E]' />
							<p className='font-bold font-xl'> 4.8</p>
						</div>
						<p className='text-[#82786E] font-lg font-medium'>
							{review.name} • {review.date}
						</p>
						<p className='font-sand'>{review.review}</p>
					</div>
				);
			})}
		</div>
  );
};

export default ReviewsSection;
