import axiosInstance from "config/Interceptor";
import moment from "moment";
import { createContext, useState, useMemo, useContext, useEffect } from "react";

const ChatContext = createContext();
function ChatProvider({ children }) {
  const [chatList, setChatList] = useState([]);
  const [createdChat, setCreatedChat] = useState();
  const [selectedChat, setSelectedChat] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [chatLoading, setChatLoading] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [toggleChatDisplay, setToggleChatDisplay] = useState(false);
  const [chatHasMore, setChatHasMore] = useState(false);
  const [messagesHasmore, setMessagesHasmore] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isInstitute, setIsInstitute] = useState(null);
  const [newMessage, setNewmessage] = useState("");
  const [socket, setSocket] = useState();

  //Fetch ChatList with infinite Scroll
  function filterDuplicates(data) {
    const unique = new Map();

    data.forEach((item) => {
      if (!unique.has(item._id)) {
        unique.set(item._id, item);
      }
    });

    return Array.from(unique.values());
  }
  const fetchChatlist = async (
    fetchMore = false,
    hasMore = chatHasMore,
    page = 0,
    perPage = 10
  ) => {
    if (hasMore) {
      setChatLoading(true);
      let url = `api/v1/secure/chat/?page=${page}&perPage=${perPage}`;
      let body = {
        isInstitute,
      };
      const res = await axiosInstance.get(url, {
        params: body,
      });
      if (fetchMore) {
        setChatList((chatList) => chatList.concat(res.data.chats));
        setChatList((chatList) => filterDuplicates(chatList));
      } else setChatList(res.data.chats);
      if (
        res.data.pages - 1 === page ||
        res.data.pages === 0 ||
        res.data.list?.length === 0
      )
        setChatHasMore(false);
      setChatLoading(false);
    }
  };

  const fetchMoreChats = (hasMore, page, perPage, fetchMore = true) => {
    fetchChatlist(fetchMore, hasMore, page, perPage);
  };
  // Fetch Message with infinite Scroll
  const fetchMessages = async (
    id,
    fetchMore = false,
    hasMore = messagesHasmore,
    page = 0,
    perPage = 20
  ) => {
    if (hasMore) {
      setChatLoading(true);
      let url = `api/v1/secure/message/${id}?page=${page}&perPage=${perPage}`;
      const res = await axiosInstance.get(url);

      if (fetchMore) {
        setMessages((messages) => messages.concat(res.data.messages));
      } else setMessages(res.data.messages);
      if (
        res.data.pages - 1 === page ||
        res.data.pages === 0 ||
        res.data.list?.length === 0
      )
        setMessagesHasmore(false);
      setChatLoading(false);
    }
  };

  const fetchMoreMessage = (id, hasMore, page, perPage, fetchMore = true) => {
    fetchMessages(id, fetchMore, hasMore, page, perPage);
  };
  //Create new Chat
  const createChat = async (receiverId) => {
    try {
      let url = `/api/v1/secure/chat`;
      let body = {
        memberId: receiverId,
        isInstitute,
      };

      const res = await axiosInstance.post(url, body);
      setSelectedChat(res.data);
      setCreatedChat({ ...res.data });
    } catch (error) {
      console.error("Error Posting Message ", error);
    }
  };

  ///Post Message
  const postMessage = async (chat, message) => {
    try {
      let url = `/api/v1/secure/message`;
      let body = {
        chatId: chat._id,
        text: message,
      };
      const updatedChatList = chatList.map((item) => {
        if (item._id === chat._id) {
          return {
            ...item,

            lastMessage: { text: message, createdAt: moment() },
          };
        }
        return item;
      });
      setChatList(sortByLastMessageDate(updatedChatList));
      const res = axiosInstance.post(url, body);
    } catch (error) {
      console.error("Error Posting Message ", error);
    }
  };

  function sortByLastMessageDate(data) {
    return data.sort((a, b) => {
      if (!a.lastMessage) return 1;
      if (!b.lastMessage) return -1;

      let dateA = new Date(a.lastMessage.createdAt);
      let dateB = new Date(b.lastMessage.createdAt);

      return dateB - dateA;
    });
  }

  const valueToShare = useMemo(
    () => ({
      toggleChatDisplay,
      setToggleChatDisplay,
      setCreatedChat,
      sortByLastMessageDate,
      createdChat,
      setChatList,
      socket,
      setSocket,
      createChat,
      messages,
      receiverId,
      setReceiverId,
      selectedChat,
      chatList,
      postMessage,
      setSelectedChat,
      chatLoading,
      fetchChatlist,
      fetchMoreChats,
      chatHasMore,
      setChatHasMore,
      messagesHasmore,
      setMessagesHasmore,
      fetchMessages,
      fetchMoreMessage,
      setIsInstitute,
      isInstitute,
      newMessage,
      setNewmessage,
      setMessages,
    }),
    [
      toggleChatDisplay,
      createdChat,
      chatList,
      socket,
      newMessage,
      selectedChat,
      chatLoading,
      chatHasMore,
      messagesHasmore,
      receiverId,
      messages,
      isInstitute,
    ]
  );

  return <ChatContext.Provider value={valueToShare}>{children}</ChatContext.Provider>;
}
function useChatContext() {
  return useContext(ChatContext);
}
export { ChatProvider, useChatContext };
export default ChatContext;
