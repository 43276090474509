import React, { useState, useContext, useEffect, useRef } from "react";
import { VscSettings, VscClose } from "react-icons/vsc";
import { HiChevronLeft } from "react-icons/hi";
import SearchMob from "Component/Search/SearchMob";
import Map from "../../Component/utils/MapGoogle";
import Datacontext from "../../Context/Datacontext";
import InstituteCard from "../../Component/Search/InstituteCard";
import prestationContext from "Context/prestationContext";
import SalonDataContext from "Context/SalonDataContext";
import "styles/animate.css";

import { useNavigate, useParams } from "react-router-dom";
import "../../styles/Search.css";
import InfiniteScroll from "react-infinite-scroll-component";
import SmSpinner from "Component/ui/SmSpinner";

const Search = (props) => {
  const fadeInDown = `animate__animated  animate__fadeInDown`;
  const searchbarChoices = [
    { name: "Coiffeurs", value: "coiffeur" },
    { name: "Barbiers", value: "barbier" },
    { name: "Médecine esthétique", value: "esthetique" },
    { name: "Institut de beauté", value: "spa" },
  ];
  const { setSelectedPrestation, setSelectedFreeSlot } = useContext(prestationContext);
  const { setSalon } = useContext(SalonDataContext);
  const wrapperRef = useRef(null);
  let { typeParams, cityParams } = useParams();
  const [Sort, setSort] = useState("Pertinence");

  const [Filter, setFilter] = useState(false);
  const [maxPrice, setmaxPrice] = useState(0);
  const [togglesortSelect, SetSelectsort] = useState(false);
  const [params, setParams] = useState({
    type: typeParams ? typeParams : "",
    city: cityParams ? cityParams : "",
  });
  const [TempFilter, setTempFilter] = useState({
    name: "all",
    type: "all",
    maxPrice: 1000,
    clientGender: "all",
    address: "all",
    payment: "all",
    promotion: false,
  });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedCard, setSelectedCard] = useState(null);
  const {
    institutes,
    setFilterlist,
    FilterList,
    fetchMore,
    hasMore,
    setHasMore,
    fetch,
    fetching,
  } = useContext(Datacontext);
  const navigate = useNavigate();

  const [middle, setMiddle] = useState([]);
  const addresss = institutes.map((item) => {
    return {
      address: item.address,
      lat: item.position.lat,
      lng: item.position.lng,
    };
  });

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setFilter(false);
    }
  };
  const handleClick = (event) => {
    handleClickOutside(event);
  };
  const handleKeydown = (event) => {
    if (event.key === "Escape") {
      setFilter(false);
    }
  };

  const handleFilterbox = () => {
    setFilter(!Filter);
  };

  useEffect(() => {
    if (typeParams && cityParams) {
      fetch(true,{ ...FilterList, type: typeParams, city: cityParams }, false, true);
    } else if (typeParams) {
      fetch(true,{ ...FilterList, type: typeParams }, false, true);
    }
  }, [typeParams, cityParams]);

  useEffect(() => {
    //initialize the prestation selected Array and salon in local storage:
    localStorage.removeItem("startTimersToSkip");
    setSelectedPrestation([]);
    setSelectedFreeSlot("");
    setSalon([]);
    setPage(0);
    setHasMore(true);
    navigate("/search");
    fetch(true,FilterList, false, true);
  }, [FilterList]);

  const handleSelectedCard = (address, e) => {
    setSelectedCard(address);
  };

  const fetchMoreData = () => {
    fetchMore(true,FilterList, hasMore, page + 1, perPage);
    setPage(page + 1);
  };

  return (
    <div className="flex flex-col justify-center">
      <SearchMob searchbarChoices={searchbarChoices} />
      <div className="h-[100%] md:flex md:flex-col items-center hidden pb-3 ">
        {/*filter Bar*/}
        <div className="flex-wrap content-center justify-start  whitespace-nowrap z-40 min-w-[713px] !overflow-y-visible items-center h-fit  no-scrollbar py-2 px-2 flex flex-row  border-[1px] border-opacity-25  border-gray-400  w-[72vw] linear-background mt-5 rounded-full ">
          <div className="ml-6 flex gap-2 w-fit items-center">
            <button
              onClick={handleFilterbox}
              className={`text-beige text-sm flex items-center gap-1 px-3 py-1 border-2 rounded-3xl w-20 h-fit
            ${Filter ? "bg-white text-black" : ""}`}>
              <VscSettings size={15} />
              Filter
            </button>
            <div className="relative overflow-y-visible overflow-x-visible">
              <button
                onClick={() => SetSelectsort(!togglesortSelect)}
                className="text-beige text-sm flex items-center gap-1 px-3 py-1 border-2 rounded-3xl w-fit h-fit">
                Trier par: {Sort}
                <HiChevronLeft
                  size={20}
                  className={`${
                    togglesortSelect ? "rotate-[270deg]" : "rotate-0"
                  } transition duration-300 `}
                />
              </button>
              {togglesortSelect && (
                <div className="absolute rounded-3xl bg-beige translate-y-1 p-2  w-44 h-fit overflow-y-visible">
                  <h6
                    className="hover:bg-black hover:text-white rounded-3xl p-2"
                    onClick={() => {
                      setSort("Pertinence");
                      SetSelectsort(false);
                    }}>
                    Pertinence
                  </h6>
                  <h6
                    className="hover:bg-black hover:text-white rounded-3xl p-2"
                    onClick={() => {
                      setSort("Prix");
                      SetSelectsort(false);
                    }}>
                    Prix
                  </h6>
                  <h6
                    className="hover:bg-black hover:text-white rounded-3xl p-2"
                    onClick={() => {
                      setSort("Rate");
                      SetSelectsort(false);
                    }}>
                    Rate
                  </h6>
                </div>
              )}
            </div>
          </div>
          <div className="ml-6 flex flex-row overflow-x-scroll gap-6 no-scrollbar search-bar-choices">
            {searchbarChoices.map((choice, idx) => {
              return (
                <button
                  type="button"
                  aria-label={`rechercher les ${choice.name}`}
                  key={idx + choice.value}
                  onClick={() => {
                    setFilterlist({ ...FilterList, type: choice.value });
                  }}
                  className={`text-beige text-sm rounded-3xl px-2 py-1.5 ${
                    FilterList.type === choice.value && "bg-beige text-black"
                  } `}>
                  {choice.name}
                </button>
              );
            })}
            <button
              type="button"
              aria-label={`réintialiser la recherche`}
              onClick={() => {
                setParams({ type: "", city: "" });
                setFilterlist({
                  name: "all",
                  city: "all",
                  type: "all",
                  maxPrice: 0,
                  clientGender: "all",
                  address: "all",
                  payment: "all",
                  promotion: false,
                });
                setTempFilter({
                  name: "all",
                  type: "all",
                  maxPrice: 1000,
                  clientGender: "all",
                  address: "all",
                  payment: "all",
                  promotion: false,
                });
                setSort("Pertinence");
              }}
              className={`text-beige text-sm rounded-3xl px-2 py-1.5 active:bg-beige active:text-black transition duration-200`}>
              Supprimer les filtres
            </button>
          </div>
        </div>
        {/* main wrapper */}
        <div className="flex flex-row w-[72vw] h-[100%] mt-4 gap-7">
          <div className=" w-8/12  justify-start ins-cards-wrapper p-2  pb-5  ">
            {/* institute maping */}
            <div id="scrollableDiv" style={{ height: "98vh", overflow: "auto" }}>
              <InfiniteScroll
                dataLength={institutes.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={
                  <h5 className="text-center text-white mt-2 overflow-hidden">
                    <SmSpinner w={7} h={7} />
                  </h5>
                }
                scrollableTarget="scrollableDiv">
                {institutes.length > 0 ? (
                  institutes.map((institute, i) => (
                    <InstituteCard
                      setMiddle={setMiddle}
                      selectedCard={selectedCard}
                      handleSelectedCard={handleSelectedCard}
                      key={institute._id}
                      institute={institute}
                    />
                  ))
                ) : (
                  <>
                    {!fetching ? (
                      <div className="p-5 animate__animated  animate__fadeInDown text-beige text-sm flex flex-col justify-center items-center h-full gap-6 linear-background rounded-3xl overflow-hidden">
                        <p className="text-base">
                          Votre recheche n'a donné aucuns résultats
                        </p>
                        <button
                          type="button"
                          aria-label={`réintialiser la recherche`}
                          onClick={() => {
                            setParams({ type: "", city: "" });
                            setFilterlist({
                              name: "all",
                              city: "all",
                              type: "all",
                              maxPrice: 0,
                              clientGender: "all",
                              address: "all",
                              payment: "all",
                              promotion: false,
                            });
                            setTempFilter({
                              name: "all",
                              type: "all",
                              maxPrice: 1000,
                              clientGender: "all",
                              address: "all",
                              payment: "all",
                              promotion: false,
                            });
                            setSort("Pertinence");
                          }}
                          className={`text-black bg-beige text-sm rounded-3xl px-3 py-1.5 active:bg-beige active:text-black transition duration-200`}>
                          Supprimer les filtres
                        </button>
                      </div>
                    ) : null}
                  </>
                )}
              </InfiniteScroll>
            </div>
          </div>
          <div className="flex flex-col gap-3 w-[20vw] justify-between pt-2">
            {/* filter */}
            <div
              ref={wrapperRef}
              className={` glass-effect rounded-3xl flex flex-col ${
                Filter ? "min-h-[70%] no-scrollbar" : "hidden"
              }`}>
              <div className="flex justify-between pl-8 py-3 pr-6 text-white">
                <p>Filter</p>
                <button onClick={() => setFilter(false)}>
                  <VscClose size={24} />
                </button>
              </div>
              <hr />
              <div className="p-6 flex flex-col overflow-hidden overflow-y-scroll">
                <div className="flex flex-row text-white text-sm justify-between  ">
                  <span>Prix maximum</span>
                  <span>{TempFilter.maxPrice} €</span>
                </div>
                <input
                  type="range"
                  value={TempFilter.maxPrice}
                  onChange={(e) => {
                    setmaxPrice(e.target.value);
                    setTempFilter({ ...TempFilter, maxPrice: maxPrice });
                  }}
                  min={5}
                  max={1000}
                />
                <div className="text-beige text-sm">Type d’établissement</div>
                <div className="flex flex-row gap-2 mt-1 mb-2 flex-wrap ">
                  <button
                    onClick={() => setTempFilter({ ...TempFilter, clientGender: "all" })}
                    className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl w-fit h-fit 
                ${
                  TempFilter.clientGender === "all" ? "bg-white text-black" : "text-beige"
                }`}>
                    Tous
                  </button>
                  <button
                    onClick={() =>
                      setTempFilter({ ...TempFilter, clientGender: "woman" })
                    }
                    className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl w-fit h-fit 
                ${
                  TempFilter.clientGender === "woman"
                    ? "bg-white text-black"
                    : "text-beige"
                }`}>
                    Femme
                  </button>
                  <button
                    onClick={() => setTempFilter({ ...TempFilter, clientGender: "man" })}
                    className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl w-fit h-fit 
                ${
                  TempFilter.clientGender === "man" ? "bg-white text-black" : "text-beige"
                }`}>
                    Homme
                  </button>
                </div>
                <div className="text-beige text-sm">Lieu</div>
                <div className="flex flex-row gap-2 mt-1 mb-2 flex-wrap ">
                  <button
                    onClick={() => setTempFilter({ ...TempFilter, address: "all" })}
                    className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl w-fit h-fit 
                ${TempFilter.address === "all" ? "bg-white text-black" : "text-beige"}`}>
                    Tous
                  </button>
                  <button
                    onClick={() => setTempFilter({ ...TempFilter, address: "onsite" })}
                    className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl w-fit h-fit 
                ${
                  TempFilter.address === "onsite" ? "bg-white text-black" : "text-beige"
                }`}>
                    Sur place
                  </button>
                  <button
                    onClick={() => setTempFilter({ ...TempFilter, address: "remote" })}
                    className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl w-fit h-fit 
                ${
                  TempFilter.address === "remote" ? "bg-white text-black" : "text-beige"
                }`}>
                    à domicile
                  </button>
                </div>
                <div className="text-beige text-sm">Paiement</div>
                <div className="flex flex-row gap-2 mt-1 mb-2 flex-wrap">
                  <button
                    onClick={() => setTempFilter({ ...TempFilter, payment: "all" })}
                    className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl w-fit h-fit 
                ${TempFilter.payment === "all" ? "bg-white text-black" : "text-beige"}`}>
                    Tous
                  </button>
                  <button
                    onClick={() => setTempFilter({ ...TempFilter, payment: "onsite" })}
                    className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl w-fit h-fit 
                ${
                  TempFilter.payment === "onsite" ? "bg-white text-black" : "text-beige"
                }`}>
                    Sur place
                  </button>
                  <button
                    onClick={() => setTempFilter({ ...TempFilter, payment: "online" })}
                    className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl w-fit h-fit 
                ${
                  TempFilter.payment === "online" ? "bg-white text-black" : "text-beige"
                }`}>
                    En ligne
                  </button>
                </div>
                <div className="text-beige text-sm">Promotions</div>
                <div className="flex flex-row gap-2 mt-1 mb-2 flex-wrap">
                  <button
                    onClick={() => setTempFilter({ ...TempFilter, promotion: false })}
                    className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl w-fit h-fit 
                ${TempFilter.promotion ? "text-beige" : "bg-white text-black"}`}>
                    Tous
                  </button>
                  <button
                    onClick={() => setTempFilter({ ...TempFilter, promotion: true })}
                    className={` text-sm flex justify-center items-center gap-1 px-3 py-2 border-2 rounded-3xl w-fit h-fit 
                  ${!TempFilter.promotion ? "text-beige" : "bg-white text-black"}`}>
                    Uniquement salon avec promotions
                  </button>
                </div>
                <div className="flex flex-row justify-between items-center mt-3">
                  <button
                    onClick={() => {
                      setFilter(false);
                      setFilterlist({ ...TempFilter });
                    }}
                    className="bg-white rounded-3xl font-roboto text-sm text-black px-3 py-2 w-full underline-offset-2">
                    Appliquer
                  </button>
                </div>
              </div>
            </div>
            {/* map */}
            <div className="h-full  rounded-3xl w-[100%] overflow-hidden">
              <Map
                middle={middle}
                markers={addresss}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
