import { useContext } from "react";
import  moment  from "moment";
import AgendaContext from "Context/AgendaContext";
import { HiOutlineClock, HiPhone } from "react-icons/hi";


const WeekCalendaritem = ({
  Slot,
  event,
  customer,
  timePeriod,
  
  
  duration,
  startTimer,
  endTimer
}) => {
  let size = duration * 7.5;

  const { OpenSelected ,CurrentDate } = useContext(AgendaContext);
  const currentUtcOffset = moment(startTimer).utcOffset();
  const Start = moment(startTimer).local();
  const Current = moment(CurrentDate).utc(currentUtcOffset);
  const end = moment(endTimer).local();

  return (
    <div className=" ">
      <div
        style={{ height: size }}
        onClick={()=>{OpenSelected(Slot)}}
        className={`flex justify-evenly py-[5px] px-[9%] bg-calenderSelected  border-b-2 border-sand flex-col  ${
          Current.isBetween(Start, end, "minute", []) ? "border-white  opacity-100 shadow " : "opacity-70"
        } 
         hover:bg-calendarHover hover:cursor-pointer  text-white    px-4 py-1`}>
        <div className={` text-sand text-2xl justify-start flex `}>{event}</div>

        <div className=" font-bold">{customer.firstName} {customer.lastName}</div>
        <div>
          <div className=" flex gap-3 ">
            <HiOutlineClock size={20} className=" text-sand " />
            {timePeriod[0]} - {timePeriod[1]}
          </div>
          <span className="flex items-center gap-3">
            <HiPhone />
            {customer.phone}
          </span>
        </div>
      </div>
      
    </div>
  );
};

export default WeekCalendaritem;
