import React, { useState, useContext, useEffect } from "react";
import VerificationMob from "Component/Search/VerificationMob";
import Map from "../../Component/utils/MapGoogle";
import Datacontext from "../../Context/Datacontext";
import InstituteCard from "../../Component/Search/InstituteCard";
import "styles/animate.css";
import { useNavigate } from "react-router-dom";
import "../../styles/Search.css";
import InfiniteScroll from "react-infinite-scroll-component";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

const Verification = (props) => {
  const Navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedCard, setSelectedCard] = useState(null);
  const {
    institutes,

    FilterList,
    fetchMore,
    hasMore,
    setHasMore,
    fetch,
    fetching,
  } = useContext(Datacontext);

  const [middle, setMiddle] = useState([]);
  const addresss = institutes.map((item) => {
    return {
      address: item.address,
      lat: item.position.lat,
      lng: item.position.lng,
    };
  });

  const handleSelectedCard = (address, e) => {
    setSelectedCard(address);
  };
  useEffect(() => {
    const authToken = Cookies.get("authToken");
    if (authToken) {
      const decoded = jwtDecode(authToken);
      if (!decoded.isAdmin) {
        Navigate("/search");
      }
      if (decoded.isAdmin) {
        fetch(false, {}, false, true);
      }
    } else {
      Navigate("/search");
    }
  }, []);

  const fetchMoreData = () => {
    fetchMore(false, FilterList, hasMore, page + 1, perPage);
    setPage(page + 1);
  };

  return (
    <div className="flex flex-col justify-center">
      <VerificationMob />
      <div className="h-[100%] md:flex md:flex-col items-center hidden pb-3 ">
        {/*filter Bar*/}

        {/* main wrapper */}
        <div className="flex flex-row w-[72vw] h-[100%] mt-4 gap-7">
          <div className=" w-8/12  justify-start ins-cards-wrapper p-2  pb-5  ">
            {/* institute maping */}
            <div id="scrollableDiv" style={{ height: "98vh", overflow: "auto" }}>
              <InfiniteScroll
                dataLength={institutes.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h5 className="text-center text-white mt-2 overflow-hidden"></h5>}
                scrollableTarget="scrollableDiv">
                {institutes.length > 0 ? (
                  institutes.map((institute, i) => (
                    <InstituteCard
                      setMiddle={setMiddle}
                      selectedCard={selectedCard}
                      handleSelectedCard={handleSelectedCard}
                      key={institute._id}
                      institute={institute}
                    />
                  ))
                ) : (
                  <>
                    {!fetching ? (
                      <div className="p-5 animate__animated  animate__fadeInDown text-beige text-sm flex flex-col justify-center items-center h-full gap-6 linear-background rounded-3xl overflow-hidden">
                        <p className="text-base">
                          Votre recheche n'a donné aucuns résultats
                        </p>
                      </div>
                    ) : null}
                  </>
                )}
              </InfiniteScroll>
            </div>
          </div>
          <div className="flex flex-col gap-3 w-[20vw] justify-between pt-2">
            {/* filter */}

            {/* map */}
            <div className="h-full  rounded-3xl w-[100%] overflow-hidden">
              <Map
                middle={middle}
                markers={addresss}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
