import React, { useContext, useState } from "react";
import SalonDataContext from "../../Context/SalonDataContext";
import { FaStar } from "react-icons/fa6";
import { IoStar } from "react-icons/io5";

function Avis() {
  const { salon, calculateAverageRating } = useContext(SalonDataContext);
  
  // Create state to manage expanded reviews
  const [expandedReviews, setExpandedReviews] = useState([]);

  // Function to toggle review expansion
  const toggleReviewExpansion = (index) => {
    setExpandedReviews((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <div className="bg-[#F6F0E8] text-black md:text-[#F6F0E8] md:bg-transparent mb-10 mt-5 md:mt-0">
      <h5 className="font-syne font-bold text-[28px] mx-4 pt-8 md:mx-0 md:pt-4   mb-4">
        Avis
      </h5>
      <div className=" flex flex-col md:flex-row md:gap-5 ">
        {/* avis  */}
        {salon && salon.reviews && (
          <div className="bga flex flex-col items-center gap-2.5 self-stretch p-4 md:max-h-[350px] mx-8 pt-8 md:mx-0 md:pt-4 border-[#D0CBC4] border-2 md:border-[##F6F0E8] rounded-[25px] md:border-0 md:w-1/5">
            <div className="p-2 w-20 h-20 bg-black md:bg-[#F6F0E8] md:text-black rounded-full text-white flex flex-col items-center justify-center font-roboto text-[28px] font-bold">
              {calculateAverageRating()}
            </div>

            <p className="font-roboto text-[#82786E] text-base md:text-[#F6F0E8]">
              {salon.reviews.length} avis
            </p>
            {salon.reviews.map((review) => (
              <div
                key={review._id}
                className="flex md:flex-row  flex-wrap justify-between w-full  font-roboto"
              >
                <p className="capitalize   ">{review.typeOfRev}</p>
                <div className="flex flex-row gap-1 items-center ">
                  {[...Array(5)].map((_, index) => (
                    <div key={index}>
                    <FaStar className="hidden md:block"
                      
                      color={
                        index < Math.floor(review.ratingRev)
                          ? "#F6F0E8"
                          : "#494745"
                      } //  5stars based   -rating
                      size={16}
                    />
                        <FaStar className=" md:hidden block"
                      
                      color={
                        index < Math.floor(review.ratingRev)
                        ? "#262524"
                        : "#918b85"

                      } //  5stars based   -rating
                      size={16}
                    />
                    </div>
                  ))}

                  <p>{review.ratingRev}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        {/* Data -avis  */}

        <div className="bga md:w-4/5 md:rounded-3xl p-3">
        {salon &&
            salon.reviews &&
            salon.reviews.map((review, index) => (
              <div
                key={index}
                className="flex flex-col p-3 font-roboto text-base md:text-[#F6F0E8] text-[#0F0F0F] space-y-4"
              >
                <div className="flex flex-row items-center gap-1">
                  <IoStar size={16} />
                  <span>{review.ratingRev}</span>
                </div>
                {expandedReviews[index] || review.descriptionRev.length <= 150 ? (
                  <p>{review.descriptionRev}</p>
                ) : (
                  <p>
                    {review.descriptionRev.slice(0, 150)}{" "}
                    <span
                      className="underline cursor-pointer"
                      onClick={() => toggleReviewExpansion(index)}
                    >
                      Tout lire
                    </span>
                  </p>
                )}
                <div className="flex flex-row items-center gap-2 text-[#82786E] font-roboto text-sm md:text-[#F6F0E8] md:text-base">
                  {/* <p>{review.client}</p> */}
                  {/* <li>Juin 2023</li> */}
                </div>
                {index !== salon.reviews.length - 1 && (
                  <div className="h-[1px] md:bg-[#D0CBC4] bg-black"></div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Avis;