import React from "react";

export const Star = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none">
      <path
        d="M8.74452 2.80359C8.84505 2.58565 9.15478 2.58565 9.25531 2.80359L10.9971 6.57979C11.0381 6.66861 11.1222 6.72977 11.2194 6.74129L15.349 7.23092C15.5873 7.25918 15.6831 7.55375 15.5068 7.7167L12.4537 10.5402C12.3819 10.6066 12.3497 10.7055 12.3688 10.8015L13.1793 14.8803C13.226 15.1157 12.9755 15.2977 12.766 15.1805L9.13729 13.1493C9.05194 13.1015 8.94789 13.1015 8.86254 13.1493L5.2338 15.1805C5.02438 15.2977 4.77379 15.1157 4.82057 14.8803L5.63102 10.8015C5.65009 10.7055 5.61793 10.6066 5.54612 10.5402L2.49298 7.7167C2.31678 7.55375 2.41249 7.25918 2.65082 7.23092L6.78045 6.74129C6.87758 6.72977 6.96176 6.66861 7.00273 6.57979L8.74452 2.80359Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const Search = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none">
      <path
        d="M14.9999 15L12.1046 12.1047M12.1046 12.1047C12.5999 11.6094 12.9927 11.0215 13.2608 10.3744C13.5288 9.72734 13.6668 9.0338 13.6668 8.33341C13.6668 7.63302 13.5288 6.93948 13.2608 6.2924C12.9927 5.64532 12.5999 5.05737 12.1046 4.56212C11.6094 4.06687 11.0214 3.67401 10.3744 3.40598C9.72728 3.13795 9.03374 3 8.33335 3C7.63296 3 6.93942 3.13795 6.29234 3.40598C5.64526 3.67401 5.05731 4.06687 4.56206 4.56212C3.56185 5.56233 2.99994 6.9189 2.99994 8.33341C2.99994 9.74792 3.56185 11.1045 4.56206 12.1047C5.56227 13.1049 6.91884 13.6668 8.33335 13.6668C9.74786 13.6668 11.1044 13.1049 12.1046 12.1047Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const Info = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none">
      <g clip-path="url(#clip0_135_2712)">
        <path
          d="M8.43744 7.875H8.99994V12.9375H9.56244M16.3124 9C16.3124 10.9394 15.542 12.7994 14.1707 14.1707C12.7993 15.5421 10.9393 16.3125 8.99994 16.3125C7.06054 16.3125 5.20058 15.5421 3.82922 14.1707C2.45786 12.7994 1.68744 10.9394 1.68744 9C1.68744 7.06061 2.45786 5.20064 3.82922 3.82928C5.20058 2.45792 7.06054 1.6875 8.99994 1.6875C10.9393 1.6875 12.7993 2.45792 14.1707 3.82928C15.542 5.20064 16.3124 7.0606 16.3124 9Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.34369 5.34375C9.34369 5.5336 9.18979 5.6875 8.99994 5.6875C8.81009 5.6875 8.65619 5.5336 8.65619 5.34375C8.65619 5.1539 8.81009 5 8.99994 5C9.18979 5 9.34369 5.1539 9.34369 5.34375Z"
          fill="white"
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_135_2712">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const Trash = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none">
      <path
        d="M15.1874 4.5C15.1874 5.43198 12.4172 6.1875 8.99994 6.1875C5.58268 6.1875 2.81244 5.43198 2.81244 4.5M15.1874 4.5C15.1874 3.56802 12.4172 2.8125 8.99994 2.8125C5.58268 2.8125 2.81244 3.56802 2.81244 4.5M15.1874 4.5L13.4999 14.625C13.4999 14.625 12.9374 15.75 8.99994 15.75C5.06244 15.75 4.49994 14.625 4.49994 14.625L2.81244 4.5M10.4062 9.28125L7.59369 12.0938M7.59369 9.28125L10.4062 12.0938"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const Send = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none">
      <circle cx="21" cy="21" r="21" fill="#D9D9D9" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.7175 19.4393C28.4986 20.2204 28.4986 21.4867 27.7175 22.2677L22.9249 27.0603C22.5344 27.4509 21.9012 27.4509 21.5107 27.0603C21.1202 26.6698 21.1202 26.0367 21.5107 25.6461L26.3033 20.8535L21.5107 16.0609C21.1202 15.6704 21.1202 15.0372 21.5107 14.6467C21.9012 14.2562 22.5344 14.2562 22.9249 14.6467L27.7175 19.4393Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.439 21.1464C27.439 21.6987 26.9913 22.1464 26.439 22.1464L13.8683 22.1464C13.316 22.1464 12.8683 21.6987 12.8683 21.1464C12.8683 20.5941 13.316 20.1464 13.8683 20.1464L26.439 20.1464C26.9913 20.1464 27.439 20.5941 27.439 21.1464Z"
        fill="white"
      />
    </svg>
  );
};

export default Star;
