import Widget from "Component/ProConnected/Widget";
import React, { useContext, useEffect, useState } from "react";
import { BiChevronDown, BiTransfer } from "react-icons/bi";
import { FiExternalLink } from "react-icons/fi";
import { HiOutlineBanknotes, HiArrowRightOnRectangle } from "react-icons/hi2";
import { IoNotificationsOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useCaisseContext } from "Context/CaisseContext";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import LoggedContext from "Context/isLogged";
import { MdOutlineHomeRepairService } from "react-icons/md";
import { HiChevronDown } from "react-icons/hi";
import { HiOutlineRectangleGroup } from "react-icons/hi2";
import {
  DisconnectUserInFront,
  disconnectUserInBackend,
} from "Component/utils/connectionHandler";
import Cookies from "js-cookie";
import toast from "react-hot-toast";

function CaisseDetails() {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonth = month[new Date().getMonth()];
  const currentYear = new Date().getFullYear();
  const {
    fetchTransactions,
    hasMoreTransactions,
    fetchMoreTransactions,
    dateFilter,
    setDateFilter,
    transactions,
    totalToday,
    totalTodayClients,
    totalMonth,
    totalYear,
    totalMonthClients,
    totalYearClients,
    setTransactions,
    setHasMoreTransactions,
  } = useCaisseContext();
  const {isPro} = useContext(LoggedContext)
  const [page, setPage] = useState(0);
  const [perPage] = useState(10);

  useEffect(() => {
    if (dateFilter) {
      setPage(0);
      setTransactions([]);
      setHasMoreTransactions(true);
      fetchTransactions(false, true, 0, perPage);
    }
  }, [dateFilter]);

  useEffect(() => {
    return () => {
      setPage(0);
      setTransactions([]);
      setHasMoreTransactions(true);
    };
  }, []);

  const fetchMoreData = () => {
    fetchMoreTransactions(hasMoreTransactions, page + 1, perPage);
    setPage(page + 1);
  };
  const { userGlobal } = useContext(LoggedContext);

  const disconnectUserInFront = DisconnectUserInFront();

  const handleUserDisconnection = () => {
    let token = Cookies.get("authToken");
    disconnectUserInBackend(token).then((res) => {
      if (!res.ok) {
        console.log("error");
      }
      disconnectUserInFront();
      toast.success("Vous êtes déconnecté");
    });
  };
  return (
    <>
      {/* header */}
      <div className="flex flex-row justify-between items-center md:w-[80%]  w-full container mx-auto  p-3 border-b-2 border-[#4A4A49]  ">
        <div className="flex flex-row justify-between items-center gap-4 font-syne">
          <Link
            to="/ProConnected/caisseDash"
            className=" flex flex-col justify-between items-center space-y-1   "
          >
            <HiOutlineBanknotes size={20} />
            <p className="text-[#A29689]">Caisse</p>
          </Link>
          <Link
            to="/ProConnected/caissedetails"
            className=" flex flex-col  justify-between items-center space-y-1"
          >
            <BiTransfer size={20} className="text-[#FFC77C]" />
            <p>Transaction</p>
          </Link>
        </div>
        <div>
          <div className="flex flex-row justify-between items-center space-x-4">
            <IoNotificationsOutline
              size={30}
              className="absolute cursor-pointer"
            />
            <span className="bg-[#F59E0B] h-2 w-2 rounded-full relative bottom-2 right-0  cursor-pointer"></span>
            <div className="cursor-pointer">
              {userGlobal.imageUrl ? (
                <img
                  src={userGlobal.imageUrl}
                  alt="user profile PIC"
                  className="w-8 h-8 rounded-full "
                />
              ) : (
                <span className="tracking-wide" id="ProprofileImage">
                  {userGlobal.firstName[0]?.toUpperCase() +
                    userGlobal.lastName[0]?.toUpperCase()}
                </span>
              )}
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger className="flex flex-row justify-between items-center space-x-1 cursor-pointer group">
                <p className="text-[#F6F0E8] text-sm not-italic font-bold capitalize leading-[normal] font-roboto cursor-pointer">
                  {userGlobal.firstName}
                </p>
                <HiChevronDown className="w-5 h-5 transition duration-500 ease-in-out transform scale-y-100 group-data-[state=open]:-scale-y-100 " />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="bg-[#2D2E2F] text-white mx-8 rounded-xl">
                <Link to={!isPro? '/profile' : '/proconnected/caisseDash'}>
                  <DropdownMenuItem className="pl-7 py-3 hover:underline flex flex-row items-center gap-2 text-white">
                    <HiOutlineRectangleGroup className="h-6 w-6" />
                    <span className="text-white">Dashboard</span>
                  </DropdownMenuItem>
                </Link>
                <DropdownMenuSeparator />
                <Link to="/pro">
                  <div className="hover:underline flex flex-row gap-2 justify-start py-3 pl-6 pr-20 cursor-pointer">
                    <MdOutlineHomeRepairService className="w-6 h-6 text-white" />
                    <span className="text-white">Pro Home</span>
                  </div>
                </Link>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={handleUserDisconnection}>
                  <div className="hover:underline flex flex-row gap-2 justify-start py-3 pl-6 pr-20 cursor-pointer">
                    <HiArrowRightOnRectangle className="w-6 h-6 text-white" />
                    <span className="text-white">Se déconnecter</span>
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      {/* header -end */}

      <div className=" w-full mx-auto container ">
        <div className="flex  flex-col p-2  w-full  mx-auto">
          <Widget
            month={currentMonth}
            year={currentYear}
            totalToday={totalToday}
            totalMonth={totalMonth}
            totalYear={totalYear}
            totalTodayClients={totalTodayClients}
            totalMonthClients={totalMonthClients}
            totalYearClients={totalYearClients}
          />
          <div className="rounded border border-[#4A4A49] border-solid mt-4 w-full  ">
            <div className="flex flex-row lg:p-5 p-0 justify-between items-center mx-2 overflow-x-scroll  no-scrollbar  whitespace-nowrap">
              <div className="flex flex-row  justify-between items-center space-x-4 lg:space-x-7 mx-2 ">
                <div
                  onClick={() => setDateFilter("today")}
                  className={
                    "cursor-pointer border-solid text-base not-italic font-bold leading-[35px] font-syne " +
                    (dateFilter === "today"
                      ? "border-b-[#F6F0E8] border-b text-[#F6F0E8]"
                      : "text-[#A29689]")
                  }
                >
                  Aujourd’hui
                </div>
                <div
                  onClick={() => setDateFilter("month")}
                  className={
                    "cursor-pointer text-base not-italic font-bold leading-[35px] font-syne " +
                    (dateFilter === "month"
                      ? "border-b-[#F6F0E8] border-b text-[#F6F0E8]"
                      : "text-[#A29689]")
                  }
                >
                  {currentMonth} {currentYear}
                </div>
                <div
                  onClick={() => setDateFilter("year")}
                  className={
                    "cursor-pointer text-[#A29689] text-base not-italic font-bold leading-[35px] font-syne " +
                    (dateFilter === "year"
                      ? "border-b-[#F6F0E8] border-b text-[#F6F0E8]"
                      : "text-[#A29689]")
                  }
                >
                  Année {currentYear}
                </div>
              </div>
              <Link className="flex flex-row gap-2 mr-3">
                Tout exporter
                <span>
                  <FiExternalLink />
                </span>
              </Link>
            </div>
            {/* table */}
            <div className="w-full lg:p-6 p-2 mt-4 lg:mt-0 h-full overflow-scroll no-scrollbar">
              <div
                id="scrollableDiv"
                style={{
                  height: "320px",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                <InfiniteScroll
                  dataLength={transactions.length}
                  next={fetchMoreData}
                  hasMore={hasMoreTransactions}
                  loader={<h5 className="text-center mt-5 mb-4">Loading...</h5>}
                  endMessage={
                    <p className="text-center mt-2">
                      <b>No more data.</b>
                    </p>
                  }
                  scrollableTarget="scrollableDiv"
                >
                  <table className="w-full text-[#F6F0E8]">
                    <thead>
                      <tr className="bg-[#4A4A49] text-[#F6F0E8] text-base not-italic font-bold leading-[normal] font-roboto">
                        <th className="text-left p-2 w-28 ">Date</th>
                        <th className="text-left p-2 w-[44px]">Heure</th>
                        <th className="text-left p-2">Prestations</th>
                        <th className="text-left p-2">Nom du client</th>
                        <th className="text-left p-2">Nom de l'employeur</th>
                        <th className="text-left p-2 w-[60px]">Tarif</th>
                        <th className="text-left p-2 w-[55px]">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((transaction, i) => (
                        <tr
                          key={transaction._id + i}
                          className=" text-[#F6F0E8]  text-base not-italic font-normal leading-[normal] font-roboto whitespace-nowrap border-b border-[#4A4A49]   "
                        >
                          <td className="p-2">
                            {moment(transaction.createdAt).format("DD/MM/YYYY")}
                          </td>
                          <td className="p-2">
                            {moment(transaction.createdAt).format("HH:mm")}
                          </td>
                          <td className="p-2">
                            {transaction.reservation.prestation &&
                            transaction.reservation.prestation.length > 0 ? (
                              <span>
                                <span>
                                  {transaction.reservation.prestation[0].name}{" "}
                                </span>
                                {transaction.reservation.prestation.length >
                                1 ? (
                                  <span className="text-xs right-1">
                                    (+
                                    {transaction.reservation.prestation.length -
                                      1}
                                    )
                                  </span>
                                ) : null}
                              </span>
                            ) : null}
                          </td>
                          <td className="p-2">
                            {transaction.customer.firstName}{" "}
                            {transaction.customer.lastName}
                          </td>
                          <td className="p-2">
                            {transaction.user.firstName}{" "}
                            {transaction.user.lastName}
                          </td>
                          <td className="p-2">
                            {transaction.amountPaid}{" "}
                            <span className="text-xs">CHF</span>
                          </td>
                          <td className="p-2 cursor-pointer hover:underline">
                            Exporter
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CaisseDetails;
