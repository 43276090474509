import React from "react";
function Comments() {
  return (
    <div className="frame py-20">
      <div className="  container mx-auto   ">
        <div className="flex  flex-col md:flex-row  justify-center mt-20 items-center md:space-x-10 space-y-10 md:space-y-0    pb-14 ">
          <div className="max-w-xs  p-5  w-full trans-bg rounded-[25px]">
            <div className="h-[70px] w-[70px] mb-2 pt-2   ">
              <img
                src="https://d34u8crftukxnk.cloudfront.net/slackpress/prod/sites/6/E12KS1G65-W0168RE00G7-133faf432639-512.jpeg"
                alt=""
                className="rounded-full h-full w-full object-cover"
              />
            </div>
            <p className="text-[18px] mb-3 font-semibold font-syne text-[#F6F0E8]">
              Depuis que j'utilise cette plateforme, ma clientèle a augmenté de 20%. Les
              outils de gestion simplifient mon quotidien !
            </p>
            <p className="text-[#D0CBC4] mt-1 font-roboto">Karl, coiffeur.</p>
          </div>

          <div className="max-w-xs   p-5  w-full trans-bg rounded-[25px]">
            <div className="h-[70px] w-[70px]   mb-2 pt-2   ">
              <img
                src="https://i.dailymail.co.uk/i/pix/2016/05/23/22/348B850600000578-3605456-image-m-32_1464040491071.jpg"
                alt=""
                className="rounded-full h-full w-full object-cover"
              />
            </div>
            <p className="text-[18px] mb-3 font-semibold text-[#F6F0E8] font-syne">
              La réduction des rendez-vous manqués a transformé mon activité.
              <br /> Moins de stress et plus de revenus.
            </p>
            <p className="text-[#D0CBC4] mt-1 font-roboto">
              Jessica, spécialiste extension de cils
            </p>
          </div>
          <div className="max-w-xs   p-5  w-full trans-bg rounded-[25px]">
            <div className="h-[70px] w-[70px]   mb-2 pt-2   ">
              <img
                src="https://img.freepik.com/free-photo/curly-man-with-broad-smile-shows-perfect-teeth-being-amused-by-interesting-talk-has-bushy-curly-dark-hair-stands-indoor-against-white-blank-wall_273609-17092.jpg?size=626&ext=jpg&ga=GA1.1.1224184972.1715040000&semt=ais"
                alt=""
                className="rounded-full h-full w-full object-cover"
              />
            </div>
            <p className="text-[18px] mb-3 font-semibold text-[#F6F0E8] font-syne">
              Grâce à la plateforme, je passe moins de temps sur l'administratif et plus
              avec mes clients. Un vrai changement !
            </p>
            <p className="text-[#D0CBC4] mt-1 font-roboto">Amine, barbier</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Comments;
