import React, { useEffect, useState } from "react";
import SinglePrestationForm from "./SinglePrestationForm";
import Nav from "../institute-registration/Nav";
import { useNewInstitute } from "Context/InstituteAuthContext";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-hot-toast";
//icons
import { HiOutlineTag, HiPlus, HiOutlineClipboard } from "react-icons/hi";

const InstituteInfoPrestations = ({ handleStepChange }) => {
  const { newInstitute, setNewInstitute } = useNewInstitute();

  const [categoriesToDisplay, setCategoriesToDisplay] = useState(() => {
    const savedCategories = localStorage.getItem("categoriesToDisplay");
    return savedCategories
      ? JSON.parse(savedCategories)
      : [
          "forfaits",
          "brushing",
          "Coupe et coiffure",
          "Soin du cheveux et cuir chevelu",
          "Coloration",
          "Mèches",
          "Décoloration",
          "Permanente",
          "Lissage",
        ];
  });
  const [newCategory, setNewCategory] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("categoriesToDisplay", JSON.stringify(categoriesToDisplay));
  }, [categoriesToDisplay]);

  useEffect(() => {
    setPrestationsCategories();
  }, []);

  function handlePrestationCategory(e) {
    let category = e.target.name;
    let checked = e.target.checked;
    if (checked) {
      setNewInstitute((prev) => {
        return {
          ...prev,
          prestationsCategories: [...prev.prestationsCategories, category],
        };
      });
    } else {
      let newCategories = newInstitute.prestationsCategories.filter(
        (cat) => cat !== category
      );
      setNewInstitute((prev) => {
        return {
          ...prev,
          prestationsCategories: newCategories,
        };
      });
    }
  }

  const openNewCategoryForm = (e) => {
    let newCategoryForm = document.querySelector("[data-new-category-form]");
    newCategoryForm.showModal();
    newCategoryForm.addEventListener("click", (e) => {
      const dialogDimensions = newCategoryForm.getBoundingClientRect();
      if (
        e.clientX < dialogDimensions.left ||
        e.clientX > dialogDimensions.right ||
        e.clientY < dialogDimensions.top ||
        e.clientY > dialogDimensions.bottom
      ) {
        newCategoryForm.close();
      }
    });
  };

  const closeNewCategoryForm = (e) => {
    let newCategoryForm = document.querySelector("[data-new-category-form]");
    let input = document.getElementById("category");
    newCategoryForm.close();
    input.value = "";
  };

  const addNewCategory = (e) => {
    e.preventDefault();
    // AddNewCategory to institute Profile
    setNewInstitute((prev) => {
      return {
        ...prev,
        prestationsCategories: [...prev.prestationsCategories, newCategory],
      };
    });
    // AddNewCategory to categoriesToDisplay
    setCategoriesToDisplay((prev) => {
      return [...prev, newCategory];
    });
    // Close the form

    closeNewCategoryForm();
  };

  //dummy data for testing
  const setPrestationsCategories = () => {
    // retreive institute type from first form and set prestation categories by default from API then :
  };

  const duplicateRow = (idx) => {
    setLoading(true);
    //assign fresh ID
    let newId = uuidv4() + Date.now().toString();
    // make a copie of the array
    let institutePrestation = newInstitute.prestations;
    //create a new object to insert
    const duplicatedRow = { ...institutePrestation[idx], id: newId };
    //insert the new object at just on top of the row user wants to duplicate
    institutePrestation.splice(idx, 0, duplicatedRow);
    setNewInstitute({ ...newInstitute, prestations: institutePrestation });
    setDurationOnDuplicatedRow(idx, duplicatedRow.duration);
  };

  const setDurationOnDuplicatedRow = async (idx, duration) => {
    //Duration Is made of 2 input type select, so we have to split the duration string and populate the select
    //waiting for node to exist in DOM
    const hourInput = await waitForElm("#prestation_" + idx + "_duration_hours");
    const minuteInput = await waitForElm("#prestation_" + idx + "_duration_minutes");
    //split the duration string and populate the select
    const hours = duration.split(":")[0];
    const minutes = duration.split(":")[1];
    hourInput.value = hours;
    minuteInput.value = minutes;
    setLoading(false);
  };

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector));
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }
  const deleteRow = (idx) => {
    if (newInstitute.prestations.length === 1) return;
    let insitutePrestation = newInstitute.prestations;
    insitutePrestation.splice(idx, 1);
    setNewInstitute({ ...newInstitute, prestations: insitutePrestation });
  };

  const addNewRow = async (e) => {
    e.preventDefault();
    let insitutePrestation = newInstitute.prestations;
    insitutePrestation.push({
      name: "",
      duration: "",
      price: "",
      category: "",
    });
    await setNewInstitute((prev) => {
      return { ...newInstitute, prestations: insitutePrestation };
    });
    let index = newInstitute.prestations.length - 1;

    let newRow = document.getElementById("prestation_" + index + "_name");
    //now scroll to the bottom of the list to show the new row

    // newRow.scrollIntoView({ behavior: "smooth" });

    //and focus on it
    newRow.focus();
  };

  const handleSubmitPrestation = (e) => {
    e.preventDefault();
    if (!checkFormValidity()) return;
    handleStepChange(e, "next");
  };

  const checkFormValidity = () => {
    let isValid = true;
    let prestationList = newInstitute.prestations;
    prestationList.forEach((prestation, index) => {
      if (
        !prestation.description ||
        prestation.name === "" ||
        prestation.duration === "00:00" ||
        prestation.duration === "" ||
        prestation.price === "" ||
        prestation.category === ""
      ) {
        toast.error("Veuillez remplir tous les champs de la prestation " + (index + 1));
        isValid = false;
      }
    });
    return isValid;
  };

  return (
    <>
      {newInstitute && (
        <>
          <h1 className="font-syne text-2xl mt-8 font-bold text-white">
            Mes prestations
          </h1>
          <div className="grow flex flex-col gap-4 h-full my-4">
            {/* PRESTATIONS CATEGORIES */}
            <div className="bg-black border border-taupe p-6 rounded-md max-h-[205px] overflow-y-scroll">
              {/* SUBTITLE */}
              <div className="flex flex-row justify-between">
                <div className="flex flex-row justify-start items-center gap-2">
                  {/* tag Icon */}
                  <HiOutlineTag className="w-6 h-6" />
                  <h2 className=" text-lg font-syne">Catégories</h2>
                </div>
                <div className="relative flex flex-row justify-start items-center gap-2">
                  <button
                    type="button"
                    className="btn-outline border-black dark:border-beige gap-1 flex flex-row"
                    onClick={openNewCategoryForm}>
                    {/*  plus ICON */}
                    <HiPlus className="w-6 h-6 cursor-pointer" />
                    <span className="xs:max-sm:hidden">Ajouter</span>
                  </button>
                </div>
              </div>

              {/* CATEGORIES LIST */}
              <div className="flex flex-row flex-wrap justify-start items-center gap-3 mt-4">
                {categoriesToDisplay.map((category, index) => {
                  return (
                    <div
                      className="relative flex flex-row justify-start items-center gap-2"
                      key={"category_" + index}>
                      <input
                        defaultChecked
                        type="checkbox"
                        name={category}
                        id={"category_" + index}
                        className="w-4 h-4 absolute peer top-3 left-2.5 checkbox-dark"
                        onChange={handlePrestationCategory}
                      />{" "}
                      <label
                        htmlFor={"category_" + index}
                        className="font-sm font-roboto peer-checked:bg-transparent dark:peer-checked:border-taupe peer-checked:border peer-checked:border-dark_grey dark:bg-dark_grey  bg-light_grey p-2 pl-8 rounded-md">
                        {category}
                      </label>
                    </div>
                  );
                })}
                {/* ADD NEW CATEGORY */}

                {/* NEW CATEGORY FORM */}
                <dialog data-new-category-form className="rounded-md ">
                  <form
                    className="flex flex-col gap-6 py-4 px-10 bg-beige dark:bg-black text-black border border-dark_grey dark:border-beige rounded-md dark:text-beige"
                    onSubmit={addNewCategory}>
                    <label htmlFor="category" className="flex flex-row gap-2">
                      {/* tag Icon */}

                      <HiOutlineTag className="w-6 h-6" />
                      <span className="text-lg font-syne"> Nouvelle catégorie</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder="Votre catégorie"
                      name="category"
                      id="category"
                      className=" input border border-taupe rounded-md p-2"
                      onChange={(e) => setNewCategory(e.target.value)}
                    />
                    <button className="btn-light" type="submit" aria-label="valider">
                      Ajouter
                    </button>
                    <button
                      type="button"
                      aria-label="Annuler"
                      onClick={closeNewCategoryForm}>
                      Annuler
                    </button>
                  </form>
                </dialog>
              </div>
            </div>
            {/* PRESTATIONS LIST */}
            <form
              action=""
              onSubmit={handleSubmitPrestation}
              className="flex flex-col grow justify-between">
              <div className="lg:h-[calc(100vh_-_605px)] lg:min-h-[200px] lg:overflow-y-scroll bg-black relative border border-taupe mb-6 rounded-md p-6">
                {/* SUBTITLE */}
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row justify-start items-center gap-2">
                    {/* clipboard Icon */}
                    <HiOutlineClipboard className="w-6 h-6" />
                    <h2 className="text-lg font-syne">Prestations</h2>
                  </div>
                  <button
                    type="button"
                    className="btn-outline border-black dark:border-beige gap-1 flex flex-row"
                    onClick={addNewRow}>
                    {/*  plus ICON */}
                    <HiPlus className="w-6 h-6 cursor-pointer" />
                    <span className="xs:max-sm:hidden">Ajouter</span>
                  </button>
                </div>

                <ul className="bg-transparent mt-4">
                  {newInstitute.prestations?.map((prestation, index) => {
                    return (
                      <SinglePrestationForm
                        {...prestation}
                        key={"prestation_" + index}
                        duplicateRow={duplicateRow}
                        deleteRow={deleteRow}
                        index={index}
                        categoriesToDisplay={categoriesToDisplay}
                        loading={loading}
                      />
                    );
                  })}
                </ul>
                {/*  plus ICON */}
              </div>
              {/* NAVIGATION */}
              <Nav handleStepChange={handleStepChange} disableSkip />
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default InstituteInfoPrestations;
