import React from "react";
import { useNewInstitute } from "Context/InstituteAuthContext";
import TeamMemberCard from "./TeamMemberCard";
import Nav from "./Nav";
import toast from "react-hot-toast";

const DisplayTeamMembers = ({ handleStepChange, isAStandAloneComponent }) => {
  const { newInstitute, setNewInstitute } = useNewInstitute();

  const skipThisForm = (e) => {
    if (newInstitute.staff.length > 0) {
      if (
        window.confirm(
          "Vous avez des membres d'équipe enregistrés, êtes-vous sûr de vouloir passer cette étape ?"
        ) === true
      ) {
        setNewInstitute((prev) => {
          return {
            ...prev,
            staff: [],
          };
        });
        handleStepChange(e, "next");
      } else return;
    } else handleStepChange(e, "next");
  };
  const handleNextStepForAlreadySubmittedInfo = (e) => {
    e.preventDefault();
    if (newInstitute.staff.length > 0) {
      handleStepChange(e, "next");
    } else {
      toast.error(
        "Vous devez ajouter au moins un membre d'équipe pour valider cette étape"
      );
      return;
    }
  };

  const deleteThisMember = (e, index) => {
    e.preventDefault();
    setNewInstitute((prev) => {
      return {
        ...prev,
        staff: prev.staff.filter((member, key) => key !== index),
      };
    });
  };

  return (
    <>
      {newInstitute?.staff.length > 0 ? (
        <div
          className={`flex grow overflow-x-scroll flex-row bg-black calc(100vh_-_398px) xs:max-sm:flex-wrap items-center xs:max-sm:justify-center gap-6 rounded-md p-6 my-6 ${
            !isAStandAloneComponent && "border dark:border-dark_grey"
          }`}>
          {newInstitute.staff.map((member, key) => (
            <div key={"team-member_" + key} className="w-48">
              <TeamMemberCard
                isAStandAloneComponent={isAStandAloneComponent}
                name={member.fullName}
                role={member.post}
                imageUrl={member.imgStaff}
                deleteThisMember={deleteThisMember}
                index={key}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="flex grow overflow-x-scroll flex-row bg-black calc(100vh_-_398px) xs:max-sm:flex-wrap items-center text-center xs:max-sm:justify-center justify-center  gap-6 rounded-md p-6 my-6">
          <span className="md:text-2xl text-base">
            Aucun membre d'équipe n'a encore été ajouté. <br></br>{" "}
            <span className=" md:text-sm text-xs text-beige opacity-80  mt-3">
              Appuyez sur le bouton 'En Ajouter' pour ajouter un membre d'équipe.
            </span>
          </span>
        </div>
      )}
      {!isAStandAloneComponent && (
        <Nav
          disableNextButton={!newInstitute.staff.length}
          disableSkip={true}
          handleStepChange={handleStepChange}
          skipThisForm={skipThisForm}
          isAlreadySubmitted
          handleNextStepForAlreadySubmittedInfo={handleNextStepForAlreadySubmittedInfo}
        />
      )}
    </>
  );
};

export default DisplayTeamMembers;
