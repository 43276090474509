import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import SalonDataContext from "Context/SalonDataContext";
import prestationContext from "Context/prestationContext";

import { v4 as uuidv4 } from "uuid";
// import Picture from "../../assets/ProCardmob.png";
import { AiFillStar } from "react-icons/ai";

const ProCardMob = ({ institute }) => {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const { name, address, prestations, _id } = institute;
  const { fetchSalonByid } = useContext(SalonDataContext);
  const { setSelectedPrestation } = useContext(prestationContext);
  const [imgErr, setimgErr] = useState("");

  const handleError = () => {
    setimgErr("https://via.assets.so/img.jpg?w=400&h=300");
  };

  //reduce the number of services to 3
  useEffect(() => {
    let _services = prestations && prestations?.slice(0, 2);
    setServices(_services);
  }, [prestations]);
  const handleClick = (id) => {
    navigate(`/salon/${id}`);
  };

  const handleSelectedPrestation = async (prestationId) => {
    // if a prestations is selected, fetch the salon by id to populate the localStorage
    if (_id) await fetchSalonByid(_id);
    let newId = uuidv4() + Date.now().toString();
    let _prestationArray = [...institute.prestations];
    let newPrestation = _prestationArray.find(
      (institute) => institute.id === prestationId
    );
    let _prestation = { ...newPrestation, idStaff: "", uid: newId };
    setSelectedPrestation((prev) => [...prev, _prestation]);
    navigate("/reservation");
  };
  const getPrestationDuration = (duration) => {
    return +duration?.split(":")[1] + +duration?.split(":")[0] * 60;
  };
  const [randomIndex, setRandomIndex] = useState(0);

  // Function to generate a random image [index]
  const getRandomIndex = () => {
    return Math.ceil(Math.random() * (institute?.photos.length || 0));
  };

  useEffect(() => {
    // Update the random index when the component mounts
    setRandomIndex(getRandomIndex());
  }, [institute]);

  // calculate the average rating of the institutes
  // const averageRatingInstitutes = () => {
  //   if (!institute || !institute.reviews || institute.reviews.length === 0) {
  //     return null; // return nothing  if no reviews are available
  //   }

  //   const totalRating = institute.reviews.reduce(
  //     (sum, review) => sum + review.ratingRev,
  //     0
  //   );
  //   return (totalRating / institute.reviews.length).toFixed(1);
  // };

  return (
    <div
      onClick={() => handleClick(institute._id)}
      className=" cursor-pointer glass-effect p-3 rounded-md h-[100%]  min-w-[70%] grow-1 snap-start overflow-hidden">
      <div className="flex justify-center items-center">
        <img
          src={
            imgErr
              ? "https://via.assets.so/img.jpg?w=400&h=300"
              : institute?.photos[0]
              ? institute?.photos[0]
              : "https://via.assets.so/img.jpg?w=400&h=300"
          }
          alt="procard"
          onError={handleError}
          className="w-full"
          style={{ aspectRatio: "16/9", width: "100%", objectFit: "contain" }}
        />
      </div>
      <div className="px-4 pb-2">
        <Link to={`/salon/${_id}`}>
          <h2 className="font-syne text-lg font-extrabold text-beige mt-2 overflow-hidden text-ellipsis">
            {name}
          </h2>
        </Link>
        <div className="flex items-center font-roboto text-sm gap-1">
          <AiFillStar /> {institute.rating} ({institute && institute.reviews})
        </div>
        <h3 className="font-roboto text-sm">{address}</h3>
        <div className="flex flex-col gap-4 mt-2 mb-2">
          {services &&
            services.length > 0 &&
            services &&
            services?.map((service, idx) => (
              <button
                onClick={() => handleSelectedPrestation(service.id)}
                key={service._id}
                aria-label={`choisir ${service.name}, durée: ${getPrestationDuration(
                  service.duration
                )}mn, prix: ${service.price} CHF `}
                className="flex flex-row justify-between text-roboto items-start text-beige text-sm">
                <div className="flex flex-col w-8/12 text-left">
                  <p className="line-clamp-2 text-roboto">{service.name}</p>
                  <p>{getPrestationDuration(service.duration)} mn</p>
                </div>
                <p>{service.price} CHF</p>
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProCardMob;
