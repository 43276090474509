import React from "react";
import WeeklyCalendarAgenda from "./WeeklyCalendarAgenda";
import { useContext, useEffect } from "react";
import AgendaContext from "Context/AgendaContext";
import moment from "moment";

const WeeklyContent = () => {
  const { SelectedEmpl, FetchEmployeeData, sevenDays, CurrentDate ,RulerData } =
    useContext(AgendaContext);
  useEffect(() => {
    FetchEmployeeData(SelectedEmpl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SelectedEmpl]);

  let ruler = [];
  if (RulerData[0]) {
    for (let x = RulerData[0]; x <= RulerData[1]; x++) {
      ruler.push(
        <div key={x} className="h-[450px] border-t-2 border-white px-4 py-1 text-white">
          <h6>{x}h</h6>
        </div>
      );
    }
  } else {
    for (let x = 8; x < 24; x++) {
      ruler.push(
        <div key={x} className="h-[300px] px-4 py-1 text-white">
          <h6>{x}h</h6>
        </div>
      );
    }
  }
  return (
    <div className="w-full">
      <div
        className={`text-sand mt-4  text-2xl font-extrabold flex  w-full pl-[4.55rem] mb-4   gap-4  `}>
        {sevenDays.map((item, i) => (
          <div
            key={i}
            className={`text-xs font-syne text-center grow p-4 border-sand border-t-2 border-opacity-30
            ${CurrentDate.isSame(moment(item), "day") ? "bg-[#A29689]" : ""}
            ${CurrentDate.isAfter(moment(item), "day") ? "opacity-50" : ""} `}>
            {moment(item).format("ddd Do/MM")}
          </div>
        ))}
      </div>
      <div className="w-full py-4 flex">
        <div className="flex flex-col w-[4.55rem]">{ruler.map((rule) => rule)}</div>
        <div className="bg-black grow justify-between flex gap-4 ">
          {sevenDays.map((item, i) => (
            <WeeklyCalendarAgenda Date={moment(item)} key={"w" + i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WeeklyContent;
