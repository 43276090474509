import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import InputFileElement from "./InputFileElement";
import Nav from "./Nav";
import ImageFromUrl from "./ImageFromUrl";
import { useNewInstitute } from "Context/InstituteAuthContext";
//icons
import { HiOutlineTrash } from "react-icons/hi2";

const InstituteInfoPhotos = ({ handleStepChange }) => {
  const { newInstitute, setNewInstitute } = useNewInstitute();
  const [message, setMessage] = useState("hey this is a message");
  const [imageSlot, setImageSlot] = useState(0);

  // Set the number of SLot available for upload
  useEffect(() => {
    let displayedImages =
      newInstitute?.photosFromGgAPI.length + newInstitute?.photos.length;

    if (displayedImages > 19) return setImageSlot(0);

    if (displayedImages >= 1) setImageSlot(1);
    else setImageSlot(1 - displayedImages);
  }, [newInstitute?.photosFromGgAPI, newInstitute?.photos]);

  function skipThisForm(e) {
    if (newInstitute.photosFromGgAPI.length || newInstitute.photos.length) {
      if (
        window.confirm(
          "Vous avez des photos enregistrées, êtes-vous sûr de vouloir passer cette étape ?"
        ) === true
      ) {
        setNewInstitute((prev) => {
          return {
            ...prev,
            photos: [],
            photosFromGgAPI: [],
          };
        });
        handleStepChange(e, "next");
      } else return;
    } else handleStepChange(e, "next");
  }

  const handleFile = (e) => {
    let file = e.target.files;
    const fileType = file[0]["type"];
    const validImageTypes = ["image/gif", "image/jpeg", "image/jpg", "image/png"];
    if (validImageTypes.includes(fileType)) {
      if (file[0].size > 5000000) {
        // 5MB
        setMessage("le fichier est trop volumineux");
        // console.log("too heavy");
        toast.error("le fichier est trop volumineux");
      } else {
        toast.success("image ajoutée");
        setNewInstitute({
          ...newInstitute,
          photos: [...newInstitute.photos, file[0]],
        });
        e.target.value = null;
      }
    } else {
      setMessage("seules les images sont autorisées");
      toast.error("seules les images sont autorisées");
    }
  };

  const deletePhotosUrl = (index) => {
    let newPhotosUrl = newInstitute.photosFromGgAPI.filter((photo, idx) => idx !== index);
    setNewInstitute((prev) => {
      return { ...prev, photosFromGgAPI: newPhotosUrl };
    });
  };

  const removeFile = (index) => {
    let newPhotos = newInstitute.photos.filter((photo, idx) => idx !== index);
    setNewInstitute((prev) => {
      return { ...prev, photos: newPhotos };
    });
    toast.success("image supprimée");
  };

  const handleSubmitPhotos = (e) => {
    e.preventDefault();
    handleStepChange(e, "next");
  };

  return (
    <>
      {newInstitute && (
        <section className="flex flex-col justify-start gap-6 grow min-h-[570px]">
          <div className="grow flex flex-col justify-start">
            <h1 className="font-syne text-2xl mt-8 font-bold text-white">Mes photos</h1>
            <form
              onSubmit={handleSubmitPhotos}
              className="mt-6 grow flex flex-col justify-between">
              <div
                id="institute_registration_photo_holder_step_5"
                className="bg-black min-h-[390px] max-h-[calc(100vh_-_335px)] sm:max-h-[calc(100vh_-_400px)] flex grow flex-row xs:max-lg:flex-wrap overflow-x-scroll xs:max-lg:justify-center items-center gap-6 border border-taupe p-6 rounded-md mb-6">
                {newInstitute.photosFromGgAPI &&
                  newInstitute.photosFromGgAPI.map((photoUrl, index) => (
                    <ImageFromUrl
                      photoUrl={photoUrl}
                      key={index}
                      deletePhotosUrl={deletePhotosUrl}
                      index={index}
                    />
                  ))}
                {newInstitute.photos.map((photo, index) => (
                  <div
                    className="aspect-video rounded-md relative w-60 min-[500px]:w-72 sm:w-96 h-auto shrink-0"
                    key={index}>
                    <img
                      src={URL.createObjectURL(photo)}
                      alt="salon"
                      width={400}
                      height={400}
                      className="aspect-video object-cover object-center rounded-md"
                    />
                    <HiOutlineTrash
                      className="w-7 h-7 absolute top-1 right-1 dark:bg-black bg-beige rounded-full p-1 cursor-pointer "
                      onClick={() => removeFile(index)}
                    />
                  </div>
                ))}
                {Array.from({ length: imageSlot }).map((e, i) => (
                  <div className="sm:m-6" key={i}>
                    <InputFileElement
                      index={i}
                      handleFile={handleFile}
                      message={message}
                    />
                  </div>
                ))}
              </div>
              <Nav
                disableNextButton={!newInstitute.photosFromGgAPI.length && !newInstitute.photos.length? true : false}
                disableSkip={true}
                handleStepChange={handleStepChange}
                skipThisForm={skipThisForm}
              />
            </form>
          </div>
        </section>
      )}
    </>
  );
};

export default InstituteInfoPhotos;
