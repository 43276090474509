import React, { useEffect } from "react";
import { useNewInstitute } from "../../../Context/InstituteAuthContext";
//icons
import { HiOutlineUser } from "react-icons/hi";
const Clientele = () => {
	const { newInstitute, setNewInstitute } = useNewInstitute();

	useEffect(() => {
		const handleClientGenderChanges = (e) => {
			if (newInstitute?.clientGender.femmes && newInstitute?.clientGender.hommes && newInstitute?.clientGender.enfants) {
				setNewInstitute({
					...newInstitute,
					clientGender: {
						...newInstitute.clientGender,
						allGender: true,
					},
				});
			} else
				setNewInstitute({
					...newInstitute,
					clientGender: {
						...newInstitute?.clientGender,
						allGender: false,
					},
				});
		};
		handleClientGenderChanges();
	}, [newInstitute?.clientGender.femmes, newInstitute?.clientGender.hommes, newInstitute?.clientGender.enfants]);

	const selectAllGender = (e) => {
		setNewInstitute({
			...newInstitute,
			clientGender: {
				...newInstitute.clientGender,
				allGender: e.target.checked,
				hommes: e.target.checked,
				femmes: e.target.checked,
				enfants: e.target.checked,
			},
		});
	};

	
	return (<>
		{ newInstitute &&
		<div className='rounded-md border dark:border-dark_grey p-4 pt-2 bg-black'>
			<div className='flex flex-row flex-wrap justify-between items-end gap-2'>
				<div className='flex flex-row justify-start items-center gap-2'>
					<HiOutlineUser className='w-7 h-7 text-beige' />
					<h2 className='my-4 text-lg font-syne font-bold text-beige'>Clientèle</h2>
				</div>
				<label htmlFor='institute_homeService' className='relative inline-flex items-center mb-5 cursor-pointer '>
					<input
						checked={newInstitute?.homeService}
						onChange={(e) =>
							setNewInstitute({
								...newInstitute,
								homeService: e.target.checked,
							})
						}
						id='institute_homeService'
						type='checkbox'
						className='sr-only peer'
					/>
					<div className="w-9 h-5 bg-transparent peer-focus:outline-none rounded-full peer border border-dark_grey peer-checked:after:translate-x-full peer-checked:after:bg-beige peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-dark_grey"></div>
					<span className='ml-3 text-xs font-medium dark:peer-checked:text-beige peer-checked:text-black text-gray-400'>Je me déplace chez le client</span>
				</label>
			</div>
			<div className='grid xs:max-sm:grid-cols-2 xs:max-sm:grid-rows-2 sm:grid-cols-4 gap-2'>
				<label htmlFor='institute_gender_all' className='shrink grow dark:border-taupe border py-2 px-6 rounded-md gap-2 flex flex-col items-center justify-center cursor-pointer text-beige'>
					<span className='text-sm font-roboto'>Tous</span>
					<div className=' relative inline-flex'>
						<input
							checked={newInstitute?.clientGender.allGender}
							onChange={(e) => {
								selectAllGender(e);
							}}
							id='institute_gender_all'
							type='checkbox'
							className='sr-only peer '
						/>
						<div className="w-11 h-6 bg-transparent peer-focus:outline-none rounded-full peer border border-dark_grey peer-checked:after:translate-x-full peer-checked:after:bg-beige peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-dark_grey transition-all duration-500"></div>
					</div>
				</label>
				<label
					htmlFor='institute_gender_femmes'
					className='shrink grow dark:border-taupe border py-2 px-6 rounded-md gap-2 flex flex-col items-center justify-center cursor-pointer text-beige'>
					<span className='text-sm font-roboto'>Femmes</span>
					<div className='relative inline-flex'>
						<input
							checked={newInstitute?.clientGender.femmes}
							onChange={(e) => {
								setNewInstitute((prev) => {
									return {
										...prev,
										clientGender: {
											...prev.clientGender,
											femmes: e.target.checked,
										},
									};
								});
							}}
							id='institute_gender_femmes'
							type='checkbox'
							className='sr-only peer'
						/>
						<div className="w-11 h-6 bg-transparent peer-focus:outline-none rounded-full peer border border-dark_grey peer-checked:after:translate-x-full peer-checked:after:bg-beige peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-dark_grey transition-all duration-500"></div>
					</div>
				</label>
				<label
					htmlFor='institute_gender_hommes'
					className='shrink grow dark:border-taupe border py-2 px-6 rounded-md gap-2 flex flex-col items-center justify-center cursor-pointer text-beige'>
					<span className='text-sm font-roboto'>Hommes</span>
					<div className='relative inline-flex'>
						<input
							checked={newInstitute?.clientGender.hommes}
							onChange={(e) => {
								setNewInstitute((prev) => {
									return {
										...prev,
										clientGender: {
											...prev.clientGender,
											hommes: e.target.checked,
										},
									};
								});
							}}
							id='institute_gender_hommes'
							type='checkbox'
							className='sr-only peer'
						/>
						<div className="w-11 h-6 bg-transparent peer-focus:outline-none rounded-full peer border border-dark_grey peer-checked:after:translate-x-full peer-checked:after:bg-beige peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-dark_grey transition-all duration-500"></div>
					</div>
				</label>
				<label
					htmlFor='institute_gender_enfants'
					className='shrink grow dark:border-taupe border py-2 px-6 rounded-md gap-2 flex flex-col items-center justify-center cursor-pointer text-beige'>
					<span className='text-sm font-roboto'>Enfants</span>
					<div className='relative inline-flex'>
						<input
							checked={newInstitute?.clientGender.enfants}
							onChange={(e) => {
								setNewInstitute((prev) => {
									return {
										...prev,
										clientGender: {
											...prev.clientGender,
											enfants: e.target.checked,
										},
									};
								});
							}}
							id='institute_gender_enfants'
							type='checkbox'
							className='sr-only peer'
						/>
						<div className="w-11 h-6 bg-transparent peer-focus:outline-none rounded-full peer border border-dark_grey peer-checked:after:translate-x-full peer-checked:after:bg-beige peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-dark_grey transition-all duration-500"></div>
					</div>
				</label>
			</div>
		</div>
	}
	</>);
};

export default Clientele;