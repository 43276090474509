import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";

Chart.register(ArcElement);



const HalfDoughnutChart = ({calculateMonthlyGoalPercentage}) => {
  const HalfDoughnutChartValue = calculateMonthlyGoalPercentage()
  const remainingPercentage = 100 - HalfDoughnutChartValue;

  const data = {
    datasets: [
      {
        data: [HalfDoughnutChartValue, remainingPercentage], // Update the data
        backgroundColor: ["#F6F0E8", "#A29689"], // White and gray
        borderWidth: 0, // No border
      },
    ],
  };
  return (
    <div>
      <Doughnut
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          rotation: -90,
          circumference: 180,
          cutout: "85%", 
          maintainAspectRatio: true,
          responsive: true,
        }}
      />
 
    </div>
  );
};

export default HalfDoughnutChart;
