import React from "react";
import HomeDesktop from "../Component/Home/HomeDesktop";
import HomeMob from "../Component/Home/HomeMob";

const Home = () => {
  return (
    <>
    <div className=" hidden md:flex bg-home-woman">
      <HomeDesktop />
    </div>
    <div className="sm:flex md:hidden">
      <HomeMob />
    </div>
  </>
  );
};

export default Home;
