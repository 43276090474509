import React, { useEffect } from "react";
import {
  RdvReminder,
  RdvModified,
  RdvAborted,
  NewReview,
  NewMessage,
} from "./NotificationCards";
import data from "../../dummyData/dummyNotifications.json";


const Notifications = ({setNewNotification} ) => {
  // Sort notifications by date
  const notifications = data.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const unreadNotifications = notifications.filter(
    (notification) => notification.isNew
  );
  
  let readNotifications = notifications.filter(
    (notification) => !notification.isNew
  );
  // Limit the number of read notifications to 15
  readNotifications.length = 15;

  //  When the notification panel has been visited turn all the notification as read.
  //will not work until react.strictmode is removed . 
  useEffect(() => {
    return () => {
      notifications.map(notification =>  {
        return notification.isNew = false;
      });
      setNewNotification(false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get the time ago from the createdAt date
  const getTimeAgo = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const timeDifference = now - createdDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days >= 7) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
       
      };
      return createdDate.toLocaleDateString("fr-FR", options);
    } else if (days > 0) {
      return `il y a ${days} jour${days > 1 ? "s" : ""}`;
    } else if (hours > 0) {
      return `il y a ${hours} heure${hours > 1 ? "s" : ""}`;
    } else if (minutes > 0) {
      return `il y a ${minutes} minute${minutes > 1 ? "s" : ""}`;
    } else {
      return `il y a quelques secondes`;
    }
  };

 // Get the notification component depending on the type
  const getNotificationComponent = (type, index, message, isNew, timeAgo) => {
    switch (type) {
      case "RdvReminder":
        return (
          <RdvReminder
            key={index}
            message={message}
            isNew={isNew}
            timeAgo={timeAgo}
          />
        );
      case "RdvModified":
        return (
          <RdvModified
            key={index}
            message={message}
            isNew={isNew}
            timeAgo={timeAgo}
          />
        );
      case "RdvAborted":
        return (
          <RdvAborted
            key={index}
            message={message}
            isNew={isNew}
            timeAgo={timeAgo}
          />
        );
      case "NewReview":
        return (
          <NewReview
            key={index}
            message={message}
            isNew={isNew}
            timeAgo={timeAgo}
          />
        );
      case "NewMessage":
        return (
          <NewMessage
            key={index}
            message={message}
            isNew={isNew}
            timeAgo={timeAgo}
          />
        );
      default:
        return <div key={index}>Notification type not found</div>;
    }
  };

  return (
    <div className="flex flex-col gap-10 dark:text-beige">
      {!unreadNotifications.length && (
        <h3 className="text-lg font-normal">Pas de nouvelle notification</h3>
      )}
      {unreadNotifications.map((notification, index) => {
        const timeAgo = getTimeAgo(notification.createdAt);
        const notificationComponent = getNotificationComponent(
          notification.type,
          index,
          notification.subject,
          true,
          timeAgo
        );

        return <div key={index}>{notificationComponent}</div>;
      })}
      {readNotifications && <h3 className="text-lg">Lu</h3>}
      {readNotifications.map((notification, index) => {
        const timeAgo = getTimeAgo(notification.createdAt);
        const notificationComponent = getNotificationComponent(
          notification.type,
          index,
          notification.subject,
          false,
          timeAgo
        );

        return <div key={index}>{notificationComponent}</div>;
      })}{""}
    </div>
  );
};

export default Notifications;
