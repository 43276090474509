import { useContext, useEffect, useState } from "react";
import LoggedContext from "../Context/isLogged";
import { Outlet, useLocation ,Navigate } from "react-router-dom";
import SideNavProConnected from "Component/Header/SideNavProConnected";
import { useProConnectedContext } from "Context/ProConnectedContext";
import { BsLayoutSidebarInset } from "react-icons/bs";
import { AgendaProvider } from "Context/AgendaContext";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";


const ProConnectedlayout = () => {
  //const { isLogged } = useContext(LoggedContext);
  const location = useLocation();
  const { sideBar, setSideBar } = useProConnectedContext();
  const [mainClass, setMainClass] = useState(
    window.innerWidth > 1024 && window.innerWidth <= 1900 && location.pathname !== "/ProConnected/agenda" ? "ml-[11.9rem]" : "ml-0"
  );



  //set darkTheme
  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.add("dark");
  }, []);
  

  //sidebar always display in lg screen
  useEffect(() => {
    if(location.pathname !== "/ProConnected/agenda"){
      if (window.innerWidth > 1024 ) {
        setSideBar(true);
      } else {
        setSideBar(false);
      }
    }
   
  }, [location,window.innerWidth]);

  const handleSmallScreenClick = () => {
    if (window.innerWidth <= 1024) {
      setSideBar(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setMainClass(
        window.innerWidth > 1024 && window.innerWidth <= 1900 && sideBar&& location.pathname !== "/ProConnected/agenda" ? "ml-[11.9rem] mx-2" :`ml-0 mx-0`
      );
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    // return () => {
    //   if(location.pathname === "/ProConnected/agenda"){

    //     window.removeEventListener("resize", handleResize);
    //   }
    // };
  }, [sideBar, location,mainClass]);
  const isAuthenticated = !!Cookies.get("authToken");
  if (isAuthenticated) {
	  const decoded = jwt_decode(Cookies.get("authToken"));
  
	  if (decoded) {
		if (!decoded.isPro) {
		  return (
			<Navigate to="/profile" state={{ from: location }} />
		  );
		}
	  }
	}

  return (
    <AgendaProvider>
      <div>
        <div className="pro-background bg-cover h-screen xs:bg-center    ">
          {sideBar ? (
            <SideNavProConnected setSideBar={setSideBar} sideBar={sideBar} />
          ) : (
            <BsLayoutSidebarInset
              className="ml-[5%] cursor-pointer mt-2"
              onClick={() => setSideBar(!sideBar)}
              size={30}
              color="white"
            />
          )}

          <main
            className={`text-white ${mainClass} transition-all duration-500 ease-in-out`}
            onClick={handleSmallScreenClick}
          >
            <Outlet />
          </main>
        </div>
      </div>
    </AgendaProvider>
  );
};

export default ProConnectedlayout;
