import { useState } from "react";
import CalendarItem from "./CalendarItem";
import FreeCalendarItem from "./FreeCalendarItem";
import { HiMoon } from "react-icons/hi";
import { useContext, useEffect } from "react";
import moment from "moment";
import AgendaContext from "Context/AgendaContext";

const CalendarAgenda = ({ Employee }) => {
  const { EmployeeData, SelectedDate, CombineFreeslots } = useContext(AgendaContext);

  const getDuration = (frame) => {
    let period = [frame.timerPeriod[0].split(":"), frame.timerPeriod[1].split(":")];
    let a = (Number(period[1][0]) - Number(period[0][0])) * 60;
    let b = Number(period[1][1]) - Number(period[0][1]);
    return a + b;
  };
  const [FiltredData, setFiltredData] = useState([]);
  useEffect(() => {
    let Temp = EmployeeData.filter((item) => {
      return (
        moment(item.startTimer).isSame(SelectedDate, "day") && Employee === item.staff
      );
    });
    setFiltredData(CombineFreeslots(Temp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EmployeeData, SelectedDate]);

  return (
    <div className="flex flex-col w-1/2 ">
       {FiltredData.map((item, index) => { 
        return item.isEvent === false ? (
           <FreeCalendarItem key={item._id} duration={getDuration(item) * 5} />
         ) : item.type === "reservation" ? (
          <CalendarItem
            Slot={item}
            key={item._id}
            SelectedDate={SelectedDate}
            event={item.name}
            customer={item.customer}
            timePeriod={item.timerPeriod}
            phone={item.phone}
            endTimer={item.endTimer}
            startTimer={item.startTimer}
            email={item.email}
            duration={getDuration(item)}
          />
        ) : (
          <div key={item._id}>
            <div
              style={{ height: getDuration(item) * 5 }}
              className="bg-black  flex items-center text-white border-y-2  border-opacity-60 border-beige ">
              <button className="  p-2   mx-2">
                <HiMoon />
              </button>{" "}
              <h5 className="font-syne text-lg">En pause</h5>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CalendarAgenda;
