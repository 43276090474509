import { createContext, useContext, useState, useMemo } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import toast from "react-hot-toast";
import { RdvModified } from "Component/utils/NotificationCards";
import { ConnectUserInFront } from "Component/utils/connectionHandler";
import axiosInstance from "config/Interceptor";
import LoggedContext from "./isLogged";
const FormContext = createContext();

function FormDataProvider({ children }) {
  const connectUserInFrontEnd = ConnectUserInFront();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  // const [renddata, setRenddata] = useState([]);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {setIsLogged} = useContext(LoggedContext)

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`/api/v1/secure/client/get`);
      setFormData(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
      // console.log(error);
    }
  };
  const [editedFormData, setEditedFormData] = useState({ ...formData });

  const [selectedFile, setSelectedFile] = useState();

  const submitForm = async () => {
    try {
      const formDataToSend = new FormData();
      const user = {
        firstName: editedFormData.firstName,
        lastName: editedFormData.lastName,
        phone: editedFormData.phone,
        email: editedFormData.email,
      };

      formDataToSend.append("user", JSON.stringify(user));
      formDataToSend.append("files", selectedFile);

      const response = await axiosInstance.put(
        `/api/v1/secure/client/update`,
        formDataToSend
      );
      // Assuming response.data contains the updated imageUrl
      const newImageUrl = response.data.imageUrl;
      // Update the imageUrl state
      setImagePreview(newImageUrl);
      if (response.data?.client) {
        setFormData(response.data.client);
      }
      // Update the user info  in the front-end
      connectUserInFrontEnd(response.data, true);
    } catch (error) {
      console.error("Error updating profile", error);
    }
  };

  //fetch rendez-vous
  const [userId, SetUserId] = useState();
  const getUserInfo = () => {
    const authToken = Cookies.get("authToken");
    if (authToken) {
      const decoded = jwt_decode(authToken);

      let { id } = decoded;
      SetUserId(id);
    }
  };
  // const fetchRend = async () => {
  //   try {
  //     const res = await axiosInstance.get(`/api/v1/secure/reservation/client`);
  //     setRenddata(res.data);
  //   } catch (error) {
  //     // Handle error here
  //     console.error("Error fetching data:", error);
  //   }
  // };

  //psw update

  const handleUpdatePsw = () => {
    axiosInstance
      .post(`/api/v1/secure/client/recoverpwd`, {
        password,
        email: formData.email,
      })
      .then((response) => {
        // console.log("password updated successfully:", response.data);
        // console.log(password);
      })
      .catch((error) => {
        console.error("Error updating password:", error);
      });
  };
  const [imagePreview, setImagePreview] = useState(null);

  const [selectedImg, setSelectedImg] = useState(null);

  const handleImgChange = (event) => {
    const file = event.target.files[0];
    setSelectedImg(file);

    //  preview of the selected image
    const reader = new FileReader();
    reader.onload = (e) => {
      setImagePreview(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmitImg = async () => {
    if (selectedImg) {
      const formData = new FormData();
      formData.append("file", selectedImg);

      try {
        const response = await axiosInstance.post(
          `/api/v1/secure/upload/profile/image`,
          formData
        );

        if (response.status === 200) {
          // console.log("File uploaded successfully");
          toast.success("Image uploaded successfully");
        } else {
          console.error("File upload failed");
        }
      } catch (error) {
        console.error("Error uploading file", error);
      }
    }
  };

  //calendar
  const [showModal, setShowModal] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [staffId, setStaffId] = useState("");
  const [instituteIdForUpdate, setInstituteIdForUpdate] = useState("");
  const [slotList, setSlotList] = useState([]);
  const [loadingSlots, setLoadingSlots] = useState(true);

  const fetchSlotList = async () => {
    try {
      setLoadingSlots(true);

      const res = await axiosInstance.get(
        `/api/v1/secure/slots/list/staff/${staffId}`
      );
      setSlotList(res.data);
      setLoadingSlots(false); // Data fetching is complete, set loading to false
    } catch (error) {
      // Handle error here
      console.error("Error fetching data:", error);
      setLoadingSlots(false); // Handle error and set loading to false
    }
  };
  // Handle Update()  the selected event and toggle isEvent
  // State to track the selected slot and its background color
  const [selectedSlot, setSelectedSlot] = useState(null);

  // Function to clear the style from localStorage
  const clearSlotStyleInLocalStorage = () => {
    localStorage.removeItem("selectedSlotStyle");
  };

  // Function to toggle the 'isEvent' property of a slot via API call with Authorization header
  const toggleSlotIsEvent = (slot) => {
    const slot_Id = slot._id;
    const updatedSlot = { ...slot, isEvent: !slot.isEvent }; // Toggle isEvent property
    axiosInstance
      .put(`/api/v1/secure/slots/id/${slot_Id}`, updatedSlot)
      .then((response) => {
        // Handle the response if needed
        setSelectedSlot(null);
        // Clear the selected slot after the update
        fetchSlotList();
        toast.custom(
          <RdvModified message="rendez-vous updated successfully" toast />
        );
      })
      .finally(() => {
        // Clear the stored style
        clearSlotStyleInLocalStorage();
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });
  };

  //  modal delete account
  const [showModalDeleteacc, setShowModalDeleteacc] = useState(false);

  //   delete account
  const removeUser = async () => {
    try {
      const response = await axiosInstance.delete(
        `/api/v1/secure/client/remove`
      );
      setIsLogged(false)

      // Handle the successful response here (e.g., show a success message)
      // console.log("User removed successfully:", response.data);
    } catch (error) {
      // Handle any errors (e.g., show an error message)
      console.error("Error removing user:", error);
    }
  };

  const valueToShare = useMemo(
    () => ({
      password,
      formData,
      submitForm,
      selectedFile,
      setSelectedFile,
      loading,
      error,
      handleUpdatePsw,
      setPassword,
      handleSubmitImg,
      handleImgChange,
      imagePreview,
      showModal,
      setShowModal,
      selectedName,
      setSelectedName,
      showModalDeleteacc,
      setShowModalDeleteacc,
      removeUser,
      fetchData,
      setImagePreview,
      slotList,
      loadingSlots,
      fetchSlotList,
      setStaffId,
      setInstituteIdForUpdate,
      instituteIdForUpdate,
      setSelectedSlot,
      selectedSlot,
      toggleSlotIsEvent,
      clearSlotStyleInLocalStorage,
      editedFormData,
      setEditedFormData,
    }),
    [
      formData,
      submitForm,

      imagePreview,
      showModal,

      selectedName,

      showModalDeleteacc,

      removeUser,

      fetchData,
      setImagePreview,
      slotList,

      fetchSlotList,

      instituteIdForUpdate,
      setSelectedSlot,
      selectedSlot,

      editedFormData,
    ]
  );

  return (
    <FormContext.Provider value={valueToShare}>{children}</FormContext.Provider>
  );
}

function useFormContext() {
  return useContext(FormContext);
}

export { FormDataProvider, useFormContext };
export default FormContext;
