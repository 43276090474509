import React from "react";
import { useContext } from "react";
import { HiPlus } from "react-icons/hi";
import AgendaContext from "Context/AgendaContext";

const FreeCalendarItem = ({  duration }) => {
  const { AddEvent } = useContext(AgendaContext);
  return (
    <div>
      <div
        onClick={AddEvent}
        style={{ height: duration }}
        className="bg-black  flex items-center text-white  border-opacity-60 border-beige ">
        <button className="rounded-full  p-2  bg-mud mx-4" onClick={AddEvent}>
          <HiPlus />
        </button>{" "}
        <h5>Créneau libre</h5>
      </div>
    </div>
  );
};

export default FreeCalendarItem;
