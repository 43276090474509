import React, { useState, useContext } from "react";
import LoggedContext from "../../Context/isLogged";
import { Link, useNavigate } from "react-router-dom";
// import googleIcon from "../../assets/googleIcon.svg";
import axios from "axios";
import Cookies from "js-cookie";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import PhoneNumberInput from "./PhoneNumberInput";
import { useNewUser } from "../../Context/UserAuthContext";
import { initiatePhoneCheck, resgisterUser } from "./apiService";
import SmSpinner from "Component/ui/SmSpinner";

import { toast } from "react-hot-toast";
import { HiOutlineEyeSlash, HiOutlineEye, HiXMark } from "react-icons/hi2";
//icons
const UserSignupForm = ({ isAmodal, isStep, setStep, setUserWantsToSignup }) => {
  const { toggleUserState, setUserGlobal } = useContext(LoggedContext);
  const { newUser, setNewUser } = useNewUser();
  const [googleUser, setGoogleUser] = useState(null);
  const [inputType, setInputType] = useState("password");
  const [isPossiblePhoneNumber, setIsPossiblePhoneNumber] = useState(true);
  const [error, setError] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleError = (message) => {
    // console.log("message", message);
    setError(message);
    setTimeout(() => {
      setError("");
    }, 6000);
  };

  const handleFormInput = (e) => {
    setNewUser((user) => {
      return { ...user, [e.target.name]: e.target.value };
    });
  };

  const handlePaswordVisibility = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  const handleFormSubmit = async (tokenResponse) => {
    // console.log(tokenResponse)
    // if (!checkFormValidity()) return;
    setLoading(true);
    // initiatePhoneCheck(newUser.phone)
    resgisterUser(tokenResponse? tokenResponse : newUser)
      .then((res) => {
        // console.log(res);
        if (res.status === 201) {
          // console.log("passed");
          Cookies.set("authToken", res.data.token, { expires: 3 });
          setUserGlobal({ ...res.data.client });
          toggleUserState();
          toast.success("Votre compte a été créé avec succès");
          if (!isAmodal) {
            setTimeout(() => {
              navigate("/dashboard");
              setLoading(false);
            }, 3000);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
        // Handle any errors that occurred in the previous steps
        switch (error.response?.status) {
          case 422:
            setLoading(false);
            handleError("Certains champs sont manquants ou incorrects");
            break;
          case 429:
            setLoading(false);
            handleError("Trop de tentatives, veuillez réessayer plus tard");
            break;
          case 409:
            setLoading(false);
            handleError("Un compte existe déjà avec ce numéro de téléphone");
            setPhoneError(true);
            break;
          case 400:
            setLoading(false);
            handleError("Votre Numéro de téléphone est invalide");
            toast.error("Numéro de téléphone est invalide");
            break;
          default:
            break;
        }
      });
  };
  const checkFormValidity = () => {
    const emptyFields = [];

    if (!newUser.firstName) {
      emptyFields.push("Nom");
    }
    if (!newUser.lastName) {
      emptyFields.push("Prénom");
    }
    if (!newUser.email) {
      emptyFields.push("Email");
    }
    if (!newUser.password) {
      emptyFields.push("Mot de passe");
    }
    if (!newUser.phone) {
      emptyFields.push("Numéro de téléphone");
    }
    if (!isPossiblePhoneNumber) {
      emptyFields.push("Numéro de téléphone invalide");
    }
    if (document.querySelector("#CGI").checked === false) {
      emptyFields.push("Acceptez les CGU");
    }
    if (emptyFields.length > 0) {
      const emptyFieldsMessage = emptyFields.join(", ");
      toast.error(`Veuillez renseigner les champs suivants : ${emptyFieldsMessage}`);
      return false;
    } else return true;
  };
  const connectSpinner = (loading) => {
    if (loading) {
      return (
        <>
          <SmSpinner h={5} w={5} />
          Se connecter
        </>
      );
    } else {
      return "Se Connecter";
    }
  };

  const signupSpinner = (loading) => {
    if (loading) {
      return (
        <>
          <SmSpinner h={5} w={5} />
          S'inscrire
        </>
      );
    } else {
      return "S'inscrirer";
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      
      handleFormSubmit(tokenResponse);
    },
  });
  return (
    <section
      className={`${
        isStep === 2 && "blur pointer-events-none select-none"
      } transition-all duration-300 bg-beige w-10/12 sm:w-8/12 lg:w-6/12 xl:w-[800px] flex flex-col items-center justify-center mx-auto p-6 rounded-xl relative`}>
      {isAmodal ? (
        <div className="w-full flex flex-row justify-between items-baseline mb-2">
          <span className="text-xl self-start">Créer mon compte</span>
          <HiXMark size={20} onClick={() => setUserWantsToSignup(false)} />
        </div>
      ) : (
        <h1 className="text-xl my-3 self-start"> S'inscrire</h1>
      )}

      <form
        action=""
        className="flex flex-col gap-6 w-full mb-3"
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}>
        {/* Name */}
        <div className="flex flex-row flex-wrap justify-start items-baseline gap-6 w-full">
          <div className="flex flex-col grow">
            <label className="text-sm" htmlFor="prenom">
              Nom
            </label>
            <input
              placeholder="Prénom"
              className="input rounded-md placeholder:font-light placeholder:text-light_grey w-full"
              type="text"
              name="lastName"
              id="prenom"
              onChange={(e) => handleFormInput(e)}
            />
          </div>
          <div className="flex flex-col grow">
            <label className="text-sm" htmlFor="nom">
              Prénom{" "}
            </label>
            <input
              placeholder="Nom"
              className="input rounded-md placeholder:font-light placeholder:text-light_grey w-full"
              type="text"
              name="firstName"
              id="nom"
              onChange={(e) => handleFormInput(e)}
            />
          </div>
        </div>
        {/* Mail */}
        <div className="flex flex-col">
          <label className="text-sm" htmlFor="email">
            Mail
          </label>
          <input
            placeholder="Mail"
            className="input check-validity rounded-md placeholder:font-light placeholder:text-light_grey w-full"
            type="email"
            name="email"
            id="email"
            onChange={(e) => handleFormInput(e)}
          />
        </div>
        {/* Password */}
        <div className="flex flex-col relative">
          {inputType === "password" ? (
            <HiOutlineEye
              className="w-4 h-4 absolute top-[32px] right-6 z-0"
              onClick={handlePaswordVisibility}
            />
          ) : (
            <HiOutlineEyeSlash
              className="w-4 h-4 absolute top-[32px] right-6 z-0"
              onClick={handlePaswordVisibility}
            />
          )}
          <label className="text-sm select-none" htmlFor="password">
            Mot de passe
          </label>
          <input
            placeholder="Mot de passe"
            className="input check-validity rounded-md placeholder:font-light placeholder:text-beige w-full"
            type={inputType}
            name="password"
            pattern="^(?=.*[A-Za-z])(?=.*\d).{8,}$"
            id="password"
            onChange={(e) => handleFormInput(e)}
          />
          <span className="text-xs text-taupe ">
          Au moins 8 caractères et un chiffre
          </span>
        </div>
        <div className="flex flex-col">
          <label className="text-sm" htmlFor="telephone">
            <PhoneNumberInput
              error={phoneError}
              setIsPossiblePhoneNumber={setIsPossiblePhoneNumber}
            />
          </label>
        </div>

        <div className="">
          <label
            htmlFor="CGI"
            className="relative inline-flex items-center mb-5 cursor-pointer">
            <input name="CGI" id="CGI" type="checkbox" className="h-5 w-5" />
            <span className="ml-3 text-sm font-medium">
              J'accepte les <span className="underline">CGU</span> de Stunify
            </span>
          </label>
        </div>

        <div className="flex flex-col ">
          <button
            type="submit"
            className="btn-dark disabled:pointer-events-none select-none disabled:opacity-50">
            {isAmodal ? signupSpinner(loading) : connectSpinner(loading)}
          </button>
          <button
            className="btn-dark mt-3   w-full items-center  text-white py-2 px-3  gap-3 rounded-full flex justify-center"
            onClick={(e) => {
              e.preventDefault();
              googleLogin();
            }}>
            <img className="aspect-square w-[25px]" src={'https://bucket-stunify-app.s3.eu-north-1.amazonaws.com/static/assets/googleIcon.svg'} alt="google" />
            Continuer avec Google{" "}
          </button>
          {error && (
            <p className="text-red-800 text-sm ml-1 -mb-5 h-5 mt-1">{error || ""}</p>
          )}
        </div>
      </form>
      {!isAmodal && (
        <>
          {" "}
          <hr className="w-full h-px my-2 bg-light_grey border-0 " />
          <h2 className="text-xl my-3 self-start text-black">Déjà un compte ?</h2>
          <Link to="/login" className="text-center btn-light w-full">
            J'ai déjà un compte
          </Link>
        </>
      )}
    </section>
  );
};

export default UserSignupForm;
