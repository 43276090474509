import axiosInstance from "config/Interceptor";
import { createContext, useState, useEffect, useMemo } from "react";
import toast from "react-hot-toast";
const salonContext = createContext();

function getInitialState() {
  const salon = localStorage.getItem("salon");
  return salon ? JSON.parse(salon) : [];
}

function SalonDataProvider({ children }) {

  const [salon, setSalon] = useState(getInitialState);
  const [salonForReservation, SetSalonForReservation] = useState({});
  const [prestationArray, setPrestationArray] = useState([]);

  useEffect(() => {
    localStorage.setItem("salon", JSON.stringify(salon));
  }, [salon]);

  useEffect(() => {
    let storedPrestations = localStorage.getItem("prestations");
    storedPrestations = storedPrestations ? JSON.parse(storedPrestations) : [];
    if (
      (!prestationArray || prestationArray.length === 0) &&
      storedPrestations?.length > 0
    )
      setPrestationArray(storedPrestations);
  }, [prestationArray]);

  const fetchSalonByid = async (id) => {
    try {
      const res = await axiosInstance.get(`/api/v1/institute/id/${id}`);
      if (salon._id === res.data._id && salon.isValidated === res.data.isValidated) return;
      setSalon(res.data);
      if (res.data?.prestations) {
        setPrestationArray(res.data.prestations);
        localStorage.setItem("prestations", JSON.stringify(res.data.prestations));
      }
    } catch (error) {
      // Handle error here
      console.error("Error fetching data:", error);
    }
  };
  const validateSalon = async (id) => {
    try {
      const res = await axiosInstance.put(`api/v1/secure/institute/validate/${id}`);
      if (res.status === 200) {
        toast.success("Institute valider");
        fetchSalonByid(id)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // to update staff for salon reservation
  const fetchSalonByidForReservationUpdate = async (id) => {
    try {
      const res = await axiosInstance.get(`/api/v1/institute/id/${id}`);

      SetSalonForReservation(res.data);
    } catch (error) {
      // Handle error here
      console.error("Error fetching data:", error);
    }
  };

  // calculate  opening hours
  // Get the current date and time
  const currentDate = new Date();
  const currentDay = currentDate.toLocaleDateString("fr-FR", {
    weekday: "long",
  });

  // Convert the current day to uppercase to match with api
  const currentDayUpperCase = currentDay.toUpperCase();

  // Find the business hours for the current day
  const currentDayHours =
    salon &&
    salon.businessHours &&
    salon.businessHours.find((day) => day.name.toUpperCase() === currentDayUpperCase);

  // Check if the salon is open today
  const isSalonOpen = currentDayHours && currentDayHours.isOpen;

  // Format the opening hours for display and start with ferme then change it if the salon is open
  let openingHoursText = "Fermé aujourd’hui";

  if (isSalonOpen) {
    const morningStart = currentDayHours.morningStart;
    const eveningEnd = currentDayHours.eveningEnd;

    if (morningStart && eveningEnd) {
      openingHoursText = `Ouvert aujourd’hui ${morningStart} - ${eveningEnd}`;
    }
  }

  // calculate average rating for salon (instiute )

  const calculateAverageRating = () => {
    if (!salon || !salon.reviews || salon.reviews.length === 0) {
      return null; // return nothing  if no reviews in api are available
    }
    const totalRatingforSalon = salon.reviews.reduce(
      (sum, review) => sum + review.ratingRev,
      0
    );
    return (totalRatingforSalon / salon.reviews.length).toFixed(1);
  };

  const valueToShare = useMemo(
    () => ({
      validateSalon,
      fetchSalonByid,
      calculateAverageRating,
      salon,
      setSalon,
      openingHoursText,
      prestationArray,
      
      salonForReservation,
      SetSalonForReservation,
      fetchSalonByidForReservationUpdate,
    }),
    [salon, setSalon, prestationArray, salonForReservation, fetchSalonByid]
  );
  return <salonContext.Provider value={valueToShare}>{children}</salonContext.Provider>;
}
export { SalonDataProvider };
export default salonContext;
