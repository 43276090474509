import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import SmSpinner from "../../Component/ui/SmSpinner";
// import googleIcon from "../../assets/googleIcon.svg";
import {
  connectUserInBackend,
  ConnectUserInFront,
} from "Component/utils/connectionHandler";
import axios from "axios";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";

import { toast } from "react-hot-toast";
// icons
import { HiOutlineEyeSlash, HiOutlineEye, HiXMark } from "react-icons/hi2";
import LoggedContext from "Context/isLogged";

const LoginPage = ({ isAmodal, setUserWantsToConnect , prologin }) => {
  const { isLogged, isPro } = useContext(LoggedContext);
  const navigate = useNavigate();
  const [inputType, setInputType] = useState("password");
  const [error, setError] = useState("");
  const [formSubmited, setFormSubmited] = useState(false);
  const [loading, setLoading] = useState(false);
  const connectUserInFrontEnd = ConnectUserInFront();
  const location = useLocation();

  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [googleToken, setgoogleToken] = useState(null);

  useEffect(() => {
    
    if (isLogged) {
      if (isPro && location.pathname === "/login") navigate("/ProConnected/caisseDash");
      else if (location.pathname === "/login") {
        navigate("/profile");
      }
    }
  }, [isLogged]);

  const handlePaswordVisibility = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  const handleError = (message) => {
    setError(message);
    setTimeout(() => {
      setError("");
    }, 8000);
  };

  const handleLogin = async (googleToken) => {
    setFormSubmited(true);

    if ((form.email && form.password) || googleToken) {
      setLoading(true);
      

      connectUserInBackend(form, googleToken , prologin)
        .then((res) => {
          if (!res.ok) {
            setLoading(false);
            return Promise.reject(res.status);
          } else return res.json();
        })
        .then((data) => {
         
          connectUserInFrontEnd(data, isAmodal);
          setLoading(false);
        })
        .catch((error) => {
          // Handle any errors that occurred in the previous steps

          console.error("An error occurred:", error);
          switch (error) {
            case 422:
              setLoading(false);
              googleLogout();
              toast.error("Les informations d'identification ne sont pas valides !");
              handleError("Identifiants incorrects");
              break;

            default:
              setLoading(false);
              toast.error("Les informations d'identification ne sont pas valides !");
              handleError("Identifiants incorrects");
              break;
          }
        });
    } else {
      toast.error("Les informations d'identification ne sont pas valides !");

      handleError("Identifiants incorrects");
    }
  };


  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      handleLogin(codeResponse);
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/calendar.app.created https://www.googleapis.com/auth/calendar.calendarlist.readonly openid profile email'
    
  });

  return (
    <>
      <div
        className={`mx-auto flex flex-col justify-center items-center ${prologin? 'glass-effect text-white' : 'bg-beige'}  w-full sm:w-8/12 lg:w-6/12 xl:w-[800px] rounded-xl ${
          isAmodal && "rounded-br-none rounded-bl-none"
        } p-6`}>
        {isAmodal ? (
          <div className=" w-full flex flex-row justify-between items-baseline mb-2">
            <span className="font-bold text-lg font-roboto self-start">Connexion</span>
            <HiXMark size={20} onClick={() => setUserWantsToConnect(false)} />
          </div>
        ) : (
          <h1 className="text-xl my-3 self-start">Déjà un compte ?</h1>
        )}
        <form
          className="gap-6 flex flex-col justify-center w-full"
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}>
          <div className="flex flex-col">
            <label htmlFor="email" className="w-full text-sm">
              Mail
            </label>
            <input
              className={
                "input rounded-md text-black placeholder:text-light_grey " +
                (formSubmited && !form.email ? "check-validity" : "")
              }
              value={form.email}
              id="email"
              placeholder="Mail"
              type="email"
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="password" className="relative  w-full text-sm">
              Mot de passe
              {inputType === "password" ? (
                <HiOutlineEye
                  className="w-4 h-4 absolute top-[32px] right-6 z-0"
                  onClick={handlePaswordVisibility}
                />
              ) : (
                <HiOutlineEyeSlash
                  className="w-4 h-4 absolute top-[32px] right-6 z-0"
                  onClick={handlePaswordVisibility}
                />
              )}
            </label>
            <input
              className={
                "input rounded-md text-black placeholder:text-light_grey " +
                (formSubmited && !form.password ? "check-validity" : "")
              }
              id="password"
              placeholder="Mot de passe"
              type={inputType}
              value={form.password}
              onChange={(e) => setForm({ ...form, password: e.target.value })}
            />
          </div>
          <div className="forgortpass-container self-start">
            {/* <Link
              to="#"
              className="text-sm mb-4 my-5 text-black underline hover:opacity-80">
              Mot de passe oublié ?
            </Link> */}
          </div>
          <div className="my-4 mb-3.5 w-full">
            <p className="text-red-400 text-sm ml-1 -mb-5 h-5">{error || ""}</p>

            <button
              className={`${prologin? 'btn-light' : 'btn-dark'} mb-3  w-full items-center  text-white py-2 px-3 rounded-full`}
              type="submit">
              {loading ? <SmSpinner h={5} w={5} /> : ""} Se connecter
            </button>
            <button
              className={`${prologin? 'btn-light' : 'btn-dark'}  mb-3   w-full items-center  text-white py-2 px-3  gap-3 rounded-full flex justify-center`}
              onClick={(e) => {
                e.preventDefault();
                googleLogin();
              }}>
              <img className="aspect-square -translate-x-4   w-[25px]" src={'https://bucket-stunify-app.s3.eu-north-1.amazonaws.com/static/assets/googleIcon.svg'} alt="google" />
             <span className="-translate-x-4">Continuer avec Google</span> 
            </button>
            {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
          </div>
        </form>
        {isAmodal ? (
          ""
        ) : (
          <>
            <hr className="w-full h-px my-2 bg-light_grey border-0 " />
            <p className="text-xl my-3 self-start">S'inscrire</p>
            <Link
              to={prologin? '/pro/institute-registration' : '/signup'}
              className="btn-light border-black w-full  my-3 text-center">
              Créer mon compte
            </Link>
          </>
        )}
      </div>
    </>
  );
};

export default LoginPage;
