import { useContext, useEffect, useState } from "react";
import SmSpinner from "Component/ui/SmSpinner";
import moment from "moment";
import AgendaContext from "Context/AgendaContext";
import { HiOutlineCalendar, HiOutlineEmojiSad } from "react-icons/hi";
import { useModalContext } from "Context/ModalContext";

const DateView = ({ staff, setDisabled }) => {
  const { sixDays } = useContext(AgendaContext);

  const {
    FiltredData,
    setEditBody,
    editBody,
    Date,
    setDate,
    Loading,
    SelectedPrestation,
    SetSlotid,
    EmployeeSelected,
    SetBody,
    Body,
    FetchFreeSlot,
    SelectedDate,
    SetSelctedDate,
    slotPicked,
    SelectedClient,
    setslotPicked,
  } = useModalContext();

  //////////////////////////check if it's a new RDV or Edit RDV and Fetch freeslot ////////////////////////


  

  useEffect(() => {
    if (staff) {
      FetchFreeSlot(staff,SelectedClient);
    } else {
      FetchFreeSlot(EmployeeSelected,SelectedClient);
    }
  }, [EmployeeSelected, SelectedDate]);

  return (
    <>
      {!Body.slotids ? (
        <div className="modalElement_eventModal bg-black bg-opacity-60 border-[1px] border-[#4A4A49] p-6  rounded-md items-center">
          <div className="flex gap-2 col-span-3">
            <HiOutlineCalendar size={24} /> <p className="text-xl font-syne ">Date </p>
          </div>
          {/* DAYS  */}
          <div className="flex  self-start  ">
            {sixDays.map((item, i) => {
              return (
                <div
                  key={i}
                  onClick={() => SetSelctedDate(moment(item))}
                  className={`flex flex-col items-center grow  cursor-pointer ${
                    moment(item).isSame(moment(SelectedDate), "day")
                      ? "opacity-100 "
                      : "opacity-20"
                  }`}>
                  <span className="text-[#F6F0E8]">{moment(item).format("dddd")}</span>
                  <div className="flex gap-2 text-[#82786E]">
                    <span>{moment(item).format("DD")}</span>
                    <span>{moment(item).format("MMMM")}</span>
                  </div>
                </div>
              );
            })}
          </div>
          {/* Times */}
          {Loading ? (
            <div className="flex justify-center items-center mt-3">
              {" "}
              <SmSpinner w={10} h={10} />{" "}
            </div>
          ) : (
            <div className="flex flex-wrap  justify-start mt-3 gap-3 ">
              {FiltredData.length === 0 ? (
                <div className="flex gap-3 w-full justify-center items-center mt-3">
                  <HiOutlineEmojiSad size={20} />
                  <span className="font-syne">
                    il n'y a pas de créneau horaire disponible
                  </span>
                </div>
              ) : (
                FiltredData.map((item, index) => (
                  <span
                    onClick={() => {
                      setDate(moment(item.startTimer).utcOffset(0));
                      if (setDisabled) {
                        setDisabled(false);
                      }

                      SetBody({
                        ...Body,
                        slotids: SetSlotid(SelectedPrestation.duration, item._id),
                      });
                      setslotPicked(item._id);
                    }}
                    key={item._id}
                    className={`cursor-pointer  h-[90%] rounded-3xl text-xs px-3 py-2  ${
                      Body.slotids[0] === item._id
                        ? "bg-black text-white border-2 border-white"
                        : "text-black bg-sand"
                    }`}>
                    {item.timerPeriod[0]}
                  </span>
                ))
              )}
            </div>
          )}
          {/* {staff && <button onClick={() => SetToggleDate(!ToggleDate)}>test</button>} */}
        </div>
      ) : (
        <div className="modalElement_eventModal bg-black bg-opacity-60 border-[1px] border-[#4A4A49] px-4 py-4 rounded-md flex flex-col gap-2">
          <div className="flex gap-2 justify-between items-center">
            <div className="flex gap-3 items-center font-syne text-lg">
              {" "}
              <HiOutlineCalendar size={24} />{" "}
              <p className="text-xl ">
                {moment(Date).format("dddd DD MMMM - hh:mm") + "h"}
              </p>
            </div>
            <p
              onClick={() => {
                SetBody({ ...Body, slotids: "" });
                setEditBody({ ...editBody, slotids: "" });
              }}
              className="cursor-pointer text-sm underline  ">
              Modifier
            </p>
          </div>
        </div>
      )}
      {/* TITLE + ICON */}
    </>
  );
};

export default DateView;
