import StatsWidget from "Component/ProConnected/StatsWidget";
import React, { useContext, useEffect } from "react";

import BarChart from "../../../Component/ProConnected/BarChart";
import { Link } from "react-router-dom";
import { IoNotificationsOutline } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { BiChevronDown } from "react-icons/bi";
// import profile from "./../../../assets/profile.jpeg";
import { AiOutlinePieChart } from "react-icons/ai";
import { PiNotepadLight } from "react-icons/pi";
import { BsBookmarkHeart } from "react-icons/bs";
import { useProConnectedContext } from "Context/ProConnectedContext";
import LoadSpinner from "Component/utils/LoadSpinner";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import {
  HiOutlineRectangleGroup,
  HiArrowRightOnRectangle,
} from "react-icons/hi2";
import LoggedContext from "Context/isLogged";
import { HiChevronDown } from "react-icons/hi";
import { MdOutlineHomeRepairService } from "react-icons/md";
import Cookies from "js-cookie";
import {
  DisconnectUserInFront,
  disconnectUserInBackend,
} from "Component/utils/connectionHandler";
import toast from "react-hot-toast";
function StatsDash() {
  const {
    fetchdataProStats,
    dataProStats,
    monthlyGoal,
    setMonthlyGoal,
    calculateMonthlyGoalPercentage,
    setSelectedYear,
    selectedYear,
    fetching,
  } = useProConnectedContext();
  const { userGlobal , isPro } = useContext(LoggedContext);

  useEffect(() => {
    fetchdataProStats();
  }, [selectedYear]);

  const disconnectUserInFront = DisconnectUserInFront();

  const handleUserDisconnection = () => {
    let token = Cookies.get("authToken");
    disconnectUserInBackend(token).then((res) => {
      if (!res.ok) {
        console.log("error");
      }
      disconnectUserInFront();
      toast.success("Vous êtes déconnecté");
    });
  };

  return (
    <>
      {/* header */}
      <div className="flex flex-row justify-between items-center   md:w-[95%]  w-full container mx-auto p-3 border-b-2 border-[#4A4A49]  ">
        <div className="md:flex md:flex-row  grid grid-cols-2  md:justify-between  justify-center items-center md:gap-4 gap-6 md:space-x-8 space-x-3 font-syne">
          <Link
            to="/ProConnected/caissedash"
            className=" flex flex-col justify-between items-center space-y-1 text-center ml-2 md:ml-0  "
          >
            <PiNotepadLight size={20} className="text-[#FFC77C] mb-2 md:mb-0" />
            <p className="text-[#A29689] text-sm md:text-base lg:text-base">
              Mon Salon
            </p>
          </Link>
          <Link
            to="/ProConnected/caissedetails"
            className=" flex flex-col hover:text-[#FFC77C]  justify-between items-center space-y-1  text-center"
          >
            <AiOutlinePieChart size={20} />
            <p className="text-sm md:text-base lg:text-base">
              Analyse des Prestations{" "}
            </p>
          </Link>
          <Link
            to="/ProConnected/caissedetails"
            className=" flex flex-col hover:text-[#FFC77C]  justify-between items-center space-y-1  text-center"
          >
            <BsBookmarkHeart size={20} />
            <p className="text-sm md:text-base lg:text-base">
              Meilleures clients{" "}
            </p>
          </Link>
          <Link
            to="/ProConnected/caissedetails"
            className=" flex flex-col hover:text-[#FFC77C]  justify-between items-center space-y-1  text-center"
          >
            <CgProfile size={20} />
            <p className="text-sm md:text-base lg:text-base">
              Pourcentage de vente{" "}
            </p>
          </Link>
        </div>
        <div>
          <div className="flex md:flex-row flex-col md:space-y-0 space-y-6 justify-between items-center space-x-4">
            <IoNotificationsOutline
              size={30}
              className="absolute cursor-pointer ml-4 md:ml-0"
            />
            <span className="bg-[#F59E0B] h-2 w-2 rounded-full relative bottom-6 right-[-3px] md:bottom-2  md:right-0 cursor-pointer"></span>
            <div className="cursor-pointer">
              {userGlobal.imageUrl ? (
                <img
                  src={userGlobal.imageUrl}
                  alt="user profile PIC"
                  className="w-8 h-8 rounded-full "
                />
              ) : (
                <span className="tracking-wide" id="ProprofileImage">
                  {userGlobal.firstName[0]?.toUpperCase() +
                    userGlobal.lastName[0]?.toUpperCase()}
                </span>
              )}
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger className="flex flex-row justify-between items-center space-x-1 cursor-pointer group">
                <p className="text-[#F6F0E8] text-sm not-italic font-bold capitalize leading-[normal] font-roboto cursor-pointer">
                  {userGlobal?.firstName}
                </p>
                <HiChevronDown className="w-5 h-5 transition duration-500 ease-in-out transform scale-y-100 group-data-[state=open]:-scale-y-100 " />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="bg-[#2D2E2F] text-white mx-8 rounded-xl">
                <Link to={!isPro? '/profile' : '/proconnected/caisseDash'}>
                  <DropdownMenuItem className="pl-7 py-3 hover:underline flex flex-row items-center gap-2 text-white">
                    <HiOutlineRectangleGroup className="h-6 w-6" />
                    <span className="text-white">Dashboard</span>
                  </DropdownMenuItem>
                </Link>
                <DropdownMenuSeparator />
                <Link to="/pro">
                  <div className="hover:underline flex flex-row gap-2 justify-start py-3 pl-6 pr-20 cursor-pointer">
                    <MdOutlineHomeRepairService className="w-6 h-6 text-white" />
                    <span className="text-white">Pro Home</span>
                  </div>
                </Link>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={handleUserDisconnection}>
                  <div className="hover:underline flex flex-row gap-2 justify-start py-3 pl-6 pr-20 cursor-pointer">
                    <HiArrowRightOnRectangle className="w-6 h-6 text-white" />
                    <span className="text-white">Se déconnecter</span>
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      {/* header -end */}
      <div className=" flex flex-row mt-2  md:container md:mx-auto lg:px-0 md:px-3 px-6  ">
        <div className="  w-full    ">
          {/* widget */}
          <StatsWidget
            dataProStats={dataProStats}
            monthlyGoal={monthlyGoal}
            setMonthlyGoal={setMonthlyGoal}
            calculateMonthlyGoalPercentage={calculateMonthlyGoalPercentage}
            setSelectedYear={setSelectedYear}
          />

          <div className="container mx-auto mt-8">
            <h1 className="text-white text-lg not-italic font-bold leading-[normal] font-syne ml-1 md:ml-0 ">
              CA / mois <span className="text-sm">({selectedYear})</span>
            </h1>
            <BarChart dataProStats={dataProStats} />
          </div>
        </div>
      </div>
      {fetching && <LoadSpinner />}
    </>
  );
}

export default StatsDash;
