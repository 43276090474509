import React, { useContext , useState } from "react";
import SalonDataContext from "Context/SalonDataContext";
import prestationContext from "Context/prestationContext";
import "styles/chooseAnEmployeeCard.css";

const PrestationCard = ({ onDelete, index, ...prestation }) => {
  const { selectedPrestation, setSelectedPrestation } =
    useContext(prestationContext);
  const { salon } = useContext(SalonDataContext);
  const [staffimgerr, setstaffimgerr] = useState("");

  const handlestaffimgError = () => {
    setstaffimgerr(true);
  };
  const handleChange = (e) => {
    prestation.idStaff = e.target.value;
    let _selectedPrestation = [...selectedPrestation];
    let index = _selectedPrestation.findIndex(
      (item) => item._id === prestation._id && item.uid === prestation.uid
    );
    _selectedPrestation[index] = prestation;
    setSelectedPrestation(_selectedPrestation);
  };

  const handleDeleteClick = () => {
    onDelete(prestation._id);
  };

  const [hours, minutes] = prestation.duration.split(":").map(Number);
  const totalMinutes = hours * 60 + minutes;
  return (
    <div className="backdrop-blur-lg bg-white/30 lg:bg-[#F6F0E8] lg:backdrop-filter-none cus-radius lg:p-[1.7rem]  w-[350px] lg:max-w-[500px]  lg:w-auto   h-max p-9 flex flex-col rounded-3xl	items-start mx-auto ">
      {/* pc */}
      <div className=" lg:flex-row justify-between mb-5 w-full font-roboto text-base hidden lg:flex">
        <p className="flex flex-col w-4/6 font-roboto text-base ">
          {prestation.name}
          <span>{totalMinutes} mn</span>
        </p>
        <p className="font-bold  ">{prestation.price} CHF</p>
        <p
          className="underline underline-offset-2 cursor-pointer"
          onClick={handleDeleteClick}
        >
          Supprimer
        </p>
      </div>
      {/* mobile */}
      <div className="font-roboto  text-white text-lg lg:hidden w-full    ">
        <div className="flex flex-row justify-between  w-full mb-1 ">
          <p className="lg:hidden leading-5 font-roboto text-lg  font-bold	 ">
            Prestation n°{index + 1}
          </p>
          <p
            className="underline underline-offset-2  text-xs cursor-pointer "
            onClick={handleDeleteClick}
          >
            Supprimer
          </p>
        </div>

        <div className="flex flex-row justify-between  w-full ">
          <p className="flex flex-col font-roboto w-52 leading-5 text-white  ">
            {prestation.name}
            <span className="text-black text-sm lg:text-base mt-1">
              {totalMinutes}mn
            </span>
          </p>
          <p className="font-semibold ">{`${prestation.price} CHF`}</p>
        </div>
      </div>

      <h5 className=" font-roboto text-xl font-semibold  text-white lg:text-black  md:mb-2 mb-1 ">
        Avec qui ?
      </h5>
      <div className="flex flex-wrap items-stretch gap-[0.7rem]  w-full">
        <label
          onClick={() => handleChange({ target: { value: "" } })}
          htmlFor={"pas_de_preference" + index}
          className="checkbox-label lg:w-[45%] w-full border p-4 rounded-lg flex mx-auto items-center space-x-2   "
        >
          <input
            onChange={handleChange}
            id={"pas_de_preference" + index}
            type="radio"
            checked={prestation.idStaff === ""}
            value={""}
          />
          &nbsp; Pas de préférence
        </label>
        {salon.staff.map((employee, employeeIndex) => {
          const isRadioChecked =
            prestation.idStaff + prestation._id + prestation.uid ===
            employee._id + prestation._id + prestation.uid;
          return (
            <label
              onClick={() => handleChange({ target: { value: employee._id } })}
              title={`${prestation.idStaff}_${prestation._id}_${prestation.uid}`}
              htmlFor={employee._id + prestation._id + prestation.uid  }
              key={prestation._id + employee._id + prestation.uid }
              className="checkbox-label lg:w-[45%] w-full border p-4 rounded-lg flex mx-auto items-center space-x-2"
            >
              <input
                id={employee._id + prestation._id + prestation.uid }
                type="radio"
                value={employee._id}
                checked={isRadioChecked}
                onChange={handleChange}
                name={prestation._id+prestation.uid}
              />
              {!employee.imgStaff || staffimgerr  ?  (
                <div className="lg:w-10 lg:h-10 w-8 h-8 p-2 rounded-full bg-[#D0CBC4] text-[#4A4A49] lg:text-xl text-sm font-syne flex items-center justify-center capitalize shrink-0">
                  {employee.fullName.split("")[0]}
                </div>
              ):(
                <div className="h-11 w-11 rounded-full overflow-hidden relative shrink-0">
                  <img
                    src={employee.imgStaff}
                    alt={`${employee.fullName}`}
                    className="h-full w-full object-cover absolute"
                    onError={handlestaffimgError}
                  />
                </div>
              ) }
              <div className="flex flex-col ">
                <p className="text-base font-bold text-black font-syne capitalize">
                  {" "}
                  {employee.fullName}
                </p>{" "}
                <p className="lg:text-[#82786E] text-white font-roboto text-sm">
                  {employee.post}
                </p>
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default PrestationCard;
