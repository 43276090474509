import { useNewInstitute } from "Context/InstituteAuthContext";
import {v4 as uuidv4} from "uuid"
// icons
import { HiOutlineClipboardDocument, HiOutlineTrash } from "react-icons/hi2";

const SinglePrestationForm = ({ name, category, description, duration, price, duplicateRow, deleteRow, index, categoryToDisplay, loading }) => {
	const { newInstitute, setNewInstitute } = useNewInstitute();

	const updatePrestations = (index, e) => {
		e.preventDefault();
		const updatedPrestations = newInstitute.prestations.map((prestation, i) => {
			if (i === index) {
				return {
					...prestation,
					[e.target.name]: e.target.value,
					id: uuidv4() + Date.now().toString()
				};
			}
			return prestation;
		});

		setNewInstitute((prev) => ({
			...prev,
			prestations: updatedPrestations,
		}));
	};
	const updatePrestationsDuration = (index, e) => {
		const hourInput = document.getElementById("prestation_" + index + "_duration_hours");
		const minuteInput = document.getElementById("prestation_" + index + "_duration_minutes");
		const duration = hourInput.value + ":" + minuteInput.value;
		const updatedPrestations = newInstitute.prestations.map((prestation, i) => {
			if (i === index) {
				return {
					...prestation,
					duration: duration,
				};
			}
			return prestation;
		});
		setNewInstitute((prev) => ({
			...prev,
			prestations: updatedPrestations,
		}));

	}


	return (
		<li className='py-6 flex flex-row flex-wrap justify-start items-center gap-6 first:pt-0 xs:max-lg:border-b xs:max-lg:border-taupe last:xs:max-lg:border-none'>
			<label htmlFor={"prestation_" + index + "_name"} className='text-sm font-roboto'>
				{" "}
				Nom
				<input
					value={name}
					id={"prestation_" + index + "_name"}
					type='text'
					name='name'
					className='input rounded-md'
					placeholder='Nom de la prestation'
					onChange={(e) => {
						updatePrestations(index, e);
					}}
				/>
			</label>
			<label htmlFor={"prestation_" + index + "_description"} className='grow text-sm font-roboto'>
				{" "}
				Decription
				<input
					
					value={description? description : ""}
					id={"prestation_" + index + "_description"}
					type='text'
					name='description'
					className='input rounded-md'
					placeholder='Description de la prestation'
					onChange={(e) => {
						updatePrestations(index, e);
					}}
				/>
			</label>

			<div className='flex flex-row xs:max-sm:flex-wrap gap-6 items-center'>
				<label htmlFor={"prestation_" + index + "_category"} className='flex text-sm font-roboto flex-col'>
					Catégorie
					<select
						value={category}
						id={"prestation_" + index + "_category"}
						name='category'
						className='input rounded-md appearance-none xs:max-xl:!w-32 '
						onChange={(e) => {
							updatePrestations(index, e);
						}}>
						<option disabled value='' hidden>
							catégorie
						</option>
						{newInstitute.prestationsCategories.map((category, index) => {
							return (
								<option key={"category_" + index} value={category}>
									{category}
								</option>
							);
						})}
					</select>
				</label>
				<div id={"prestation_" + index + "_duration"} className='flex justify-start text-sm font-roboto flex-col w-fit'>
					Durée
					<div className='flex flex-row justify-start items-center input !p-0 rounded-md '>
						<label htmlFor={"prestation_" + index + "_duration_hours"}>
							<select
								id={"prestation_" + index + "_duration_hours"}
								name='duration_hours'
								value={duration.split(":")[0]}
								className='input !border-0 !pl-2 !pr-1 appearance-none w-4'
								onChange={(e) => {
									updatePrestationsDuration(index, e);
								}}>
								{" "}
								{[...Array(10)].map((hour, index) => {
									return (
										<option key={"hour_" + index} value={"0" + index}>
											0{index}
										</option>
									);
								})}
							</select>
						</label>
						<span>:</span>
						<label htmlFor={"prestation_" + index + "_duration_minutes"}>
							<select
								id={"prestation_" + index + "_duration_minutes"}
								name='duration_minutes'
								className='input !border-0 !pl-1 !pr-2 rounded-md appearance-none w-4'
								value={duration.split(":")[1]}
								onChange={(e) => {
									updatePrestationsDuration(index, e);
								}}>
								<option value='00'>00</option>
								<option value='20'>20</option>
								<option value='40'>40</option>
							</select>
						</label>
					</div>
				</div>
				<label htmlFor={"prestation_" + index + "_price"} className='flex flex-col text-sm font-roboto relative grow-0 w-28'>
					Prix
					<input
						id={"prestation_" + index + "_price"}
						value={price}
						name='price'
						min='0'
						max='9999'
						type='number'
						pattern='^[0-9]+$'
						className='input rounded-md peer'
						onChange={(e) => {
							updatePrestations(index, e);
						}}
					/>
					<span className='absolute bottom-2 right-2 bg-beige dark:bg-black peer-focus:bg-light_grey dark:peer-focus:bg-dark_grey pt-1'>CHF</span>
				</label>
				{/* duplicate Icon */}
				<button type='button' disabled={loading} aria-label='dupliquer la ligne' onClick={() => duplicateRow(index)}>
					<HiOutlineClipboardDocument className='w-6 h-6 mt-4 hover:opacity-80' />
				</button>
				{/* trashBin Icon */}
				<button type='button' aria-label='Effacer la ligne' onClick={() => deleteRow(index)}>
					<HiOutlineTrash className={`w-6 h-6 mt-4 hover:opacity-80 ${newInstitute.prestations.length === 1 && "dark:text-dark_grey text-light_grey"} `} />
				</button>
			</div>
		</li>
	);
};

export default SinglePrestationForm;
