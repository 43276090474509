import DetailsWidge from "Component/ProConnected/DetailsWidge";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineStar } from "react-icons/ai";
import { PiNotepadLight } from "react-icons/pi";
import { Link } from "react-router-dom";
import { IoNotificationsOutline } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { BiChevronDown } from "react-icons/bi";
// import profile from "./../../../assets/profile.jpeg";
import { AiOutlinePieChart } from "react-icons/ai";
import { BsBookmarkHeart } from "react-icons/bs";
import { useStatsContext } from "Context/StatsContext";
import LoadSpinner from "Component/utils/LoadSpinner";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import LoggedContext from "Context/isLogged";
import { MdOutlineHomeRepairService } from "react-icons/md";
import { HiChevronDown } from "react-icons/hi";
import {
  HiOutlineRectangleGroup,
  HiArrowRightOnRectangle,
} from "react-icons/hi2";
import {
  DisconnectUserInFront,
  disconnectUserInBackend,
} from "Component/utils/connectionHandler";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
function StatsDetails() {
  const {
    fetchStats,
    stats,
    selectedYear,
    setSelectedYear,
    sortBy,
    setSortBy,
    fetching,
  } = useStatsContext();
  const [prestations, setPrestations] = useState([]);
  const [years, setYears] = useState([]);

  useEffect(() => {
    selectedYear && sortBy && fetchStats(selectedYear, sortBy);
  }, [selectedYear, sortBy]);

  useEffect(() => {
    if (stats?.sortedPrestations?.length) {
      setPrestations(stats.sortedPrestations);
      setYears(stats.years);
    }
  }, [stats]);

  const handleYearChanged = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };
  const { userGlobal , isPro } = useContext(LoggedContext);

  const disconnectUserInFront = DisconnectUserInFront();

  const handleUserDisconnection = () => {
    let token = Cookies.get("authToken");
    disconnectUserInBackend(token).then((res) => {
      if (!res.ok) {
        console.log("error");
      }
      disconnectUserInFront();
      toast.success("Vous êtes déconnecté");
    });
  };
  return (
    <>
      <div className="flex flex-row justify-between items-center     w-full container mx-auto p-3 border-b-2 border-[#4A4A49]  ">
        <div className="md:flex md:flex-row  grid grid-cols-2  md:justify-between  justify-center items-center md:gap-4 gap-6 md:space-x-8 space-x-3 font-syne">
          <Link
            to="/ProConnected/caissedash"
            className=" flex flex-col justify-between items-center space-y-1 text-center ml-2 md:ml-0  "
          >
            <PiNotepadLight size={20} className="text-[#FFC77C] mb-2 md:mb-0" />
            <p className="text-[#A29689] text-sm md:text-base lg:text-base">
              Mon Salon
            </p>
          </Link>
          <Link
            to="/ProConnected/caissedetails"
            className=" flex flex-col  justify-between items-center space-y-1  text-center"
          >
            <AiOutlinePieChart size={20} />
            <p className="text-sm md:text-base lg:text-base">
              Analyse des Prestations{" "}
            </p>
          </Link>
          <Link
            to="/ProConnected/caissedetails"
            className=" flex flex-col  justify-between items-center space-y-1  text-center"
          >
            <BsBookmarkHeart size={20} />
            <p className="text-sm md:text-base lg:text-base">
              Meilleures clients{" "}
            </p>
          </Link>
          <Link
            to="/ProConnected/caissedetails"
            className=" flex flex-col  justify-between items-center space-y-1  text-center"
          >
            <CgProfile size={20} />
            <p className="text-sm md:text-base lg:text-base">
              Pourcentage de vente{" "}
            </p>
          </Link>
        </div>
        <div>
          <div className="flex md:flex-row flex-col md:space-y-0 space-y-6 justify-between items-center space-x-4">
            <IoNotificationsOutline
              size={30}
              className="absolute cursor-pointer ml-4 md:ml-0"
            />
            <span className="bg-[#F59E0B] h-2 w-2 rounded-full relative bottom-6 right-[-3px] md:bottom-2  md:right-0 cursor-pointer"></span>

            <div className="cursor-pointer">
              {userGlobal.imageUrl ? (
                <img
                  src={userGlobal.imageUrl}
                  alt="user profile PIC"
                  className="w-8 h-8 rounded-full "
                />
              ) : (
                <span className="tracking-wide" id="ProprofileImage">
                  {userGlobal.firstName[0]?.toUpperCase() +
                    userGlobal.lastName[0]?.toUpperCase()}
                </span>
              )}
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger className="flex flex-row justify-between items-center space-x-1 cursor-pointer group">
                <p className="text-[#F6F0E8] text-sm not-italic font-bold capitalize leading-[normal] font-roboto cursor-pointer">
                  {userGlobal.firstName}
                </p>
                <HiChevronDown className="w-5 h-5 transition duration-500 ease-in-out transform scale-y-100 group-data-[state=open]:-scale-y-100 " />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="bg-[#2D2E2F] text-white mx-8 rounded-xl">
                <Link to={!isPro? '/profile' : '/proconnected/caisseDash'}>
                  <DropdownMenuItem className="pl-7 py-3 hover:underline flex flex-row items-center gap-2 text-white">
                    <HiOutlineRectangleGroup className="h-6 w-6" />
                    <span className="text-white">Dashboard</span>
                  </DropdownMenuItem>
                </Link>
                <DropdownMenuSeparator />
                <Link to="/pro">
                  <div className="hover:underline flex flex-row gap-2 justify-start py-3 pl-6 pr-20 cursor-pointer">
                    <MdOutlineHomeRepairService className="w-6 h-6 text-white" />
                    <span className="text-white">Pro Home</span>
                  </div>
                </Link>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={handleUserDisconnection}>
                  <div className="hover:underline flex flex-row gap-2 justify-start py-3 pl-6 pr-20 cursor-pointer">
                    <HiArrowRightOnRectangle className="w-6 h-6 text-white" />
                    <span className="text-white">Se déconnecter</span>
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      {/* header -end */}
      <div className="w-full  flex flex-row mt-2  md:container md:mx-auto lg:px-0 md:px-3 px-6  ">
        <div className="  w-full  ">
          <div className="flex w-full ml-2">
            <div className="bg-black border border-[#F6F0E8)]  lg:mt-0 rounded-[30px] border-solid   py-3 px-6 place-self-end ">
              <select
                name="year"
                className="bg-black text-white outline-none focus:border-white  md:w-[150px] w-[120px] "
                value={selectedYear}
                onChange={handleYearChanged}
              >
                {years?.length > 0 &&
                  years.map((year) => (
                    <option value={year} key={year}>
                      {year}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <DetailsWidge
            mostUsedPrestation={stats?.mostUsedPrestation}
            reservationCount={stats?.reservationCount}
            selectedYear={selectedYear}
            occupationPercentage={stats?.occupationPercentage}
            freeHours={stats?.freeHours}
          />

          <div className="flex flex-row justify-between items-center  mt-4">
            <div className="flex flex-row mt-3 items-center gap-2 ">
              <PiNotepadLight size={25} />
              <p>Données prestations</p>
            </div>

            <div className="flex flex-col items-center md:items-start justify-center ">
              <p className="md:ml-5 ml-0 md:mb-2 mb-0">Trier par</p>

              <div className="bg-black border border-[#F6F0E8)]  lg:mt-0 rounded-[30px] border-solid  py-3 px-6 place-self-end ">
                <select
                  name="promo"
                  className="bg-black text-white outline-none focus:border-white   w-[150px] "
                  value={sortBy}
                  onChange={handleSortChange}
                >
                  <option value="mostUsed">Les plus réalisés</option>
                  <option value="highPaid">Les plus payés</option>
                  <option value="name">Nom</option>
                </select>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-3 gap-4 font-roboto">
            {/* Favorie */}
            {prestations?.length > 0 &&
              prestations.map((prestationObj, index) => (
                <div
                  key={
                    prestationObj.prestation.name + prestationObj.prestation.uid
                  }
                  style={{
                    backgroundColor:
                      index === 1
                        ? "rgba(74, 74, 73, 0.70)"
                        : index === 2
                        ? "rgba(74, 74, 73, 0.500)"
                        : "",
                  }}
                  className={
                    "flex flex-row white py-4 px-5 rounded justify-between items-center " +
                    (index === 0
                      ? "border bg-[#4a4a49] border-[#A29689] border-solid"
                      : "")
                  }
                >
                  <div className="flex flex-row justify-between items-center gap-6  ">
                    {index === 0 && <AiOutlineStar size={30} />}
                    <div className="flex flex-col justify-between  items-start">
                      <p
                        className={
                          "text-[#F6F0E8] text-base  not-italic md:font-bold  font-semibold leading-[19px] " +
                          (index === 0 ? "md:text-xl" : "md:text-l")
                        }
                      >
                        {prestationObj.prestation.name}
                      </p>
                      {/* <p className="text-[#A29689]  text-sm not-italic font-normal leading-[normal] mt-1">
                        40mn
                      </p> */}
                    </div>
                  </div>
                  <div className="flex flex-row justify-between items-center gap-6">
                    <p className="flex flex-col md:flex-row items-center justify-center gap-2  text-[#F6F0E8)] text-xl not-italic font-bold leading-[19px]">
                      {prestationObj.count}{" "}
                      <span className="text-sm not-italic font-normal leading-[normal] text-[#F6F0E8]">
                        Prestations
                      </span>
                    </p>
                    <p className="whitespace-nowrap text-[#F6F0E8] text-xl not-italic font-bold leading-[19px]">
                      {prestationObj.totalRevenue} €
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {fetching && <LoadSpinner />}
    </>
  );
}

export default StatsDetails;
