export const registerInstitute = async (institute) => {
  let _staff = institute.staff;
  let _photos = institute.photos;
  const formData = new FormData();

  // Add each photo from institute.photos to the formData
  if (_photos.length > 0) {
    _photos.forEach((photo) => {
      formData.append("files", photo);
    });
  }
  let ind = 0;
  _staff.forEach((staffMember) => {
    if (typeof staffMember.imgStaff === "object") {
      staffMember.file = ind;
      ind++;
      formData.append("staffImages", staffMember.imgStaff);
    }
  });

  formData.append("staff", JSON.stringify(_staff));

  // Add the rest of the institute object to the formData
  formData.append("institute", JSON.stringify(institute));
  // console.log("Payload to be sent:", Object.fromEntries(formData.entries()));

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/api/v1/auth/institute/register`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("Error while sending data to the API:", error);
    throw error;
  }
};
