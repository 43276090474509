import { useModalContext } from "Context/ModalContext";
import React, { useEffect, useState, useContext } from "react";
import AgendaContext from "Context/AgendaContext";
import moment, { duration } from "moment";
import {
  HiX,
  HiArrowSmLeft,
  HiOutlineCalendar,
  HiOutlineUserCircle,
  HiOutlineClipboard,
  HiTrash,
} from "react-icons/hi";
import DateView from "./DateView";
import Newclient from "./Newclient";
import SmSpinner from "Component/ui/SmSpinner";

const EditRDV = ({ selectionMode, bodySelector, setSelectionMode, selectedEvent }) => {
  const {
    deletePosting,
    deleteReservation,
    closeModal,
    postLoading,
    getPrestationDuration,
    geteventDuration,
    updateReservation,
    deleteRdv,
    ToggleDate,
    SetToggleDate,
    editBody,
    setEditBody,
    ClientList,
    FetchClient,
    SelectedClient,
    SetSelectedclient,
    SelectedPrestation,
    SetselectedPres,
    handleEditBodySlots,
    slotPicked,
    setslotPicked,
  } = useModalContext();
  const [endTimer, setEndtimer] = useState();
  const [editclient, setEditClient] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [currentTime, setCurrentTime] = useState();
  const [eventTime, setEventTime] = useState();

  ////////////////////////////////////////////Auto Setting event info to the states ///////////////////////////////////

  useEffect(() => {
    SetSelectedclient(selectedEvent.customer);
    SetselectedPres(selectedEvent.prestation);
    // console.log(selectedEvent);
  }, [selectedEvent]);

  useEffect(() => {
    let time = moment(selectedEvent.startTimer);

    let duration = getPrestationDuration(SelectedPrestation.duration);

    let momentTime = time;
    momentTime = moment(momentTime).add(duration, "minutes");

    setEndtimer(momentTime.utcOffset(0).format("HH:mm"));

    SetToggleDate(true);
    setslotPicked("");
    setEditBody({
      ...editBody,
      startTimer: selectedEvent.startTimer,
      endTimer: momentTime.format(),
      slotids: [selectedEvent._id],
      selectedslotid: selectedEvent._id,
      clientid: SelectedClient._id,
      prestationid: SelectedPrestation._id,
    });
  }, [selectedEvent, SelectedPrestation, SelectedClient]);

  ///////////////////////////////////////////Date Comparison Setup ///////////////////////////////////////////////////
  useEffect(() => {
    var ct = new Date();
    var convertTime = moment(ct).format("YYYY-MM-DDTHH:mm:ss");
    var convertTimeObject = new Date(convertTime);

    setCurrentTime(convertTimeObject);

    ct = new Date(selectedEvent.startTimer);
    convertTime = moment(ct).utcOffset(0).format("YYYY-MM-DDTHH:mm:ss");
    convertTimeObject = new Date(convertTime);
    setEventTime(convertTimeObject);
  }, [selectedEvent]);
  useEffect(() => {
    if (
      getPrestationDuration(selectedEvent.prestation.duration) <
      getPrestationDuration(SelectedPrestation.duration)
    ) {
      SetToggleDate(!ToggleDate);
    }
    if (selectedEvent.customer._id !== SelectedClient._id) {
      SetToggleDate(!ToggleDate);
    }

    if (SelectedPrestation) {
      handleEditBodySlots(SelectedPrestation.duration, slotPicked);
    }
  }, [slotPicked, SelectedPrestation, SelectedClient]);
  useEffect(() => {
    if (
      !ToggleDate ||
      selectedEvent.customer._id !== SelectedClient._id ||
      selectedEvent.prestation._id !== SelectedPrestation._id
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [SelectedPrestation, SelectedClient]);

  return (
    <div>
      {(selectionMode && bodySelector) || (
        <div className=" gap-2 flex-col flex">
          {/* Name */}
          <div className="flex justify-between">
            <h1 className="font-syne text-2xl mb-5 font-normal">
              {SelectedPrestation.name}
            </h1>
            <HiX
              onClick={() => closeModal()}
              className="translate-x-5 cursor-pointer -translate-y-9"
              size={25}
            />
          </div>
          {/* time */}
          {!ToggleDate ? (
            <DateView setDisabled={setDisabled} staff={selectedEvent.staff} />
          ) : (
            <div className="modalElement_eventModal bg-black bg-opacity-60 border-[1px] border-[#4A4A49] p-4 flex-col  rounded-md items-center">
              <div className="flex justify-between">
                <div className="flex gap-2 col-span-3">
                  <HiOutlineCalendar size={24} />{" "}
                  <p className="text-xl font-syne ">
                    <span>{selectedEvent.timerPeriod[0] + " - " + endTimer}</span>{" "}
                  </p>
                </div>
                {currentTime < eventTime && (
                  <p
                    className="col-start-4 flex justify-end underline underline-offset-2 cursor-pointer"
                    onClick={() => SetToggleDate(!ToggleDate)}>
                    Modifier
                  </p>
                )}
              </div>
            </div>
          )}

          {/* client */}
          {!editclient ? (
            <div className="modalElement_eventModal bg-black bg-opacity-60 border-[1px] border-[#4A4A49] p-4 rounded-md max-h-[9.3rem] grid grid-cols-4  row-span-2 items-center">
              <div className="flex gap-2 col-span-3">
                <HiOutlineUserCircle size={24} />
                <p className="text-xl">
                  {SelectedClient?.firstName + " " + SelectedClient?.lastName || ""}
                </p>
              </div>
              {currentTime < eventTime && ToggleDate && (
                <p
                  className="col-start-4 flex justify-end underline underline-offset-2 cursor-pointer"
                  onClick={() => setSelectionMode("client")}>
                  Modifier
                </p>
              )}
              <div
                style={{ background: "#252525" }}
                className="p-2 px-4 col-span-4 my-2 rounded-md">
                <div className="flex justify-between">
                  <p>{SelectedClient?.email || ""}</p>
                  {currentTime < eventTime && SelectedClient?.offline && (
                    <p
                      onClick={() => setEditClient(true)}
                      className="col-start-4 flex justify-end underline underline-offset-2 cursor-pointer">
                      Modifier
                    </p>
                  )}
                </div>
                <p>{SelectedClient?.phone || ""}</p>
              </div>
            </div>
          ) : (
            <div className="modalElement_eventModal bg-black bg-opacity-60 border-[1px] border-[#4A4A49] p-4 rounded-md flex  flex-col justify-start  w-[100%]">
              <button
                onClick={() => setEditClient(false)}
                className="mr-8 relative h-10 mx-2 w-10 rounded-full border-2 border-white  hover:text-black hover:bg-white ">
                <HiArrowSmLeft size={40} />
              </button>
              <Newclient
                setEditClient={setEditClient}
                SetSelectedclient={SetSelectedclient}
                SelectedClient={SelectedClient}
              />
            </div>
          )}
          {/* prestation */}
          <div className="modalElement_eventModal bg-black bg-opacity-60 border-[1px] border-[#4A4A49] px-4 py-4 rounded-md flex flex-col gap-2">
            <div className="flex gap-2 justify-between">
              <div className="flex gap-3 items-center">
                <HiOutlineClipboard size={24} /> <p className="text-xl ">Prestation</p>
              </div>
              {currentTime < eventTime && (
                <p
                  onClick={() => {
                    setSelectionMode("prestation");
                  }}
                  className="cursor-pointer text-sm underline  ">
                  Modifier
                </p>
              )}
            </div>
            <div className="bg-[#252525] flex justify-between  p-2">
              <div>
                <p className="text-base">{SelectedPrestation.name}</p>
                <p className="text-[#A29689] text-sm">
                  {+SelectedPrestation.duration?.split(":")[1] +
                    +SelectedPrestation.duration?.split(":")[0] * 60}{" "}
                  mn
                </p>
              </div>
              <div className="flex items-center">
                <p className="-translate-x-2">{SelectedPrestation.price} £</p>
              </div>
            </div>
          </div>

          <div className="flex justify-between mt-3">
            <div className="flex items-center gap-2 text-sand grow text-center ">
              <button
                onClick={() => deleteReservation()}
                className={`underline-offset-2 underline flex justify-center py-5 items-center ${
                  !(currentTime < eventTime)
                    ? "w-full  text-lg text-center text-black rounded-full bg-sand"
                    : ""
                }`}>
                {deletePosting ? <SmSpinner w={5} h={5} /> : <HiTrash size={20} />}
                Supprimer
              </button>
            </div>
            {currentTime && eventTime && currentTime < eventTime && (
              <button
                disabled={disabled}
                onClick={() => {
                  // console.log(editBody);
                  updateReservation();
                }}
                className={`rounded-full bg-sand py-4 px-6 text-black ${
                  disabled ? "opacity-40" : "opacity-100"
                }`}>
                {postLoading && <SmSpinner className="translate-x-1" w={5} h={5} />}{" "}
                Modifier
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EditRDV;
