import React from "react";
import { useNewInstitute } from "Context/InstituteAuthContext";

const PaymentMethod = ({ method, handlePaymentMethod }) => {
  const { newInstitute } = useNewInstitute();
  // set the path of the icon to display
  const iconSrc = `https://bucket-stunify-app.s3.eu-north-1.amazonaws.com/static/assets/paymentMethod/${method}.svg`;
  return (
    <div className="flex flex-row justify-start items-center gap-6 ">
      <label
        htmlFor={method}
        className="relative inline-flex items-center mr-0 cursor-pointer">
        <input
          id={method}
          type="checkbox"
          checked={newInstitute.payementMethode.includes(method)}
          className="sr-only peer"
          name={method}
          onChange={(e) => {
            handlePaymentMethod(e);
          }}
        />
        <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-400"></div>
        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
      </label>
      <div className="flex sm:flex-row items-center flex-col gap-2 sm:gap-4">
        <img className={`${method === 'postfinance'? '' : 'bg-white' } rounded-lg w-20 h-10`}  src={iconSrc} alt={`${method}_logo`} width={84} height={58} />
        <p className="max-[360px]:hidden xs:max-sm:text-sm">{method.toUpperCase()}</p>
      </div>
    </div>
  );
};

export default PaymentMethod;
