import React, { useState, useEffect } from "react";
import PhoneNumberInput from "./PhoneNumberInput";
import Adresse from "../institute-registration/Adresse";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useNewInstitute } from "Context/InstituteAuthContext";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

//icons
import { HiOutlineEyeSlash, HiOutlineEye } from "react-icons/hi2";

const InstituteInfoCreateAnAccount = ({ handleStepChange }) => {
  const [inputType, setInputType] = useState("password");
  const { newInstitute, setNewInstitute } = useNewInstitute();
  const [isPossiblePhoneNumber, setIsPossiblePhoneNumber] = useState(true);
  const [checking, setChecking] = useState(false);

  const isAuthenticated = !!Cookies.get("authToken");
  const location = useLocation();

  if (isAuthenticated) {
    const decoded = jwt_decode(Cookies.get("authToken"));

    if (decoded) {
      if (decoded.isPro) {
        return <Navigate to="/ProConnected/caisseDash" state={{ from: location }} />;
      }
    }
  }

  //fetch get insitute category
  const instituteCategories = [
    "coiffeur",
    "esthetique",
    "massage",
    "onglerie",
    "barbier",
    "tatoueur",
    "piercing",
    "spa",
    "maquillage",
  ];

  // Password visibility
  const handlePaswordVisibility = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  const handleSubmitInfo = (e) => {
    e.preventDefault();
    if (!checkFormValidity()) return;
    handleStepChange(e, "next");
  };

  const checkFormValidity = () => {
    const emptyFields = [];
    if (!newInstitute.name) {
      blink(document.getElementById("institute_name"));
      emptyFields.push("Nom");
    }
    if (!newInstitute.phoneNumber) {
      blink(document.getElementById("telephone"));
      emptyFields.push("Telephone");
    }
    if (!newInstitute.type) {
      blink(document.getElementById("institute_type"));
      emptyFields.push("Type d'établissement");
    }

    if (!newInstitute.email) {
      blink(document.getElementById("institute_email"));
      emptyFields.push("Email");
    }
    if (!newInstitute.address) {
      blink(document.getElementById("address_found_innerText"));
      emptyFields.push("Address");
    }
    if (!newInstitute.password) {
      blink(document.getElementById("institute_password"));
      emptyFields.push("Mot de passe");
    }
    if (!newInstitute.password_confirmation) {
      blink(document.getElementById("institute_password_confirmation"));
      emptyFields.push("Confirmation du mot de passe");
    }
    if (newInstitute.password !== newInstitute.password_confirmation) {
      blink(document.getElementById("institute_password"));
      blink(document.getElementById("institute_password_confirmation"));
      emptyFields.push("Les mots de passe ne correspondent pas");
    }
    if (emptyFields.length > 0) {
      return false;
    } else return true;
  };

  const blink = (element) => {
    // console.log("blink on:" + element.id);
    element.className += " blink";
    // console.log(element.className);

    setTimeout(function () {
      element.className = element.className.replace(" blink", "");
      // console.log(element.className);
    }, 2000);
  };

  return (
    <>
      {newInstitute && (
        <section className="w-full">
          <h1 className="font-syne text-2xl mt-8 font-bold text-white">
            Créer un compte
          </h1>
          <form
            action=""
            onSubmit={handleSubmitInfo}
            className="flex flex-col w-full mt-6">
            <div
              className="flex flex-col lg:flex-row justify-between gap-6"
              id="accountInfoContainer">
              <div className="flex-grow flex flex-col gap-4">
                {/* COORDONNEES DU SALON */}
                <div className="rounded-md border dark:border-dark_grey p-6 flex flex-col gap-4 bg-black">
                  {/* Name an type of the Institute */}
                  <div className="grid sm:grid-cols-2 xs:max-sm:grid-rows-2 gap-4">
                    {/* Nom de l'établissement */}
                    <label
                      htmlFor="institute_name"
                      className="text-sm font-roboto text-beige">
                      Nom de l'établissement
                      <input
                        type="text"
                        value={newInstitute.name}
                        id="institute_name"
                        placeholder="Nom de votre établissement"
                        onChange={(e) =>
                          setNewInstitute({
                            ...newInstitute,
                            name: e.target.value,
                          })
                        }
                        className="input rounded-md placeholder:text-beige xs:max-sm:placeholder:text-xs whiteBorder"
                      />
                    </label>
                    <label
                      htmlFor="institute_type"
                      className="text-sm font-roboto text-beige">
                      Type d'établissement
                      <select
                        type="text"
                        value={newInstitute.type}
                        id="institute_type"
                        onChange={(e) =>
                          setNewInstitute({
                            ...newInstitute,
                            type: e.target.value,
                          })
                        }
                        className="input rounded-md appearance-none xs:max-sm:text-xs whiteBorder">
                        <option disabled value="" hidden>
                          Type d'établissement
                        </option>
                        {instituteCategories.map((category, index) => (
                          <option key={"cat_" + index} value={category}>
                            {category}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                  {/* Adresse */}
                  <Adresse setIsPossiblePhoneNumber={setIsPossiblePhoneNumber} />

                  {/* email and phoneNumber */}
                  <div className="grid sm:grid-cols-2 xs:max-sm:grid-rows-2  sm:gap-4 flex-wrap w-full">
                    <label
                      htmlFor="institute_phoneNumber"
                      className="xs:max-sm:w-full text-sm text-beige font-roboto grow ">
                      Numéro de téléphone
                      <PhoneNumberInput
                        id="institute_phoneNumber"
                        checking={checking}
                        setIsPossiblePhoneNumber={setIsPossiblePhoneNumber}
                      />
                    </label>
                    <label
                      htmlFor="institute_email"
                      className="grow text-sm font-roboto text-beige xs:max-sm:mt-2">
                      Email
                      <input
                        type="email"
                        id="institute_email"
                        placeholder="email de l'établissement"
                        onChange={(e) =>
                          setNewInstitute({
                            ...newInstitute,
                            email: e.target.value,
                          })
                        }
                        className="input rounded-md check-validity placeholder:text-beige whiteBorder"
                      />
                    </label>
                  </div>
                  {/* Password */}
                  <div className="grid sm:grid-cols-2 xs:max-sm:grid-rows-2 gap-4 select-none">
                    <label
                      htmlFor="institute_password"
                      className="relative flex-grow sm:basis-1/2 text-sm font-roboto text-beige">
                      Mot de passe
                      {/* Show Password onClick */}
                      {inputType === "password" ? (
                        <HiOutlineEye
                          className="w-4 h-4 absolute top-[32px] right-6 z-0"
                          onClick={handlePaswordVisibility}
                        />
                      ) : (
                        <HiOutlineEyeSlash
                          className="w-4 h-4 absolute top-[32px] right-6 z-0"
                          onClick={handlePaswordVisibility}
                        />
                      )}
                      <input
                        placeholder="Mot de passe"
                        className="check-validity outline-none input rounded-md border border-dark_grey xs:max-sm:placeholder:text-xs placeholder:font-light placeholder:text-beige w-full whiteBorder"
                        type={inputType}
                        name="password"
                        id="institute_password"
                        value={newInstitute.password}
                        pattern="^(?=.*[A-Za-z])(?=.*\d).{8,}$"
                        onChange={(e) =>
                          setNewInstitute((institute) => {
                            return {
                              ...newInstitute,
                              password: e.target.value,
                            };
                          })
                        }
                      />
                      <span className="text-xs text-taupe ">
                        Au moins 8 caractères et un chiffre
                      </span>
                    </label>
                    <label
                      htmlFor="institute_password_confirmation"
                      className="relative flex-grow sm:basis-1/2 text-sm font-roboto text-beige">
                      <span className="whitespace-nowrap">
                        Confirmation du mot de passe
                      </span>
                      {inputType === "password" ? (
                        <HiOutlineEye
                          className="w-4 h-4 absolute top-[32px] right-6 z-0"
                          onClick={handlePaswordVisibility}
                        />
                      ) : (
                        <HiOutlineEyeSlash
                          className="w-4 h-4 absolute top-[32px] right-6 z-0"
                          onClick={handlePaswordVisibility}
                        />
                      )}
                      <input
                        placeholder="Confirmation du mot de passe"
                        className="check-validity outline-none input rounded-md border border-dark_grey xs:max-sm:placeholder:text-xs placeholder:font-light placeholder:text-beige w-full whiteBorder"
                        type={inputType}
                        value={newInstitute.password_confirmation}
                        name="password_confirmation"
                        id="institute_password_confirmation"
                        pattern={newInstitute.password}
                        onChange={(e) =>
                          setNewInstitute((institute) => {
                            return {
                              ...newInstitute,
                              password_confirmation: e.target.value,
                            };
                          })
                        }
                      />
                    </label>
                  </div>
                </div>
                {/* CLIENTELE */}
                {/* DESCRIPTION */}
              </div>

              {/* RIGHT SIDE  HORAIRES DU SALON*/}
            </div>

            <div className="flex flex-row justify-between my-6">
              <Link
                to="/pro"
                className="text-beige btn-outline border-beige rounded-3xl w-fit flex flex-row justify-center items-center px-3 gap-1">
                {/* arrow right */}
                <span>Annuler</span>
              </Link>
              <button
                onClick={() => {
                  setChecking(true);
                }}
                onSubmit={() => {
                  handleSubmitInfo();
                }}
                className={`btn-light dark:bg-beige border border-dark_grey self-end w-fit flex flex-row gap-1 items-center dark:text-black opacity-100`}>
                <span>Valider</span>
                {/* arrow right */}
              </button>
            </div>
          </form>
        </section>
      )}
    </>
  );
};

export default InstituteInfoCreateAnAccount;
