import React from "react";
import { HiOutlineBanknotes } from "react-icons/hi2";

function Widget({
  month,
  year,
  totalToday,
  totalMonth,
  totalYear,
  totalTodayClients,
  totalMonthClients,
  totalYearClients,
}) {
  return (
    <div className="flex flex-col lg:flex-row gap-4 justify-center items-center w-full mb-2">
      <div className="lg:w-1/3 w-full flex flex-row items-center p-3 h-20 bg-[#2D2E2F]  rounded-[10px]">
        <div className="flex flex-col lg:w-[75%]  w-full justify-center ">
          <div className="flex flex-row  justify-between items-end font-roboto ">
            <HiOutlineBanknotes size={30} />

            <div className="flex flex-col items-center justify-around ">
              <p className="  text-xs not-italic font-normal leading-[normal] mb-2  whitespace-nowrap">
                Aujoud’hui
              </p>
              <p className=" text-xl not-italic font-bold leading-[19px]  whitespace-nowrap">
                {totalTodayClients} Clients
              </p>
            </div>
            <p className=" text-xl not-italic font-bold leading-[19px]  whitespace-nowrap ">
              {totalToday} CHF
            </p>
          </div>
        </div>
      </div>
      <div className="lg:w-1/3 w-full flex flex-row items-center p-3 h-20 bg-[#2D2E2F]  rounded-[10px]">
        <div className="flex flex-col lg:w-[75%]  w-full justify-center ">
          <div className="flex flex-row  justify-between items-end font-roboto ">
            <HiOutlineBanknotes size={30} />

            <div className="flex flex-col items-center  justify-around">
              <p className="  text-xs not-italic font-normal leading-[normal] mb-2  whitespace-nowrap">
                Total {month} {year}
              </p>
              <p className=" text-xl not-italic font-bold leading-[19px]  whitespace-nowrap">
                {totalMonthClients} Clients
              </p>
            </div>
            <p className=" text-xl not-italic font-bold leading-[19px]  whitespace-nowrap ">
              {totalMonth} CHF
            </p>
          </div>
        </div>
      </div>
      <div className="lg:w-1/3 w-full flex flex-row items-center p-3 h-20 bg-[#2D2E2F]  rounded-[10px]">
        <div className="flex flex-col lg:w-[75%]  w-full justify-center ">
          <div className="flex flex-row  justify-between items-end font-roboto ">
            <HiOutlineBanknotes size={30} />

            <div className="flex flex-col items-center  justify-around">
              <p className="  text-xs not-italic font-normal leading-[normal] mb-2  whitespace-nowrap">
                Total {year}
              </p>
              <p className=" text-xl not-italic font-bold leading-[19px]  whitespace-nowrap">
                {totalYearClients} Clients
              </p>
            </div>
            <p className=" text-xl not-italic font-bold leading-[19px]  whitespace-nowrap ">
              {totalYear} CHF
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Widget;
