import React, { useState, useEffect } from "react";
import mapTheme from "styles/mapStyle.json";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { GrMap } from "react-icons/gr";
import { Spinner } from "@material-tailwind/react";

const MapGoogle = ({ markers, selectedCard, setSelectedCard, middle }) => {
  let locations = [...markers];
  const [selectedMarkerAnimation, setSelectedMarkerAnimation] = useState(null);
  const [selectedMarkerOpacity, setSelectedMarkerOpacity] = useState(null);
  const [loadFailed, setLoadFailed] = useState(false);
  // Calculate the middle point of an array of locations to set the map center
  const calculateMiddlePoint = (locations) => {
    if (locations.length === 0) {
      return null; // No locations to calculate
    }

    let totalLat = 0;
    let totalLng = 0;

    for (const location of locations) {
      totalLat += location.lat;
      totalLng += location.lng;
    }

    const averageLat = totalLat / locations.length;
    const averageLng = totalLng / locations.length;

    return {
      lat: averageLat,
      lng: averageLng,
    };
  };

  const middlePoint =
    middle?.length === 1 ? calculateMiddlePoint(middle) : calculateMiddlePoint(locations);

  // Set the selected marker animation to null after a delay
  useEffect(() => {
    if (selectedMarkerAnimation !== null) {
      const timeout = setTimeout(() => {
        setSelectedMarkerAnimation(null); // Reset the selected marker after a delay
      }, 1000); // Delay in milliseconds (adjust as needed)

      return () => clearTimeout(timeout);
    }
  }, [selectedMarkerAnimation]);

  //Animate Marker when a card is selected
  useEffect(() => {
    setSelectedMarkerAnimation(selectedCard);
    setSelectedMarkerOpacity(selectedCard);
  }, [selectedCard]);

  //Check if locations array is populated
  useEffect(() => {
    if (locations[0]?.lat === undefined || locations[0]?.lng === undefined) {
      setLoadFailed(true);
    } else setLoadFailed(false);
  }, [markers, locations]);

  // Set the selected marker style and animation when a marker is clicked
  const handleMarkerClick = (address) => {
    if (selectedCard) {
      setSelectedMarkerAnimation(address);
      setSelectedMarkerOpacity(address);
      setSelectedCard(address);
    } else return;
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });
  if (loadError) return console.log("Error loading maps");
  if (!isLoaded) return "Loading Maps";
  window.gm_authFailure = function () {
    setLoadFailed(true);
  };
  return (
    <>
      {!loadFailed ? (
        <GoogleMap
          center={middlePoint}
          zoom={12}
          mapContainerClassName="w-full h-full "
          options={{ styles: mapTheme }}>
          {locations?.map(({ lat, lng, address }, index) => (
            <MarkerF
              key={index + "_" + address}
              position={{ lat: lat, lng: lng }}
              options={{
                opacity: selectedMarkerOpacity === address ? 1 : 0.7,
                clickable: true,
                icon: {
                  url: "https://bucket-stunify-app.s3.eu-north-1.amazonaws.com/static/assets/logo2.png",
                  scaledSize: { width: 30, height: 30 },
                },
              }}
              onClick={() => handleMarkerClick(address)}
              animation={selectedMarkerAnimation === address ? 4 : null}
            />
          ))}
        </GoogleMap>
      ) : locations.length <= 0 ? (
        <div className="p-4 gap-3 border bg-white/30 w-full h-full flex flex-col justify-center items-center">
          <GrMap className="w-20 h-20" />
          <p className="font-syne font-bold">aucune donnée disponible</p>
        </div>
      ) : (
        <div className="p-4 gap-3 border bg-white/30 w-full h-full flex flex-col justify-center items-center">
          <GrMap className="w-20 h-20" /> <Spinner />
        </div>
      )}
    </>
  );
};

export default MapGoogle;
